import { Plugin } from "@nuxt/types";
import moment from "moment";

const plugin: Plugin = ({ app }) => {
  // beforeLanguageSwitch called right before setting a new locale
  app.i18n.beforeLanguageSwitch = (/* oldLocale, newLocale */) => {};
  // onLanguageSwitched called right after a new locale has been set
  app.i18n.onLanguageSwitched = (_oldLocale, newLocale) => {
    moment.locale(newLocale);
  };
};

export default plugin;
