var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.removeQuestionsAboutConverting)?[_c('CommonQuestion',{scopedSlots:_vm._u([{key:"question",fn:function(){return [_vm._v(_vm._s(_vm.$t("Finns det möjlighet att prova Gritify gratis först?")))]},proxy:true}],null,false,3234115625),model:{value:(_vm.question1Open),callback:function ($$v) {_vm.question1Open=$$v},expression:"question1Open"}},[_vm._v("\n      "+_vm._s(_vm.$t(
          "Självklart! Välj Explore for free ovan för att skapa ett konto och lära känna Gritify helt kostnadsfritt. Inget betalkort eller liknande krävs och du kommer i gång direkt."
        ))+"\n    ")]),_vm._v(" "),_c('v-divider')]:_vm._e(),_vm._v(" "),_c('CommonQuestion',{scopedSlots:_vm._u([{key:"question",fn:function(){return [_vm._v("\n      "+_vm._s(_vm.$t("Hur vet man vad en anställning kommer att kosta?")))]},proxy:true}]),model:{value:(_vm.question2Open),callback:function ($$v) {_vm.question2Open=$$v},expression:"question2Open"}},[_vm._v("\n    "+_vm._s(_vm.$t(
        "Alla priser är helt transparenta och du ser tydligt i Gritify vad priset vid anställning är när du startar ett jobb. När du startar ett jobb låser du även in det priset du ser och kan således känna dig trygg med vad kostnaden vid anställning kommer att bli."
      ))+"\n  ")]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('CommonQuestion',{scopedSlots:_vm._u([{key:"question",fn:function(){return [_vm._v("\n      "+_vm._s(_vm.$t("Varför varier priset per anställning för Access & Succes?")))]},proxy:true}]),model:{value:(_vm.question3Open),callback:function ($$v) {_vm.question3Open=$$v},expression:"question3Open"}},[_vm._v("\n    "+_vm._s(_vm.$t(
        "Priset per anställning beror på utbud och efterfrågan på marknaden. Priserna varierar därför över tid, mellan olika orter och för olika roller. Det betyder att vissa jobb till och med kan vara helt kostnadsfria."
      ))+"\n  ")]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('CommonQuestion',{scopedSlots:_vm._u([{key:"question",fn:function(){return [_vm._v("\n      "+_vm._s(_vm.$t(
          "Kan man börja med en prisplan och uppgradera sitt konto vid ett senare tillfälle?"
        )))]},proxy:true}]),model:{value:(_vm.question4Open),callback:function ($$v) {_vm.question4Open=$$v},expression:"question4Open"}},[_vm._v("\n    "+_vm._s(_vm.$t(
        "Givetvis. Ni kan själva uppgradera ert konto från Explore for free till Access & Succes när ni vill från ert konto. I det fall ni är intresserad av Fixed rate, oavsett vilken prisplan ni har innan, så hör ni av er till oss för att få ett prisförslag."
      ))+"\n  ")]),_vm._v(" "),(!_vm.removeQuestionsAboutConverting)?[_c('v-divider'),_vm._v(" "),_c('CommonQuestion',{scopedSlots:_vm._u([{key:"question",fn:function(){return [_vm._v("\n        "+_vm._s(_vm.$t("Vad krävs för att komma igång med Gritify?")))]},proxy:true}],null,false,2676197524),model:{value:(_vm.question5Open),callback:function ($$v) {_vm.question5Open=$$v},expression:"question5Open"}},[_vm._v("\n      "+_vm._s(_vm.$t(
          "Ingenting egentligen. Ni får svara på ett antal frågor samtidigt som Gritify läser in en massa offentligt tillgänglig information om ert företag. Detta används sedan för att med generativ AI och Gritifys kunskap om det aktuella jobbet automatiskt skapa allt som behövs för att komma igång."
        ))+"\n    ")])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }