import FilterHelpers from "./filterHelpers";
import { JobbOfferVisualForView } from "~/models/types";

export const getJobOfferIsEnded = (jo: JobbOfferVisualForView): boolean => {
  return (
    (FilterHelpers.hasDeclined(jo) && !!jo.invited) ||
    (!!jo.removed && !!jo.lastRead && jo.lastRead > jo.removed)
  );
};
export const getJobOfferIsUnanswered = (
  jo: JobbOfferVisualForView
): boolean => {
  return (
    !FilterHelpers.hasDeclined(jo) &&
    !jo.removed &&
    !jo.accepted &&
    !!jo.invited
  );
};
export const getJobOfferOngoing = (jo: JobbOfferVisualForView): boolean => {
  return (
    !FilterHelpers.hasDeclined(jo) &&
    !jo.removed &&
    !!jo.accepted &&
    !!jo.invited
  );
};

export const getJobOfferIsProcessHistory = (
  jo: JobbOfferVisualForView
): boolean => {
  return (
    !!jo.accepted &&
    !!jo.invited &&
    // (FilterHelpers.hasDeclined(jo) || !!jo.removed) &&
    true
  );
};

const isAnswered = (jo: JobbOfferVisualForView) =>
  !!jo.accepted || !!jo.declined;

export const isRequestHistoryItem = (jo: JobbOfferVisualForView) =>
  !!jo.invited && isAnswered(jo);
