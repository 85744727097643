<i18n>
{
  "en": {
    "Denna sida finns inte tyvärr": "Sorry, this page does not exist",
    "Gå till startsidan": "Go to the start page",
    "Cookies tillåts inte i din webbläsare. Aktivera cookies i webbläsarens inställningar för att fortsätta.": "Cookies are not allowed in your browser. Enable cookies in your browser settings to continue.", 
    "Cookies krävs": "Cookies are required",
    "Försök igen senare!": "Please try again later!",
    "Oops! Nånting gick fel när sidan skulle laddas": "Oops! Something went wrong while loading the page"
  },
  "sv": {
    "Denna sida finns inte tyvärr": "Denna sida finns inte tyvärr",
    "Gå till startsidan": "Gå till startsidan",
    "Cookies tillåts inte i din webbläsare. Aktivera cookies i webbläsarens inställningar för att fortsätta.": "Cookies tillåts inte i din webbläsare. Aktivera cookies i webbläsarens inställningar för att fortsätta.", 
    "Cookies krävs": "Cookies krävs",
    "Försök igen senare!": "Försök igen senare!",
    "Oops! Nånting gick fel när sidan skulle laddas": "Oops! Nånting gick fel när sidan skulle laddas"
  }
}
</i18n>
<template>
  <div v-if="error.statusCode === 666">
    <v-overlay color="white">
      <v-progress-circular indeterminate color="primary" size="64" />
    </v-overlay>
  </div>
  <v-container v-else-if="error.statusCode === 404" class="text-center">
    <div class="my-12">
      <GrHeadline type="mega">404</GrHeadline>
      <br />
      <br />
      <GrHeadline type="large">
        {{ $t("Denna sida finns inte tyvärr") }}
      </GrHeadline>
    </div>
    <br />
    <v-btn
      text
      :to="
        localePath({
          name: 'index',
        })
      "
    >
      {{ $t("Gå till startsidan") }}
    </v-btn>
  </v-container>
  <v-container v-else-if="error.statusCode === 667" class="text-center">
    <div class="my-12">
      <GrHeadline type="mega">{{ $t("Cookies krävs") }}</GrHeadline>
      <br />
      <v-icon>mdi-cookie</v-icon>
      <br />
      <GrTextBlock type="large">
        {{
          $t(
            "Cookies tillåts inte i din webbläsare. Aktivera cookies i webbläsarens inställningar för att fortsätta."
          )
        }}
      </GrTextBlock>
    </div>
    <br />
    <v-btn
      text
      :to="
        localePath({
          name: 'index',
        })
      "
    >
      {{ $t("Gå till startsidan") }}
    </v-btn>
  </v-container>
  <v-container v-else class="text-center">
    <div class="my-12">
      <GrHeadline class="primary--text" type="mega">=(</GrHeadline>
      <br />
      <br />
      <GrHeadline class="error--text" type="medium">
        {{ $t("Oops! Nånting gick fel när sidan skulle laddas") }}
        <br />
        {{ $t("Försök igen senare!") }}
      </GrHeadline>
    </div>
    <br />
    <v-btn
      text
      :to="
        localePath({
          name: 'index',
        })
      "
    >
      {{ $t("Gå till startsidan") }}
    </v-btn>
  </v-container>
</template>

<script>
export default {
  // layout: "empty",
  props: {
    error: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      pageNotFound: "404 Sidan finns inte än",
      otherError: "An error occurred",
    };
  },
  head() {
    const title =
      this.error.statusCode === 666
        ? ""
        : this.error.statusCode === 404
        ? this.pageNotFound
        : this.otherError;
    return {
      title,
    };
  },
  mounted() {
    if (this.error) {
      if (
        this.error.statusCode === 500 &&
        /^Loading chunk [0-9]+ failed/.test(this.error.message) &&
        window.location.hash !== "#retry"
      ) {
        // the chunk might no longer be available due to a recent redeployment of the page
        // mark the page to don't trigger reload infinitely
        window.location.hash = "#retry";
        window.location.reload(true);
      }
    }
  },
};
</script>

<style scoped>
h1 {
  font-size: 20px;
}
</style>
