




















































import { Vue, Component } from "nuxt-property-decorator";
import GrPublicNavBar from "~/components/common/GrPublicNavBar.vue";
import Confirm from "~/components/Confirm.vue";
import IsOnIEModal from "~/components/IsOnIEModal.vue";
import CommonHelpers from "~/helpers/CommonHelpers";
import CrispHelper from "~/helpers/CrispHelper";

@Component({
  components: {
    Confirm,
    IsOnIEModal,
    GrPublicNavBar,
  },
  middleware: [],
})
export default class NewDefaultLayout extends Vue {
  phoneMenuOpen = false;

  get rootUrl(): string {
    return CommonHelpers.getBaseUrl();
  }

  get imageUrl() {
    return "/og-gritify-2.png";
  }

  cookiesAreAccepted() {
    CrispHelper.showChat();
  }

  head() {
    return {
      meta: [
        {
          property: "og:image",
          content: `${this.rootUrl}${this.imageUrl}`,
        },
        {
          name: "twitter:image",
          content: `${this.rootUrl}${this.imageUrl}`,
        },
        {
          "http-equiv": "content-language",
          content: this.$i18n.locale === "en" ? "en-US" : "sv-SE",
        },
      ],
    };
  }

  get isIe() {
    if (!process.client) {
      return false;
    }
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");
    const msie11 = ua.indexOf("Trident/");
    const isIE = msie > 0 || msie11 > 0;
    return isIE;
  }

  $refs!: Vue["$refs"] & {
    confirm: Confirm;
    IsOnIEModal?: IsOnIEModal;
  };

  mounted() {
    // CrispHelper.hideChat();
    (this.$root as any).$confirm = this.$refs.confirm.open;

    if (this.isIe) {
      this.$refs.IsOnIEModal?.open();
    }
  }
}
