/* eslint-disable import/no-mutable-exports */
import VueI18n, { IVueI18n } from "vue-i18n";

let $i18n: VueI18n & IVueI18n;

export function initializei18n(i18nCompany: VueI18n & IVueI18n) {
  $i18n = i18nCompany;
}

export { $i18n };
