import { Middleware } from "@nuxt/types";
import { authStore, companyBaseStore, jobbOfferStore } from "~/store";
import { $i18n } from "~/utils/i18n";

// eslint-disable-next-line space-before-function-paren
const myMiddleware: Middleware = async ({ app, error, redirect, route }) => {
  if (!navigator.cookieEnabled) {
    error({
      message: "Slå på cookies!",
      statusCode: 667,
    });
    return;
  }

  await app.$msal.loadAuthModule();

  const token = await app.$msal.acquireToken();
  if (typeof token === "string") {
    authStore.SET_ACCESS_TOKEN(token);
    const queryCompanyId = route.params.companyId;
    if (
      typeof queryCompanyId === "string" &&
      !!authStore.companyId &&
      authStore.companyId !== queryCompanyId
    ) {
      app.$msal.acquireTokenRedirect({
        scopes: [
          process.env.ENVIRONMENT === "Production"
            ? "https://gritifyauth.onmicrosoft.com/api/gritify"
            : process.env.ENVIRONMENT === "Stage"
            ? "https://gritifyauthstage.onmicrosoft.com/api/gritify"
            : "https://gritifytalentauthtest.onmicrosoft.com/api/gritify",
        ],
        extraQueryParameters: {
          policy_type: "Login",
          ui_locales: $i18n.locale,
          companyId: queryCompanyId,
        },
      });

      error({
        statusCode: 666,
        message: "du måste vara inloggad",
      });
    } else if (!authStore.tokenClaims.companyId) {
      redirect(
        `${app.i18n.locale === "sv" ? "" : "/" + app.i18n.locale}/talent`
      );
    } else {
      companyBaseStore.connectSignalR({
        lang: app.i18n.locale,
      });
      await companyBaseStore.loadUserDetails();
      await companyBaseStore.loadNumberOfUnseenNotifications({ force: false });
      await companyBaseStore.loadCompanyDetails();
      await jobbOfferStore.loadCompanyTeamMembers();
      await jobbOfferStore.loadExternalFormDatas();
      await companyBaseStore.loadTalentConversationsUnreadCount({
        force: false,
      });
      companyBaseStore.loadCompanyTalents();
      const showOnboardingStepper =
        companyBaseStore.companyDetailsObject?.showNewCompanyStepper ?? false;

      if (!showOnboardingStepper) {
        if (!companyBaseStore.userDetails.firstName) {
          const redirectUrl = `${
            app.i18n.locale === "sv" ? "" : "/" + app.i18n.locale
          }/company/${authStore.companyId}/account`;
          if (route.fullPath !== redirectUrl) {
            redirect(redirectUrl);
          }
        }
      }
    }
  } else {
    const token = route.query.token;
    if (token) {
      app.$msal.signIn({
        scopes: [
          process.env.ENVIRONMENT === "Production"
            ? "https://gritifyauth.onmicrosoft.com/api/gritify"
            : process.env.ENVIRONMENT === "Stage"
            ? "https://gritifyauthstage.onmicrosoft.com/api/gritify"
            : "https://gritifytalentauthtest.onmicrosoft.com/api/gritify",
        ],
        extraQueryParameters: {
          client_assertion: <string>token,
          client_assertion_type:
            "urn:ietf:params:oauth:client-assertion-type:jwt-bearer",
          policy_type: "SignUp",
          user_type: "2",
          ui_locales: app.i18n.locale,
        },
      });
    } else {
      app.$msal.signIn({
        scopes: [
          process.env.ENVIRONMENT === "Production"
            ? "https://gritifyauth.onmicrosoft.com/api/gritify"
            : process.env.ENVIRONMENT === "Stage"
            ? "https://gritifyauthstage.onmicrosoft.com/api/gritify"
            : "https://gritifytalentauthtest.onmicrosoft.com/api/gritify",
        ],
        extraQueryParameters: {
          policy_type: "Login",
          ui_locales: app.i18n.locale,
        },
      });
    }

    error({
      statusCode: 666,
      message: "du måste vara inloggad",
    });
  }
};

export default myMiddleware;
