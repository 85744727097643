import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import {
  BusinessAreasType,
  CompanyProfileDto,
  ExternalTalentExperience,
  ExternalTalentFilter,
  ExternalTalentProfileDto,
  ExternalTalentProfileSaved,
  ExternalTalentProfileToAdd,
  TalentJobOffer,
  TalentQuestionSection,
} from "~/models/types";
import LookupDataService from "~/services/lookupDataService";
import { externalTalentsStore } from "~/utils/store-accessor";
import jobOfferService from "~/services/jobOfferService";
import TokenHelpers from "~/helpers/tokenHelpers";

const defaultExperience: ExternalTalentExperience = {
  branchIds: [],
  education: [],
  employments: [],
  languageIds: [],
  hasNoEducation: false,
  hasCostResponsibility: false,
  hasProjectResponsibility: false,
  hasStaffResponsibility: false,
  costResonsibilityNumber: null,
  costResonsibilityCurrancy: "SEK",
  projectResonsibilityNumber: null,
  projectResonsibilityCurrancy: "SEK",
  staffResonsibilityInPersons: null,
};

@Module({
  name: "externalTalents",
  stateFactory: true,
  namespaced: true,
})
export default class ExternalTalents extends VuexModule {
  defaultExperience = defaultExperience;

  externalLinkData: { recruitmentId: string; companyId: string } | null = null;

  showFilters = false;

  filters: { [key: string]: ExternalTalentFilter[] } = {};

  @Mutation
  SET_FILTERS(v: { recruitmentId: string; filters: ExternalTalentFilter[] }) {
    this.filters = {
      ...this.filters,
      [v.recruitmentId]: v.filters,
    };
  }

  @Mutation
  SET_SHOW_FILTERS(v: boolean) {
    this.showFilters = v;
  }

  @Mutation
  SET_EXTERNAL_LINK_DATA(
    v: { recruitmentId: string; companyId: string } | null
  ) {
    this.externalLinkData = v;
  }

  @Action
  async loadExternalLinkData(v: { externalLinkId: string }) {
    const data = await LookupDataService.getInfoFromExternalFormLink({
      linkPart: v.externalLinkId,
    });

    externalTalentsStore.SET_EXTERNAL_LINK_DATA(data);
  }

  talentProfile: ExternalTalentProfileDto = {
    firstName: null,
    lastName: null,
    taskLang: "en",
    isEmployeeToday: false,
    experience: defaultExperience,
    tasksAndSkills: {
      roles: [],
      skills: [],
      hiddenRoleIds: [],
    },
    contactEmail: null,
    phoneNumber: null,
  };

  showNamesPerRecruitment: { [key: string]: boolean } = {};

  @Mutation
  SET_SHOW_NAMES(v: { recruitmentId: string; showNames: boolean }) {
    this.showNamesPerRecruitment = {
      ...this.showNamesPerRecruitment,
      [v.recruitmentId]: v.showNames,
    };
  }

  talentsInternal: ExternalTalentProfileSaved[] = [];

  get talentsPerRecruitment(): { [key: string]: ExternalTalentProfileSaved[] } {
    return this.talentsInternal.reduce(
      (acc: { [key: string]: ExternalTalentProfileSaved[] }, t) => {
        const arr = acc[t.recruitmentId] ?? [];
        const showNames =
          this.showNamesPerRecruitment[t.recruitmentId] ?? false;
        acc[t.recruitmentId] = [
          ...arr,
          showNames
            ? t
            : {
                ...t,
                firstName: "Anonym",
                lastName: (arr.length + 1).toString(),
                contactEmail: "",
                phoneNumber: "",
              },
        ];
        return acc;
      },
      {}
    );
  }

  jobOffer: TalentJobOffer | null = null;
  companyProfile: CompanyProfileDto | null = null;
  talentsLoaded: string[] = [];

  talentQuestionSections: TalentQuestionSection[] = [];

  @Mutation
  SET_TALENT_PROFILE(v: ExternalTalentProfileDto) {
    this.talentProfile = v;
  }

  @Mutation
  SET_TALENT_QUESTION_SECTIONS(v: TalentQuestionSection[]) {
    this.talentQuestionSections = v;
  }

  @Action
  async AddTalentProfile(v: { talentProfile: ExternalTalentProfileToAdd }) {
    await LookupDataService.upsertExternalTalentProfile({
      talentProfile: v.talentProfile,
    });
  }

  @Action
  async loadTalentQuestionSections(v: {
    recruitmentId: string;
    companyId: string;
  }) {
    const result = await LookupDataService.getExternalTalentQuestions({
      recruitmentId: v.recruitmentId,
    });

    externalTalentsStore.SET_SECTION_HISTORY([]);

    externalTalentsStore.SET_TALENT_QUESTION_SECTIONS(
      result?.questionSections ?? []
    );
  }

  sectionHistory: string[] = [];

  @Mutation
  SET_SECTION_HISTORY(v: string[]) {
    this.sectionHistory = v;
  }

  @Mutation
  ADD_TO_SECTION_HISTORY(v: string) {
    this.sectionHistory.unshift(v);
  }

  @Mutation
  REMOVE_FROM_SECTION_HISTORY() {
    this.sectionHistory.shift();
  }

  @Mutation
  SET_EXTERNAL_TALENTS(v: ExternalTalentProfileSaved[]) {
    this.talentsInternal = v;
  }

  @Mutation
  SET_JOB_OFFER(v: TalentJobOffer) {
    this.jobOffer = v;
  }

  @Mutation
  SET_TALENTS_LOADED(v: string[]) {
    this.talentsLoaded = v;
  }

  @Mutation
  SET_COMPANY_PROFILE(v: CompanyProfileDto) {
    this.companyProfile = v;
  }

  @Action
  async loadJobOffer(v: { recruitmentId: string; companyId: string }) {
    const jobOffer = await LookupDataService.getExternalJobOffer(v);

    externalTalentsStore.SET_JOB_OFFER(jobOffer);
  }

  @Action
  async loadCompanyProfile(v: { companyId: string }) {
    const profile = await LookupDataService.getCompanyProfileById(v.companyId);

    externalTalentsStore.SET_COMPANY_PROFILE({
      logoUrl: profile.logoUrl,
      companyName: profile.companyName,
      ingress: profile.ingress,
      logoIncludesName: profile.logoIncludesName,
      branchId: profile.branchId,
      industryId: profile.industryId,
      businessAreasType:
        profile.businessAreasType || BusinessAreasType.BusinessAreas,
      atAGlance: {
        message: profile.message,
        inShort: profile.inShort,
        socialMedia: profile.socialMedia,
      },
      company: {
        companyVideoUrl: profile.companyVideoUrl,
        companyVideoType: profile.companyVideoType || "Youtube",
        businessAreas: profile.businessAreas,
        values: {
          valuesVisible: profile.valuesVisible,
          valuesImageUrl: profile.valuesImageUrl,
          valueList: profile.values,
        },
        milestones: {
          milestonesVisible: profile.milestonesVisible,
          milestoneList: profile.milestones,
        },
      },
      people: {
        statistics: profile.statistics,
        team: profile.team,
        teamVisible: profile.teamVisible,
        perks: {
          perksImageUrl: profile.perksImageUrl,
          perksVisible: profile.perksVisible,
          perkList: profile.perks,
        },
        roles: {
          visible: profile.rolesVisible,
          roleList: profile.roles,
        },
        offices: profile.offices.map(o => ({
          description: o.description || "",
          id: o.id,
          name: o.name,
          officeImage: o.officeImage,
          latitude: o.latitude,
          longitude: o.longitude,
        })),
      },
    });
  }

  @Action
  async loadExternalTalents(v: { recruitmentId: string }) {
    await Promise.resolve();

    if (!this.talentsLoaded.includes(v.recruitmentId)) {
      const token = await TokenHelpers.getToken();

      const talents = await jobOfferService.getExternalTalentProfiles({
        recruitmentId: v.recruitmentId,
        token,
      });

      externalTalentsStore.SET_EXTERNAL_TALENTS([
        ...this.talentsInternal,
        ...talents,
      ]);

      externalTalentsStore.SET_TALENTS_LOADED([
        ...this.talentsLoaded,
        v.recruitmentId,
      ]);
    }
  }
}
