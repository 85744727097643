import { render, staticRenderFns } from "./ConversationDrawerContent.vue?vue&type=template&id=7b6d31fa&scoped=true&"
import script from "./ConversationDrawerContent.vue?vue&type=script&lang=ts&"
export * from "./ConversationDrawerContent.vue?vue&type=script&lang=ts&"
import style0 from "./ConversationDrawerContent.vue?vue&type=style&index=0&id=7b6d31fa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b6d31fa",
  null
  
)

/* custom blocks */
import block0 from "./ConversationDrawerContent.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5Ca%5C1%5Cs%5Ccomponents%5CConversationDrawerContent.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAppBar,VBadge,VBtn,VDivider,VIcon,VNavigationDrawer,VProgressLinear,VSpacer,VTextField,VTextarea})
