



































import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component
export default class CommonQuestion extends Vue {
  @Prop({ type: Boolean, required: true })
  readonly value!: boolean;

  @Emit("input")
  inputEmit(v: boolean) {
    return v;
  }

  get questionIsOpen(): boolean {
    return this.value;
  }

  set questionIsOpen(v: boolean) {
    this.inputEmit(v);
  }
}
