import { Middleware } from "@nuxt/types";
// eslint-disable-next-line import/named
import { talentProfileStore } from "~/store";

// eslint-disable-next-line space-before-function-paren
const myMiddleware: Middleware = async ({ app }) => {
  const locales = app.i18n.locales.map((x: any) => x.code!);

  await talentProfileStore.loadTalentProfile(app.i18n.locale);
  if (
    !!talentProfileStore.profile.lang &&
    talentProfileStore.profile.lang !== app.i18n.locale &&
    locales.includes(talentProfileStore.profile.lang)
  ) {
    return app.i18n.setLocale(talentProfileStore.profile.lang);
  }
};

export default myMiddleware;
