export default function(req, res, next) {
  const pathsToExclude = [
    "/talent",
    "/company",
    "/createProfileStart",
    "/adredirect",
    "/sharedtalent",
    "/inviteFriend",
    "/finishProfile",
    "/rec/",
    "/jobs/",
  ];
  // A very simple check
  if (pathsToExclude.some(p => req.originalUrl.includes(p))) {
    // Will trigger the "traditional SPA mode"
    res.spa = true;
    // }
    // Don't forget to call next in all cases!
    // Otherwise, your app will be stuck forever :|
    next();
  } else {
    next();
  }
}
