import { render, staticRenderFns } from "./FixedRateInfoBox.vue?vue&type=template&id=c5a026e6&scoped=true&"
import script from "./FixedRateInfoBox.vue?vue&type=script&lang=ts&"
export * from "./FixedRateInfoBox.vue?vue&type=script&lang=ts&"
import style0 from "./FixedRateInfoBox.vue?vue&type=style&index=0&id=c5a026e6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5a026e6",
  null
  
)

/* custom blocks */
import block0 from "./FixedRateInfoBox.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5Ca%5C1%5Cs%5Ccomponents%5Ccompany%5CFixedRateInfoBox.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports