




























































































































import { Vue, Component } from "vue-property-decorator";
import { fixLargeNumbers } from "~/helpers/CommonHelperFunctions";
import { companyBaseStore } from "~/store";

@Component
export default class FixedRateInfoBox extends Vue {
  get fixedRateMonthlyPrice(): string {
    const priceOrNull =
      companyBaseStore.companyDetailsObject?.pricing?.fixedPricePerMonth ??
      null;
    return priceOrNull
      ? this.$t("{monthlyFixedRate} kr /mån", {
          monthlyFixedRate: fixLargeNumbers(priceOrNull),
        }).toString()
      : this.$t("Unikt för er").toString();
  }
}
