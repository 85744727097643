

















import { Vue, Component } from "nuxt-property-decorator";

@Component({
  inheritAttrs: false,
})
export default class GrHeadline extends Vue {
  get styleObj() {
    return {
      fontSize: `${this.fontSize}px !important`,
      lineHeight: `${this.lineHeight}px !important`,
    };
  }

  get fontSize(): number {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
      case "sm":
      case "md":
        return 15;
      case "lg":
      case "xl":
        return 18;
    }
  }

  get lineHeight(): number {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
      case "sm":
      case "md":
        return 16;
      case "lg":
      case "xl":
        return 22;
    }
  }
}
