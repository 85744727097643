


























































import { Vue, Component, Emit, Prop } from "vue-property-decorator";
import GritifyAILogo from "./GritifyAILogo.vue";

const gritifyAiHelpCardTypesArray = [
  "JobOfferHeading",
  "JobOfferIngress",
  "MessageHelp",
] as const;

export type GritifyAiHelpCardTypes = typeof gritifyAiHelpCardTypesArray[number];

@Component({
  components: {
    GritifyAILogo,
  },
})
export default class GritifyAiHelpCard extends Vue {
  @Emit("generateText")
  generateText() {}

  @Prop({ type: Boolean, required: true })
  readonly loadingGeneration!: boolean;

  @Prop({ type: String, default: gritifyAiHelpCardTypesArray[0] })
  readonly type!: GritifyAiHelpCardTypes;

  @Prop({ type: String, default: null })
  readonly buttonTextProp!: string | null;

  @Prop({ type: String, default: null })
  readonly backgroundColor!: string | null;

  get titleText() {
    switch (this.type) {
      case "JobOfferHeading":
      case "JobOfferIngress":
        return this.$t("Vill du ha hjälp?").toString();
      case "MessageHelp":
        return "";
    }
  }

  get small(): boolean {
    return this.type === "MessageHelp";
  }

  get descriptionText(): string {
    switch (this.type) {
      case "JobOfferHeading":
      case "JobOfferIngress":
        return this.$t(
          "Vårt AI skriver gärna förslag på texter åt dig, att använda eller inspireras av."
        ).toString();
      case "MessageHelp":
        return "";
    }
  }

  get buttonText() {
    if (this.buttonTextProp) {
      return this.buttonTextProp;
    }
    switch (this.type) {
      case "JobOfferHeading":
      case "JobOfferIngress":
        return this.$t("Generera förslag på text").toString();
      case "MessageHelp":
        return this.$t("Generera förslag på svar").toString();
    }
  }
}
