









import { Vue, Component, Prop } from "nuxt-property-decorator";

@Component
export default class GrTextBlock extends Vue {
  @Prop({
    validator: v => ["large", "default", "small"].includes(v),
    default: "default",
  })
  readonly type!: "large" | "default" | "small";

  get isLarge() {
    return this.type === "large";
  }

  get styleObj() {
    return {
      fontSize: `${this.fontSize}px`,
      letterSpacing: `${this.letterSpacing}px`,
      lineHeight: `${this.lineHeight}px`,
    };
  }

  get fontSize(): number {
    const breakpoint = this.$vuetify.breakpoint.name;

    switch (this.type) {
      case "large":
        switch (breakpoint) {
          case "xs":
          case "sm":
            return 16;
          case "md":
            return 20;
          case "lg":
          case "xl":
            return 22;
          default:
            return 22;
        }
      case "default":
        switch (breakpoint) {
          case "xs":
          case "sm":
          case "md":
            return 16;
          case "lg":
          case "xl":
            return 18;
          default:
            return 18;
        }
      case "small":
        switch (breakpoint) {
          case "xs":
            return 13;
          case "sm":
          case "md":
            return 13;
          case "lg":
          case "xl":
            return 13;
          default:
            return 13;
        }
    }
  }

  get letterSpacing(): number {
    const breakpoint = this.$vuetify.breakpoint.name;

    switch (this.type) {
      case "large":
        switch (breakpoint) {
          case "xs":
          case "sm":
          case "md":
          case "lg":
          case "xl":
            return 0;
          default:
            return 0;
        }
      case "default":
        switch (breakpoint) {
          case "xs":
          case "sm":
          case "md":
          case "lg":
          case "xl":
            return 0;
          default:
            return 0;
        }
      case "small":
        switch (breakpoint) {
          case "xs":
          case "sm":
          case "md":
          case "lg":
          case "xl":
            return 0.4;
          default:
            return 0.4;
        }
    }
  }

  get lineHeight(): number {
    const breakpoint = this.$vuetify.breakpoint.name;

    switch (this.type) {
      case "large":
        switch (breakpoint) {
          case "xs":
            return 24;
          case "sm":
            return 24;
          case "md":
            return 32;
          case "lg":
          case "xl":
            return 36;
          default:
            return 36;
        }
      case "default":
        switch (breakpoint) {
          case "xs":
            return 20;
          case "sm":
            return 20;
          case "md":
            return 24;
          case "lg":
          case "xl":
            return 28;
          default:
            return 28;
        }
      case "small":
        switch (breakpoint) {
          case "xs":
            return 18;
          case "sm":
          case "md":
            return 18;
          case "lg":
          case "xl":
            return 18;
          default:
            return 18;
        }
    }

    // return 20;
  }
}
