import axios from "axios";
import { gritifyApiUrl } from "./ServiceHelper";
import { JobOfferDepartment, JobOfferDepartmentToAdd } from "~/models/types";

export default {
  getDepartments(accessToken: string): Promise<JobOfferDepartment[]> {
    return axios
      .get<JobOfferDepartment[]>(`${gritifyApiUrl}/GetDepartment`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(r => {
        return r.data;
      });
  },
  createDepartment(
    department: JobOfferDepartmentToAdd,
    accessToken: string
  ): Promise<string> {
    return axios
      .post<string>(`${gritifyApiUrl}/CreateDepartment`, department, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(r => {
        return r.data;
      });
  },
  updateDepartment(
    department: JobOfferDepartment,
    accessToken: string
  ): Promise<void> {
    return axios
      .put<void>(
        `${gritifyApiUrl}/UpdateDepartment?departmentId=${department.id}`,
        department,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },
};
