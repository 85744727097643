/* eslint-disable no-use-before-define */
import { pipe } from "fp-ts/lib/function";
import SortHelper from "./sortHelper";
import FilterHelpers from "./filterHelpers";
import {
  CompletionListItem,
  EmploymentPeriod,
  EmploymentType,
} from "~/models/types";

export default class EmploymentHelper {
  static getWorkExperienceNumberOfYears(
    emps: (EmploymentPeriod & {
      employmentType: EmploymentType | null;
    })[]
  ): number {
    return this.getWorkExperienceNumberOfYearsWithNow(
      emps.filter(e => e.employmentType !== EmploymentType.Intern),
      new Date()
    );
  }

  static getWorkExperienceNumberOfMonthsWithNow(
    emps: EmploymentPeriod[],
    now: Date
  ): number {
    return this.getOverlapGroups(emps, now).reduce((acc: number, g) => {
      const fromYearInMonths = g.reduce((acc: number, e) => {
        return acc < e.fromInMonths ? acc : e.fromInMonths;
      }, g[0]!.fromInMonths);
      const toYearInMonths = g.reduce((acc: number, e) => {
        return acc > e.toInMonths ? acc : e.toInMonths;
      }, g[0]!.toInMonths);
      const months = toYearInMonths - fromYearInMonths;
      return acc + months;
    }, 0);
  }

  static getWorkExperienceNumberOfYearsWithNow(
    emps: EmploymentPeriod[],
    now: Date
  ): number {
    return Math.max(
      0,
      Math.round(this.getWorkExperienceNumberOfMonthsWithNow(emps, now) / 12)
    );
  }

  static MapToPeriodInMonths(r: EmploymentPeriod, now: Date) {
    return {
      fromInMonths: SortHelper.getMonthsFromDateString(r.fromYear, now),
      toInMonths:
        r.toYear !== null
          ? SortHelper.getMonthsFromDateString(r.toYear, now)
          : Math.max(
              SortHelper.getMonthsFromDateString(r.toYear, now),
              SortHelper.getMonthsFromDateString(
                r.toYear,
                new Date(`${r.fromYear}-01`)
              )
            ),
    };
  }

  private static getOverlapGroups(
    rangesInput: (EmploymentPeriod & { parent?: number })[],
    now: Date
  ): periodInMonths[][] {
    const ranges: (periodInMonths & {
      parent?: number;
    })[] = rangesInput.map(r => this.MapToPeriodInMonths(r, now));
    const set = new DisjointSet(ranges.length);

    const IsOverlaping = (a: periodInMonths, b: periodInMonths) => {
      return a.fromInMonths < b.toInMonths && b.fromInMonths < a.toInMonths;
    };

    for (let i = 0; i < ranges.length; i++) {
      for (let j = 0; j < ranges.length; j++) {
        if (IsOverlaping(ranges[i]!, ranges[j]!)) {
          set.Union(i, j);
        }
      }
    }

    for (let i = 0; i < set.count; i++) {
      ranges[i]!.parent = set.parent[i];
    }
    return ranges.reduce(
      (acc: (periodInMonths & { parent?: number })[][], r) => {
        const currentGroup =
          acc.find(g => g.some(x => x.parent === r.parent)) || [];

        if (!currentGroup.length) {
          acc.push(currentGroup);
        }

        currentGroup.push(r);
        return acc;
      },
      []
    );
  }
}

interface periodInMonths {
  fromInMonths: number;
  toInMonths: number;
}

class DisjointSet {
  /// <summary>
  /// The number of elements in the universe.
  /// </summary>
  count: number;

  /// <summary>
  /// The parent of each element in the universe.
  /// </summary>
  parent: number[];

  /// <summary>
  /// The rank of each element in the universe.
  /// </summary>
  rank: number[];

  /// <summary>
  /// The size of each set.
  /// </summary>
  sizeOfSet: number[];

  /// <summary>
  /// The number of disjoint sets.
  /// </summary>
  setCount: number;

  /// <summary>
  /// Initializes a new Disjoint-Set data structure, with the specified amount of elements in the universe.
  /// </summary>
  /// <param name='count'>
  /// The number of elements in the universe.
  /// </param>
  constructor(count: number) {
    this.count = count;
    this.setCount = count;
    this.parent = [];
    this.rank = [];
    this.sizeOfSet = [];

    for (let i = 0; i < this.count; i++) {
      this.parent[i] = i;
      this.rank[i] = 0;
      this.sizeOfSet[i] = 1;
    }
  }

  /// <summary>
  /// Find the parent of the specified element.
  /// </summary>
  /// <param name='i'>
  /// The specified element.
  /// </param>
  /// <remarks>
  /// All elements with the same parent are in the same set.
  /// </remarks>
  public Find(i: number): number {
    if (this.parent[i] === i) {
      return i;
    } else {
      // Recursively find the real parent of i, and then cache it for later lookups.
      this.parent[i] = this.Find(this.parent[i]!);
      return this.parent[i]!;
    }
  }

  /// <summary>
  /// Unite the sets that the specified elements belong to.
  /// </summary>
  /// <param name='i'>
  /// The first element.
  /// </param>
  /// <param name='j'>
  /// The second element.
  /// </param>
  public Union(i: number, j: number) {
    // Find the representatives (or the root nodes) for the set that includes i
    const irep = this.Find(i);
    // And do the same for the set that includes j
    const jrep = this.Find(j);
    // Get the rank of i's tree
    const irank = this.rank[irep]!;
    // Get the rank of j's tree
    const jrank = this.rank[jrep]!;

    // Elements are in the same set, no need to unite anything.
    if (irep === jrep) return;

    this.setCount--;

    // If i's rank is less than j's rank
    if (irank < jrank) {
      // Then move i under j
      this.parent[irep] = jrep;
      this.sizeOfSet[jrep] += this.sizeOfSet[irep]!;
    } // Else if j's rank is less than i's rank
    else if (jrank < irank) {
      // Then move j under i
      this.parent[jrep] = irep;
      this.sizeOfSet[irep] += this.sizeOfSet[jrep]!;
    } // Else if their ranks are the same
    else {
      // Then move i under j (doesn't matter which one goes where)
      this.parent[irep] = jrep;
      this.sizeOfSet[jrep] += this.sizeOfSet[irep]!;

      // And increment the the result tree's rank by 1
      this.rank[jrep]++;
    }
  }

  /// <summary>
  /// Return the element count of the set that the specified elements belong to.
  /// </summary>
  /// <param name='i'>
  /// The element.
  /// </param>
  public setSize(i: number): number {
    return this.sizeOfSet[this.Find(i)]!;
  }
}

const registeredPercent = (v: {
  numberOfWorkYearsStartDate: Date | null | undefined;
  emps: (EmploymentPeriod & {
    employmentType: EmploymentType | null;
  })[];
}): number | null => {
  const totalWorkExperience = FilterHelpers.getNumberOfYearsFromDate(
    v.numberOfWorkYearsStartDate ?? null
  );

  if (totalWorkExperience === null || totalWorkExperience === 0) {
    return null;
  }
  const employmentsYearsSum = EmploymentHelper.getWorkExperienceNumberOfYears(
    v.emps
  );

  if (totalWorkExperience < employmentsYearsSum) {
    return 100;
  }

  return FilterHelpers.roundToWholeNumber(
    (employmentsYearsSum / totalWorkExperience) * 100
  );
};

export const hasUnregisteredEmployments = (v: {
  emps: (EmploymentPeriod & {
    employmentType: EmploymentType | null;
  })[];
  numberOfWorkYearsStartDate: Date | null | undefined;
  items: CompletionListItem[];
}): boolean => {
  const items = v.items;
  const allItemsAreDone = items.every(x => x.isDone);

  return (
    allItemsAreDone && pipe(registeredPercent(v), x => x !== null && x !== 100)
  );
};
