import colors from "vuetify/es5/util/colors";

import "@mdi/font/css/materialdesignicons.css";

export default function({ app }) {
  return {
    lang: {
      t: (key, ...params) => app.i18n.t(key, params),
    },
    icons: {
      iconfont: "mdi",
    },
    theme: {
      options: {
        customProperties: true,
      },
      dark: false,
      options: { customProperties: true },
      themes: {
        light: {
          secundary: "#040F3B",
          // primary: "#c79e20",
          primary: "#0C2481",
          accent: "#FE1376",
          error: "#FF4D4D",
          warning: "#ffc107",
          info: "#FFE7A3",
          success: "#169746",
        },
        dark: {
          primary: colors.blue.base,
        },
      },
    },
  };
}
