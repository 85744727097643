import { render, staticRenderFns } from "./UpgradeToNewPricingModelModal.vue?vue&type=template&id=eca420fa&"
import script from "./UpgradeToNewPricingModelModal.vue?vue&type=script&lang=ts&"
export * from "./UpgradeToNewPricingModelModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./UpgradeToNewPricingModelModal.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5Ca%5C1%5Cs%5Ccomponents%5CUpgradeToNewPricingModelModal.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VForm,VIcon,VTextField})
