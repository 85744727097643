// import uuidv1 from "uuid/v1";
import { SuperSearchItem } from "~/models/types";

export default {
  getSuperSearchResults(_searchTerm: string): Promise<SuperSearchItem[]> {
    return new Promise<SuperSearchItem[]>(resolve => {
      resolve([]);
    });
  },
};
