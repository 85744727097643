

































































































































































































































































































































































import { Vue, Component, Watch } from "nuxt-property-decorator";
import { pipe } from "fp-ts/lib/function";
import { reduce } from "fp-ts/lib/Array";
import confirm from "../components/Confirm.vue";
import { talentProfileStore } from "~/store";
import GritifyLogo from "~/components/styling/GritifyLogo.vue";
import {
  getJobOfferOngoing,
  getJobOfferIsUnanswered,
} from "~/helpers/TalentJobHelperFunctions";
import GrButtonNew from "~/components/common/GrButtonNew.vue";
import CrispHelper, { canShowChat } from "~/helpers/CrispHelper";

@Component({
  middleware: [
    "authenticated",
    "fixLocale",
    async function({ app }) {
      await Promise.all([
        talentProfileStore.loadTalentJobOffers(),
        talentProfileStore.loadTalentProfile(app.i18n.locale),
      ]);
    },
  ],
  components: {
    confirm,
    GritifyLogo,
    GrButtonNew,
  },
})
export default class TalentLayout extends Vue {
  drawer = false;
  isHydrated = false;
  phoneMenuOpen = false;

  get isMobile() {
    if (!this.isHydrated) {
      return false;
    }
    return this.$vuetify.breakpoint.smAndDown;
  }

  get isDisconnectedFromSignalR(): boolean {
    return talentProfileStore.isDisconnected;
  }

  get profileStatus(): {
    text: string;
    status: "active" | "paused" | "incomplete";
  } {
    if (!this.isDone) {
      return {
        text: this.$t("Skapa profil").toString(),
        status: "incomplete",
      };
    }
    if (!talentProfileStore.allItemsAreDone) {
      return {
        text: this.$t("Ofullständig").toString(),
        status: "incomplete",
      };
    }
    if (this.isPaused) {
      return {
        text: this.$t("Pausad").toString(),
        status: "paused",
      };
    }
    return {
      text: this.$t("Aktiv").toString(),
      status: "active",
    };
  }

  get isDone(): boolean {
    return talentProfileStore.profile.isDone;
  }

  @Watch("isDone")
  async onIsDoneChange(v: boolean) {
    console.log("isDone", v);
    if (v) {
      await new Promise(resolve => setTimeout(resolve, 200));
      this.fixFixedMargins();
    }
  }

  @Watch("isMobile")
  async onIsMobileChange(v: boolean) {
    if (v) {
      await new Promise(resolve => setTimeout(resolve, 200));
      this.fixFixedMargins();
    }
  }

  get isPaused(): boolean {
    return talentProfileStore.isPaused;
  }

  get showNumberOfJobOffers(): boolean {
    return this.unansweredJobOffersLength > 0;
  }

  get unansweredJobOffersLength(): number {
    return talentProfileStore.jobOffers.filter(jo =>
      getJobOfferIsUnanswered(jo)
    ).length;
  }

  get unansweredJobOffersLengthString(): string {
    return talentProfileStore.jobOffers
      .filter(jo => getJobOfferIsUnanswered(jo))
      .length.toString();
  }

  get unansweredData(): { countString: string; show: boolean } {
    return pipe(
      talentProfileStore.jobOffers.filter(getJobOfferIsUnanswered),
      xs => ({
        countString: xs.length.toString(),
        show: xs.length > 0,
      })
    );
  }

  get ongoingData(): { countString: string; show: boolean } {
    return pipe(
      talentProfileStore.jobOffers.filter(getJobOfferOngoing),
      reduce(0, (acc, jo) => acc + jo.numberOfNewMessages),
      x => ({
        countString: x.toString(),
        show: x > 0,
      })
    );
  }

  get isOnSettings(): boolean {
    return this.$route.name?.includes("talent-accountsettings") ?? false;
  }

  $refs!: Vue["$refs"] & {
    header?: {
      offsetHeight?: number;
    };
    bottomNav?: {
      offsetHeight?: number;
    };
  };

  reloadSite() {
    location.reload();
  }

  ignoredDisconnected = true;

  cancelReloadPage() {
    this.ignoredDisconnected = true;
  }

  headerHeight: number = 0;
  bottomNavHeight: number = 0;

  fixFixedMargins() {
    const headerHeight: number | null = this.$refs.header?.offsetHeight ?? null;
    const bottomNavHeight: number | null =
      this.$refs.bottomNav?.offsetHeight ?? null;

    this.headerHeight = headerHeight ?? 0;
    this.bottomNavHeight = bottomNavHeight ?? 0;
  }

  mounted() {
    (this.$root as any).$confirm = (this.$refs.confirm as any).open;
    this.fixFixedMargins();
    this.isHydrated = true;
    if (!canShowChat()) {
      CrispHelper.hideChat();
    }
  }

  get fixedHeightsMargin() {
    return {
      marginTop: `${this.headerHeight}px`,
      marginBottom: `${this.bottomNavHeight}px`,
    };
  }

  goHome() {
    this.phoneMenuOpen = false;
    this.$router.push(
      this.localePath({
        name: "talent",
      })
    );
  }

  goToCreateProfile() {
    this.phoneMenuOpen = false;
    this.$router.push(
      this.localePath({
        name: "talent-createProfile",
      })
    );
  }

  goToMyExperience() {
    this.phoneMenuOpen = false;
    this.$router.push(
      this.localePath({
        name: "talent-myExperience",
      })
    );
  }

  goToMyPreferences() {
    this.phoneMenuOpen = false;
    this.$router.push(
      this.localePath({
        name: "talent-myPreferences",
      })
    );
  }

  goToAccountSettings() {
    this.phoneMenuOpen = false;
    this.$router.push(
      this.localePath({
        name: "talent-accountsettings",
      })
    );
  }

  logout() {
    this.$msal.signOut();
  }
}
