const middleware = {}

middleware['authenticated'] = require('..\\middleware\\authenticated.ts')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['authenticatedCompany'] = require('..\\middleware\\authenticatedCompany.ts')
middleware['authenticatedCompany'] = middleware['authenticatedCompany'].default || middleware['authenticatedCompany']

middleware['campaignCode'] = require('..\\middleware\\campaignCode.ts')
middleware['campaignCode'] = middleware['campaignCode'].default || middleware['campaignCode']

middleware['checkInviterId'] = require('..\\middleware\\checkInviterId.ts')
middleware['checkInviterId'] = middleware['checkInviterId'].default || middleware['checkInviterId']

middleware['finishedProfile'] = require('..\\middleware\\finishedProfile.ts')
middleware['finishedProfile'] = middleware['finishedProfile'].default || middleware['finishedProfile']

middleware['fixLocale'] = require('..\\middleware\\fixLocale.ts')
middleware['fixLocale'] = middleware['fixLocale'].default || middleware['fixLocale']

middleware['fixLocaleCompany'] = require('..\\middleware\\fixLocaleCompany.ts')
middleware['fixLocaleCompany'] = middleware['fixLocaleCompany'].default || middleware['fixLocaleCompany']

middleware['requireCookies'] = require('..\\middleware\\requireCookies.ts')
middleware['requireCookies'] = middleware['requireCookies'].default || middleware['requireCookies']

middleware['requireFullAuth'] = require('..\\middleware\\requireFullAuth.ts')
middleware['requireFullAuth'] = middleware['requireFullAuth'].default || middleware['requireFullAuth']

middleware['selectiveSSR'] = require('..\\middleware\\selectiveSSR.js')
middleware['selectiveSSR'] = middleware['selectiveSSR'].default || middleware['selectiveSSR']

middleware['unauthenticated'] = require('..\\middleware\\unauthenticated.ts')
middleware['unauthenticated'] = middleware['unauthenticated'].default || middleware['unauthenticated']

export default middleware
