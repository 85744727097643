import { Module, VuexModule, Mutation } from "vuex-module-decorators";
import SortHelper from "~/helpers/sortHelper";
import TokenHelpers from "~/helpers/tokenHelpers";
import { MsalAuthToken } from "~/models/authTypes";
import {
  RecruitmentTeamMember,
  RecruitmentTeamMemberType,
} from "~/models/types";
import { jobbOfferStore } from "~/utils/store-accessor";

@Module({
  name: "auth",
  stateFactory: true,
  namespaced: true,
})
export default class AuthStore extends VuexModule {
  authTokenNotUsed: MsalAuthToken | null = null;
  isCreatingAccount = false;
  accessToken: string | null = null;

  get hasFullAccess(): boolean {
    if (!this.companyUser) {
      return false;
    }
    const fullAccessTypes: RecruitmentTeamMemberType[] = ["Full", "SuperAdmin"];
    return fullAccessTypes.includes(this.companyUser.userType);
  }

  get isSuperAdmin(): boolean {
    if (!this.companyUser) {
      return false;
    }

    return this.companyUser.userType === "SuperAdmin";
  }

  get canViewAllRecruitments(): boolean {
    if (!this.companyUser) {
      return false;
    }
    const accessTypes: RecruitmentTeamMemberType[] = ["Full", "SuperAdmin"];
    return accessTypes.includes(this.companyUser.userType);
  }

  get canEditRecruitments(): boolean {
    if (!this.companyUser) {
      return false;
    }
    const fullAccessTypes: RecruitmentTeamMemberType[] = ["Full", "SuperAdmin"];
    return fullAccessTypes.includes(this.companyUser.userType);
  }

  get canInviteUser(): boolean {
    if (!this.companyUser) {
      return false;
    }
    const fullAccessTypes: RecruitmentTeamMemberType[] = ["Full", "SuperAdmin"];
    return fullAccessTypes.includes(this.companyUser.userType);
  }

  get userAccess(): RecruitmentTeamMemberType {
    return this.companyUser?.userType ?? "Restricted";
  }

  get userAccessLevel(): number {
    return SortHelper.getAccessLevel(this.userAccess);
  }

  get companyId(): string | null {
    return this.tokenClaims.companyId || null;
  }

  get userId(): string | null {
    return this.tokenClaims.sub || null;
  }

  get companyUser(): RecruitmentTeamMember | null {
    const companyUser = jobbOfferStore.companyMembers.find(
      x => x.userId === this.userId
    );

    return companyUser ?? null;
  }

  @Mutation
  SET_ACCESS_TOKEN(v: string) {
    this.accessToken = v;
  }

  get tokenClaims(): {
    [key: string]: string;
  } {
    const token = this.accessToken;

    return TokenHelpers.getTokenClaims(token);
    // if (token) {
    //   const base64Url = token.split(".")[1];
    //   const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    //   const jsonPayload = decodeURIComponent(
    //     atob(base64)
    //       .split("")
    //       .map(function(c) {
    //         return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    //       })
    //       .join("")
    //   );

    //   return JSON.parse(jsonPayload);
    // }

    // return {};
  }

  @Mutation
  SET_IS_CREATING_ACCOUNT(v: boolean) {
    this.isCreatingAccount = v;
  }
}
