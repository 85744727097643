var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"id":"grAppBar","clipped-left":"","color":_vm.greyBackground ? '#f5f5f5' : 'white',"app":"","elevate-on-scroll":"","height":_vm.navBarHeight}},[_c('v-row',{staticClass:"app-bar-row",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center px-4 px-md-16",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"d-flex gap-4 align-center"},[_c('nuxt-link',{staticClass:"d-flex align-center",attrs:{"to":_vm.localePath({
                    name: 'index',
                  })}},[(_vm.showAlternativeLogo)?_c('img',{attrs:{"height":"18.48","width":"24.64","src":"/alternative-logo.png"}}):_c('GritifyLogo')],1),_vm._v(" "),_c('div',{staticStyle:{"border-right":"1px solid #E0E0E0","align-self":"stretch"}}),_vm._v(" "),_c('GrNavLinkDense',{attrs:{"no-active-color":true,"link-object":{
                  name: 'talentStart',
                }}},[_vm._v("\n                "+_vm._s(_vm.$t("För talanger"))+"\n              ")]),_vm._v(" "),_c('v-menu',{attrs:{"open-on-hover":"","rounded":"lg","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('svg',_vm._g(_vm._b({attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"25","height":"24","viewBox":"0 0 25 24","fill":"none"}},'svg',attrs,false),on),[_c('rect',{attrs:{"x":"0.832031","y":"0.5","width":"23","height":"23","rx":"11.5","stroke":"#E0E0E0"}}),_vm._v(" "),_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M5.33203 10L11.1654 15.8333L12.332 17L13.4987 15.8333L19.332 10L18.1654 8.83333L12.332 14.6667L6.4987 8.83333L5.33203 10Z","fill":"#FE1376"}})])]}}])},[_vm._v(" "),_c('div',{staticStyle:{"border":"1px solid var(--grey-2, #E0E0E0)"}},[_c('div',{staticClass:"gr-heading-small",staticStyle:{"display":"flex","padding":"16px 17px","align-items":"center","gap":"16px","border-bottom":"1px solid var(--grey-2, #E0E0E0)","background":"var(--White, #FFF)","color":"var(--Black, #000)","text-align":"center"}},[_vm._v("\n                    "+_vm._s(_vm.$t("För talanger"))+"\n                  ")]),_vm._v(" "),_c('nuxt-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":_vm.localePath({
                        name: 'companyStart',
                      })}},[_c('div',{staticClass:"gr-heading-small",staticStyle:{"background":"var(--grey-3, #F5F5F5)","display":"flex","padding":"16px 17px","align-items":"center","gap":"16px","align-self":"stretch","color":"var(--Colors-Link-Blue, #0034FF)","text-align":"center"}},[_vm._v("\n                      "+_vm._s(_vm.$t("För företag"))+"\n                    ")])])],1)])],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('div',{staticClass:"d-flex gap-10 align-center"},[_c('div',{staticClass:"d-flex gap-4"},[_c('GrButton',{attrs:{"to":_vm.localePath({ name: 'talent' }),"color":"outlinedBlack"}},[_vm._v(_vm._s(_vm.$t("Logga in")))])],1)])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }