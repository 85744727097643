









import { Vue, Component, Prop } from "vue-property-decorator";

export type JobylonIconColor = "blue";

@Component
export default class JobylonIcon extends Vue {
  @Prop({ type: String, default: "white" })
  readonly color!: JobylonIconColor;

  @Prop({ type: Boolean, default: false })
  readonly xLarge!: Boolean;

  @Prop({ type: Boolean, default: false })
  readonly large!: Boolean;

  @Prop({ type: Number, default: null })
  readonly size!: number | null;

  get imageSrc(): string {
    switch (this.color) {
      case "blue":
        return "/jobylonIcon.jpeg";
    }
  }

  get width(): string {
    if (this.size !== null) {
      return this.size + "px";
    }
    if (this.xLarge) {
      return 60 + "px";
    }
    if (this.large) {
      return 30 + "px";
    }
    return 24 + "px";
  }
}
