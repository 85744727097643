








































































import { Vue, Component } from "vue-property-decorator";
import GrButton from "../common/GrButton.vue";
import CommonQuestion from "../common/CommonQuestion.vue";
import UpgradeToNewPricingModelModal from "../UpgradeToNewPricingModelModal.vue";
import CommonQuestionsPricingList from "../common/CommonQuestionsPricingList.vue";
import TextWrapperReplacer from "./robotStepperModules/TextWrapperReplacer.vue";
import TermsForFixedPriceModelModal from "./TermsForFixedPriceModelModal.vue";
import FixedRateInfoBox from "./FixedRateInfoBox.vue";
import { companyBaseStore } from "~/store";
import {
  canUpgradeToFixedAccount,
  getCompanyAccountType,
} from "~/helpers/agreemendHelper";

export type StartFixedPriceAccountModalResult =
  | {
      type: "Saved";
    }
  | {
      type: "Cancel";
    };

@Component({
  components: {
    GrButton,
    TextWrapperReplacer,
    TermsForFixedPriceModelModal,
    CommonQuestion,
    UpgradeToNewPricingModelModal,
    CommonQuestionsPricingList,
    FixedRateInfoBox,
  },
})
export default class StartFixedPriceAccountModal extends Vue {
  dialog = false;
  resolve: ((v: StartFixedPriceAccountModalResult) => void) | null = null;

  open(): Promise<StartFixedPriceAccountModalResult> {
    this.dialog = true;

    return new Promise<StartFixedPriceAccountModalResult>(resolve => {
      this.resolve = resolve;
    });
  }

  get accessFeeMonthly() {
    return companyBaseStore.accessFee;
  }

  acceptsTerms = false;

  question1Open = false;
  question2Open = false;
  question3Open = false;
  question4Open = false;

  openTermsModal() {
    this.$refs.TermsForFixedPriceModelModal.open();
  }

  $refs!: Vue["$refs"] & {
    TermsForFixedPriceModelModal: TermsForFixedPriceModelModal;
    UpgradeToNewPricingModelModal: UpgradeToNewPricingModelModal;
  };

  get accountType() {
    return getCompanyAccountType(companyBaseStore.companyDetailsObject);
  }

  get canUpgradeToFixed() {
    return canUpgradeToFixedAccount(companyBaseStore.companyDetailsObject);
  }

  get greyText(): string {
    switch (this.accountType) {
      case "AccessSuccess":
        return this.$t("AccessSuccess grå text").toString();
      case "Explore":
        return !this.canUpgradeToFixed
          ? ""
          : this.$t("Explore till fixed grå text").toString();
      case "OldFixed":
        return this.$t("OldFixed grå text").toString();
      case "NewFixed":
        return "";
    }
  }

  get blackBigText(): string {
    switch (this.accountType) {
      case "AccessSuccess":
        return this.$t("AccessSuccess svart större text").toString();
      case "Explore":
        return !this.canUpgradeToFixed
          ? ""
          : this.$t("Explore till fixed svart större text").toString();
      case "OldFixed":
        return this.$t("OldFixed svart större text").toString();
      case "NewFixed":
        return "";
    }
  }

  get buttonText(): string {
    switch (this.accountType) {
      case "AccessSuccess":
        return this.$t("AccessSuccess knapptext").toString();
      case "Explore":
        return !this.canUpgradeToFixed
          ? ""
          : this.$t("Explore till fixed knapptext").toString();
      case "OldFixed":
        return this.$t("OldFixed knapptext").toString();
      case "NewFixed":
        return "";
    }
  }

  async openUpgradeToNewPricingModelModal() {
    const result = await this.$refs.UpgradeToNewPricingModelModal.open({
      upgradeToAccessSuccess: false,
    });

    if (result.type === "Saved") {
      if (this.resolve) {
        this.resolve({
          type: "Saved",
        });
      }
      this.dialog = false;
    }
  }

  cancel() {
    if (this.resolve) {
      this.resolve({
        type: "Cancel",
      });
    }
    this.dialog = false;
  }

  save() {
    if (this.resolve) {
      this.resolve({
        type: "Saved",
      });
    }
    this.dialog = false;
  }
}
