import { render, staticRenderFns } from "./NotificationsList.vue?vue&type=template&id=63b2504d&scoped=true&"
import script from "./NotificationsList.vue?vue&type=script&lang=ts&"
export * from "./NotificationsList.vue?vue&type=script&lang=ts&"
import style0 from "./NotificationsList.vue?vue&type=style&index=0&id=63b2504d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63b2504d",
  null
  
)

/* custom blocks */
import block0 from "./NotificationsList.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5Ca%5C1%5Cs%5Ccomponents%5Ccompany%5CNotificationsList.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VIcon,VSpacer})
