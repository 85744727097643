import { render, staticRenderFns } from "./companyPublicLayout.vue?vue&type=template&id=2761b8ec&"
import script from "./companyPublicLayout.vue?vue&type=script&lang=ts&"
export * from "./companyPublicLayout.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./companyPublicLayout.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VApp,VMain,VSheet})
