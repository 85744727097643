import { Middleware } from "@nuxt/types";
// eslint-disable-next-line import/named
import { LocaleObject } from "nuxt-i18n";
import { companyBaseStore } from "~/store";

// eslint-disable-next-line space-before-function-paren
const myMiddleware: Middleware = async ({ app }) => {
  const isLocaleObject = (l: LocaleObject | string): l is LocaleObject => {
    return typeof l !== "string" && !!l.code;
  };

  const locales = (app.i18n.locales as (string | LocaleObject)[])
    .filter(isLocaleObject)
    .map(x => x.code);

  await companyBaseStore.loadUserDetails();

  const user = companyBaseStore.userDetails;
  if (
    !!user.lang &&
    user.lang !== app.i18n.locale &&
    locales.includes(user.lang)
  ) {
    // reload with correct lang
    return app.i18n.setLocale(user.lang);
  }
};

export default myMiddleware;
