import TokenHelpers from "./tokenHelpers";
import { ListObject } from "~/models/ListObject";
import { WorkTitleDto } from "~/models/types";
import lookupDataService from "~/services/lookupDataService";

export default class TitleHelper {
  public static async getTitleAndRole(v: {
    selectedTitle: WorkTitleDto;
    allRoles: ListObject[];
    excludeOtherRole: boolean;
  }) {
    let titleCopy = { ...v.selectedTitle };
    if (!titleCopy.id) {
      const realTitle = await lookupDataService.getTitleByName({
        titleName: titleCopy.text,
      });

      if (!realTitle) {
        const token = await TokenHelpers.getToken();
        const matchedRoleIds = await lookupDataService.tryMatchUndefinedTitleToRole(
          {
            titleText: titleCopy.text,
            token,
          }
        );

        if (v.excludeOtherRole) {
          matchedRoleIds.filter(x => x !== roleIdForOtherRole);
        }

        if (matchedRoleIds.length > 0) {
          titleCopy = {
            ...titleCopy,
            roles: matchedRoleIds,
            pickFromSelectedGroups: matchedRoleIds.length > 1,
          };
        }
      }

      titleCopy = realTitle || titleCopy;
    }
    const newRoleId = !titleCopy
      ? null
      : titleCopy.roles.length === 1
      ? titleCopy.roles[0]
      : null;

    const newRole = !newRoleId
      ? null
      : v.allRoles.find(x => x.id === newRoleId) || null;
    return { newRole, titleCopy };
  }
}

export const roleIdForOtherRole = "fd034685-927b-4e98-87af-fe92ba47c149";

export const getTitleIsOutOfScope = (v: {
  title: WorkTitleDto | null;
  roleId: string | null;
}) => {
  if (v.roleId) {
    return v.roleId === roleIdForOtherRole;
  }

  return false;
};
