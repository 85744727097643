import Vue from "vue";

import rg4js from "raygun4js";
if (process.env.ENVIRONMENT !== "Dev") {
  const apiKey =
    process.env.ENVIRONMENT === "Production"
      ? "SCQnJqOfP0f1JRWtYsvG1g"
      : process.env.ENVIRONMENT === "Stage"
      ? "JAsL1jUSPv2GimSU7rzoZA"
      : "JAsL1jUSPv2GimSU7rzoZA";

  rg4js("apiKey", apiKey);
  rg4js("enableCrashReporting", true);
  Vue.config.errorHandler = function(err, vm, info) {
    if (
      vm &&
      vm.$store &&
      vm.$store.app &&
      vm.$store.app.$msal &&
      vm.$store.app.$msal.data &&
      vm.$store.app.$msal.data.account &&
      vm.$store.app.$msal.data.account.idTokenClaims
    ) {
      rg4js("setUser", {
        identifier: vm.$store.app.$msal.data.account.idTokenClaims.sub,
        isAnonymous: false,
        uuid: vm.$store.app.$msal.data.account.idTokenClaims.sub,
      });
    }
    rg4js("send", {
      error: err,
      customData: [{ info }],
    });
  };
}
