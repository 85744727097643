import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import {
  Interview,
  JobbOfferPerk,
  JobOfferVisaulToUpdate,
  CoWorkerDto,
  JobOfferDepartment,
  CreateVisualStepType,
} from "~/models/types";
import {
  jobbOfferVisualStore,
  jobbOfferStore,
  profileLookupsStore,
} from "~/store";
import jobOfferService from "~/services/jobOfferService";
import VisualJobOfferHelper from "~/helpers/visualJobOfferHelper";
import TokenHelpers from "~/helpers/tokenHelpers";
import { ListObjectNullable } from "~/models/ListObjectNullable";

const defaultVisual: JobOfferVisaulToUpdate = {
  coWorkers: [],
  department: null,
  imageUrl: null,
  ingress: null,
  useSeparateInterview: false,
  announcementTitle: null,
  manager: null,
  perks: [],
  skills: [],
  taskIds: [],
  bannerImageUrl: null,
  recruitmentImagePackId: null,
  textInterview: [],
  includeInterview: false,
};

@Module({
  name: "jobbOfferVisual",
  stateFactory: true,
  namespaced: true,
})
export default class JobbOfferVisualStore extends VuexModule {
  visual: JobOfferVisaulToUpdate = defaultVisual;
  visualLoaded: string | null = null;
  showAllTasks = false;
  showAllSkills = false;
  showAllPerks = false;

  createVisualCurrentStepTypeByRecruitmentId: Record<
    string,
    CreateVisualStepType
  > = {};

  @Mutation
  SET_CREATE_VISUAL_CURRENT_STEP(v: {
    recId: string;
    step: CreateVisualStepType;
  }) {
    this.createVisualCurrentStepTypeByRecruitmentId = {
      ...this.createVisualCurrentStepTypeByRecruitmentId,
      [v.recId]: v.step,
    };
  }

  get skills(): ListObjectNullable[] {
    return this.showSkillsInVisual ? this.visual.skills : [];
  }

  get showSkillsInVisual(): boolean {
    const roleId = jobbOfferStore.baseInfo
      ? jobbOfferStore.baseInfo.roleId
      : null;

    return VisualJobOfferHelper.getShowSkillsInVisual({
      roleId,
      roles: profileLookupsStore.roles,
    });
  }

  get visualIsComplete(): boolean {
    return this.visualTaskList.every(t => t.complete);
  }

  get visualTaskList() {
    return VisualJobOfferHelper.getVisualTaskList(this.visual);
  }

  @Mutation
  SET_SHOW_ALL_TASKS(v: boolean) {
    this.showAllTasks = v;
  }

  @Mutation
  SET_SHOW_ALL_SKILLS(v: boolean) {
    this.showAllSkills = v;
  }

  @Mutation
  SET_SHOW_ALL_PERKS(v: boolean) {
    this.showAllPerks = v;
  }

  @Mutation
  SET_MANAGER(v: CoWorkerDto | null) {
    this.visual.manager = v;
  }

  @Mutation
  SET_USER_SEPARATE_INTERVIEW(v: boolean) {
    this.visual.useSeparateInterview = v;
  }

  @Mutation
  SET_MANAGER_INTERVIEW(v: Interview) {
    this.visual.manager = this.visual.manager
      ? {
          ...this.visual.manager,
          interview: v,
        }
      : null;
  }

  ingressByRecId: Record<string, string | null> = {};

  @Mutation
  SET_INGRESS_BY_REC_ID(v: { recId: string; ingress: string | null }) {
    this.ingressByRecId = {
      ...this.ingressByRecId,
      [v.recId]: v.ingress,
    };
  }

  headingByRecId: Record<string, string | null> = {};

  @Mutation
  SET_HEADING_BY_REC_ID(v: { recId: string; heading: string | null }) {
    this.headingByRecId = {
      ...this.headingByRecId,
      [v.recId]: v.heading,
    };
  }

  @Mutation
  SET_INGRESS(v: string | null) {
    this.visual.ingress = v;
  }

  currentRecruitmentId: string | null = null;

  @Mutation
  SET_CURRENT_RECRUITMENT_ID(v: string | null) {
    this.currentRecruitmentId = v;
  }

  @Mutation
  SET_ANNOUNCEMENT_TITLE(v: string | null) {
    this.visual.announcementTitle = v;
  }

  @Mutation
  SET_IMAGE_URL(v: string | null) {
    this.visual.imageUrl = v;
  }

  @Mutation
  SET_PERKS(v: JobbOfferPerk[]) {
    this.visual.perks = v;
  }

  @Mutation
  SET_SKILLS(v: ListObjectNullable[]) {
    this.visual.skills = v;
  }

  @Mutation
  SET_TASK_IDS(v: string[]) {
    this.visual.taskIds = v;
  }

  @Mutation
  SET_DEPARTMENT(v: JobOfferDepartment | null) {
    this.visual.department = v;
  }

  @Mutation
  SET_COWORKERS(v: CoWorkerDto[]) {
    this.visual.coWorkers = v;
  }

  @Mutation
  SET_JOB_OFFER_VISUAL(v: JobOfferVisaulToUpdate | null) {
    this.visual = v || defaultVisual;
  }

  @Mutation
  SET_VISUAL_LOADED(recruitmentId: string) {
    this.visualLoaded = recruitmentId;
  }

  @Action
  async loadJobbOfferVisual(obj: { recruitmentId: string; force: boolean }) {
    if (obj.force || this.visualLoaded !== obj.recruitmentId) {
      const token = await TokenHelpers.getToken();
      const visual = await jobOfferService.getJobbOfferVisual(
        obj.recruitmentId,
        token
      );
      jobbOfferVisualStore.SET_JOB_OFFER_VISUAL(visual);
      jobbOfferStore.SET_COMPARE_FILTERED_TASK_IDS(
        (visual || { taskIds: [] }).taskIds
      );
      jobbOfferStore.SET_COMPARE_FILTERED_SKILLS(
        (visual?.skills ?? []).reduce((acc: string[], s) => {
          if (s.id !== null) {
            return [...acc, s.id];
          }
          return acc;
        }, [])
      );

      jobbOfferVisualStore.SET_VISUAL_LOADED(obj.recruitmentId);
    }
  }

  @Action
  async updateJobbOfferVisual(v: {
    visual: JobOfferVisaulToUpdate;
    recruitmentId: string;
  }) {
    const token = await TokenHelpers.getToken();
    const etag = await jobOfferService.updateJobbOfferVisual(
      v.visual,
      v.recruitmentId,
      token
    );

    jobbOfferVisualStore.SET_JOB_OFFER_VISUAL({
      ...v.visual,
      etag,
    });

    jobbOfferStore.SET_RECRUITMENT_LIST(
      jobbOfferStore.recruitmentList.map(r => {
        if (r.id === v.recruitmentId) {
          return {
            ...r,
            departmentId: (v.visual.department || { id: null }).id,
            managerCoworkerId: (v.visual.manager || { id: null }).id,
            visualCoworkersIds: v.visual.coWorkers.map(cw => cw.id),
            includeInterview: v.visual.includeInterview,
          };
        }
        return r;
      })
    );

    jobbOfferVisualStore.SET_VISUAL_LOADED(v.recruitmentId);
  }
}
