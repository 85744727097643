var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"keep"}},[_c('v-app-bar',{staticClass:"start-app-bar",attrs:{"color":"white","clipped-left":"","app":"","elevate-on-scroll":""}},[_c('v-row',{staticClass:"app-bar-row px-4 px-md-8 ",attrs:{"no-gutters":"","justify":"center","align":"stretch"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center"},[_c('nuxt-link',{staticClass:"d-flex align-center",attrs:{"to":_vm.localePath({
                name: 'talent',
              })}},[_c('GritifyLogo')],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(_vm.isDone)?_c('v-btn',{staticClass:"d-md-none",attrs:{"color":"primary","text":"","exact":"","to":_vm.localePath({
                name: 'talent-editStatus',
              })}},[(_vm.isPaused)?_c('v-icon',{staticClass:"me-2 error--text"},[_vm._v("mdi-pause-octagon")]):_c('v-icon',{staticClass:"me-2 success--text"},[_vm._v("mdi-account-check")]),_vm._v(" "),_c('span',{class:[_vm.isPaused ? 'error--text' : 'success--text']},[_vm._v(_vm._s(_vm.isPaused ? _vm.$t("Pausad") : _vm.$t("Aktiv")))])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-none d-md-block"},[(!_vm.isDone)?_c('v-btn',{attrs:{"text":"","color":"primary","exact":"","to":_vm.localePath({
                  name: 'talent-createProfile',
                })}},[_c('v-icon',{staticClass:"me-2"},[_vm._v("mdi-pencil")]),_vm._v("\n              "+_vm._s(_vm.$t("Profilregistrering"))+"\n            ")],1):_vm._e(),_vm._v(" "),(_vm.isDone)?_c('v-btn',{attrs:{"text":"","color":"primary","exact":"","to":_vm.localePath({
                  name: 'talent-editStatus',
                })}},[(_vm.isPaused)?_c('v-icon',{staticClass:"me-1 error--text"},[_vm._v("mdi-pause-octagon")]):_c('v-icon',{staticClass:"me-1 success--text"},[_vm._v("mdi-account-check")]),_vm._v(" "),_c('span',{class:[_vm.isPaused ? 'error--text' : 'success--text']},[_vm._v(_vm._s(_vm.isPaused ? _vm.$t("Pausad") : _vm.$t("Aktiv")))])],1):_vm._e(),_vm._v(" "),(_vm.isDone)?_c('v-btn',{attrs:{"text":"","color":"primary","exact":"","to":_vm.localePath({
                  name: 'talent',
                })}},[_c('v-icon',{staticClass:"me-2"},[_vm._v("mdi-apps")]),_vm._v("\n              "+_vm._s(_vm.$t("Översikt"))+"\n            ")],1):_vm._e(),_vm._v(" "),(_vm.isDone)?_c('v-btn',{attrs:{"text":"","color":"primary","to":_vm.localePath({
                  name: 'talent-myExperience',
                })}},[_c('v-icon',{staticClass:"me-2"},[_vm._v("mdi-briefcase-check")]),_vm._v("\n              "+_vm._s(_vm.$t("Mina erfarenheter"))+"\n            ")],1):_vm._e(),_vm._v(" "),(_vm.isDone)?_c('v-btn',{attrs:{"text":"","color":"primary","exact":"","to":_vm.localePath({
                  name: 'talent-myPreferences',
                })}},[_c('v-icon',{staticClass:"me-2"},[_vm._v("mdi-format-list-checks")]),_vm._v("\n              "+_vm._s(_vm.$t("Mina preferenser"))+"\n            ")],1):_vm._e(),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary","exact":"","to":_vm.localePath({
                  name: 'talent-accountsettings',
                })}},[_c('v-icon',{staticClass:"me-2"},[_vm._v("mdi-cog")]),_vm._v("\n              "+_vm._s(_vm.$t("Mitt konto"))+"\n            ")],1),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":_vm.logout}},on),[_c('v-icon',[_vm._v("mdi-logout")])],1)]}}])},[_vm._v("\n              "+_vm._s(_vm.$t("Logga ut"))+"\n            ")])],1),_vm._v(" "),_c('v-toolbar-items',{staticClass:"d-md-none"},[_c('v-btn',{staticClass:"primary--text",attrs:{"text":""},on:{"click":function($event){_vm.phoneMenuOpen = true}}},[_c('v-icon',[_vm._v("mdi-menu")])],1)],1)],1)])],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"fullscreen":"","transition":"dialog-top-transition"},model:{value:(_vm.phoneMenuOpen),callback:function ($$v) {_vm.phoneMenuOpen=$$v},expression:"phoneMenuOpen"}},[_c('v-card',{staticClass:"d-flex flex-column"},[_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":"","justify":"center","align":"stretch"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"d-flex"},[_c('GritifyLogo'),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"primary--text",attrs:{"text":""},on:{"click":function($event){_vm.phoneMenuOpen = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column mt-12"},[(!_vm.isDone)?_c('GrNavLink',{class:{
                'accent--text':
                  _vm.$route.path.toLowerCase() ===
                  _vm.localeRoute('/talent/createprofile').path.toLowerCase(),
              },staticStyle:{"margin-top":"35px","cursor":"pointer"},attrs:{"link":false},on:{"click":_vm.goToCreateProfile}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"primary--text me-2",class:{
                    'accent--text':
                      _vm.$route.path.toLowerCase() ===
                      _vm.localeRoute('/talent/createprofile').path.toLowerCase(),
                  }},[_vm._v("\n                  mdi-apps\n                ")]),_vm._v("\n                "+_vm._s(_vm.$t("Profilregistrering"))+"\n              ")],1)]):_vm._e(),_vm._v(" "),(_vm.isDone)?_c('GrNavLink',{class:{
                'accent--text':
                  _vm.$route.path.toLowerCase() ===
                  _vm.localeRoute('/talent').path.toLowerCase(),
              },staticStyle:{"margin-top":"35px","cursor":"pointer"},attrs:{"link":false},on:{"click":_vm.goHome}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"primary--text me-2",class:{
                    'accent--text':
                      _vm.$route.path.toLowerCase() ===
                      _vm.localeRoute('/talent').path.toLowerCase(),
                  }},[_vm._v("\n                  mdi-apps\n                ")]),_vm._v("\n                "+_vm._s(_vm.$t("Översikt"))+"\n              ")],1)]):_vm._e(),_vm._v(" "),(_vm.isDone)?_c('GrNavLink',{class:{
                'accent--text':
                  _vm.$route.path.toLowerCase() ===
                  _vm.localeRoute('/talent/myexperience').path.toLowerCase(),
              },staticStyle:{"margin-top":"35px","cursor":"pointer"},attrs:{"link":false},on:{"click":_vm.goToMyExperience}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"primary--text me-2",class:{
                    'accent--text':
                      _vm.$route.path.toLowerCase() ===
                      _vm.localeRoute('/talent/myexperience').path.toLowerCase(),
                  }},[_vm._v("\n                  mdi-briefcase-check\n                ")]),_vm._v("\n                "+_vm._s(_vm.$t("Mina erfarenheter"))+"\n              ")],1)]):_vm._e(),_vm._v(" "),(_vm.isDone)?_c('GrNavLink',{class:{
                'accent--text':
                  _vm.$route.path.toLowerCase() ===
                  _vm.localeRoute('/talent/mypreferences').path.toLowerCase(),
              },staticStyle:{"margin-top":"35px","cursor":"pointer"},attrs:{"link":false},on:{"click":_vm.goToMyPreferences}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"primary--text me-2",class:{
                    'accent--text':
                      _vm.$route.path.toLowerCase() ===
                      _vm.localeRoute('/talent/mypreferences').path.toLowerCase(),
                  }},[_vm._v("\n                  mdi-format-list-checks\n                ")]),_vm._v("\n                "+_vm._s(_vm.$t("Mina preferenser"))+"\n              ")],1)]):_vm._e(),_vm._v(" "),_c('GrNavLink',{class:{
                'accent--text':
                  _vm.$route.path.toLowerCase() ===
                  _vm.localeRoute('/talent/accountsettings').path.toLowerCase(),
              },staticStyle:{"margin-top":"35px","cursor":"pointer"},attrs:{"link":false},on:{"click":_vm.goToAccountSettings}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"primary--text me-2",class:{
                    'accent--text':
                      _vm.$route.path.toLowerCase() ===
                      _vm.localeRoute(
                        '/talent/accountsettings'
                      ).path.toLowerCase(),
                  }},[_vm._v("\n                  mdi-cog\n                ")]),_vm._v("\n                "+_vm._s(_vm.$t("Mitt konto"))+"\n              ")],1)]),_vm._v(" "),_c('GrNavLink',{staticStyle:{"margin-top":"35px","cursor":"pointer"},attrs:{"link":false},on:{"click":_vm.logout}},[_vm._v("\n              "+_vm._s(_vm.$t("Logga ut"))+"\n            ")])],1)])],1)],1)],1),_vm._v(" "),_c('v-main',[_c('v-dialog',{attrs:{"max-width":"700","value":_vm.isDisconnectedFromSignalR && !_vm.ignoredDisconnected,"persistent":""}},[_c('v-card',[_c('v-card-title',[_vm._v("\n          "+_vm._s(_vm.$t("Nätverksproblem"))+"\n        ")]),_vm._v(" "),_c('v-card-text',{staticClass:"black--text"},[_vm._v("\n          "+_vm._s(_vm.$t("Liveuppdateringar är just nu inte..."))+"\n        ")]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.cancelReloadPage}},[_vm._v("\n            "+_vm._s(_vm.$t("Ignorera"))+"\n          ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.reloadSite}},[_vm._v("\n            "+_vm._s(_vm.$t("Ladda om sidan"))+"\n          ")])],1)],1)],1),_vm._v(" "),_c('nuxt')],1),_vm._v(" "),_c('confirm',{ref:"confirm"}),_vm._v(" "),_c('SuccessErrorSnackbar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }