import { Middleware } from "@nuxt/types";
import { authStore, profileLookupsStore, talentProfileStore } from "~/store";
import PublicApisService from "~/services/publicApisService";
import CookiesHelper from "~/helpers/CookiesHelper";

// eslint-disable-next-line space-before-function-paren
const myMiddleware: Middleware = async ({ app, error, redirect, route }) => {
  if (!navigator.cookieEnabled) {
    error({
      message: "Slå på cookies!",
      statusCode: 667,
    });
    return;
  }

  await app.$msal.loadAuthModule();

  const token = await app.$msal.acquireToken();

  if (typeof token === "string") {
    authStore.SET_ACCESS_TOKEN(token);
    if (authStore.companyId) {
      redirect(
        `${app.i18n.locale === "sv" ? "" : "/" + app.i18n.locale}/company/${
          authStore.companyId
        }`
      );
    } else {
      talentProfileStore.connectSignalR();
    }
  } else {
    if (
      authStore.isCreatingAccount &&
      talentProfileStore.profile.expectations
    ) {
      authStore.SET_IS_CREATING_ACCOUNT(false);
      const expectationsId = await PublicApisService.addSignUpExpectations({
        expectations: {
          ...talentProfileStore.profile.expectations,
          InvitedByTalentId:
            profileLookupsStore.friendInvitationData?.talentId ?? null,
        },
      });

      const gaCookieInfo = CookiesHelper.getCookie("_ga");

      app.$msal.signIn({
        scopes: [
          process.env.ENVIRONMENT === "Production"
            ? "https://gritifyauth.onmicrosoft.com/api/gritify"
            : process.env.ENVIRONMENT === "Stage"
            ? "https://gritifyauthstage.onmicrosoft.com/api/gritify"
            : "https://gritifytalentauthtest.onmicrosoft.com/api/talent",
        ],
        extraQueryParameters: {
          talentExpectations: expectationsId,
          policy_type: "SignUp",
          user_type: "1",
          ui_locales: app.i18n.locale,
          _ga: gaCookieInfo,
        },
      });
    } else if (
      route.query.ForgottenPassword &&
      !(await app.$msal.isAuthenticated())
    ) {
      app.$msal.signIn({
        scopes: [
          process.env.ENVIRONMENT === "Production"
            ? "https://gritifyauth.onmicrosoft.com/api/gritify"
            : process.env.ENVIRONMENT === "Stage"
            ? "https://gritifyauthstage.onmicrosoft.com/api/gritify"
            : "https://gritifytalentauthtest.onmicrosoft.com/api/gritify",
        ],
        extraQueryParameters: {
          policy_type: "ForgotPassword",
          user_type: "1",
          ui_locales: app.i18n.locale,
        },
      });
    } else {
      app.$msal.signIn({
        scopes: [
          process.env.ENVIRONMENT === "Production"
            ? "https://gritifyauth.onmicrosoft.com/api/gritify"
            : process.env.ENVIRONMENT === "Stage"
            ? "https://gritifyauthstage.onmicrosoft.com/api/gritify"
            : "https://gritifytalentauthtest.onmicrosoft.com/api/gritify",
        ],
        extraQueryParameters: {
          policy_type: "Login",
          ui_locales: app.i18n.locale,
        },
      });
    }
    error({
      statusCode: 666,
      message: "du måste vara inloggad",
    });
  }
};

export default myMiddleware;
