import { render, staticRenderFns } from "./createCompanyAccountLayout.vue?vue&type=template&id=eb6469cc&scoped=true&"
import script from "./createCompanyAccountLayout.vue?vue&type=script&lang=ts&"
export * from "./createCompanyAccountLayout.vue?vue&type=script&lang=ts&"
import style0 from "./createCompanyAccountLayout.vue?vue&type=style&index=0&id=eb6469cc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb6469cc",
  null
  
)

/* custom blocks */
import block0 from "./createCompanyAccountLayout.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VApp,VAppBar,VMain,VSheet})
