import axios from "axios";
import { gritifyApiUrl } from "./ServiceHelper";
import { CompanyTalentGetDto } from "~/models/types";

export default class CompanyTalentService {
  public static getCompanyTalentGetDtoByTalentId(v: {
    talentId: string;
    token: string;
  }): Promise<CompanyTalentGetDto | null> {
    return axios
      .get<CompanyTalentGetDto | null>(
        `${gritifyApiUrl}/GetCompanyTalentGetDtoByTalentId?talentId=${v.talentId}`,
        {
          headers: {
            authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static getCompanyTalentGetDtos(v: {
    accessToken: string;
  }): Promise<CompanyTalentGetDto[]> {
    return axios
      .get<CompanyTalentGetDto[]>(`${gritifyApiUrl}/GetCompanyTalentGetDtos`, {
        headers: {
          authorization: `Bearer ${v.accessToken}`,
        },
      })
      .then(x => x.data);
  }

  public static getPausedTalentIds(v: {
    accessToken: string;
    talentIds: string[];
  }): Promise<string[]> {
    return axios
      .post<string[]>(
        `${gritifyApiUrl}/GetPausedTalentIds`,
        {
          talentIds: v.talentIds,
        },
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }
}
