import Vue from "vue";
import GrHeadline from "~/components/styling/GrHeadline.vue";
import GrTextBlock from "~/components/styling/GrTextBlock.vue";
import GritifyLogo from "~/components/styling/GritifyLogo.vue";
import GrButtonPrimary from "~/components/styling/GrButtonPrimary.vue";
import GrNavLink from "~/components/styling/GrNavLink.vue";
import SuccessErrorSnackbar from "~/components/base/SuccessErrorSnackbar.vue";
import GrHtmlSpan from "~/components/base/GrHtmlSpan.vue";
import TalentPoolIcon from "~/components/styling/TalentPoolIcon.vue";
import TeamTailorIcon from "~/components/styling/TeamTailorIcon.vue";
import JobylonIcon from "~/components/styling/JobylonIcon.vue";
import GrButtonNew from "~/components/common/GrButtonNew.vue";

Vue.component("GrHeadline", GrHeadline);
Vue.component("GrTextBlock", GrTextBlock);
Vue.component("GritifyLogo", GritifyLogo);
Vue.component("GrButtonPrimary", GrButtonPrimary);
Vue.component("GrNavLink", GrNavLink);
Vue.component("SuccessErrorSnackbar", SuccessErrorSnackbar);
Vue.component("GrHtmlSpan", GrHtmlSpan);
Vue.component("TalentPoolIcon", TalentPoolIcon);
Vue.component("TeamTailorIcon", TeamTailorIcon);
Vue.component("JobylonIcon", JobylonIcon);
Vue.component("GrButtonNew", GrButtonNew);
