import { render, staticRenderFns } from "./StartAccessSuccessModal.vue?vue&type=template&id=7b03f272&scoped=true&"
import script from "./StartAccessSuccessModal.vue?vue&type=script&lang=ts&"
export * from "./StartAccessSuccessModal.vue?vue&type=script&lang=ts&"
import style0 from "./StartAccessSuccessModal.vue?vue&type=style&index=0&id=7b03f272&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b03f272",
  null
  
)

/* custom blocks */
import block0 from "./StartAccessSuccessModal.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5Ca%5C1%5Cs%5Ccomponents%5Ccompany%5CStartAccessSuccessModal.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VCheckbox,VDialog,VIcon,VImg,VSpacer,VTooltip})
