import axios from "axios";
import { gritifyApiUrl } from "./ServiceHelper";
import { LaneForLibrary, LaneForLibraryToAdd } from "~/models/types";

export default class CompanyLaneService {
  public static getAllLanes(v: {
    accessToken: string;
    lang: string;
  }): Promise<LaneForLibrary[]> {
    return axios
      .get<LaneForLibrary[]>(
        `${gritifyApiUrl}/GetCompanyLanes?lang=${v.lang}`,
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => r.data);
  }

  public static updateLane(v: {
    accessToken: string;
    lane: LaneForLibrary;
  }): Promise<void> {
    return axios
      .put<void>(`${gritifyApiUrl}/UpdateCompanyLane`, v.lane, {
        headers: {
          Authorization: `Bearer ${v.accessToken}`,
        },
      })
      .then(r => {
        return r.data;
      });
  }

  public static updateLaneSortOrder(v: {
    accessToken: string;
    laneIds: string[];
  }): Promise<void> {
    return axios
      .put<void>(`${gritifyApiUrl}/UpdateCompanyLaneSortOrder`, v.laneIds, {
        headers: {
          Authorization: `Bearer ${v.accessToken}`,
        },
      })
      .then(r => {
        return r.data;
      });
  }

  public static addLane(v: {
    accessToken: string;
    lane: LaneForLibraryToAdd;
  }): Promise<string> {
    return axios
      .post<string>(`${gritifyApiUrl}/CreateCompanyLane`, v.lane, {
        headers: {
          Authorization: `Bearer ${v.accessToken}`,
        },
      })
      .then(r => {
        return r.data;
      });
  }

  public static updateRecruitmentLanes(v: {
    accessToken: string;
    laneIds: string[];
    recruitmentId: string;
  }): Promise<void> {
    return axios
      .put<void>(
        `${gritifyApiUrl}/UpdateRecruitmentLanes?recruitmentId=${v.recruitmentId}`,
        v.laneIds,
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }
}
