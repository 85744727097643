





















import { Vue, Component, Prop } from "nuxt-property-decorator";

@Component({
  inheritAttrs: false,
})
export default class GrNavLink extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly link!: boolean;

  @Prop({ type: String, default: "primary--text" })
  readonly textClass!: string;

  get styleObj() {
    return {
      fontSize: `${this.fontSize}px`,
      lineHeight: `${this.lineHeight}px`,
      fontWeight: `${this.fontWeigth}`,
    };
  }

  get fontSize(): number {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return 24;
      case "sm":
        return 30;
      case "md":
      case "lg":
      case "xl":
        return 14;
    }
  }

  get fontWeigth(): number {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return 700;
      case "sm":
        return 700;
      case "md":
      case "lg":
      case "xl":
        return 600;
    }
  }

  get lineHeight(): number {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return 26;
      case "sm":
        return 30;
      case "md":
      case "lg":
      case "xl":
        return 14;
    }
  }
}
