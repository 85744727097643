import {
  Configuration,
  PublicClientApplication,
  AccountInfo,
  RedirectRequest,
  AuthenticationResult,
} from "@azure/msal-browser";

export default class GritifyMsal {
  lib: PublicClientApplication;
  request: RedirectRequest;
  private account: AccountInfo | null; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-common/modules/_src_account_accountinfo_.html

  data: AuthenticationResult | null = null;
  constructor(conf: Configuration, defaultRequest: RedirectRequest) {
    this.lib = new PublicClientApplication(conf);
    this.request = defaultRequest;
    this.account = null;
  }

  signIn(request?: RedirectRequest) {
    // if (!this.lib.isCallback(window.location.hash) && !this.lib.getAccount()) {
    // request can be used for login or token request, however in more complex situations this can have diverging options
    this.lib.loginRedirect(request || this.request);
    // }
  }

  signOut() {
    this.lib.logout();
  }

  async acquireToken(
    request: RedirectRequest = this.request
  ): Promise<string | false> {
    try {
      if (this.account) {
        request = {
          ...request,
          account: this.account,
        };
      }
      // Always start with acquireTokenSilent to obtain a token in the signed in user from cache
      const response = await this.lib.acquireTokenSilent(request);
      this.data = response;
      return response.accessToken;
    } catch (error) {
      // Upon acquireTokenSilent failure (due to consent or interaction or login required ONLY)
      // Call acquireTokenRedirect
      if (this.requiresInteraction(error.errorCode)) {
        this.lib.acquireTokenRedirect(request); // acquireTokenPopup
      }
      return false;
    }
  }

  acquireTokenRedirect(request: RedirectRequest = this.request): void {
    if (this.account) {
      request = {
        ...request,
        account: this.account,
      };
    }
    this.lib.acquireTokenRedirect(request);
  }

  /**
   * Checks whether we are in the middle of a redirect and handles state accordingly. Only required for redirect flows.
   *
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#redirect-apis
   */
  async loadAuthModule() {
    const result = await this.lib
      .handleRedirectPromise()
      .then((resp: AuthenticationResult | null) => {
        this.handleResponse(resp);
        return resp;
      })
      .catch(console.error);

    return result;
  }

  /**
   * Handles the response from a popup or redirect. If response is null, will check if we have any accounts and attempt to sign in.
   * @param response
   */
  handleResponse(response: AuthenticationResult | null) {
    if (response !== null) {
      this.account = response.account;
    } else {
      this.account = this.getAccount();
    }
  }

  async isAuthenticated(): Promise<boolean> {
    const account = this.getAccount();
    if (!account) {
      return false;
    }
    try {
      const response = await this.lib.acquireTokenSilent({
        ...this.request,
        account,
      });
      return !!response.accessToken;
    } catch (error) {
      return false;
    }
  }

  private getAccount(): AccountInfo | null {
    // need to call getAccount here?
    const currentAccounts = this.lib.getAllAccounts();
    if (currentAccounts === null) {
      console.log("No accounts detected");
      return null;
    }

    if (currentAccounts.length > 1) {
      // Add choose account code here
      console.log(
        "Multiple accounts detected, need to add choose account code."
      );
      return currentAccounts[0]!;
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0]!;
    }

    return null;
  }

  private requiresInteraction(errorCode: string) {
    if (!errorCode || !errorCode.length) {
      return false;
    }
    return (
      errorCode === "consent_required" ||
      errorCode === "interaction_required" ||
      errorCode === "login_required"
    );
  }
}
