import { render, staticRenderFns } from "./CompanySuperSearch.vue?vue&type=template&id=2b3ceb00&scoped=true&"
import script from "./CompanySuperSearch.vue?vue&type=script&lang=ts&"
export * from "./CompanySuperSearch.vue?vue&type=script&lang=ts&"
import style0 from "./CompanySuperSearch.vue?vue&type=style&index=0&id=2b3ceb00&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b3ceb00",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAvatar,VCard,VCardText,VIcon,VList,VListItem,VListItemAvatar,VListItemTitle,VMenu,VTextField})
