




















































import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import FilterHelpers from "~/helpers/filterHelpers";
import TalentHelpers from "~/helpers/talentHelpers";
import { TalentConversation } from "~/models/types";
import { companyBaseStore, jobbOfferStore, talentPoolStore } from "~/store";

@Component
export default class ConversationListItem extends Vue {
  @Emit("click")
  clickEmit() {}

  @Prop({ type: Object, required: true })
  readonly item!: TalentConversation;

  @Prop({ type: Boolean, required: true })
  readonly isSelected!: boolean;

  get name(): string {
    return TalentHelpers.getNameForTalent({
      recTalent: {
        accepted: new Date(),
        name: this.item.talentName,
        talentId: this.item.talentId,
      },
      poolTalents: talentPoolStore.poolTalents,
      companyTalents: companyBaseStore.companyTalents,
    });
  }

  get initials(): string {
    return FilterHelpers.getInitials(this.name);
  }

  get lastMessageShort(): string {
    return this.item.lastMessage;
  }

  get dateString(): string {
    return FilterHelpers.getFromNowString(this.item.lastMessageDateTimeUtc);
  }

  get recruitmentTitle(): string | null {
    if (this.item.recruitmentIdOrNull === null) {
      return null;
    }

    return (
      jobbOfferStore.recruitmentList.find(
        r => r.id === this.item.recruitmentIdOrNull
      )?.title ?? null
    );
  }
}
