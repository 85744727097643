import {
  CompanyProfileDto,
  ProfileDiffType,
  CompanyProfileListItemWithImage,
  CompanyProfileListItemWithIcon,
  CompanyProfileMilestone,
  CoWorkerDto,
  CompanyProfileOffice,
  UpdateInShortDto,
  CompanyProfileListItem,
  GetWorkingProfileDto,
  PublishedCompoanyProfileDto,
  BusinessAreasType,
} from "~/models/types";

export default class CompanyProfileHelper {
  static MapToCompanyProfileDto(
    profile: PublishedCompoanyProfileDto
  ): CompanyProfileDto {
    return {
      logoUrl: profile.logoUrl,
      companyEmail: profile.companyEmail,
      companyName: profile.companyName,
      ingress: profile.ingress,
      logoIncludesName: profile.logoIncludesName,
      branchId: profile.branchId,
      industryId: profile.industryId,
      businessAreasType:
        profile.businessAreasType || BusinessAreasType.BusinessAreas,
      atAGlance: {
        message: profile.message,
        inShort: profile.inShort,
        socialMedia: profile.socialMedia,
      },
      company: {
        companyVideoUrl: profile.companyVideoUrl,
        companyVideoType: profile.companyVideoType || "Youtube",
        businessAreas: profile.businessAreas,
        values: {
          valuesVisible: profile.valuesVisible,
          valuesImageUrl: profile.valuesImageUrl,
          valueList: profile.values,
        },
        milestones: {
          milestonesVisible: profile.milestonesVisible,
          milestoneList: profile.milestones,
        },
      },
      people: {
        statistics: profile.statistics,
        team: profile.team,
        teamVisible: profile.teamVisible,
        perks: {
          perksImageUrl: profile.perksImageUrl,
          perksVisible: profile.perksVisible,
          perkList: profile.perks,
        },
        roles: {
          visible: profile.rolesVisible,
          roleList: profile.roles,
        },
        offices: profile.offices.map(o => ({
          description: o.description || "",
          id: o.id,
          name: o.name,
          officeImage: o.officeImage,
          latitude: o.latitude,
          longitude: o.longitude,
        })),
      },
    };
  }

  static getMappedProfile(p: GetWorkingProfileDto): GetWorkingProfileDto {
    return {
      ...p,
      teamVisible: p.teamVisible || false,
      companyVideoType: p.companyVideoType || "Youtube",
      logoIncludesName: p.logoIncludesName || false,
      inShort: p.inShort.map(x => {
        return {
          ...x,
          useThousandsSeparator: x.disableThousandsSeparator || false,
        };
      }),
    };
  }

  static getDiffs(
    a: CompanyProfileDto,
    b: CompanyProfileDto
  ): ProfileDiffType[] {
    // const a = this.getProfileWithDefaults(aIn);
    // const b = this.getProfileWithDefaults(bIn);

    const result: ProfileDiffType[] = [];

    if (
      ((
        (a.atAGlance || { message: { imageUrl: null } }).message || {
          imageUrl: null,
        }
      ).imageUrl || null) !==
      ((
        (b.atAGlance || { message: { imageUrl: null } }).message || {
          imageUrl: null,
        }
      ).imageUrl || null)
    ) {
      result.push(ProfileDiffType.CoverImage);
    }

    if ((a.ingress || null) !== (b.ingress || null)) {
      result.push(ProfileDiffType.Ingress);
    }

    if (a.logoIncludesName !== b.logoIncludesName) {
      result.push(ProfileDiffType.LogoIncludesName);
    }

    const aBusinessAreas = (
      a.company || { businessAreas: <CompanyProfileListItemWithImage[]>[] }
    ).businessAreas
      .map(x => `${x.imageUrl}|${x.longDescription}|${x.shortDescription}`)
      .join(",");
    const bBusinessAreas = (
      b.company || { businessAreas: <CompanyProfileListItemWithImage[]>[] }
    ).businessAreas
      .map(x => `${x.imageUrl}|${x.longDescription}|${x.shortDescription}`)
      .join(",");

    if (aBusinessAreas !== bBusinessAreas) {
      result.push(ProfileDiffType.BusinessAreas);
    }

    const aValuesList = (
      (a.company || { values: null }).values || {
        valueList: <CompanyProfileListItemWithIcon[]>[],
      }
    ).valueList
      .map(x => `${x.icon}|${x.longDescription}|${x.shortDescription}`)
      .join(",");
    const bValuesList = (
      (b.company || { values: null }).values || {
        valueList: <CompanyProfileListItemWithIcon[]>[],
      }
    ).valueList
      .map(x => `${x.icon}|${x.longDescription}|${x.shortDescription}`)
      .join(",");

    if (aValuesList !== bValuesList) {
      result.push(ProfileDiffType.CompanyValuesList);
    }

    const aValuesVisible = (
      (a.company || { values: null }).values || { valuesVisible: false }
    ).valuesVisible;

    const bValuesVisible = (
      (b.company || { values: null }).values || { valuesVisible: false }
    ).valuesVisible;

    if (aValuesVisible !== bValuesVisible) {
      result.push(ProfileDiffType.CompanyValuesVisible);
    }

    const aValuesImageUrl =
      ((a.company || { values: null }).values || { valuesImageUrl: null })
        .valuesImageUrl || null;

    const bValuesImageUrl =
      ((b.company || { values: null }).values || { valuesImageUrl: null })
        .valuesImageUrl || null;

    if (aValuesImageUrl !== bValuesImageUrl) {
      result.push(ProfileDiffType.CompanyValuesImage);
    }

    const aMilestonesList = (
      (a.company || { milestones: null }).milestones || {
        milestoneList: <CompanyProfileMilestone[]>[],
      }
    ).milestoneList
      .map(
        x =>
          `${x.imageUrl}|${x.longDescription}|${x.shortDescription}|${x.year}`
      )
      .join(",");
    const bMilestonesList = (
      (b.company || { milestones: null }).milestones || {
        milestoneList: <CompanyProfileMilestone[]>[],
      }
    ).milestoneList
      .map(
        x =>
          `${x.imageUrl}|${x.longDescription}|${x.shortDescription}|${x.year}`
      )
      .join(",");

    if (aMilestonesList !== bMilestonesList) {
      result.push(ProfileDiffType.milestonesList);
    }

    const aMilestonesVisible = (
      (a.company || { milestones: null }).milestones || {
        milestonesVisible: false,
      }
    ).milestonesVisible;
    const bMilestonesVisible = (
      (b.company || { milestones: null }).milestones || {
        milestonesVisible: false,
      }
    ).milestonesVisible;

    if (aMilestonesVisible !== bMilestonesVisible) {
      result.push(ProfileDiffType.milestonesVisible);
    }

    const aTeam = (a.people || { team: <CoWorkerDto[]>[] }).team
      .map(m => m.imageUrl)
      .join(",");
    const bTeam = (b.people || { team: <CoWorkerDto[]>[] }).team
      .map(m => m.imageUrl)
      .join(",");

    if (aTeam !== bTeam) {
      result.push(ProfileDiffType.team);
    }

    const aTeamVisible = (a.people || { teamVisible: false }).teamVisible;
    const bTeamVisible = (b.people || { teamVisible: false }).teamVisible;

    if (aTeamVisible !== bTeamVisible) {
      result.push(ProfileDiffType.teamVisible);
    }

    const aOffices = (
      (a.people || { offices: <CompanyProfileOffice[]>[] }).offices || []
    )
      .map(m => m.name)
      .join(",");
    const bOffices = (
      (b.people || { offices: <CompanyProfileOffice[]>[] }).offices || []
    )
      .map(m => m.name)
      .join(",");

    if (aOffices !== bOffices) {
      result.push(ProfileDiffType.offices);
    }

    const aStats = (a.people || { statistics: null }).statistics || {
      noOfCountries: null,
      noOfEmployees: null,
      noOfMarkets: null,
    };
    const bStats = (b.people || { statistics: null }).statistics || {
      noOfCountries: null,
      noOfEmployees: null,
      noOfMarkets: null,
    };

    if (
      (aStats.noOfCountries || 0) !== (bStats.noOfCountries || 0) ||
      (aStats.noOfEmployees || 0) !== (bStats.noOfEmployees || 0) ||
      (aStats.noOfMarkets || 0) !== (bStats.noOfMarkets || 0)
    ) {
      result.push(ProfileDiffType.inNumbers);
    }

    const aInShort = (
      (a.atAGlance || { inShort: <UpdateInShortDto[]>[] }).inShort || []
    )
      .map(
        x =>
          `${x.icon}|${x.number}|${x.prefix || ""}|${x.suffix || ""}|${x.text ||
            ""}`
      )
      .join(",");
    const bInShort = (
      (b.atAGlance || { inShort: <UpdateInShortDto[]>[] }).inShort || []
    )
      .map(
        x =>
          `${x.icon}|${x.number}|${x.prefix || ""}|${x.suffix || ""}|${x.text ||
            ""}`
      )
      .join(",");

    if (aInShort !== bInShort) {
      result.push(ProfileDiffType.inNumbers);
    }

    const aRolesList = (
      (
        (a.people || { roles: null }).roles || {
          roleList: <CompanyProfileListItem[]>[],
        }
      ).roleList || []
    )
      .map(r => `${r.longDescription}|${r.shortDescription}`)
      .join(",");
    const bRolesList = (
      (
        (b.people || { roles: null }).roles || {
          roleList: <CompanyProfileListItem[]>[],
        }
      ).roleList || []
    )
      .map(r => `${r.longDescription}|${r.shortDescription}`)
      .join(",");

    if (aRolesList !== bRolesList) {
      result.push(ProfileDiffType.rolesList);
    }

    const aRolesVisible = (
      (a.people || { roles: null }).roles || { visible: false }
    ).visible;
    const bRolesVisible = (
      (b.people || { roles: null }).roles || { visible: false }
    ).visible;

    if (aRolesVisible !== bRolesVisible) {
      result.push(ProfileDiffType.rolesVisible);
    }

    const aVideo =
      (a.company || { companyVideoUrl: null }).companyVideoUrl || "";
    const bVideo =
      (b.company || { companyVideoUrl: null }).companyVideoUrl || "";

    if (aVideo !== bVideo) {
      result.push(ProfileDiffType.companyVideo);
    }

    const aSocialMedia = (a.atAGlance || { socialMedia: null }).socialMedia || {
      facebookUrl: null,
      instagramUrl: null,
      linkedInUrl: null,
      twitterUrl: null,
      youtubeUrl: null,
    };

    const bSocialMedia = (b.atAGlance || { socialMedia: null }).socialMedia || {
      facebookUrl: null,
      instagramUrl: null,
      linkedInUrl: null,
      twitterUrl: null,
      youtubeUrl: null,
    };

    if (
      (aSocialMedia.facebookUrl || null) !==
        (bSocialMedia.facebookUrl || null) ||
      (aSocialMedia.instagramUrl || null) !==
        (bSocialMedia.instagramUrl || null) ||
      (aSocialMedia.linkedInUrl || null) !==
        (bSocialMedia.linkedInUrl || null) ||
      (aSocialMedia.twitterUrl || null) !== (bSocialMedia.twitterUrl || null) ||
      (aSocialMedia.youtubeUrl || null) !== (bSocialMedia.youtubeUrl || null)
    ) {
      result.push(ProfileDiffType.socialMedia);
    }

    if (a.businessAreasType !== b.businessAreasType) {
      result.push(ProfileDiffType.businessAreaType);
    }

    return [...new Set(result)];
  }
}
