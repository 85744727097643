import axios from "axios";
import { publicApiUrl } from "./ServiceHelper";
import {
  CompanyInterestObject,
  AddCompetitionAnswerDto,
  GetCompanyDto,
  GetTokenByShortTokenDto,
  UpsertQuarterlyUpdateRegistrationDto,
  ApplyForCompanyAccountPublicStatus,
} from "~/models/types";
import { TalentExpectations } from "~/models/TalentExpectations";
import { ListObjectNullable } from "~/models/ListObjectNullable";

export default class PublicApisService {
  public static upsertCompanyInterest(v: {
    companyInterestObject: CompanyInterestObject;
  }): Promise<string> {
    return axios
      .post<string>(
        `${publicApiUrl}/UpsertCompanyInterest`,
        v.companyInterestObject
      )
      .then(x => x.data);
  }

  public static addSignUpExpectations(v: {
    expectations: TalentExpectations & { InvitedByTalentId: string | null };
  }): Promise<string> {
    return axios
      .post<string>(`${publicApiUrl}/SignUp/Expectations`, {
        ...v.expectations,
      })
      .then(x => x.data);
  }

  public static addCompetitionAnswer(v: {
    answer: AddCompetitionAnswerDto;
  }): Promise<void> {
    return axios
      .post<void>(`${publicApiUrl}/AddCompetitionAnswer`, v.answer)
      .then(x => x.data);
  }

  public static upsertQuarterlyUpdateRegistration(v: {
    dto: UpsertQuarterlyUpdateRegistrationDto;
  }): Promise<void> {
    return axios
      .post<void>(`${publicApiUrl}/UpsertQuarterlyUpdateRegistration`, v.dto)
      .then(x => x.data);
  }

  public static upsertQuarterlyUpdateBannerRemoval(): Promise<void> {
    return axios
      .post<void>(`${publicApiUrl}/UpsertQuarterlyUpdateBannerRemoval`, {})
      .then(x => x.data);
  }

  public static getCompanyById(v: {
    lang: string;
    token: string;
    comapnyId: string;
  }): Promise<GetCompanyDto | null> {
    return axios
      .get<GetCompanyDto | null>(
        `${publicApiUrl}/BasicData/Company?lang=${v.lang}&companyId=${v.comapnyId}`
      )
      .then(x => x.data);
  }

  public static getTokenByShortToken(v: {
    token: string;
  }): Promise<GetTokenByShortTokenDto> {
    return axios
      .get<GetTokenByShortTokenDto>(
        `${publicApiUrl}/GetTokenByShortToken?token=${v.token}`
      )
      .then(x => x.data);
  }
}

export const applyForCompanyAccountPublic = (v: {
  email: string;
  company: ListObjectNullable;
  campaignCode: string | null;
  lang: string;
}) => {
  return axios
    .post<{
      status: ApplyForCompanyAccountPublicStatus;
      campaignApproved: boolean;
      campaignText: string;
    }>(`${publicApiUrl}/ApplyForCompanyAccount`, v)
    .then(x => x.data);
};

export const createCompanyAccountFromApplication = (v: { token: string }) => {
  return axios
    .post<string>(`${publicApiUrl}/CreateCompanyAccountFromApplication`, null, {
      headers: {
        Authorization: `Bearer ${v.token}`,
      },
    })
    .then(x => x.data);
};
