import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import profileService from "~/services/profileService";
// import ColorHelper from "~/helpers/ColorHelper";
import {
  // CompanyProfileDto,
  UpdateInShortDto,
  SocialMediaLinks,
  UpdateStartImageDto,
  UpdateCompanyValuesDto,
  UpdateMileStonesDto,
  CompanyProfileListItemWithImage,
  CompanyProfileListItemWithIcon,
  CompanyProfileMilestone,
  UpdatePerksDto,
  UpdateCompanyProfileRolesDto,
  UpdatePeopleStatisticsDto,
  CompanyProfileListItem,
  GetWorkingProfileDto,
  CompanyProfileOffice,
  CoWorkerDto,
  Office,
  CompanyProfileDto,
  PublishedCompoanyProfileDto,
  OfficeToUpdate,
  BusinessAreasType,
  ImageEntityType,
  CompanyVideoType,
  RequiredCompanyProfileItem,
} from "~/models/types";
import {
  companyProfileStore,
  officesStore,
  coWorkersStore,
  companyBaseStore,
} from "~/utils/store-accessor";
import CompanyPhotosService from "~/services/CompanyPhotosService";
import CompanyProfileHelper from "~/helpers/CompanyProfileHelper";
import TokenHelpers from "~/helpers/tokenHelpers";
import { updatePerksByLang } from "~/services/companyService";

export const profileBaseDefault: GetWorkingProfileDto = {
  companyName: "",
  logoUrl: null,
  logoIncludesName: false,
  businessAreas: [],
  companyVideoUrl: null,
  companyVideoType: "UploadedVideo",
  ingress: null,
  businessAreasType: BusinessAreasType.BusinessAreas,
  branchId: "",
  industryId: null,
  inShort: [],
  message: {
    imageUrl: null,
    text: null,
  },
  milestones: [],
  milestonesVisible: false,
  perks: [],
  perksImageUrl: null,
  perksVisible: false,
  roles: [],
  coWorkerIds: [],
  officeIds: [],
  rolesVisible: false,
  socialMedia: {
    facebookUrl: null,
    instagramUrl: null,
    linkedInUrl: null,
    twitterUrl: null,
    youtubeUrl: null,
  },
  statistics: {
    noOfCountries: 0,
    noOfEmployees: 0,
    noOfMarkets: 0,
  },
  values: [],
  valuesImageUrl: null,
  valuesVisible: false,
  teamVisible: false,
};

@Module({
  name: "companyProfile",
  stateFactory: true,
  namespaced: true,
})
export default class CompanyProfile extends VuexModule {
  loadingInternal = false;
  profile: GetWorkingProfileDto = profileBaseDefault;
  private _publicProfile: PublishedCompoanyProfileDto = {
    ...profileBaseDefault,
    offices: [],
    team: [],
  };

  @Mutation
  SET_PUBLIC_PROFILE(v: PublishedCompoanyProfileDto) {
    this._publicProfile = v;
  }

  get publicProfile(): CompanyProfileDto {
    return {
      ...this._publicProfile,
      companyName: companyBaseStore.companyName,
      branchId: companyBaseStore.companyBranchId,
      businessAreasType:
        this._publicProfile.businessAreasType ||
        BusinessAreasType.BusinessAreas,
      atAGlance: {
        inShort: this._publicProfile.inShort,
        message: this._publicProfile.message,
        socialMedia: this._publicProfile.socialMedia,
      },
      company: {
        businessAreas: this._publicProfile.businessAreas,
        companyVideoUrl: this._publicProfile.companyVideoUrl,
        companyVideoType: this._publicProfile.companyVideoType || "Youtube",
        milestones: {
          milestoneList: this._publicProfile.milestones,
          milestonesVisible: this._publicProfile.milestonesVisible,
        },
        values: {
          valueList: this._publicProfile.values,
          valuesImageUrl: this._publicProfile.valuesImageUrl,
          valuesVisible: this._publicProfile.valuesVisible,
        },
      },
      people: {
        perks: {
          perkList: this._publicProfile.perks,
          perksImageUrl: this._publicProfile.perksImageUrl,
          perksVisible: this._publicProfile.perksVisible,
        },
        roles: {
          roleList: this._publicProfile.roles,
          visible: this._publicProfile.rolesVisible,
        },
        statistics: this._publicProfile.statistics,
        team: this._publicProfile.team,
        teamVisible: this._publicProfile.teamVisible,
        offices: this._publicProfile.offices.map(o => ({
          description: o.description || "",
          id: o.id,
          name: o.name,
          officeImage: o.officeImage,
          latitude: o.latitude,
          longitude: o.longitude,
        })),
      },
    };
  }

  get requiredItemsToComplete(): RequiredCompanyProfileItem[] {
    const result: RequiredCompanyProfileItem[] = [];
    if ((this.publicProfile.ingress ?? "").length === 0) {
      result.push(RequiredCompanyProfileItem.ShortDescription);
    }
    if ((this.publicProfile.people?.statistics?.noOfCountries ?? 0) === 0) {
      result.push(RequiredCompanyProfileItem.InNumbers);
    }

    return result;
  }

  get profileIsPublished(): boolean {
    return !this.requiredItemsToComplete.length;
  }

  get hasLogo(): boolean {
    return this.publicProfile.logoUrl !== null;
  }

  @Mutation
  setProfile(profile: GetWorkingProfileDto) {
    this.profile = profile;
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loadingInternal = loading;
  }

  @Mutation
  setCompanyLogo(url: string) {
    this.profile.logoUrl = url;
  }

  @Action
  async updatePerksByLang(v: { lang: string }) {
    const token = await TokenHelpers.getToken();

    const newPerks = await updatePerksByLang({
      accessToken: token,
      lang: v.lang,
    });

    companyProfileStore.setProfile({
      ...this.profile,
      perks: newPerks.map(p => ({
        icon: "",
        longDescription: "",
        shortDescription: p,
      })),
    });

    companyProfileStore.SET_PUBLIC_PROFILE({
      ...this._publicProfile,
      perks: newPerks.map(p => ({
        icon: "",
        longDescription: "",
        shortDescription: p,
      })),
    });
  }

  @Action
  async updateRoles({
    items,
    visible,
  }: {
    items: CompanyProfileListItem[];
    visible: boolean;
  }) {
    const newProfile: GetWorkingProfileDto = {
      ...this.profile,
      roles: items,
      rolesVisible: visible,
    };
    companyProfileStore.setLoading(true);
    const token = await TokenHelpers.getToken();
    await profileService.updateProfile(newProfile, token);
    await companyProfileStore.publishProfile();
    companyProfileStore.setProfile(newProfile);
    companyProfileStore.setLoading(false);
  }

  @Action
  async hideRoles() {
    const newProfile: GetWorkingProfileDto = {
      ...this.profile,
      rolesVisible: false,
    };
    companyProfileStore.setLoading(true);
    const token = await TokenHelpers.getToken();
    await profileService.updateProfile(newProfile, token);
    await companyProfileStore.publishProfile();
    companyProfileStore.setProfile(newProfile);
    companyProfileStore.setLoading(false);
  }

  @Action
  async updatePerks({
    items,
    image,
    visible,
  }: {
    items: CompanyProfileListItemWithIcon[];
    image: string | null;
    visible: boolean;
  }) {
    const newProfile: GetWorkingProfileDto = {
      ...this.profile,
      perks: items,
      perksVisible: visible,
      perksImageUrl: image,
    };
    companyProfileStore.setLoading(true);
    const token = await TokenHelpers.getToken();
    await profileService.updateProfile(newProfile, token);
    await companyProfileStore.publishProfile();
    companyProfileStore.setProfile(newProfile);
    companyProfileStore.setLoading(false);
  }

  @Action
  async updatePeopleStatistics(v: {
    peopleStatistics: UpdatePeopleStatisticsDto;
    inShort: UpdateInShortDto[];
  }) {
    const newProfile: GetWorkingProfileDto = {
      ...this.profile,
      statistics: v.peopleStatistics,
      inShort: v.inShort,
    };
    companyProfileStore.setLoading(true);
    const token = await TokenHelpers.getToken();
    await profileService.updateProfile(newProfile, token);
    companyProfileStore.setProfile(newProfile);
    await companyProfileStore.publishProfile();
    companyProfileStore.setLoading(false);
  }

  @Action
  async updateCompanyTeam(v: { coWorkerIds: string[]; visible: boolean }) {
    const newProfile: GetWorkingProfileDto = {
      ...this.profile,
      coWorkerIds: v.coWorkerIds,
      teamVisible: v.visible,
    };
    companyProfileStore.setLoading(true);
    const token = await TokenHelpers.getToken();
    await profileService.updateProfile(newProfile, token);
    await companyProfileStore.publishProfile();
    companyProfileStore.setProfile(newProfile);
    companyProfileStore.setLoading(false);
  }

  @Action
  async hideCompanyTeam() {
    const newProfile: GetWorkingProfileDto = {
      ...this.profile,
      teamVisible: false,
    };
    companyProfileStore.setLoading(true);
    const token = await TokenHelpers.getToken();
    await profileService.updateProfile(newProfile, token);
    await companyProfileStore.publishProfile();
    companyProfileStore.setProfile(newProfile);
    companyProfileStore.setLoading(false);
  }

  @Action
  async updateOfficeIds(officeIds: string[]) {
    const newProfile: GetWorkingProfileDto = {
      ...this.profile,
      officeIds,
    };
    companyProfileStore.setLoading(true);
    const token = await TokenHelpers.getToken();
    await profileService.updateProfile(newProfile, token);
    await companyProfileStore.publishProfile();
    companyProfileStore.setProfile(newProfile);
    companyProfileStore.setLoading(false);
  }

  @Action
  async updateCompanyValues(v: {
    valueList: CompanyProfileListItemWithIcon[];
    valuesVisible: boolean;
    imageFile: any;
  }) {
    const token = await TokenHelpers.getToken();

    companyProfileStore.setLoading(true);

    let imageUrl = this.profile.valuesImageUrl;

    if (v.imageFile) {
      imageUrl = await CompanyPhotosService.uploadImageToEntity({
        entityType: ImageEntityType.CompanyProfile,
        file: v.imageFile,
        token,
      });
    }

    const newProfile: GetWorkingProfileDto = {
      ...this.profile,
      values: v.valueList,
      valuesVisible: v.valuesVisible,
      valuesImageUrl: imageUrl,
    };

    await profileService.updateProfile(newProfile, token);
    await companyProfileStore.publishProfile();
    companyProfileStore.setProfile(newProfile);
    companyProfileStore.setLoading(false);
  }

  @Action
  async hideCompanyValues() {
    const token = await TokenHelpers.getToken();

    companyProfileStore.setLoading(true);

    const newProfile: GetWorkingProfileDto = {
      ...this.profile,
      valuesVisible: false,
    };

    await profileService.updateProfile(newProfile, token);
    await companyProfileStore.publishProfile();
    companyProfileStore.setProfile(newProfile);
    companyProfileStore.setLoading(false);
  }

  @Action
  async updateBusinessAreas(v: {
    businessAreasList: CompanyProfileListItemWithImage[];
    type: BusinessAreasType;
  }) {
    const newProfile: GetWorkingProfileDto = {
      ...this.profile,
      businessAreas: v.businessAreasList,
      businessAreasType: v.type,
    };
    companyProfileStore.setLoading(true);
    const token = await TokenHelpers.getToken();
    await profileService.updateProfile(newProfile, token);
    companyProfileStore.setProfile(newProfile);
    await companyProfileStore.publishProfile();
    companyProfileStore.setLoading(false);
  }

  @Action
  async updateCompanyVideoUrl(v: {
    url: string | null;
    type: CompanyVideoType;
  }) {
    const newProfile: GetWorkingProfileDto = {
      ...this.profile,
      companyVideoUrl: v.url,
      companyVideoType: v.type,
    };
    companyProfileStore.setLoading(true);
    const token = await TokenHelpers.getToken();
    await profileService.updateProfile(newProfile, token);
    await companyProfileStore.publishProfile();
    companyProfileStore.setProfile(newProfile);
    companyProfileStore.setLoading(false);
  }

  @Action
  async hideCompanyVideo() {
    const newProfile: GetWorkingProfileDto = {
      ...this.profile,
      companyVideoUrl: null,
    };
    companyProfileStore.setLoading(true);
    const token = await TokenHelpers.getToken();
    await profileService.updateProfile(newProfile, token);
    await companyProfileStore.publishProfile();
    companyProfileStore.setProfile(newProfile);
    companyProfileStore.setLoading(false);
  }

  @Action
  async updateMileStones(milestones: UpdateMileStonesDto) {
    const newProfile: GetWorkingProfileDto = {
      ...this.profile,
      milestones: milestones.milestoneList,
      milestonesVisible: milestones.milestonesVisible,
    };
    companyProfileStore.setLoading(true);
    const token = await TokenHelpers.getToken();
    await profileService.updateProfile(newProfile, token);
    await companyProfileStore.publishProfile();
    companyProfileStore.setProfile(newProfile);
    companyProfileStore.setLoading(false);
  }

  @Action
  async hideMilestones() {
    const newProfile: GetWorkingProfileDto = {
      ...this.profile,
      milestonesVisible: false,
    };
    companyProfileStore.setLoading(true);
    const token = await TokenHelpers.getToken();
    await profileService.updateProfile(newProfile, token);
    await companyProfileStore.publishProfile();
    companyProfileStore.setProfile(newProfile);
    companyProfileStore.setLoading(false);
  }

  @Action
  async publishProfile() {
    const token = await TokenHelpers.getToken();
    const response = await profileService.publishProfile(token);

    if (response.successfull) {
      companyProfileStore.SET_PUBLIC_PROFILE({
        ...this.profile,
        team: this.profile.coWorkerIds.reduce((acc: CoWorkerDto[], id) => {
          const coWorker = coWorkersStore.coWorkers.find(cw => cw.id === id);
          if (coWorker) {
            return [...acc, coWorker];
          }
          return acc;
        }, []),
        offices: this.profile.officeIds.reduce((acc: OfficeToUpdate[], id) => {
          const office = officesStore.items.find(i => i.id === id);
          if (office) {
            return [...acc, office];
          }
          return acc;
        }, []),
      });
    }

    return response;
  }

  profileIsLoaded = false;

  @Mutation
  SET_PROFILE_LOADED(v: boolean) {
    this.profileIsLoaded = v;
  }

  publicProfileIsLoaded = false;

  @Mutation
  SET_PUBLIC_PROFILE_LOADED(v: boolean) {
    this.publicProfileIsLoaded = v;
  }

  @Action
  async loadPublicProfile() {
    if (!this.publicProfileIsLoaded) {
      const token = await TokenHelpers.getToken();

      const data = await profileService.getPublishedProfile(token);

      const profile: PublishedCompoanyProfileDto = data || {
        ...profileBaseDefault,
        offices: [],
        team: [],
      };

      companyProfileStore.SET_PUBLIC_PROFILE(profile);
      companyProfileStore.SET_PUBLIC_PROFILE_LOADED(true);
    }
  }

  @Action
  async loadProfile() {
    if (!this.profileIsLoaded) {
      const token = await TokenHelpers.getToken();
      const data = await profileService.getProfile(token);

      companyProfileStore.SET_PROFILE_LOADED(true);
      companyProfileStore.setProfile(data);
    }
  }

  @Action
  async updateSocialMediaLinks(linksObject: SocialMediaLinks) {
    companyProfileStore.setLoading(true);
    const newProfile: GetWorkingProfileDto = {
      ...this.profile,
      socialMedia: linksObject,
    };
    const token = await TokenHelpers.getToken();
    await profileService.updateProfile(newProfile, token);
    await companyProfileStore.publishProfile();
    companyProfileStore.setProfile(newProfile);
    companyProfileStore.setLoading(false);
  }

  @Action
  async hideSocialMediaLinks() {
    companyProfileStore.setLoading(true);
    const newProfile: GetWorkingProfileDto = {
      ...this.profile,
      socialMedia: {
        facebookUrl: null,
        instagramUrl: null,
        linkedInUrl: null,
        twitterUrl: null,
        youtubeUrl: null,
      },
    };
    const token = await TokenHelpers.getToken();
    await profileService.updateProfile(newProfile, token);
    await companyProfileStore.publishProfile();
    companyProfileStore.setProfile(newProfile);
    companyProfileStore.setLoading(false);
  }

  @Action
  async updateInterestingFactNumbers(facts: UpdateInShortDto[]) {
    const newProfile: GetWorkingProfileDto = {
      ...this.profile,
      inShort: facts,
    };
    companyProfileStore.setLoading(true);
    const token = await TokenHelpers.getToken();
    await profileService.updateProfile(newProfile, token);
    companyProfileStore.setProfile(newProfile);
    await companyProfileStore.publishProfile();
    companyProfileStore.setLoading(false);
  }

  @Action
  async updateCompanyProfileIngress(v: { ingress: string }) {
    companyProfileStore.setLoading(true);

    const newProfile: GetWorkingProfileDto = {
      ...this.profile,
      ingress: v.ingress,
    };

    const token = await TokenHelpers.getToken();
    await profileService.updateProfile(newProfile, token);
    companyProfileStore.setProfile(newProfile);
    await companyProfileStore.publishProfile();
    companyProfileStore.setLoading(false);
  }

  @Action
  async updateLogo(v: { logoImageFile: any }) {
    const token = await TokenHelpers.getToken();

    let logoImageUrl = this.profile.logoUrl;

    if (v.logoImageFile) {
      logoImageUrl = await CompanyPhotosService.uploadImageToEntity({
        entityType: ImageEntityType.CompanyLogo,
        file: v.logoImageFile,
        token,
      });
    }

    const newProfile: GetWorkingProfileDto = {
      ...this.profile,
      logoUrl: logoImageUrl,
    };

    await profileService.updateProfile(newProfile, token);
    companyProfileStore.setProfile(newProfile);
    await companyProfileStore.publishProfile();
  }

  get loading() {
    return this.loadingInternal;
  }

  get logoUrl(): string | null {
    return this.profile.logoUrl;
  }

  get companyName(): string | null {
    return this.profile.companyName;
  }

  get companyId(): string {
    return "";
  }

  get branchId(): string {
    return "asdsd";
  }

  get startImage(): UpdateStartImageDto {
    return this.profile.message
      ? {
          imageUrl: this.profile.message.imageUrl,
          text: this.profile.message.text,
        }
      : {
          imageUrl: null,
          text: null,
        };
  }

  get interestingFactNumbers(): UpdateInShortDto[] {
    return this.profile.inShort;
  }

  get socialMediaLinks(): SocialMediaLinks {
    return (
      this.profile.socialMedia || {
        facebookUrl: null,
        instagramUrl: null,
        linkedInUrl: null,
        twitterUrl: null,
        youtubeUrl: null,
      }
    );
  }

  get socialMediaLinksNew() {
    const smlDefault: SocialMediaLinks = {
      facebookUrl: null,
      instagramUrl: null,
      linkedInUrl: null,
      twitterUrl: null,
      youtubeUrl: null,
    };
    return this.profile.socialMedia || smlDefault;
  }

  private get milestonesOrDefault(): UpdateMileStonesDto {
    return {
      milestoneList: this.profile.milestones,
      milestonesVisible: this.profile.milestonesVisible,
    };
  }

  get mileStonesVisible(): boolean {
    return this.milestonesOrDefault.milestonesVisible;
  }

  get mileStones(): CompanyProfileMilestone[] {
    return [...this.milestonesOrDefault.milestoneList].sort(
      (a, b) => a.year - b.year
    );
  }

  get businessAreas(): CompanyProfileListItemWithImage[] {
    return this.profile.businessAreas;
  }

  private get companyValuesOrDefault(): UpdateCompanyValuesDto {
    return {
      valueList: this.profile.values,
      valuesImageUrl: this.profile.valuesImageUrl,
      valuesVisible: this.profile.valuesVisible,
    };
  }

  get companyValuesImage(): string | null {
    return this.companyValuesOrDefault.valuesImageUrl;
  }

  get companyValues(): CompanyProfileListItemWithIcon[] {
    return this.companyValuesOrDefault.valueList;
  }

  get companyValuesVisible(): boolean {
    return this.companyValuesOrDefault.valuesVisible;
  }

  get companyVideoUrl() {
    return this.profile.companyVideoUrl;
  }

  get peopleNumbers(): UpdatePeopleStatisticsDto {
    return (
      this.profile.statistics || {
        noOfCountries: null,
        noOfEmployees: null,
        noOfMarkets: null,
      }
    );
  }

  private get rolesOrDefault(): UpdateCompanyProfileRolesDto {
    return {
      roleList: this.profile.roles,
      visible: this.profile.rolesVisible,
    };
  }

  get roles(): CompanyProfileListItem[] {
    return this.rolesOrDefault.roleList;
  }

  get rolesVisible(): boolean {
    return this.rolesOrDefault.visible;
  }

  private get perksOrDefault(): UpdatePerksDto {
    return {
      perkList: this.profile.perks,
      perksImageUrl: this.profile.perksImageUrl,
      perksVisible: this.profile.perksVisible,
    };
  }

  get perks(): CompanyProfileListItemWithIcon[] {
    return this.perksOrDefault.perkList;
  }

  get perksImage(): string | null {
    return this.perksOrDefault.perksImageUrl;
  }

  get perksVisible(): boolean {
    return this.perksOrDefault.perksVisible;
  }

  get teamVisible(): boolean {
    return this.profile.teamVisible;
  }

  get coWorkers(): CoWorkerDto[] {
    const coWorkers = coWorkersStore.coWorkers;
    return this.profile.coWorkerIds.reduce((acc: CoWorkerDto[], id) => {
      const coWorker = coWorkers.find(c => c.id === id);
      if (coWorker) {
        return [...acc, coWorker];
      }
      return acc;
    }, []);
  }

  get officesRaw(): Office[] {
    const offices = officesStore.items;
    return this.profile.officeIds.reduce((acc: Office[], id) => {
      const office = offices.find(c => c.id === id);
      if (office) {
        return [...acc, office];
      }
      return acc;
    }, []);
  }

  get profileDto(): CompanyProfileDto {
    const profile: GetWorkingProfileDto = this.profile;
    return {
      logoUrl: profile.logoUrl,
      companyName: profile.companyName,
      ingress: profile.ingress,
      logoIncludesName: profile.logoIncludesName,
      branchId: profile.branchId,
      industryId: profile.industryId,
      businessAreasType:
        profile.businessAreasType || BusinessAreasType.BusinessAreas,
      atAGlance: {
        message: profile.message,
        inShort: profile.inShort,
        socialMedia: profile.socialMedia,
      },
      company: {
        companyVideoUrl: profile.companyVideoUrl,
        companyVideoType: profile.companyVideoType || "Youtube",
        businessAreas: profile.businessAreas,
        values: {
          valuesVisible: profile.valuesVisible,
          valuesImageUrl: profile.valuesImageUrl,
          valueList: profile.values,
        },
        milestones: {
          milestonesVisible: profile.milestonesVisible,
          milestoneList: profile.milestones,
        },
      },
      people: {
        statistics: profile.statistics,
        team: this.coWorkers,
        teamVisible: profile.teamVisible,
        perks: {
          perksImageUrl: profile.perksImageUrl,
          perksVisible: profile.perksVisible,
          perkList: profile.perks,
        },
        roles: {
          visible: profile.rolesVisible,
          roleList: profile.roles,
        },
        offices: this.offices,
      },
    };
  }

  get profileCanBePublished(): boolean {
    return !!CompanyProfileHelper.getDiffs(this.profileDto, this.publicProfile)
      .length;
  }

  get offices(): CompanyProfileOffice[] {
    return this.officesRaw.map(o => {
      const result: CompanyProfileOffice = {
        id: o.id,
        name: o.name,
        officeImage: o.officeImage,
        description: o.description || "",
        latitude: o.latitude,
        longitude: o.longitude,
      };
      return result;
    });
  }
}
