import { CompanyObject, PricingModels } from "~/models/types";

export const isExploringFixedAccount = (x: CompanyObject | null) => {
  if (x === null) {
    return false;
  }
  return x.pricingModel === null && x.pricing?.fixedPricePerMonth !== null;
};

export const canUpgradeToFixedAccount = (x: CompanyObject | null) => {
  return x?.pricing?.fixedPricePerMonth !== null;
};

export const isFixedRateCustomer = (x: CompanyObject | null) => {
  const type = getCompanyAccountType(x);
  return type === "OldFixed" || type === "NewFixed";
};

export const getCompanyAccountType = (
  x: CompanyObject | null
): "OldFixed" | "Explore" | "AccessSuccess" | "NewFixed" => {
  if (!x) {
    return "Explore";
  }
  if (!x.pricingModel) {
    return "OldFixed";
  }

  switch (x.pricingModel) {
    case PricingModels.Explore:
      return "Explore";
    case PricingModels.AccessSuccess:
      return "AccessSuccess";
    case PricingModels.FixedPrice:
      return "NewFixed";
  }
};

export type CompanyAccountType = ReturnType<typeof getCompanyAccountType>;

export const showAccountSettings = (x: CompanyObject | null) => {
  if (x === null) {
    return false;
  }

  const type = getCompanyAccountType(x);

  if (type === "OldFixed") {
    return x.pricing?.fixedPricePerMonth !== null;
  }

  return true;
};
