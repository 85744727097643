import {
  EducationSaved,
  EducationToView,
  EducationDegrees,
} from "~/models/types";
import { ListObject } from "~/models/ListObject";

export default class EducationHelpers {
  static getEducationToView(
    e: EducationSaved,
    domains: ListObject[],
    schoolStringSeparator: string
  ): EducationToView {
    let domain: ListObject | undefined;
    let domainString: string | undefined;
    let schoolString: string | undefined;
    const abroadSchool: string | null =
      e.haveStudiedAbroad && e.abroadSchools.length
        ? e.abroadSchools.map(s => s.text).join(` ${schoolStringSeparator} `)
        : null;
    switch (e.degree) {
      case EducationDegrees.NoDegree:
        if (e.numberOfYears !== 0) {
          domain = domains.find(d => d.id === e.domainId);
          domainString = domain ? domain.text : "";
        } else {
          domainString = e.domain;
        }

        schoolString = e.schools
          .map(s => s.text)
          .join(` ${schoolStringSeparator} `);

        return {
          domain: domainString,
          school: schoolString,
          id: e.id,
          degree: e.degree,
          numberOfYears: e.numberOfYears,
          educationSaved: e,
          abroadSchool,
        };

      case EducationDegrees.UniversityDiploma:
      case EducationDegrees.Bachelor:
      case EducationDegrees.Master1Year:
      case EducationDegrees.Master2Year:
      case EducationDegrees.Licentiate:
      case EducationDegrees.Doctorate:
        domain = domains.find(d => d.id === e.domainId);
        domainString = domain ? domain.text : "";
        schoolString = e.schools
          .map(s => s.text)
          .join(` ${schoolStringSeparator} `);

        return {
          domain: domainString,
          school: schoolString,
          id: e.id,
          degree: e.degree,
          numberOfYears: null,
          educationSaved: e,
          abroadSchool,
        };

      case EducationDegrees.HigherVocational:
      case EducationDegrees.AdvancedHigherVocational:
        return {
          domain: e.domain,
          school: e.school,
          id: e.id,
          degree: e.degree,
          numberOfYears: null,
          educationSaved: e,
          abroadSchool,
        };
    }
  }

  static getEducationSaved(e: EducationSaved): EducationSaved {
    switch (e.degree) {
      case EducationDegrees.NoDegree:
        if (e.numberOfYears !== 0) {
          return {
            ...e,
            haveStudiedAbroad: e.haveStudiedAbroad || false,
            abroadSchools: e.abroadSchools || [],
          };
        }
        return {
          ...e,
          haveStudiedAbroad: false,
          abroadSchools: [],
        };
      case EducationDegrees.HigherVocational:
      case EducationDegrees.AdvancedHigherVocational:
        return {
          ...e,
          haveStudiedAbroad: false,
          abroadSchools: [],
        };
      case EducationDegrees.UniversityDiploma:
      case EducationDegrees.Bachelor:
      case EducationDegrees.Master1Year:
      case EducationDegrees.Master2Year:
      case EducationDegrees.Licentiate:
      case EducationDegrees.Doctorate:
        return {
          ...e,
          haveStudiedAbroad: e.haveStudiedAbroad || false,
          abroadSchools: e.abroadSchools || [],
        };
    }
  }
}
