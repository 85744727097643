

















































import { Vue, Component, Prop, Emit } from "nuxt-property-decorator";
import NotificationsListItem from "./NotificationsListItem.vue";
import { NotificationItem, NotificationTypeInternal } from "~/models/types";
import SortHelper from "~/helpers/sortHelper";
import FilterHelpers from "~/helpers/filterHelpers";
import { authStore, companyBaseStore } from "~/store";

@Component({
  components: { NotificationsListItem },
  layout: "companyLayout",
})
export default class NotificationsList extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly items!: NotificationItem[];

  get itemsSorted(): NotificationItem[] {
    return SortHelper.sortItemsBy(this.items, [
      { sortBy: x => x.date, desc: true },
    ]);
  }

  @Emit("itemClicked")
  itemClickedEmit() {}

  getDateColorClass(item: NotificationItem): string {
    return item.isRead ? "text-muted" : "accent--text";
  }

  getIsRead(item: NotificationItem): boolean {
    return item.isRead;
  }

  getFromNowDate(v: NotificationItem): string {
    return FilterHelpers.getFromNowString(v.date);
  }

  markAsRead(item: NotificationItem) {
    companyBaseStore.setNotificationRead({
      isRead: true,
      notificationId: item.id,
    });
  }

  get companyId() {
    return authStore.companyId!;
  }

  getIcon(v: NotificationItem): string {
    switch (v.type) {
      case NotificationTypeInternal.CommentOnSharedTalent:
        return "mdi-comment-eye";
      case NotificationTypeInternal.MessageFromTalent:
        return "mdi-comment-account";
      case NotificationTypeInternal.NotAnsweredPoolMessage:
        return "mdi-comment-alert";
      case NotificationTypeInternal.PoolMessageFromTalent:
        return "mdi-comment-account";
      case NotificationTypeInternal.SharedTalentVisited:
        return "mdi-eye-check";
      case NotificationTypeInternal.TagOnRecruitment:
        return "mdi-comment-edit";
      case NotificationTypeInternal.TagOnTalent:
        return "mdi-comment-edit";
      case NotificationTypeInternal.TalentAccepted:
        return "mdi-account-check";
      case NotificationTypeInternal.TalentDeclined:
        return "mdi-account-remove";
      case NotificationTypeInternal.TalentInterested:
        return "mdi-account-plus";
      case NotificationTypeInternal.TalentPoolTeamComment:
        return "mdi-comment-edit";
      case NotificationTypeInternal.PoolTalentInterested:
        return "mdi-account-plus";
      case NotificationTypeInternal.UnhandledRecruitment:
        return "mdi-exclamation-thick";
    }
  }

  handleItemClicked(item: NotificationItem): boolean {
    switch (item.type) {
      case NotificationTypeInternal.CommentOnSharedTalent: {
        const link = this.localePath({
          name:
            "company-companyId-recruitments-recruitmentId-candidate-talentId",
          params: {
            talentId: item.talentId,
            recruitmentId: item.recruitmentId,
            companyId: this.companyId,
          },
          query: { tab: "4" },
        });

        this.$router.push(link);
        this.itemClickedEmit();
        return true;
      }
      case NotificationTypeInternal.MessageFromTalent: {
        const link = this.localePath({
          name:
            "company-companyId-recruitments-recruitmentId-candidate-talentId",
          params: {
            talentId: item.talentId,
            recruitmentId: item.recruitmentId,
            companyId: this.companyId,
          },
          query: { tab: "2" },
        });

        this.$router.push(link);
        this.itemClickedEmit();
        return true;
      }
      case NotificationTypeInternal.NotAnsweredPoolMessage: {
        const link = this.localePath({
          name: "company-companyId-talentPool",
          params: {
            companyId: this.companyId,
          },
          query: {
            talentId: item.talentId,
          },
        });

        this.$router.push(link);
        this.itemClickedEmit();
        return true;
      }
      case NotificationTypeInternal.PoolMessageFromTalent: {
        const link = this.localePath({
          name: "company-companyId-talentPool",
          params: {
            companyId: this.companyId,
          },
          query: {
            talentId: item.talentId,
            tab: "2",
          },
        });

        this.$router.push(link);
        this.itemClickedEmit();
        return true;
      }
      case NotificationTypeInternal.PoolTalentInterested: {
        const link = this.localePath({
          name: "company-companyId-talentPool",
          params: {
            companyId: this.companyId,
          },
          query: {
            talentId: item.talentId,
          },
        });

        this.$router.push(link);
        this.itemClickedEmit();
        return true;
      }
      case NotificationTypeInternal.SharedTalentVisited: {
        const link = this.localePath({
          name:
            "company-companyId-recruitments-recruitmentId-candidate-talentId",
          params: {
            talentId: item.talentId,
            recruitmentId: item.recruitmentId,
            companyId: this.companyId,
          },
        });

        this.$router.push(link);
        this.itemClickedEmit();
        return true;
      }
      case NotificationTypeInternal.TagOnRecruitment: {
        const link = this.localePath({
          name: "company-companyId-recruitments-recruitmentId-communication",
          params: {
            recruitmentId: item.recruitmentId,
            companyId: this.companyId,
          },
          query: { tab: "2" },
        });

        this.$router.push(link);
        this.itemClickedEmit();
        return true;
      }
      case NotificationTypeInternal.TagOnTalent: {
        const link = this.localePath({
          name:
            "company-companyId-recruitments-recruitmentId-candidate-talentId",
          params: {
            talentId: item.talentId,
            recruitmentId: item.recruitmentId,
            companyId: this.companyId,
          },
          query: { tab: "4" },
        });

        this.$router.push(link);
        this.itemClickedEmit();
        return true;
      }
      case NotificationTypeInternal.TalentAccepted: {
        const link = this.localePath({
          name:
            "company-companyId-recruitments-recruitmentId-candidate-talentId",
          params: {
            talentId: item.talentId,
            recruitmentId: item.recruitmentId,
            companyId: this.companyId,
          },
        });

        this.$router.push(link);
        this.itemClickedEmit();
        return true;
      }
      case NotificationTypeInternal.TalentDeclined: {
        const link = this.localePath({
          name:
            "company-companyId-recruitments-recruitmentId-candidate-talentId",
          params: {
            talentId: item.talentId,
            recruitmentId: item.recruitmentId,
            companyId: this.companyId,
          },
        });

        this.$router.push(link);
        this.itemClickedEmit();
        return true;
      }
      case NotificationTypeInternal.TalentInterested: {
        const link = this.localePath({
          name:
            "company-companyId-recruitments-recruitmentId-candidate-talentId",
          params: {
            talentId: item.talentId,
            recruitmentId: item.recruitmentId,
            companyId: this.companyId,
          },
        });

        this.$router.push(link);
        this.itemClickedEmit();
        return true;
      }
      case NotificationTypeInternal.TalentPoolTeamComment: {
        const link = this.localePath({
          name: "company-companyId-talentPool",
          params: {
            companyId: this.companyId,
          },
          query: {
            talentId: item.talentId,
            tab: "3",
          },
        });

        this.$router.push(link);
        this.itemClickedEmit();
        return true;
      }
      case NotificationTypeInternal.UnhandledRecruitment: {
        const link = this.localePath({
          name: "company-companyId-recruitments-recruitmentId",
          params: {
            recruitmentId: item.recruitmentId,
            companyId: this.companyId,
          },
        });

        this.$router.push(link);
        this.itemClickedEmit();
        return true;
      }
    }
  }

  markAsUnread(item: NotificationItem) {
    companyBaseStore.setNotificationRead({
      isRead: false,
      notificationId: item.id,
    });
  }
}
