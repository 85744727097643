import { Middleware } from "@nuxt/types";
import LocalStorageHelpers, {
  campaignCodeKey,
} from "~/helpers/localStorageHelpers";

// eslint-disable-next-line space-before-function-paren
const myMiddleware: Middleware = ({ route }) => {
  const campaignCode = route.query.campaignCode;

  if (typeof campaignCode === "string") {
    LocalStorageHelpers.setString(campaignCodeKey, campaignCode);
  }
};

export default myMiddleware;
