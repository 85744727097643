import Vuex from "vuex";
import PublicDataStore from "~/store/publicData";

export function createStore() {
  return new Vuex.Store({
    modules: {
      PublicDataStore,
    },
  });
}
