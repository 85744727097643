import { Middleware } from "@nuxt/types";
import { authStore } from "~/store";

const myMiddleware: Middleware = ({ redirect, app }) => {
  if (!authStore.hasFullAccess) {
    redirect(
      `${app.i18n.locale === "sv" ? "" : "/" + app.i18n.locale}/company/${
        authStore.companyId
      }`
    );
  }
};

export default myMiddleware;
