













import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class GrNavLinkDense extends Vue {
  @Prop({ type: Object, default: null })
  readonly linkObject!: any;

  @Prop({ type: Boolean, default: false })
  readonly isActive!: boolean;

  @Prop({ type: String, default: null })
  readonly to!: string | null;

  @Prop({ type: Boolean, default: false })
  readonly noActiveColor!: boolean;

  get urlComputed(): string {
    return this.to ?? this.localePath(this.linkObject);
  }

  get isActiveComputed(): boolean {
    if (this.isActive) {
      return true;
    }

    const fixedName = this.linkObject ? this.linkObject.name + "-" : null;
    if (!fixedName) {
      return false;
    }

    const alternativeName = fixedName.replace("-", "_");

    return this.$route.name
      ? this.$route.name?.includes(fixedName) ||
          this.$route.name?.includes(alternativeName)
      : false;
  }
}
