













import { Vue, Component, Prop } from "nuxt-property-decorator";
import { TeamComment, CommentSender, TalentMessage } from "../../models/types";
import CommentListItem, { CommentListItemDto } from "./CommentListItem.vue";
import { authStore } from "~/store";
import FilterHelpers from "~/helpers/filterHelpers";

@Component({
  components: { CommentListItem },
})
export default class CommentList extends Vue {
  @Prop({ type: Array, required: true })
  readonly comments!: ((TeamComment | TalentMessage) & {
    recruitmentId?: string;
  })[];

  @Prop({ type: Boolean, default: true })
  readonly isHtml!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isOnTalentSide!: boolean;

  avatarColors = ["#0C2481", "#FE1376", "#FFC92F", "#999999"];

  get uniqueNames(): string[] {
    return [
      ...new Set(
        [...this.comments]
          .sort((a, b) => (a.date > b.date ? 1 : a.date < b.date ? -1 : 0))
          .map(c => c.sender.userId)
      ),
    ];
  }

  get userId() {
    return authStore.userId;
  }

  isTeamComment(v: TalentMessage | TeamComment): v is TeamComment {
    return (v as TalentMessage).fromTalent === undefined;
  }

  get commentsMapped(): CommentListItemDto[] {
    return this.comments
      .map(c => {
        return {
          id: c.id,
          comment: c.comment,
          sender: `${c.sender.firstName} ${c.sender.lastName}`,
          date: c.date,
          color: this.getColor(c.sender),
          isYou: c.sender.userId === this.userId,
          initials: this.getInitials(c.sender),
          sharedToEmails: this.isTeamComment(c) ? c.sharedToEmails : [],
          recruitmentId: c.recruitmentId ?? null,
          isTeamComment: this.isTeamComment(c),
          isFromCompany: !this.isTeamComment(c) && !c.fromTalent,
        };
      })
      .sort((b, a) => (a.date < b.date ? -1 : b.date < a.date ? 1 : 0));
  }

  getColor(sender: CommentSender): string {
    const nameIndex = this.uniqueNames.indexOf(sender.userId);
    return this.avatarColors[nameIndex % this.avatarColors.length]!;
  }

  getInitials(sender: CommentSender): string {
    return FilterHelpers.getInitialsByFirstNameLastName({
      firstName: sender.firstName,
      lastName: sender.lastName,
    });
  }
}
