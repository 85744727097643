import moment from "moment";
import classifyPoint from "robust-point-in-polygon";
import EmploymentHelper from "./EmploymentHelper";
import SortHelper from "./sortHelper";
import { getRobotStatus } from "./RobotHelpers";
import { RoleResponsibility } from "~/models/RoleResponsibility";
import {
  CompanyRecommendationType,
  CurrancyTypes,
  DeclineFollowUpActionType,
  EducationDegrees,
  EmploymentTypeInternal,
  ExternalTalentFilter,
  ExternalTalentForFiltering,
  ExternalTalentProfileSaved,
  GetRecommendedCompaniesDto,
  LaneType,
  LocationDto,
  Office,
  RecruitmentListItem,
  ResponsibilityTypes,
  TalentFilterItem,
  TalentForFilterComponentDto,
  TalentPoolTalent,
  TalentQuestionUnion,
  DeclineReasonType,
  TalentRecruitmentMissmatch,
  TrelloBoardActiveTabType,
  TrelloBoardTalent,
  TrelloBoardTalentStatus,
  EmploymentType,
  EmploymentPeriodTyped,
  RecruitmentFilterItem,
  RecruitmentSortOptions,
  CommentSender,
  DeclineReasonSingle,
  CompanyTalentRecuritmentStatus,
  EducationSaved,
  CompanyTalentGetDto,
  RecruitmentLogObject,
  LogTypes,
  SourcingRobotRequirement,
  JobOfferRequirementType,
  GetTalentsForSourcingRequirementDto,
  SourcingRobotRequirementsObject,
  JobOfferVisaulToUpdate,
  RobotStepperModule,
  GetTalentsForSourcingRequirementGroupDto,
  NumberOfEmployeesSpan,
  UpdateInShortDto,
} from "~/models/types";
import { WorkFromHome } from "~/models/WorkFromHome";
import { $i18n } from "~/utils/i18n";
import { jobbOfferStore } from "~/store";
import { ListObject } from "~/models/ListObject";
import { TalentExpectations } from "~/models/TalentExpectations";

export default class FilterHelpers {
  static fixLargeNumbers(value: number | string | null): string {
    return (
      (value || "0")
        // .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    );
  }

  static getLaneTypeString(v: LaneType): string {
    switch (v) {
      case LaneType.Review:
        return $i18n.t("laneTypes.Review").toString();
      case LaneType.Interview:
        return $i18n.t("laneTypes.Interview").toString();
      case LaneType.Test:
        return $i18n.t("laneTypes.Test").toString();
      case LaneType.Custom:
        return $i18n.t("laneTypes.Custom").toString();
      case LaneType.Hired:
        return $i18n.t("laneTypes.Hired").toString();
      case LaneType.Inbox:
        return $i18n.t("laneTypes.Inbox").toString();
      case LaneType.ReviewVirtaul:
        return $i18n.t("laneTypes.ReviewVirtaul").toString();
      case LaneType.Offers:
        return $i18n.t("laneTypes.Offers").toString();
      case LaneType.References:
        return $i18n.t("laneTypes.References").toString();
    }
  }

  static getWorkFromHomeString(value: WorkFromHome): string {
    switch (value) {
      case WorkFromHome.No:
        return $i18n.t("workFromHome.No").toString();
      case WorkFromHome.OneDay:
        return $i18n.t("workFromHome.OneDay").toString();
      case WorkFromHome.TwoDays:
        return $i18n.t("workFromHome.TwoDays").toString();
      case WorkFromHome.ThreeDays:
        return $i18n.t("workFromHome.ThreeDays").toString();
      case WorkFromHome.FourDays:
        return $i18n.t("workFromHome.FourDays").toString();
      case WorkFromHome.AllWeek:
        return $i18n.t("workFromHome.AllWeek").toString();
    }
  }

  static getMoneyInSEK(v: number, currancy: CurrancyTypes): number {
    switch (currancy) {
      case "SEK":
        return v;
      case "USD":
        return this.roundWithTwoDecimals(v * 9.0904);
      case "EUR":
        return this.roundWithTwoDecimals(v * 10.8158);
    }
  }

  static getMoneyObject(
    number: number,
    currency: CurrancyTypes
  ): { inKronor: string; actual: string } {
    const inKronor = `(${this.fixLargeNumbers(
      this.getMoneyInSEK(number, currency) * 1000
    )} SEK)`;

    const actual = `${this.fixLargeNumbers(number * 1000)} ${currency}`;

    return {
      inKronor: currency === "SEK" ? "" : inKronor,
      actual,
    };
  }

  static roundWithTwoDecimals(v: number): number {
    return Math.round((v + Number.EPSILON) * 100) / 100;
  }

  static roundToWholeNumber(v: number): number {
    return Math.round(v);
  }

  static getNumberOfYearsFromDate(startDate: Date | null) {
    return this.getNumberOfYearsFromDateWithNow(startDate, new Date());
  }

  static getNumberOfYearsFromDateWithNow(startDate: Date | null, now: Date) {
    const yearsDiff = (d1: Date, d2: Date): number => {
      const numberOfWorkYearsStartDate = new Date(d1);
      const utcNow = new Date(d2);

      const yearDiff =
        utcNow.getFullYear() - numberOfWorkYearsStartDate.getFullYear() - 1;

      const addAYear =
        utcNow.getMonth() > numberOfWorkYearsStartDate.getMonth() ||
        (utcNow.getMonth() === numberOfWorkYearsStartDate.getMonth() &&
          utcNow.getDate() >= numberOfWorkYearsStartDate.getDate());

      return yearDiff + (addAYear ? 1 : 0);
    };

    return startDate ? yearsDiff(startDate, now) : null;
  }

  static getCompanyIsRecommended(v: {
    c: GetRecommendedCompaniesDto;
    empType: EmploymentTypeInternal | null;
    isConsultant: boolean;
    gettingCustomers: boolean;
  }): boolean {
    if (v.gettingCustomers) {
      return v.c.recommended.includes(CompanyRecommendationType.CustomerFirms);
    }

    switch (v.empType) {
      case EmploymentTypeInternal.Employee:
        return v.isConsultant
          ? v.c.recommended.includes(CompanyRecommendationType.ConsultantFirms)
          : v.c.recommended.includes(
              CompanyRecommendationType.NonConsultantFirms
            );
      case EmploymentTypeInternal.Intern:
        return v.c.recommended.includes(CompanyRecommendationType.InternFirms);
      case EmploymentTypeInternal.SelfEmployed:
      case null:
        return false;
    }
  }

  static getUserIsInTeam(v: {
    companyMembers: { userId: string | null; recruitmentIds: string[] }[];
    userId: string | null;
    recruitmentId: string;
  }): boolean {
    if (!v.userId) {
      return false;
    }
    return v.companyMembers.some(
      u => u.userId === v.userId && u.recruitmentIds.includes(v.recruitmentId)
    );
  }

  static getResponsibilityShortString(value: RoleResponsibility): string {
    switch (value) {
      case RoleResponsibility.Staff:
        return $i18n.t("responsibilities.personel").toString();
      case RoleResponsibility.Project:
        return $i18n.t("responsibilities.project").toString();
      case RoleResponsibility.Sales:
        return $i18n.t("responsibilities.sales").toString();
    }
  }

  static getResponsibilityLongString(value: RoleResponsibility): string {
    switch (value) {
      case RoleResponsibility.Staff:
        return $i18n.t("responsibilities.personelLong").toString();

      case RoleResponsibility.Project:
        return $i18n.t("responsibilities.projectLong").toString();

      case RoleResponsibility.Sales:
        return $i18n.t("responsibilities.salesLong").toString();
    }
  }

  private static getDeclineFollowUpActionTypesByReason(
    v: DeclineReasonType
  ): DeclineFollowUpActionType[] {
    switch (v) {
      case DeclineReasonType.NotInterestingCompany:
        return [DeclineFollowUpActionType.RejectCompany];
      case DeclineReasonType.DontUnderstand:
        return [DeclineFollowUpActionType.InviteFriend];
      case DeclineReasonType.NotOptimalNextStep:
        return [
          DeclineFollowUpActionType.InviteFriend,
          DeclineFollowUpActionType.TalentPool,
        ];
      case DeclineReasonType.LikeMyCurrentJob:
        return [
          DeclineFollowUpActionType.InviteFriend,
          DeclineFollowUpActionType.TalentPool,
        ];
      case DeclineReasonType.AlreadyChangedJobsRecently:
        return [
          DeclineFollowUpActionType.InviteFriend,
          DeclineFollowUpActionType.UpdateProfileExperience,
        ];
    }
  }

  private static getDeclineFollowUpActionTypesByReasonNew(
    v: DeclineReasonSingle
  ): DeclineFollowUpActionType[] {
    switch (v.type) {
      case "CompanyDoesNotRespond":
        return [DeclineFollowUpActionType.RejectCompany];
      case "BadExperience":
        return [DeclineFollowUpActionType.RejectCompany];
      case "NoPublicSector":
        return [DeclineFollowUpActionType.RejectCompany];
      case "NoConsult":
        return [DeclineFollowUpActionType.RejectCompany];
      case "CompanySize":
        return [DeclineFollowUpActionType.RejectCompany];
      case "NoDevelopmentOpportunities":
        return [DeclineFollowUpActionType.RejectCompany];
      case "BadLocation":
        return [DeclineFollowUpActionType.RejectCompany];
      case "MyCompetenceDoesNotFit":
        return [DeclineFollowUpActionType.InviteFriend];
      case "SeniorityDoesNotFit":
        return [DeclineFollowUpActionType.InviteFriend];
      case "InterestForJobTasks":
        return [DeclineFollowUpActionType.InviteFriend];
      case "WantOtherRole":
        return [DeclineFollowUpActionType.InviteFriend];
      case "StaffResponsibility":
        return [DeclineFollowUpActionType.InviteFriend];
      case "Salary":
        return [
          DeclineFollowUpActionType.InviteFriend,

          DeclineFollowUpActionType.UpdateSalary,
        ];
      case "NotTheRightTime":
        return [DeclineFollowUpActionType.InviteFriend];
      case "RecentlyNewJob":
        return [
          DeclineFollowUpActionType.InviteFriend,

          DeclineFollowUpActionType.UpdateProfileExperience,
        ];
      case "SelectedOtherOffer":
        return [DeclineFollowUpActionType.InviteFriend];
      case "Other":
        return [DeclineFollowUpActionType.InviteFriend];
    }
  }

  static getDeclineFollowUpActionTypes(v: {
    reasons: DeclineReasonType[];
    otherReason: string | null;
  }): DeclineFollowUpActionType[] {
    const result = v.reasons.reduce((acc: DeclineFollowUpActionType[], r) => {
      return [...acc, ...this.getDeclineFollowUpActionTypesByReason(r)];
    }, []);

    const haveSelectedOtherReason = (v.otherReason ?? "").trim() !== "";
    const haveSelectedNoReason = v.reasons.length === 0;

    if (haveSelectedOtherReason || haveSelectedNoReason) {
      result.push(DeclineFollowUpActionType.InviteFriend);
      result.push(DeclineFollowUpActionType.RejectCompany);
    }

    return [...new Set(result)];
  }

  static getDeclineFollowUpActionTypesForNewReasons(v: {
    reasons: DeclineReasonSingle[];
  }): DeclineFollowUpActionType[] {
    const result = v.reasons.reduce((acc: DeclineFollowUpActionType[], r) => {
      return [...acc, ...this.getDeclineFollowUpActionTypesByReasonNew(r)];
    }, []);

    const haveSelectedNoReason = v.reasons.length === 0;

    if (haveSelectedNoReason) {
      result.push(DeclineFollowUpActionType.InviteFriend);
      result.push(DeclineFollowUpActionType.RejectCompany);
    }

    return [...new Set(result)];
  }

  static getQuestionText(
    dict: { [key: string]: string } | null,
    lang: string
  ): string | null {
    if (!dict) {
      return null;
    }
    return dict[lang] ?? this.getFirstValueOfObject(dict);
  }

  private static getDateWithAddedDays(v: {
    startDate: Date | null;
    totalDaysBeforeOverDue: number;
    now: Date;
  }) {
    const dueDate = new Date((v.startDate ?? v.now).valueOf());

    dueDate.setDate(dueDate.getDate() + v.totalDaysBeforeOverDue);

    return dueDate;
  }

  private static getDateWithAddedMonths(v: {
    startDate: Date;
    numberOfMonths: number;
  }) {
    const date = new Date(v.startDate.valueOf());

    date.setMonth(date.getMonth() + v.numberOfMonths);

    return date;
  }

  static getNumberOfDaysLeft(v: {
    startDate: Date | null;
    totalDaysBeforeOverDue: number;
    now: Date;
  }): number {
    const dueDate = this.getDateWithAddedDays(v);

    const timeDiff = dueDate.getTime() - v.now.getTime();

    const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

    return dayDiff > 0 ? dayDiff : 1;
  }

  private static getFirstValueOfObject(obj: any) {
    return obj[Object.keys(obj)[0]!];
  }

  static validatePositiveWholeNumber(
    v: string | number | null
  ): "Valid" | "Required" | "NotAWholeNumber" | "ToBig" | "Negative" {
    if (v === null || v === "") {
      return "Required";
    }

    if (typeof v === "string") {
      if (!/^-?[0-9][0-9]*$/.test(v)) {
        return "NotAWholeNumber";
      }
    }

    const value = typeof v === "string" ? parseInt(v) : v;

    if (isNaN(value)) {
      return "NotAWholeNumber";
    }

    if (value < 0) {
      return "Negative";
    }

    if (value > 2147483648) {
      return "ToBig";
    }

    return "Valid";
  }

  static getTalentMatchesFilter(
    t: TalentForFilterComponentDto,
    filter: TalentFilterItem
  ): boolean {
    switch (filter.type) {
      case "Tasks":
        return filter.useAnd
          ? filter.ids.every(ftid => {
              return t.taskIdsDict[ftid] === true;
            })
          : filter.ids.some(ftid => {
              return t.taskIdsDict[ftid] === true;
            });
      case "Skills":
        return filter.useAnd
          ? filter.ids.every(ftid => {
              return t.skillIdsDict[ftid] === true;
            })
          : filter.ids.some(ftid => {
              return t.skillIdsDict[ftid] === true;
            });
      case "Recruitment":
        return filter.useAnd
          ? filter.ids.every(ftid => {
              return t.recruitmentIdsDict[ftid] === true;
            })
          : filter.ids.some(ftid => {
              return t.recruitmentIdsDict[ftid] === true;
            });
      case "Languages":
        return filter.useAnd
          ? filter.ids.every(ftid => {
              return t.languageIdsDict[ftid] === true;
            })
          : filter.ids.some(ftid => {
              return t.languageIdsDict[ftid] === true;
            });
      case "EducationDegrees": {
        if (t.educationDegree === null) {
          return false;
        }
        return filter.idsNumberDict[t.educationDegree] ?? false;
      }
      case "WorkYears":
        return (
          t.numberOfWorkYears >= filter.min && t.numberOfWorkYears <= filter.max
        );
      case "Project":
        return t.projectMKR >= filter.min && t.projectMKR <= filter.max;
      case "Cost":
        return t.costMKR >= filter.min && t.costMKR <= filter.max;
      case "Staff":
        return t.staffPersons >= filter.min && t.staffPersons <= filter.max;
      case "Responsibilities":
        return filter.useAnd
          ? filter.ids.every(id => t.responsibility[id])
          : filter.ids.some(id => t.responsibility[id]);
    }
  }

  public static mapToNumberOfEmployeesSpan(v: number): NumberOfEmployeesSpan {
    if (v <= 1) {
      return "0-1";
    }
    if (v <= 10) {
      return "2-10";
    }
    if (v <= 50) {
      return "11-50";
    }
    if (v <= 200) {
      return "51-200";
    }
    if (v <= 500) {
      return "201-500";
    }
    if (v <= 1000) {
      return "501-1000";
    }
    if (v <= 5000) {
      return "1001-5000";
    }
    if (v <= 10000) {
      return "5001-10000";
    }
    return "100001+";
  }

  public static mapFromNumberOfEmployeesSpan(v: NumberOfEmployeesSpan): number {
    switch (v) {
      case "0-1":
        return 1;
      case "2-10":
        return 10;
      case "11-50":
        return 50;
      case "51-200":
        return 200;
      case "201-500":
        return 500;
      case "501-1000":
        return 1000;
      case "1001-5000":
        return 5000;
      case "5001-10000":
        return 10000;
      case "100001+":
        return 100000;
    }
  }

  public static mapToInShortFromNumberOfEmployeesSpan(
    v: NumberOfEmployeesSpan,
    text: string
  ): UpdateInShortDto {
    switch (v) {
      case "0-1":
        return {
          disableThousandsSeparator: true,
          icon: "mdi-account-multiple",
          number: "0 - 1",
          prefix: null,
          suffix: null,
          text,
        };
      case "2-10":
        return {
          disableThousandsSeparator: true,
          icon: "mdi-account-multiple",
          number: "2 - 10",
          prefix: null,
          suffix: null,
          text,
        };
      case "11-50":
        return {
          disableThousandsSeparator: true,
          icon: "mdi-account-multiple",
          number: "11 - 50",
          prefix: null,
          suffix: null,
          text,
        };
      case "51-200":
        return {
          disableThousandsSeparator: true,
          icon: "mdi-account-multiple",
          number: "51 - 200",
          prefix: null,
          suffix: null,
          text,
        };
      case "201-500":
        return {
          disableThousandsSeparator: true,
          icon: "mdi-account-multiple",
          number: "201 - 500",
          prefix: null,
          suffix: null,
          text,
        };
      case "501-1000":
        return {
          disableThousandsSeparator: true,
          icon: "mdi-account-multiple",
          number: "501 - 1000",
          prefix: null,
          suffix: null,
          text,
        };
      case "1001-5000":
        return {
          disableThousandsSeparator: true,
          icon: "mdi-account-multiple",
          number: "1001 - 5000",
          prefix: null,
          suffix: null,
          text,
        };
      case "5001-10000":
        return {
          disableThousandsSeparator: true,
          icon: "mdi-account-multiple",
          number: "5001 - 10000",
          prefix: null,
          suffix: null,
          text,
        };
      case "100001+":
        return {
          disableThousandsSeparator: true,
          icon: "mdi-account-multiple",
          number: "10000+",
          prefix: null,
          suffix: null,
          text,
        };
    }
  }

  static getTalentMatchesFilterExternal(
    t: ExternalTalentForFiltering,
    filter: ExternalTalentFilter
  ): boolean {
    switch (filter.type) {
      case "Tasks":
        return filter.useAnd
          ? filter.ids.every(ftid => {
              return t.taskIdsDict[ftid] === true;
            })
          : filter.ids.some(ftid => {
              return t.taskIdsDict[ftid] === true;
            });
      case "Skills":
        return filter.useAnd
          ? filter.ids.every(ftid => {
              return t.skillIdsDict[ftid] === true;
            })
          : filter.ids.some(ftid => {
              return t.skillIdsDict[ftid] === true;
            });
      case "Languages":
        return filter.useAnd
          ? filter.ids.every(ftid => {
              return t.languageIdsDict[ftid] === true;
            })
          : filter.ids.some(ftid => {
              return t.languageIdsDict[ftid] === true;
            });
      case "EducationDegrees": {
        if (t.educationDegree === null) {
          return false;
        }
        return filter.idsNumberDict[t.educationDegree] ?? false;
      }
      case "WorkYears":
        return (
          t.numberOfWorkYears >= filter.min && t.numberOfWorkYears <= filter.max
        );
      case "Project":
        return t.projectMKR >= filter.min && t.projectMKR <= filter.max;
      case "Cost":
        return t.costMKR >= filter.min && t.costMKR <= filter.max;
      case "Staff":
        return t.staffPersons >= filter.min && t.staffPersons <= filter.max;
      case "Responsibilities":
        return filter.useAnd
          ? filter.ids.every(id => t.responsibility[id])
          : filter.ids.some(id => t.responsibility[id]);
      case "EmployeeTodayQuestion": {
        const empTodayValue = t.isEmployeeToday ? "true" : "false";
        return filter.ids.includes(empTodayValue);
      }
      case "MultipleChoiceQuestion": {
        const question = t.questions[filter.questionId];
        if (!question) {
          return false;
        }
        if (question.type !== "MultipleChoice") {
          return false;
        }
        if (question.optionAnswer === null) {
          return false;
        }
        return filter.idsStringDict[question.optionAnswer] ?? false;
      }
      case "LinearScaleQuestion": {
        const question = t.questions[filter.questionId];
        if (!question) {
          return false;
        }
        if (question.type !== "LinearScale") {
          return false;
        }
        if (question.numberValue === null) {
          return false;
        }
        return filter.idsNumberDict[question.numberValue] ?? false;
      }
    }
  }

  static getFilterIsEmptyExternal(filter: ExternalTalentFilter): boolean {
    switch (filter.type) {
      case "EmployeeTodayQuestion":
      case "Responsibilities":
      case "Tasks":
      case "Skills":
      case "Languages":
        return !filter.ids.length;
      case "EducationDegrees":
      case "LinearScaleQuestion":
        return !Object.keys(filter.idsNumberDict).length;
      case "MultipleChoiceQuestion":
        return !Object.keys(filter.idsStringDict).length;
      case "WorkYears":
      case "Project":
      case "Cost":
      case "Staff":
        return false;
    }
  }

  static getFilterIsEmpty(filter: TalentFilterItem): boolean {
    switch (filter.type) {
      case "Responsibilities":
      case "Tasks":
      case "Skills":
      case "Recruitment":
      case "Languages":
        return !filter.ids.length;
      case "EducationDegrees":
      case "Cost":
      case "Staff":
      case "Project":
      case "WorkYears":
        return false;
    }
  }

  static getRecruitmentFilterIsEmpty(filter: RecruitmentFilterItem): boolean {
    switch (filter.type) {
      case "Offices":
        return !filter.officeIds.length;
      case "Roles":
        return !filter.roleIds.length;
      case "TitleContains":
        return !filter.text.trim().length;
      case "Status":
        return (
          !!filter.statuses.length && filter.statuses.every(s => s === "Active")
        );
      case "RobotStatus":
        return !filter.statuses.length;
      case "Users":
        return !filter.users.length;
    }
  }

  static getIdForFilterExternal(filter: ExternalTalentFilter): string {
    switch (filter.type) {
      case "Tasks":
      case "Skills":
      case "EmployeeTodayQuestion":
      case "WorkYears":
      case "Responsibilities":
      case "EducationDegrees":
      case "Languages":
      case "Project":
      case "Cost":
      case "Staff":
        return filter.type;
      case "MultipleChoiceQuestion":
      case "LinearScaleQuestion":
        return `${filter.type}|${filter.questionId}`;
    }
  }

  static getIdForFilter(filter: TalentFilterItem): string {
    switch (filter.type) {
      case "Tasks":
      case "Skills":
      case "Recruitment":
      case "WorkYears":
      case "Responsibilities":
      case "EducationDegrees":
      case "Languages":
      case "Project":
      case "Cost":
      case "Staff":
        return filter.type;
    }
  }

  static getTalentIsMatchExternal(
    t: ExternalTalentForFiltering,
    filters: ExternalTalentFilter[]
  ): boolean {
    return filters.reduce((acc: boolean, filter) => {
      if (this.getFilterIsEmptyExternal(filter)) {
        return acc;
      }
      if (!acc) {
        return acc;
      }
      return this.getTalentMatchesFilterExternal(t, filter);
    }, true);
  }

  static getTalentIsMatch(
    t: TalentForFilterComponentDto,
    filters: TalentFilterItem[]
  ): boolean {
    return filters.reduce((acc: boolean, filter) => {
      if (this.getFilterIsEmpty(filter)) {
        return acc;
      }
      if (!acc) {
        return acc;
      }
      return this.getTalentMatchesFilter(t, filter);
    }, true);
  }

  static getRecruitmentMatchesFilters(v: {
    rli: RecruitmentListItem;
    filters: RecruitmentFilterItem[];
    userIdsByRecId: Record<string, string[]>;
  }): boolean {
    return v.filters.reduce((acc: boolean, filter) => {
      if (!acc) {
        return acc;
      }
      return this.getRecruitmentMatchesFilter({
        filter,
        recruitment: v.rli,
        userIdsByRecId: v.userIdsByRecId,
      });
    }, true);
  }

  static getYearAndMonth(
    v: string | null
  ): { year: string; month: string } | null {
    if (!v) {
      return null;
    }
    const isMatch = /([12]\d{3}-(0[1-9]|1[0-2]))/.test(v);

    if (isMatch) {
      const splits = v.split("-");

      return {
        year: splits[0]!,
        month: splits[1]!,
      };
    }

    return null;
  }

  static getInitials(name: string | null): string {
    return (name ?? "")
      .split(" ")
      .map(x => (x[0] ?? "").toUpperCase())
      .filter(x => x.length > 0)
      .splice(0, 2)
      .join("");
  }

  static getInitialsByFirstNameLastName(v: {
    firstName: string | null;
    lastName: string | null;
  }): string {
    const fullName = `${v.firstName ?? ""} ${v.lastName ?? ""}`.trim();
    return this.getInitials(fullName);
  }

  static getFilteredTalentsExternal(
    talents: ExternalTalentForFiltering[],
    filters: ExternalTalentFilter[]
  ): ExternalTalentForFiltering[] {
    return talents.filter(t => this.getTalentIsMatchExternal(t, filters));
  }

  static getFilteredTalents(
    talents: TalentForFilterComponentDto[],
    filters: TalentFilterItem[]
  ): TalentForFilterComponentDto[] {
    return talents.filter(t => this.getTalentIsMatch(t, filters));
  }

  static getGenetiveString(v: { inputString: string; lang: string }): string {
    const ending = v.inputString.endsWith("s")
      ? v.lang === "en"
        ? "'"
        : ""
      : v.lang === "en"
      ? "'s"
      : "s";

    return v.inputString + ending;
  }

  static getUniqueItemsBy<T>(v: { arr: T[]; getKey: (v: T) => string }): T[] {
    const resultUniqueByKey = v.arr.reduce((acc: T[], item) => {
      const existingItem = acc.find(x => v.getKey(x) === v.getKey(item));
      if (!existingItem) {
        acc.push(item);
      }
      return acc;
    }, []);

    return resultUniqueByKey;
  }

  static getDateString(value: Date): string {
    return getDateStringFormatL(value);
  }

  static getEducationTypeStringFromCompanyTalent(
    v: CompanyTalentGetDto | null
  ): string {
    const education = v?.nonAnonymouseProfileDetails?.highestEducation;

    if (!education) {
      return "";
    }

    return this.getEducationTypeString(education);
  }

  static getEducationTypeString(v: EducationSaved): string {
    return v.degree !== EducationDegrees.NoDegree
      ? this.getEducationDegreeText(v.degree)
      : this.getNumberOfYearsEducationString(v.numberOfYears);
  }

  static getEducationDegreeText(value: EducationDegrees | null): string {
    if (value === null) {
      return "";
    }
    let parsed = 0;
    if (typeof value === "string") {
      parsed = parseInt(value || "0");
      if (isNaN(parsed)) {
        return "";
      }
    } else {
      parsed = value;
    }

    switch (value) {
      case EducationDegrees.NoDegree:
        return $i18n.t("educationLevels.NoDegree").toString();
      case EducationDegrees.HigherVocational:
        return $i18n.t("educationLevels.HigherVocational").toString();
      case EducationDegrees.AdvancedHigherVocational:
        return $i18n.t("educationLevels.AdvancedHigherVocational").toString();
      case EducationDegrees.Bachelor:
        return $i18n.t("educationLevels.Bachelor").toString();
      case EducationDegrees.UniversityDiploma:
        return $i18n.t("educationLevels.UniversityDiploma").toString();
      case EducationDegrees.Master1Year:
        return $i18n.t("educationLevels.Master1Year").toString();
      case EducationDegrees.Master2Year:
        return $i18n.t("educationLevels.Master2Year").toString();
      case EducationDegrees.Licentiate:
        return $i18n.t("educationLevels.Licentiate").toString();
      case EducationDegrees.Doctorate:
        return $i18n.t("educationLevels.Doctorate").toString();
    }
  }

  static getNumberOfYearsEducationString(years: number): string {
    if (years === 0) {
      return $i18n.t("IndependentCourses").toString();
    }
    return $i18n
      .t("numberOfYearsEducation", {
        years,
        points: years * 60,
      })
      .toString();
  }

  static getLocaleLaneName(laneName: string): string {
    if (!["Inkorg", "Granskning", "Anställd"].includes(laneName)) {
      return laneName;
    }
    return $i18n.t(`laneNames.${laneName}`).toString();
  }

  static getCoordinatesAreWithin(v: {
    polygon: [number, number][];
    point: [number, number];
  }): boolean {
    return classifyPoint(v.polygon, v.point) <= 0;
  }

  static getTalentProfileWithFixedStartDate<
    T extends {
      experience: {
        employments: EmploymentPeriodTyped[];
        numberOfWorkYearsStartDate: Date | null;
      } | null;
    }
  >(newProfile: T, now: Date): T {
    const newProfileWithFixedStartDate: T = {
      ...newProfile,
      experience: newProfile.experience
        ? {
            ...newProfile.experience,
            numberOfWorkYearsStartDate: FilterHelpers.getFixedNumberOfWorkYearsStartDate(
              {
                talentProfile: newProfile,
                now,
              }
            ),
          }
        : null,
    };

    return newProfileWithFixedStartDate;
  }

  static getFixedNumberOfWorkYearsStartDate(v: {
    talentProfile: {
      experience: {
        employments: EmploymentPeriodTyped[];
        numberOfWorkYearsStartDate: Date | null;
      } | null;
    };
    now: Date;
  }): Date | null {
    const numberOfMonths = EmploymentHelper.getWorkExperienceNumberOfMonthsWithNow(
      v.talentProfile.experience?.employments.filter(
        x => x.employmentType !== EmploymentType.Intern
      ) ?? [],
      v.now
    );
    const firstDayThisMonths = new Date(
      Date.UTC(v.now.getFullYear(), v.now.getMonth(), 1)
    );

    const experienceStartDate =
      v.talentProfile.experience?.numberOfWorkYearsStartDate ?? null;

    const firstEmpDate = this.getDateWithAddedMonths({
      numberOfMonths: -numberOfMonths,
      startDate: firstDayThisMonths,
    });

    if (firstEmpDate === null) {
      return experienceStartDate;
    }

    const firstEmpDateMinusMargin = this.getDateWithAddedMonths({
      numberOfMonths: -12,
      startDate: firstEmpDate,
    });

    if (
      experienceStartDate !== null &&
      experienceStartDate !== firstEmpDate &&
      experienceStartDate > firstEmpDateMinusMargin
    ) {
      return firstEmpDate;
    }
    return experienceStartDate;
  }

  static getLocationIsMatched(v: {
    locationId: string;
    locations: LocationDto[];
    recruitmentListItem: RecruitmentListItem;
  }): boolean {
    const location = v.locations.find(l => l.id === v.locationId);
    if (!location) {
      return false;
    }
    if (location.type === "Remote") {
      return (
        (v.recruitmentListItem.workFromHome === WorkFromHome.AllWeek &&
          v.recruitmentListItem.acceptsFullRemote) ||
        v.recruitmentListItem.isFullRemote
      );
    }

    if (!v.recruitmentListItem.officeId) {
      return false;
    }

    return this.getCoordinatesAreWithin({
      point: [
        v.recruitmentListItem.longitude ?? 0,
        v.recruitmentListItem.latitude ?? 0,
      ],
      polygon: location.polygon,
    });
  }

  static getTalentRecruitmentMismatches(v: {
    talent: { id: string; expectations: TalentExpectations };
    locationMatcher:
      | { type: "preLoaded"; locationMatches: boolean }
      | { type: "Compute"; locations: LocationDto[] };
    recruitmentListItem: RecruitmentListItem;
  }): TalentRecruitmentMissmatch[] {
    const recTalentTalentId = v.recruitmentListItem.savedTalentIds.find(
      x => x === v.talent.id
    );
    const requirements: {
      missmatch: TalentRecruitmentMissmatch;
      isMet: (
        t: { expectations: TalentExpectations; id: string },
        b: RecruitmentListItem
      ) => boolean;
    }[] = [
      {
        missmatch: { type: "Role" },
        isMet: (
          t: { expectations: TalentExpectations },
          b: RecruitmentListItem
        ) => {
          return (
            !t.expectations.roleIds.length ||
            (!!b.roleId && t.expectations.roleIds.includes(b.roleId))
          );
        },
      },
      {
        missmatch: { type: "Responsibility" },
        isMet: (
          t: { expectations: TalentExpectations },
          b: RecruitmentListItem
        ) => {
          return (
            !t.expectations.responsibilityMusts.length ||
            t.expectations.responsibilityMusts.some(rm =>
              b.rolesResponsibilities.includes(rm)
            )
          );
        },
      },
      {
        missmatch: { type: "Travel" },
        isMet: (
          t: { expectations: TalentExpectations },
          b: RecruitmentListItem
        ) => {
          return !t.expectations.requireNoTrips || !b.includesTravel;
        },
      },
      {
        missmatch: {
          type: "WorkFromHome",
          workFromHome: v.talent.expectations.requireWorkFromHome,
        },
        isMet: (
          t: { expectations: TalentExpectations },
          b: RecruitmentListItem
        ) => {
          return t.expectations.requireWorkFromHome === WorkFromHome.No
            ? true
            : b.officeId === null ||
                t.expectations.requireWorkFromHome <= b.workFromHome;
        },
      },
      {
        missmatch: { type: "Location" },
        isMet: (
          _t: { expectations: TalentExpectations },
          _b: RecruitmentListItem
        ) => {
          return v.locationMatcher.type === "preLoaded"
            ? v.locationMatcher.locationMatches
            : v.talent.expectations.requiredCities.some(c =>
                this.getLocationIsMatched({
                  recruitmentListItem: v.recruitmentListItem,
                  locations:
                    v.locationMatcher.type === "Compute"
                      ? v.locationMatcher.locations
                      : [],
                  locationId: c.id,
                })
              );
        },
      },
      {
        missmatch: {
          type: "Saved",
          talentId: recTalentTalentId || null,
        },

        isMet: (
          t: { expectations: TalentExpectations; id: string },
          _b: RecruitmentListItem
        ) => {
          return !v.recruitmentListItem.savedTalentIds.includes(t.id);
        },
      },
    ];

    return requirements.reduce((acc: TalentRecruitmentMissmatch[], r) => {
      if (!r.isMet(v.talent, v.recruitmentListItem)) {
        return [...acc, r.missmatch];
      }
      return acc;
    }, []);
  }

  static getExpectationsFromPoolTalent(
    talent: TalentPoolTalent
  ): TalentExpectations {
    return {
      requireNoTrips: talent.requireNoTrips,
      requireNoConsultants: talent.requireNoConsultants,
      requireNoStartups: talent.requireNoStartups,
      requireNoPublicSectors: talent.requireNoPublicSectors,
      requireStableSalary: talent.requireStableSalary,
      requireWorkFromHome: talent.requireWorkFromHome,
      requiredCities: talent.locationIds.map(l => ({
        id: l,
        text: l,
      })),
      responsibilityMusts: talent.responsibilityMusts,
      roleIds: talent.preferenceRoleIds,
      salary: talent.salary,
      salaryCurrency: "SEK",
      rejectedCompanyIds: talent.rejectedCompanyIds,
    };
  }

  static getPoolTalentMatchesRecruitment(v: {
    talent: TalentPoolTalent;
    recruitmentListItem: RecruitmentListItem;
    locations: LocationDto[];
  }): boolean {
    const missmatches = this.getTalentRecruitmentMismatches({
      recruitmentListItem: v.recruitmentListItem,
      locationMatcher: {
        type: "Compute",
        locations: v.locations,
      },
      talent: {
        id: v.talent.id,
        expectations: this.getExpectationsFromPoolTalent(v.talent),
      },
    });

    return !missmatches.length;
  }

  static getRecruitmentsForTalent(v: {
    talent: TalentPoolTalent;
    recruitments: RecruitmentListItem[];
    locations: LocationDto[];
    offices: Office[];
  }): ListObject[] {
    return v.recruitments
      .filter(
        r =>
          !r.closedDate &&
          this.getPoolTalentMatchesRecruitment({
            talent: v.talent,
            recruitmentListItem: r,
            locations: v.locations,
          })
      )
      .map(r => {
        const office = v.offices.find(o => o.id === r.officeId);

        return {
          id: r.id,
          text: office
            ? `${r.title}  (${office.name})`
            : `${r.title}  (Remote)`,
        };
      });
  }

  static getTalentsForFiltering(v: {
    talents: TalentPoolTalent[];
    recruitments: RecruitmentListItem[];
    locations: LocationDto[];
    offices: Office[];
  }): TalentForFilterComponentDto[] {
    const projectId: ResponsibilityTypes = "projects";
    const costId: ResponsibilityTypes = "costs";
    const staffId: ResponsibilityTypes = "staff";
    return v.talents.map(t => {
      const recruitments: ListObject[] = this.getRecruitmentsForTalent({
        recruitments: v.recruitments,
        talent: t,
        locations: v.locations,
        offices: v.offices,
      });
      const result: TalentForFilterComponentDto = {
        id: t.talentId,
        skills: t.skillIds.reduce((acc: ListObject[], x) => {
          const skill = jobbOfferStore.skillsById.get(x);

          if (
            skill &&
            FilterHelpers.getIsNotPromise(skill) &&
            !!skill.textDict[$i18n.locale] &&
            skill.textDict[$i18n.locale] !== "..."
          ) {
            return [...acc, { text: skill.textDict[$i18n.locale]!, id: x }];
          }
          return acc;
        }, []),

        skillIdsDict: t.skillIds.reduce(
          (acc: { [key: string]: boolean }, s) => {
            acc[s] = true;

            return acc;
          },
          {}
        ),
        recruitments,
        recruitmentIdsDict: recruitments.reduce(
          (acc: { [key: string]: boolean }, r) => {
            acc[r.id] = true;

            return acc;
          },
          {}
        ),
        languageIdsDistinct: [...new Set(t.languageIds)],
        languageIdsDict: t.languageIds.reduce(
          (acc: { [key: string]: boolean }, lid) => {
            acc[lid] = true;

            return acc;
          },
          {}
        ),
        projectMKR: FilterHelpers.getMoneyInSEK(
          t.responsibilityExperience?.[RoleResponsibility.Project] ?? 0,
          "SEK"
        ),
        costMKR: FilterHelpers.getMoneyInSEK(
          t.responsibilityExperience?.[RoleResponsibility.Sales] ?? 0,
          "SEK"
        ),
        staffPersons:
          t.responsibilityExperience?.[RoleResponsibility.Staff] ?? 0,
        educationDegree: t.highestEducationDegree ?? EducationDegrees.NoDegree,
        distinctTaskIds: [
          ...new Set(
            t.taskIds.reduce((acc: string[], tid) => {
              return [...acc, tid];
            }, [])
          ),
        ],
        taskIdsDict: t.taskIds.reduce(
          (acc: { [key: string]: boolean }, tid) => {
            acc[tid] = true;

            return acc;
          },
          {}
        ),
        numberOfWorkYears: t.yearsOfExperience,
        responsibility: {
          [costId]:
            (t.responsibilityExperience?.[RoleResponsibility.Sales] ?? 0) > 0,
          [projectId]:
            (t.responsibilityExperience?.[RoleResponsibility.Project] ?? 0) > 0,
          [staffId]:
            (t.responsibilityExperience?.[RoleResponsibility.Staff] ?? 0) > 0,
        },
      };

      return result;
    });
  }

  static getTalentsForFilteringExternal(v: {
    talents: ExternalTalentProfileSaved[];
  }): ExternalTalentForFiltering[] {
    const projectId: ResponsibilityTypes = "projects";
    const costId: ResponsibilityTypes = "costs";
    const staffId: ResponsibilityTypes = "staff";
    return v.talents.map(t => {
      const result: ExternalTalentForFiltering = {
        id: t.id,
        isEmployeeToday: t.isEmployeeToday,
        skills: t.tasksAndSkills.skills,
        skillIdsDict: t.tasksAndSkills.skills.reduce(
          (acc: { [key: string]: boolean }, s) => {
            acc[s.id] = true;

            return acc;
          },
          {}
        ),
        languageIdsDistinct: [...new Set(t.experience.languageIds)],
        languageIdsDict: t.experience.languageIds.reduce(
          (acc: { [key: string]: boolean }, lid) => {
            acc[lid] = true;

            return acc;
          },
          {}
        ),
        projectMKR: FilterHelpers.getMoneyInSEK(
          (t.experience.projectResonsibilityNumber ?? 0) / 1000,
          t.experience.projectResonsibilityCurrancy
        ),
        costMKR: FilterHelpers.getMoneyInSEK(
          (t.experience.costResonsibilityNumber ?? 0) / 1000,
          t.experience.costResonsibilityCurrancy
        ),
        staffPersons: t.experience.staffResonsibilityInPersons ?? 0,
        educationDegree: SortHelper.getEducationDegreeWithHighestDegree(
          t.experience.education
        ),
        distinctTaskIds: [
          ...new Set(
            t.tasksAndSkills.roles.reduce((acc: string[], r) => {
              return [...acc, ...r.taskIds];
            }, [])
          ),
        ],
        taskIdsDict: t.tasksAndSkills.roles.reduce(
          (acc: { [key: string]: boolean }, r) => {
            r.taskIds.forEach(tid => {
              acc[tid] = true;
            });

            return acc;
          },
          {}
        ),
        numberOfWorkYears: EmploymentHelper.getWorkExperienceNumberOfYears(
          t.experience.employments
        ),
        responsibility: {
          [costId]: t.experience.hasCostResponsibility,
          [projectId]: t.experience.hasProjectResponsibility,
          [staffId]: t.experience.hasStaffResponsibility,
        },
        questions: t.otherQuestionSections.reduce(
          (acc: { [key: string]: TalentQuestionUnion }, s) => {
            s.questions.forEach(q => {
              acc[q.id] = q;
            });
            return acc;
          },
          {}
        ),
      };

      return result;
    });
  }

  static validatedEmail(emailString: string): boolean {
    return /^[a-zA-Z0-9.!#$%&amp;'^_`{}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      emailString
    );
  }

  static getIsNotPromise<T>(v: T | Promise<T>): v is T {
    return (v as Promise<T>).then === undefined;
  }

  static lowerFirstLetter(v: string) {
    return v.charAt(0).toLowerCase() + v.slice(1);
  }

  static getIsRobotHidden(t: TrelloBoardTalent): boolean {
    return t.invitedBySourcingRobot && !(t.accepted || t.removed);
  }

  static hasDeclined(t: TrelloBoardTalentStatus): boolean {
    return !!t.declined && !this.getIsUndoed(t);
  }

  static getIsUndoed(t: TrelloBoardTalentStatus): boolean {
    return !!t.accepted && !!t.declined && t.accepted > t.declined;
  }

  static getIsRightTab(
    tab: TrelloBoardActiveTabType | null,
    t: TrelloBoardTalent
  ): boolean {
    if (tab == null) {
      return false;
    }
    if (this.getIsRobotHidden(t)) {
      return false;
    }
    switch (tab) {
      case TrelloBoardActiveTabType.Active: {
        return !!t.saved && !t.removed && !this.hasDeclined(t);
      }
      case TrelloBoardActiveTabType.Removed:
        return !!t.saved && !!t.removed && !this.hasDeclined(t);
      case TrelloBoardActiveTabType.Declined:
        return !!t.saved && this.hasDeclined(t);
    }
  }

  static getSkillPrioText(v: number) {
    if (v <= 0.2) {
      return $i18n.t("skillPrios.1").toString();
    }
    if (v <= 0.4) {
      return $i18n.t("skillPrios.2").toString();
    }
    if (v <= 0.6) {
      return $i18n.t("skillPrios.3").toString();
    }
    if (v <= 0.8) {
      return $i18n.t("skillPrios.4").toString();
    }
    return $i18n.t("skillPrios.5").toString();
  }

  private static getTaskIdsFromRobotRequirement(v: {
    requirement: SourcingRobotRequirement;
  }): string[] {
    switch (v.requirement.requirementType) {
      case "Tasks":
      case "TasksInVisual":
      case "TasksInVisualRequired":
      case "TasksRequired":
        return v.requirement.taskIds;
      case "DomainGroupRequired":
      case "Education":
      case "Exit":
      case "ExperienceYears":
      case "IndustryGroupRequired":
      case "Language":
      case "MultipleSkillGroups":
      case "MultipleSkillGroupsRequired":
      case "RoleSpecificity":
      case "Salary":
      case "SkillGroup":
      case "SkillGroupRequired":
      case "Skills":
      case "SkillsInVisualRequired":
      case "SkillsRequired":
      case "StaffResponsibility":
      case "SubRole":
        return [];
    }
  }

  private static getlanguageIdsFromRobotRequirement(v: {
    requirement: SourcingRobotRequirement;
  }): string[] {
    switch (v.requirement.requirementType) {
      case "Language":
        return Object.keys(v.requirement.languageIdsWithRequired);
      case "Tasks":
      case "TasksInVisual":
      case "TasksInVisualRequired":
      case "TasksRequired":
      case "DomainGroupRequired":
      case "Education":
      case "Exit":
      case "ExperienceYears":
      case "IndustryGroupRequired":
      case "MultipleSkillGroups":
      case "MultipleSkillGroupsRequired":
      case "RoleSpecificity":
      case "Salary":
      case "SkillGroup":
      case "SkillGroupRequired":
      case "Skills":
      case "SkillsInVisualRequired":
      case "SkillsRequired":
      case "StaffResponsibility":
      case "SubRole":
        return [];
    }
  }

  private static getResponsibilityIdsFromRobotRequirement(v: {
    requirement: SourcingRobotRequirement;
  }): RoleResponsibility[] {
    switch (v.requirement.requirementType) {
      case "StaffResponsibility":
        return v.requirement.staffIsRequired ? [RoleResponsibility.Staff] : [];
      case "Language":
      case "Tasks":
      case "TasksInVisual":
      case "TasksInVisualRequired":
      case "TasksRequired":
      case "DomainGroupRequired":
      case "Education":
      case "Exit":
      case "ExperienceYears":
      case "IndustryGroupRequired":
      case "MultipleSkillGroups":
      case "MultipleSkillGroupsRequired":
      case "RoleSpecificity":
      case "Salary":
      case "SkillGroup":
      case "SkillGroupRequired":
      case "Skills":
      case "SkillsInVisualRequired":
      case "SkillsRequired":
      case "SubRole":
        return [];
    }
  }

  private static getindustryIdsFromRobotRequirement(v: {
    requirement: SourcingRobotRequirement;
    allRobotModules: RobotStepperModule[];
  }): string[] {
    switch (v.requirement.requirementType) {
      case "IndustryGroupRequired": {
        const req = v.requirement;
        if (v.requirement.groupIsImportant) {
          const module = FilterHelpers.filterByModuleType(
            v.allRobotModules,
            "IndustryGroupRequired"
          ).find(m => m.moduleId === req.moduleId);
          return module?.industryIds ?? [];
        }
        return [];
      }
      case "Tasks":
      case "Language":
      case "TasksInVisual":
      case "TasksInVisualRequired":
      case "TasksRequired":
      case "DomainGroupRequired":
      case "Education":
      case "Exit":
      case "ExperienceYears":
      case "MultipleSkillGroups":
      case "MultipleSkillGroupsRequired":
      case "RoleSpecificity":
      case "Salary":
      case "SkillGroup":
      case "SkillGroupRequired":
      case "Skills":
      case "SkillsInVisualRequired":
      case "SkillsRequired":
      case "StaffResponsibility":
      case "SubRole":
        return [];
    }
  }

  public static getSkillIdsFromRobotRequirements(v: {
    requirements: SourcingRobotRequirement[];
    allRobotModules: RobotStepperModule[];
  }) {
    return this.getIdsFromRequirementsAndModules({
      allRobotModules: v.allRobotModules,
      requirements: v.requirements,
      func: this.getSkillIdsFromRobotRequirement,
    });
  }

  private static getSkillIdsFromRobotRequirement(v: {
    requirement: SourcingRobotRequirement;
    allRobotModules: RobotStepperModule[];
  }): string[] {
    switch (v.requirement.requirementType) {
      case "Skills":
      case "SkillsRequired":
      case "SkillsInVisualRequired":
        return v.requirement.skillIds;
      case "SkillGroupRequired": {
        const req = v.requirement;
        if (v.requirement.groupIsImportant) {
          const module = FilterHelpers.filterByModuleType(
            v.allRobotModules,
            "SkillGroupRequired"
          ).find(m => m.moduleId === req.moduleId);
          return module?.skillIds ?? [];
        }
        return [];
      }
      case "SkillGroup": {
        const req = v.requirement;
        if (v.requirement.groupIsImportant) {
          const module = FilterHelpers.filterByModuleType(
            v.allRobotModules,
            "SkillGroup"
          ).find(m => m.moduleId === req.moduleId);
          return module?.skillIds ?? [];
        }
        return [];
      }
      case "Tasks":
      case "TasksInVisual":
      case "TasksInVisualRequired":
      case "TasksRequired":
      case "DomainGroupRequired":
      case "Education":
      case "Exit":
      case "ExperienceYears":
      case "IndustryGroupRequired":
      case "Language":
      case "MultipleSkillGroups":
      case "MultipleSkillGroupsRequired":
      case "RoleSpecificity":
      case "Salary":
      case "StaffResponsibility":
      case "SubRole":
        return [];
    }
  }

  static getMatchingSkillIdsForRecTalent(v: {
    SourcingRobot: SourcingRobotRequirementsObject | null;
    activeSourcing: {
      requirementGroups: GetTalentsForSourcingRequirementGroupDto[];
    } | null;
    allRobotModules: RobotStepperModule[];
    sourcings: {
      requirementGroups: {
        requirements: GetTalentsForSourcingRequirementDto[];
      }[];
    }[];
    visual: JobOfferVisaulToUpdate | null;
  }): string[] {
    const robotSkillIds =
      v.SourcingRobot !== null
        ? this.getSkillIdsFromRobotRequirements({
            requirements: v.SourcingRobot.requirements ?? [],
            allRobotModules: v.allRobotModules,
          })
        : [];

    const activeSourcingIds: string[] =
      v.activeSourcing?.requirementGroups.flatMap(g =>
        g.requirements.flatMap(r =>
          r.type === JobOfferRequirementType.Skill ? r.value : []
        )
      ) ?? [];

    const manualSourcingsSkillIds = this.getSkillIdsFromSaveSourcings({
      sourcings: v.sourcings,
    });

    const skillIdsFromVisual =
      v.visual?.skills.flatMap(s => (s.id !== null ? [s.id] : [])) ?? [];

    return [
      ...new Set([
        ...robotSkillIds,
        ...manualSourcingsSkillIds,
        ...skillIdsFromVisual,
        ...activeSourcingIds,
      ]),
    ];
  }

  static getMatchingTaskIdsForRecTalent(v: {
    SourcingRobot: SourcingRobotRequirementsObject | null;
    activeSourcing: {
      requirementGroups: GetTalentsForSourcingRequirementGroupDto[];
    } | null;
    sourcings: {
      requirementGroups: {
        requirements: GetTalentsForSourcingRequirementDto[];
      }[];
    }[];
    visual: JobOfferVisaulToUpdate | null;
  }): string[] {
    const robotTasksIds =
      v.SourcingRobot !== null
        ? this.getTaskIdsFromRobotRequirements(v.SourcingRobot)
        : [];

    const activeSourcingTaskIds: string[] =
      v.activeSourcing?.requirementGroups.flatMap(g =>
        g.requirements.flatMap(r =>
          r.type === JobOfferRequirementType.Task ? r.value : []
        )
      ) ?? [];

    const manualSourcingsTaskIds = this.getTaskIdsFromSaveSourcings({
      sourcings: v.sourcings,
    });

    const taskIdsFromVisual = v.visual?.taskIds ?? [];

    return [
      ...new Set([
        ...robotTasksIds,
        ...manualSourcingsTaskIds,
        ...taskIdsFromVisual,
        ...activeSourcingTaskIds,
      ]),
    ];
  }

  static getMatchingLanguageIdsForRecTalent(v: {
    SourcingRobot: SourcingRobotRequirementsObject | null;
    activeSourcing: {
      requirementGroups: GetTalentsForSourcingRequirementGroupDto[];
    } | null;
    sourcings: {
      requirementGroups: {
        requirements: GetTalentsForSourcingRequirementDto[];
      }[];
    }[];
    visual: JobOfferVisaulToUpdate | null;
  }): string[] {
    const robotLangIds =
      v.SourcingRobot !== null
        ? this.getIdsFromRequirements({
            requirements: v.SourcingRobot.requirements,
            func: this.getlanguageIdsFromRobotRequirement,
          })
        : [];

    const activeSourcingIds: string[] =
      v.activeSourcing?.requirementGroups.flatMap(g =>
        g.requirements.flatMap(r =>
          r.type === JobOfferRequirementType.Language ? r.value : []
        )
      ) ?? [];

    const manualSourcingsLangIds = this.getLanguageIdsFromSaveSourcings({
      sourcings: v.sourcings,
    });

    return [
      ...new Set([
        ...robotLangIds,
        ...manualSourcingsLangIds,
        ...activeSourcingIds,
      ]),
    ];
  }

  static getMatchingResponsibilityIdsForRecTalent(v: {
    SourcingRobot: SourcingRobotRequirementsObject | null;
    activeSourcing: {
      requirementGroups: GetTalentsForSourcingRequirementGroupDto[];
    } | null;
    sourcings: {
      requirementGroups: {
        requirements: GetTalentsForSourcingRequirementDto[];
      }[];
    }[];
    visual: JobOfferVisaulToUpdate | null;
  }): RoleResponsibility[] {
    const idsFromRobots =
      v.SourcingRobot !== null
        ? this.getIdsFromRequirements<RoleResponsibility>({
            requirements: v.SourcingRobot.requirements,
            func: this.getResponsibilityIdsFromRobotRequirement,
          })
        : [];

    const activeSourcingIds =
      v.activeSourcing?.requirementGroups.flatMap(g =>
        g.requirements.flatMap(r =>
          r.type === JobOfferRequirementType.Responsibility
            ? r.value.responsibility
            : []
        )
      ) ?? [];

    const idsFromSourcings = this.getResponsibilitiesIdsIdsFromSaveSourcings({
      sourcings: v.sourcings,
    });

    return [
      ...new Set([...idsFromRobots, ...idsFromSourcings, ...activeSourcingIds]),
    ];
  }

  static getMatchingIndustryIdsForRecTalent(v: {
    SourcingRobot: SourcingRobotRequirementsObject | null;
    activeSourcing: {
      requirementGroups: GetTalentsForSourcingRequirementGroupDto[];
    } | null;
    allRobotModules: RobotStepperModule[];
    sourcings: {
      requirementGroups: {
        requirements: GetTalentsForSourcingRequirementDto[];
      }[];
    }[];
    visual: JobOfferVisaulToUpdate | null;
  }): string[] {
    const robotIndustryIds =
      v.SourcingRobot !== null
        ? this.getIdsFromRequirementsAndModules({
            requirements: v.SourcingRobot.requirements,
            allRobotModules: v.allRobotModules,
            func: this.getindustryIdsFromRobotRequirement,
          })
        : [];

    const activeSourcingIds =
      v.activeSourcing?.requirementGroups.flatMap(g =>
        g.requirements.flatMap(r =>
          r.type === JobOfferRequirementType.BranchNew ? r.value.id : []
        )
      ) ?? [];

    const manualSourcingsIndustryIds = this.getIndustryIdsIdsFromSaveSourcings({
      sourcings: v.sourcings,
    });

    return [
      ...new Set([
        ...robotIndustryIds,
        ...manualSourcingsIndustryIds,
        ...activeSourcingIds,
      ]),
    ];
  }

  static getSalaryPercentAgainsAverage(v: {
    salary: number | null;
    averageSalary: number;
  }): number {
    if (!v.salary) {
      return 0;
    }

    if (v.averageSalary === 0) {
      return 0;
    }

    // const salaryPercent =
    //   v.salary < v.averageSalary
    //     ? ((v.averageSalary / v.salary) * 100 - 100) * -1
    //     : (v.salary / v.averageSalary) * 100 - 100;
    const salaryPercent = (v.salary / v.averageSalary) * 100 - 100;

    const roundedSalaryPercent = Math.round(salaryPercent);

    return roundedSalaryPercent;
  }

  static getAverageSalary<T>(v: {
    sortedTalents: T[];
    getSalary: (t: T) => number;
    take?: number;
  }): number {
    const talentsTopNWithOkSalary = v.sortedTalents
      .filter(t => v.getSalary(t) >= 15000)
      .slice(0, v.take ?? v.sortedTalents.length);

    if (talentsTopNWithOkSalary.length === 0) {
      return 0;
    }
    return (
      talentsTopNWithOkSalary.reduce((acc, t) => {
        return acc + v.getSalary(t);
      }, 0) / talentsTopNWithOkSalary.length
    );
  }

  static getIdsFromRequirements<T>(v: {
    requirements: SourcingRobotRequirement[] | null;
    func: (v: { requirement: SourcingRobotRequirement }) => T[];
  }): T[] {
    return [
      ...new Set(
        v.requirements?.flatMap(r => {
          return v.func({
            requirement: r,
          });
        }) ?? []
      ),
    ];
  }

  static getIdsFromRequirementsAndModules<T>(v: {
    requirements: T[] | null;
    allRobotModules: RobotStepperModule[];
    func: (v: {
      requirement: T;
      allRobotModules: RobotStepperModule[];
    }) => string[];
  }): string[] {
    return [
      ...new Set(
        v.requirements?.flatMap(r => {
          return v.func({
            requirement: r,
            allRobotModules: v.allRobotModules,
          });
        }) ?? []
      ),
    ];
  }

  static getTaskIdsFromRobotRequirements(v: {
    requirements: SourcingRobotRequirement[] | null;
  }): string[] {
    return this.getIdsFromRequirements({
      requirements: v.requirements,
      func: this.getTaskIdsFromRobotRequirement,
    });
  }

  static getTaskIdsFromSaveSourcings(v: {
    sourcings: {
      requirementGroups: {
        requirements: GetTalentsForSourcingRequirementDto[];
      }[];
    }[];
  }): string[] {
    return [
      ...new Set(
        v.sourcings.flatMap(r => {
          return r.requirementGroups
            .flatMap(rg => rg.requirements)
            .flatMap(r => {
              if (r.type === JobOfferRequirementType.Task) {
                return [r.value];
              }
              return [];
            });
        })
      ),
    ];
  }

  static getSkillIdsFromSaveSourcings(v: {
    sourcings: {
      requirementGroups: {
        requirements: GetTalentsForSourcingRequirementDto[];
      }[];
    }[];
  }): string[] {
    return [
      ...new Set(
        v.sourcings.flatMap(r => {
          return r.requirementGroups
            .flatMap(rg => rg.requirements)
            .flatMap(r => {
              if (r.type === JobOfferRequirementType.Skill) {
                return [r.value];
              }
              return [];
            });
        })
      ),
    ];
  }

  static getLanguageIdsFromSaveSourcings(v: {
    sourcings: {
      requirementGroups: {
        requirements: GetTalentsForSourcingRequirementDto[];
      }[];
    }[];
  }): string[] {
    return [
      ...new Set(
        v.sourcings.flatMap(r => {
          return r.requirementGroups
            .flatMap(rg => rg.requirements)
            .flatMap(r => {
              if (r.type === JobOfferRequirementType.Language) {
                return [r.value];
              }
              return [];
            });
        })
      ),
    ];
  }

  static getIndustryIdsIdsFromSaveSourcings(v: {
    sourcings: {
      requirementGroups: {
        requirements: GetTalentsForSourcingRequirementDto[];
      }[];
    }[];
  }): string[] {
    return [
      ...new Set(
        v.sourcings.flatMap(r => {
          return r.requirementGroups
            .flatMap(rg => rg.requirements)
            .flatMap(r => {
              if (r.type === JobOfferRequirementType.BranchNew) {
                return [r.value.id];
              }
              return [];
            });
        })
      ),
    ];
  }

  static getResponsibilitiesIdsIdsFromSaveSourcings(v: {
    sourcings: {
      requirementGroups: {
        requirements: GetTalentsForSourcingRequirementDto[];
      }[];
    }[];
  }): RoleResponsibility[] {
    return [
      ...new Set(
        v.sourcings.flatMap(r => {
          return r.requirementGroups
            .flatMap(rg => rg.requirements)
            .flatMap(r => {
              if (r.type === JobOfferRequirementType.Responsibility) {
                return [r.value.responsibility];
              }
              return [];
            });
        })
      ),
    ];
  }

  static localeLaneName(laneName: string): string {
    if (!["Inkorg", "Granskning", "Anställd"].includes(laneName)) {
      return laneName;
    }
    return $i18n.t(`laneNames.${laneName}`).toString();
  }

  static getFromNowShortNew(date: Date | null): string {
    if (date === null) {
      return "";
    }

    const dayDiffRounded = this.getNumberOfDaysFromNow(date);

    return `${dayDiffRounded}d`;
  }

  static getNumberOfDaysFromNow(date: Date) {
    const now = new Date();

    const timeDiff = now.getTime() - date.getTime();

    // To calculate the no. of days between two dates
    const dayDiff = timeDiff / (1000 * 3600 * 24);

    const dayDiffRounded = Math.floor(dayDiff);

    return dayDiffRounded;
  }

  private static getRecTalentKey(v: {
    talentId?: string;
    recruitmentId: string;
  }): string {
    return `${v.talentId}|${v.recruitmentId}`;
  }

  static getRecruitmentLogsWithoutRobotDeclines(v: {
    logs: RecruitmentLogObject[];
  }): RecruitmentLogObject[] {
    const logsByRecruitmentId = v.logs.reduce(
      (acc: { [key: string]: RecruitmentLogObject[] }, l) => {
        acc[this.getRecTalentKey(l)] = [
          ...(acc[this.getRecTalentKey(l)] || []),
          l,
        ];
        return acc;
      },
      {}
    );

    return Object.values(logsByRecruitmentId).flatMap(logs =>
      this.getRecruitmentLogsWithoutRobotDeclinesWithinRecruitment({ logs })
    );
  }

  private static getRecruitmentLogsWithoutRobotDeclinesWithinRecruitment(v: {
    logs: RecruitmentLogObject[];
  }): RecruitmentLogObject[] {
    const sortedLogs = SortHelper.sortItemsBy(v.logs, [
      {
        sortBy: x => x.date,
        desc: true,
      },
    ]);

    let showRobotInvite = false;
    let hideDecline = false;
    // let isAccepted = false;
    // let declinesBeforeAccept: string[] = [];

    const result = sortedLogs.filter(l => {
      if (l.logType === LogTypes.TalentAccepted) {
        showRobotInvite = true;
        // isAccepted = true;
      }
      if (l.logType === LogTypes.TalentInvitedByRobot) {
        const show = showRobotInvite;
        hideDecline = true;
        // isAccepted = false;

        showRobotInvite = false;
        return show;
      }
      if (l.logType === LogTypes.TalentDeclined) {
        const show = !hideDecline;
        // if (isAccepted) {
        //   declinesBeforeAccept = [...declinesBeforeAccept, l.id];
        // }

        // showRobotInvite = false;
        return show;
      }
      return true;
    });

    hideDecline = false;

    const resultAfterReversed = [...sortedLogs].reverse().filter(l => {
      if (l.logType === LogTypes.TalentInvitedByRobot) {
        hideDecline = true;
      }
      if (l.logType === LogTypes.TalentAccepted) {
        hideDecline = false;
      }
      if (l.logType === LogTypes.TalentDeclined) {
        const show = !hideDecline;
        return show;
      }
      return true;
    });

    return result.filter(l => resultAfterReversed.includes(l));
  }

  static getNumberOfDaysFromNowUntilDate(date: Date) {
    const now = new Date();

    const timeDiff = date.getTime() - now.getTime();

    // To calculate the no. of days between two dates
    const dayDiff = timeDiff / (1000 * 3600 * 24);

    const dayDiffRounded = Math.floor(dayDiff);

    return dayDiffRounded;
  }

  static getListByDateString<T>(v: {
    list: T[];
    getDate: (v: T) => Date;
  }): {
    [key: string]: T[];
  } {
    const result = [...v.list]
      .sort((b, a) => {
        const aDate = v.getDate(a);
        const bDate = v.getDate(b);
        return aDate > bDate ? 1 : bDate > aDate ? -1 : 0;
      })
      .reduce((acc: { [key: string]: T[] }, item) => {
        const date = this.getMomentCalendarDateString(v.getDate(item));
        acc[date] = [...(acc[date] || []), item];
        return acc;
      }, {});
    return result;
  }

  static getMomentCalendarDateString(date: Date): string {
    moment.locale($i18n.locale);

    return moment(date).calendar(undefined, {
      sameDay: `[${$i18n.t("dateStrings.today").toString()}]`,
      nextDay: `[${$i18n.t("dateStrings.tomorrow").toString()}]`,
      nextWeek: "dddd",
      lastDay:
        `[${$i18n.t("dateStrings.yesterday").toString()}]` + " YYYY-MM-DD",
      lastWeek:
        $i18n.t("dateStrings.lastWeekFormat").toString() + " YYYY-MM-DD",
      sameElse: "YYYY-MM-DD",
    });
  }

  static getFromNowString(value: any): string {
    if (!this.isDate(value)) {
      return value;
    }

    moment.locale($i18n.locale);

    return moment(value).fromNow();
  }

  static getFromNowStringShort(value: any): string {
    if (!this.isDate(value)) {
      return value;
    }

    const localMoment = moment(value);

    const existingLocales = moment.locales();
    if (!existingLocales.find(l => l === "enShort")) {
      moment.defineLocale("enShort", {
        relativeTime: {
          future: "in %s",
          past: "%s ago",
          s: "<1m",
          ss: "%ss",
          m: "1m",
          mm: "%dm",
          h: "1h",
          hh: "%dh",
          d: "1d",
          dd: "%dd",
          M: "1M",
          MM: "%dM",
          y: "1Y",
          yy: "%dY",
        },
      });
    }

    if (!existingLocales.find(l => l === "svShort")) {
      moment.defineLocale("svShort", {
        relativeTime: {
          future: "om %s",
          past: "%s sedan",
          s: "<1m",
          ss: "%ss",
          m: "1m",
          mm: "%dm",
          h: "1t",
          hh: "%dt",
          d: "1d",
          dd: "%dd",
          M: "1M",
          MM: "%dM",
          y: "1Å",
          yy: "%dÅ",
        },
      });
    }

    if ($i18n.locale === "sv") {
      localMoment.locale("svShort");
    } else {
      localMoment.locale("enShort");
    }

    return localMoment.fromNow(true);
  }

  static isDate(value: any): value is Date {
    return typeof value === "object" && typeof value.getMonth === "function";
  }

  static getRecruitmentMatchesFilter(v: {
    recruitment: RecruitmentListItem;
    filter: RecruitmentFilterItem;
    userIdsByRecId: Record<string, string[]>;
  }): boolean {
    switch (v.filter.type) {
      case "TitleContains":
        if (this.getRecruitmentFilterIsEmpty(v.filter)) {
          return true;
        }
        return (v.recruitment.title ?? "")
          .toLowerCase()
          .includes((v.filter.text ?? "").toLowerCase());
      case "Offices":
        if (this.getRecruitmentFilterIsEmpty(v.filter)) {
          return true;
        }
        return v.filter.officeIds.some(
          officeId =>
            (officeId.isRemote && v.recruitment.officeId === null) ||
            (!officeId.isRemote && officeId.officeId === v.recruitment.officeId)
        );
      case "Roles":
        if (this.getRecruitmentFilterIsEmpty(v.filter)) {
          return true;
        }
        return (
          !!v.recruitment.roleId &&
          v.filter.roleIds.includes(v.recruitment.roleId)
        );
      case "Users":
        if (this.getRecruitmentFilterIsEmpty(v.filter)) {
          return true;
        }
        return v.filter.users.some(u => {
          if (u.type === "User") {
            return v.userIdsByRecId[v.recruitment.id]?.includes(u.userId);
          }
          return v.userIdsByRecId[v.recruitment.id]?.length === 0;
        });
      case "Status":
        return (
          !v.filter.statuses.length ||
          v.filter.statuses.some(status =>
            status === "Active"
              ? !v.recruitment.closedDate
              : status === "Removed"
              ? !!v.recruitment.closedDate
              : true
          )
        );
      case "RobotStatus": {
        const robotStatus = getRobotStatus({
          recListItem: v.recruitment,
        });
        return (
          !v.filter.statuses.length ||
          v.filter.statuses.some(
            status =>
              (status === "Active" && robotStatus === "Active") ||
              (status === "Paused" && robotStatus === "Paused") ||
              (status === "NotStarted" && robotStatus === "NotExisting")
          )
        );
      }
    }
  }

  static getColorByRecruitmentStatus(
    v: CompanyTalentRecuritmentStatus
  ): string {
    switch (v) {
      case "Saved":
        return "accent";
      case "Invited":
        return "warning";
      case "Accepted":
        return "success";
      case "Removed":
        return "error";
      case "Declined":
        return "error";
      case "Other":
        return "grey";
      case "RobotHidden":
        return "grey";
      case "Hired":
        return "success";
    }
  }

  static getTextByRecruitmentStatus(v: CompanyTalentRecuritmentStatus): string {
    switch (v) {
      case "Saved":
        return $i18n.t("CompanyTalentRecuritmentStatusText.Saved").toString();
      case "Invited":
        return $i18n.t("CompanyTalentRecuritmentStatusText.Invited").toString();
      case "Accepted":
        return $i18n
          .t("CompanyTalentRecuritmentStatusText.Accepted")
          .toString();
      case "Removed":
        return $i18n.t("CompanyTalentRecuritmentStatusText.Removed").toString();
      case "Declined":
        return $i18n
          .t("CompanyTalentRecuritmentStatusText.Declined")
          .toString();
      case "Other":
      case "RobotHidden":
        return $i18n.t("CompanyTalentRecuritmentStatusText.Other").toString();
      case "Hired":
        return $i18n.t("CompanyTalentRecuritmentStatusText.Hired").toString();
    }
  }

  static getSortFunction(
    sortBy: RecruitmentSortOptions,
    allOffices: Office[]
  ): {
    sortBy: (v: RecruitmentListItem) => string | number | boolean | Date | null;
    desc?: boolean | undefined;
  }[] {
    switch (sortBy) {
      case "State":
        return [
          {
            sortBy: x =>
              getRobotStatus({
                recListItem: x,
              }),
          },
          {
            sortBy: x => x.createdDate,
            desc: true,
          },
        ];
      case "Price":
        return [
          {
            sortBy: x => !!x.succesPrice?.discountedPrice,
            desc: true,
          },
          {
            sortBy: x => x.succesPrice?.discountedPrice ?? 0,
          },
          {
            sortBy: x => x.createdDate,
            desc: true,
          },
        ];
      case "Office":
        return [
          {
            sortBy: x => allOffices.find(o => o.id === x.officeId)?.name ?? "",
          },
          {
            sortBy: x => x.createdDate,
            desc: true,
          },
        ];
      case "CreatedDate":
        return [
          {
            sortBy: x => x.numberOfTalentsNotHandled ?? 0,
            desc: true,
          },
          {
            sortBy: x => x.numberOfAcceptedTalents ?? 0,
            desc: true,
          },
          {
            sortBy: x => x.createdDate,
            desc: true,
          },
        ];
      case "Active":
        return [
          {
            sortBy: x => x.numberOfActiveTalents,
            desc: true,
          },
          {
            sortBy: x => x.createdDate,
            desc: true,
          },
        ];
      case "Interested":
        return [
          {
            sortBy: x => x.numberOfAcceptedTalents,
            desc: true,
          },
          {
            sortBy: x => x.createdDate,
            desc: true,
          },
        ];
    }
  }

  static mergeArrays<T>(a: T[], b: T[], getId: (v: T) => string): T[] {
    const reduced = a.filter(
      aitem => !b.find(bitem => getId(aitem) === getId(bitem))
    );
    return reduced.concat(b);
  }

  static getCommentSender(v: {
    c: {
      id: string;
      senderId: string | null;
      senderEmail: string | null;
    };
    companyCommentSenders: {
      [key: string]: CommentSender;
    };
  }) {
    return (
      (v.c.senderId ? v.companyCommentSenders[v.c.senderId] : null) ??
      (v.c.senderEmail
        ? {
            firstName: v.c.senderEmail,
            lastName: "",
            userId: v.c.senderEmail,
          }
        : {
            firstName: "??",
            lastName: "",
            userId: v.c.id,
          })
    );
  }

  static filterByType<T extends { type: string }, U extends T["type"]>(
    arr: T[],
    type: U
  ) {
    type R = Extract<T, { type: U }>;
    return arr.filter((item): item is R => item.type === type);
  }

  static filterByModuleType<
    T extends { moduleType: string },
    U extends T["moduleType"]
  >(arr: T[], moduleType: U) {
    type R = Extract<T, { moduleType: U }>;
    return arr.filter((item): item is R => item.moduleType === moduleType);
  }

  static getProcent(v: { value: number | null; maxValue: number }): number {
    if (!v.value) {
      return 0;
    }

    return Math.round((v.value / v.maxValue) * 100);
  }
}

export const isSomething = <T>(v: T | undefined | null): v is T =>
  v !== undefined && v !== null;

export const getDateStringFormatL = (value: Date): string => {
  // Har detta format: 2024-06-29

  moment.locale("sv");

  return moment(value).format("L");
};
