




































import { Vue, Component, Prop } from "vue-property-decorator";
import TalentHelpers from "~/helpers/talentHelpers";
import TalentPoolHelpers from "~/helpers/TalentPoolHelpers";
import {
  TalentConversation,
  TalentPoolTalent,
  TrelloBoardTalent,
} from "~/models/types";
import { companyBaseStore, jobbOfferStore, talentPoolStore } from "~/store";
import { LoadedRecTalentListItem } from "~/store/jobbOffer";

@Component
export default class ConversationTalentInfo extends Vue {
  @Prop({ type: Object, required: true })
  readonly selectedConversation!: TalentConversation;

  get linkUrl(): string {
    const conv = this.selectedConversation;
    if (conv.recruitmentIdOrNull !== null) {
      return this.localePath({
        name: "company-companyId-recruitments-recruitmentId-candidate-talentId",
        params: {
          talentId: conv.talentId,
          recruitmentId: conv.recruitmentIdOrNull,
          companyId: conv.companyId,
        },
        query: { tab: "2" },
      });
    }
    return this.localePath({
      name: "company-companyId-talentPool",
      params: {
        companyId: conv.companyId,
      },
      query: {
        talentId: conv.talentId,
        tab: "2",
      },
    });
  }

  get talentName(): string {
    if (this.recTalent) {
      return TalentHelpers.getNameForTalent({
        poolTalents: talentPoolStore.poolTalents,
        recTalent: this.recTalent,
        companyTalents: companyBaseStore.companyTalents,
      });
    }

    return this.selectedConversation.talentName;
  }

  get markAsReadText(): string {
    return this.liveConversation?.isRead
      ? this.$t("Markera som oläst").toString()
      : this.$t("Markera som läst").toString();
  }

  loadingMarkAsRead = false;

  get liveConversation(): TalentConversation | null {
    return companyBaseStore.talentConversations.type === "Loaded"
      ? companyBaseStore.talentConversations.conversations.find(
          x => x.id === this.selectedConversation.id
        ) ?? null
      : null;
  }

  async toggleIsRead() {
    if (!this.liveConversation) {
      return false;
    }
    this.loadingMarkAsRead = true;
    await companyBaseStore.setTalentConversationRead({
      conversationId: this.selectedConversation.id,
      isRead: !this.liveConversation.isRead,
      recruitmentId: this.selectedConversation.recruitmentIdOrNull,
      talentId: this.selectedConversation.talentId,
    });

    this.loadingMarkAsRead = false;
  }

  get poolTalent(): TalentPoolTalent | null {
    return (
      talentPoolStore.poolTalents.find(
        pt => pt.talentId === this.selectedConversation.talentId
      ) ?? null
    );
  }

  get rating(): number | null {
    if (this.recTalent) {
      return this.recTalent.rating;
    }
    return this.poolTalent?.rating ?? null;
  }

  get recruitmentTitle(): string | null {
    const conv = this.selectedConversation;
    return conv.recruitmentIdOrNull !== null
      ? jobbOfferStore.recruitmentList.find(
          r => r.id === conv.recruitmentIdOrNull
        )?.title ?? null
      : null;
  }

  get recTalentOrLoading(): LoadedRecTalentListItem | null {
    const conv = this.selectedConversation;

    if (conv.recruitmentIdOrNull === null) {
      return null;
    }

    const recTalentOrUndefined =
      jobbOfferStore.loadedRecTalentsRecord[
        TalentPoolHelpers.getRecTalentKey({
          recruitmentId: conv.recruitmentIdOrNull,
          talentId: conv.talentId,
        })
      ];

    return recTalentOrUndefined ?? null;
  }

  get recTalent(): TrelloBoardTalent | null {
    if (this.recTalentOrLoading && this.recTalentOrLoading.type === "Loaded") {
      return this.recTalentOrLoading.recTalent;
    }

    return null;
  }
}
