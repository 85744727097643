
































































































































































































































































































































































































import { Vue, Component } from "nuxt-property-decorator";
import confirm from "../components/Confirm.vue";
import { talentProfileStore } from "~/store";

@Component({
  components: {
    confirm,
  },
})
export default class TalentLayout extends Vue {
  drawer = false;
  isHydrated = false;
  phoneMenuOpen = false;

  get isMobile() {
    if (!this.isHydrated) {
      return false;
    }
    const breakPointName = this.$vuetify.breakpoint.name;
    return breakPointName === "xs";
  }

  get isDisconnectedFromSignalR(): boolean {
    return talentProfileStore.isDisconnected;
  }

  get isDone(): boolean {
    return talentProfileStore.profile.isDone;
  }

  get isPaused(): boolean {
    return talentProfileStore.isPaused;
  }

  reloadSite() {
    location.reload();
  }

  ignoredDisconnected = true;

  cancelReloadPage() {
    this.ignoredDisconnected = true;
  }

  $refs!: Vue["$refs"] & {};

  mounted() {
    (this.$root as any).$confirm = (this.$refs.confirm as any).open;
    this.isHydrated = true;
  }

  goHome() {
    this.phoneMenuOpen = false;
    this.$router.push(
      this.localePath({
        name: "talent",
      })
    );
  }

  goToCreateProfile() {
    this.phoneMenuOpen = false;
    this.$router.push(
      this.localePath({
        name: "talent-createProfile",
      })
    );
  }

  goToMyExperience() {
    this.phoneMenuOpen = false;
    this.$router.push(
      this.localePath({
        name: "talent-myExperience",
      })
    );
  }

  goToMyPreferences() {
    this.phoneMenuOpen = false;
    this.$router.push(
      this.localePath({
        name: "talent-myPreferences",
      })
    );
  }

  goToAccountSettings() {
    this.phoneMenuOpen = false;
    this.$router.push(
      this.localePath({
        name: "talent-accountsettings",
      })
    );
  }

  logout() {
    this.$msal.signOut();
  }
}
