























































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import GrNavLinkDense from "./GrNavLinkDense.vue";
import GrButton from "./GrButton.vue";

@Component({
  components: {
    GrNavLinkDense,
    GrButton,
  },
})
export default class GrPublicNavBarCompany extends Vue {
  @Prop({ default: false }) readonly greyBackground!: boolean;

  get showAlternativeLogo(): boolean {
    return this.$vuetify.breakpoint.xsOnly;
  }

  get navBarHeight(): number {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return 56;
      case "sm":
      case "md":
        return 72;
      case "lg":
      case "xl":
        return 104;
    }
  }
}
