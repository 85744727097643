import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import jobOfferService from "~/services/jobOfferService";
import { GetTalentsForSourcingDto, TalentForFiltering } from "~/models/types";
import JobbOfferRequirementsHelper from "~/helpers/jobbOfferRequirementsHelper";
import { searchTalentsStore } from "~/utils/store-accessor";
import TokenHelpers from "~/helpers/tokenHelpers";

@Module({
  name: "searchTalents",
  stateFactory: true,
  namespaced: true,
})
export default class SearchTalents extends VuexModule {
  talents: TalentForFiltering[] = [];
  visitedTalents: { talentId: string; recruitmentId: string }[] = [];
  searchIsToWide: boolean = false;
  countFromServer: number = 0;
  loading = false;
  lastSearchParams: {
    searchDateTime: Date;
    params: GetTalentsForSourcingDto;
  } | null = null;

  @Mutation
  SET_TALENTS(talents: TalentForFiltering[]) {
    this.talents = talents;
  }

  @Mutation
  SET_SEARCH_IS_TO_WIDE(toWide: boolean) {
    this.searchIsToWide = toWide;
  }

  @Mutation
  SET_VISITED_TALENTS(v: { talentId: string; recruitmentId: string }[]) {
    this.visitedTalents = v;
  }

  @Action
  setTalentVisited(v: { talentId: string; recruitmentId: string }) {
    searchTalentsStore.SET_VISITED_TALENTS([
      ...this.visitedTalents.filter(
        x => !(x.talentId === v.talentId && x.recruitmentId === v.recruitmentId)
      ),
      { talentId: v.talentId, recruitmentId: v.recruitmentId },
    ]);
  }

  @Mutation
  SET_LAST_SEARCH_PARAMS(
    v: {
      searchDateTime: Date;
      params: GetTalentsForSourcingDto;
    } | null = null
  ) {
    this.lastSearchParams = v;
  }

  @Mutation
  SET_COUNT_FROM_SERVER(count: number) {
    this.countFromServer = count;
  }

  @Mutation
  SET_LOADING_TALENTS(loading: boolean) {
    this.loading = loading;
  }

  @Action
  async searchTalents(searchParams: GetTalentsForSourcingDto) {
    const minDateForLastSearch = new Date(new Date().valueOf() - 30 * 60000);
    const oldSearchCanBeUsed =
      !!this.lastSearchParams &&
      this.lastSearchParams.searchDateTime > minDateForLastSearch &&
      JobbOfferRequirementsHelper.oldSearchCanBeUsed(
        this.lastSearchParams.params,
        searchParams
      );
    if (!oldSearchCanBeUsed) {
      this.context.commit("SET_LOADING_TALENTS", true);

      const token = await TokenHelpers.getToken();
      const talents = await jobOfferService.getTalentsForSourcingNew(
        searchParams,
        token
      );

      this.context.commit("SET_LOADING_TALENTS", false);

      this.context.commit("SET_TALENTS", talents.items);
      this.context.commit("SET_SEARCH_IS_TO_WIDE", talents.count >= 1000);
      this.context.commit("SET_COUNT_FROM_SERVER", talents.count);
      if (talents.count >= 1000 || talents.count === 0) {
        searchTalentsStore.SET_LAST_SEARCH_PARAMS(null);
      } else {
        searchTalentsStore.SET_LAST_SEARCH_PARAMS({
          params: searchParams,
          searchDateTime: new Date(),
        });
      }
    }
  }
}
