

























import { Vue, Component, Prop } from "vue-property-decorator";

const grButtonColorsArray = [
  "primary",
  "secondary",
  "accent",
  "warning",
  "link",
  "linkOutlined",
  "outlinedBlack",
  "grey",
  "greyLink",
  "greyBlack",
  "error",
  "success",
] as const;

type ButtonSize = "smallSquare" | "small" | "medium" | "large" | "xLarge";

export type GrButtonColors = typeof grButtonColorsArray[number];

@Component
export default class GrButton extends Vue {
  @Prop({ type: String, default: null })
  readonly text!: string | null;

  @Prop({ type: Boolean, default: false })
  readonly large!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly small!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly smallSquare!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly xLarge!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly noPadding!: boolean;

  get skipPadding() {
    return this.$vuetify.breakpoint.smAndDown && this.noPadding;
  }

  @Prop({
    validator(value) {
      return grButtonColorsArray.includes(value);
    },
    default: null,
  })
  readonly color!: GrButtonColors | null;

  @Prop({ type: Number, default: 0 })
  readonly elevation!: number;

  get colorStyles() {
    if (this.color === null) {
      return {
        "background-color": this.$vuetify.theme.themes.light.primary,
        color: "white",
      };
    }

    return this.getColorStyles(this.color);
  }

  get buttonSize(): ButtonSize {
    return this.smallSquare
      ? "smallSquare"
      : this.large
      ? "large"
      : this.small
      ? "small"
      : this.xLarge
      ? "xLarge"
      : "medium";
  }

  getColorStyles(colorType: GrButtonColors): {} {
    switch (colorType) {
      case "primary":
        return {
          "background-color": this.$vuetify.theme.themes.light.primary,
          color: "white",
        };
      case "secondary":
        return {
          "background-color": "#040F3B",
          color: "white",
        };
      case "accent":
        return {
          "background-color": this.$vuetify.theme.themes.light.accent,
          color: "white",
        };
      case "warning":
        return {
          "background-color": "#FFC92F",
          color: "#040F3B",
        };
      case "link":
        return {
          "background-color": "#0034FF",
          color: "white",
        };
      case "linkOutlined":
        return {
          "background-color": "white",
          border: "1px solid #E0E0E0",
          color: "#0034FF",
        };
      case "outlinedBlack":
        return {
          "background-color": "white",
          border: "1px solid #E0E0E0",
          color: "black",
        };
      case "grey":
        return {
          "background-color": "#F5F5F5",
          color: "#666666",
        };
      case "greyLink":
        return {
          "background-color": "#F5F5F5",
          color: "#0034FF",
        };

      case "greyBlack":
        return {
          "background-color": "#F5F5F5",
          color: "black",
        };
      case "error":
        return {
          "background-color": "#F13535",
          color: "white",
        };
      case "success":
        return {
          "background-color": "#00A651",
          color: "white",
        };
    }
  }

  get sizeStyles() {
    return this.getSizeStyles();
  }

  getSizeStyles(): {
    fontSize: string;
    lineHeight: string;
    padding: string;
  } {
    const breakPointName = this.$vuetify.breakpoint.name;
    switch (this.buttonSize) {
      case "smallSquare":
        switch (breakPointName) {
          case "xs":
          case "sm":
          case "md":
            return {
              fontSize: "12px",
              lineHeight: "16px",
              padding: "5px 10px",
            };
          case "lg":
          case "xl":
            return {
              fontSize: "14px",
              lineHeight: "10px",
              padding: "8px 10px",
            };
        }
        break;
      case "small":
        switch (breakPointName) {
          case "xs":
          case "sm":
          case "md":
            return {
              fontSize: "12px",
              lineHeight: "16px",
              padding: "5px 10px",
            };
          case "lg":
          case "xl":
            return {
              fontSize: "14px",
              lineHeight: "20px",
              padding: "8px 10px",
            };
        }
        break;
      case "medium":
        switch (breakPointName) {
          case "xs":
          case "sm":
          case "md":
            return {
              fontSize: "14px",
              lineHeight: "18px",
              padding: "10px 16px",
            };
          case "lg":
          case "xl":
            return {
              fontSize: "20px",
              lineHeight: "24px",
              padding: "16px 24px",
            };
        }
        break;
      case "large":
        switch (breakPointName) {
          case "xs":
          case "sm":
          case "md":
            return {
              fontSize: "16px",
              lineHeight: "18px",
              padding: "16px 24px",
            };
          case "lg":
          case "xl":
            return {
              fontSize: "24px",
              lineHeight: "28px",
              padding: "28px 40px",
            };
        }
        break;
      case "xLarge":
        switch (breakPointName) {
          case "xs":
          case "sm":
          case "md":
            return {
              fontSize: "16px",
              lineHeight: "18px",
              padding: "16px 24px",
            };
          case "lg":
          case "xl":
            return {
              fontSize: "24px",
              lineHeight: "28px",
              padding: "28px 40px",
            };
        }
        break;
    }
  }

  getFontSize(): string {
    switch (this.buttonSize) {
      case "smallSquare":
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
          case "sm":
          case "md":
            return "14px";
          case "lg":
          case "xl":
            return "16px";
        }
        break;
      case "small":
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
          case "sm":
          case "md":
            return "14px";
          case "lg":
          case "xl":
            return "16px";
        }
        break;
      case "medium":
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
          case "sm":
          case "md":
            return "14px";
          case "lg":
          case "xl":
            return "16px";
        }
        break;
      case "large":
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
          case "sm":
          case "md":
            return "16px";
          case "lg":
          case "xl":
            return "18px";
        }
        break;
      case "xLarge":
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
          case "sm":
          case "md":
            return "16px";
          case "lg":
          case "xl":
            return "18px";
        }
        break;
    }
  }

  get fontSize() {
    return {
      "font-size": this.getFontSize(),
    };
  }
}
