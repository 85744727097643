import { pipe } from "fp-ts/lib/function";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { sequenceT } from "fp-ts/lib/Apply";
import {
  getAllIsTrue,
  getIsEqualProps,
  arraysAreEqual,
  getShortText,
  tryGetById,
  fixLargeNumbers,
} from "./CommonHelperFunctions";
import FilterHelpers from "./filterHelpers";
import {
  CompanyTalentGetDto,
  GetRecruitmentListItemDto,
  JobOfferVisaulToUpdate,
  LaneType,
  Office,
  RecruitmentListItem,
  RecruitmentTeamMember,
  TalentDepartment,
  TalentInviteFailReason,
  TrelloBoardTalentStatus,
  WorkFromHomeOrPartOfWeek,
} from "~/models/types";
import { JobbOfferBaseInfo } from "~/models/JobbOfferBaseInfo";
import { ListObjectNullable } from "~/models/ListObjectNullable";
import { WorkFromHome } from "~/models/WorkFromHome";
import { RoleResponsibility } from "~/models/RoleResponsibility";

const getWorkFromHomeIsEqual = (a: JobbOfferBaseInfo, b: JobbOfferBaseInfo) => {
  const aMapped = mapToWorkFromHomeForEdit(a.workFromHome, a.isFullRemote);

  const bMapped = mapToWorkFromHomeForEdit(b.workFromHome, b.isFullRemote);

  return (
    aMapped === bMapped &&
    (a.acceptsFullRemote ?? false) === (b.acceptsFullRemote ?? false)
  );
};

export const getIsEqualBaseInfo = (
  a: JobbOfferBaseInfo,
  b: JobbOfferBaseInfo
): boolean => {
  const propsAreEqual = getIsEqualProps(a)(b);
  return getAllIsTrue([
    propsAreEqual(x => x.title?.id),
    propsAreEqual(x => x.title?.text),
    propsAreEqual(x => x.roleId),
    propsAreEqual(x => x.office?.id),
    propsAreEqual(x => x.includesTravel),
    () => getWorkFromHomeIsEqual(a, b),
    propsAreEqual(x =>
      x.rolesResponsibilities.includes(RoleResponsibility.Staff)
    ),
  ]);
};

const listObjectIsEqual = (
  a: ListObjectNullable,
  b: ListObjectNullable
): boolean => a.id === b.id && a.text === b.text;

export const getIsEqualVisual = (
  a: JobOfferVisaulToUpdate,
  b: JobOfferVisaulToUpdate
): boolean => {
  const propsAreEqual = getIsEqualProps(a)(b);
  return getAllIsTrue([
    propsAreEqual(x => x.announcementTitle),
    propsAreEqual(x => x.department?.id),
    propsAreEqual(x => x.ingress),
    () => arraysAreEqual(a.perks, b.perks, (a, b) => a.text === b.text),
    () => arraysAreEqual(a.skills, b.skills, listObjectIsEqual),
    () => arraysAreEqual(a.taskIds, b.taskIds, (a, b) => a === b),
  ]);
};

export const askAboutSkills = (v: {
  rolesAll: { id: string; showSkillsInVisual: boolean }[];
  roleId: string | null;
}) =>
  pipe(
    v.roleId,
    O.fromNullable,
    O.flatMap(tryGetShowSkillsInVisual(v)),
    O.getOrElse(() => false)
  );

const tryGetShowSkillsInVisual = (v: {
  rolesAll: { id: string; showSkillsInVisual: boolean }[];
  roleId: string | null;
}) => (roleId: string): O.Option<boolean> => {
  return pipe(
    v.rolesAll,
    A.findFirst(r => r.id === roleId),
    O.map(r => r.showSkillsInVisual)
  );
};

export const mapToWorkFromHomeForEdit = (
  v: WorkFromHome | null,
  isFullRemove: boolean
): WorkFromHomeOrPartOfWeek | null => {
  if (v == null) {
    return null;
  }
  if (isFullRemove) {
    return "FullRemote";
  }
  switch (v) {
    case WorkFromHome.OneDay:
    case WorkFromHome.TwoDays:
    case WorkFromHome.ThreeDays:
    case WorkFromHome.FourDays:
      return "PartOfTheWeek";
    case WorkFromHome.AllWeek:
      return "AllWeek";
    case WorkFromHome.No:
      return "Never";
  }
};

export const mapToWorkFromHome = (
  v: WorkFromHomeOrPartOfWeek | null
): WorkFromHome => {
  if (v == null) {
    return WorkFromHome.No;
  }
  switch (v) {
    case "Never":
      return WorkFromHome.No;
    case "FullRemote":
      return WorkFromHome.AllWeek;
    case "AllWeek":
      return WorkFromHome.AllWeek;
    case "PartOfTheWeek":
      return WorkFromHome.OneDay;
  }
};

export const getSalaryRecommendations = (v: {
  salaryAverage: number;
  step: number;
}): number[] => {
  return A.makeBy(7, i => {
    return (-3 + i) * v.step + v.salaryAverage;
  }).filter(x => x > 0);
};

export const showTeam = (team: TalentDepartment | null | undefined) => {
  return !!team?.name;
};

export const getLatestEmploymentString = (ct: CompanyTalentGetDto) =>
  pipe(
    ct.nonAnonymouseProfileDetails,
    O.fromNullable,
    O.map(x =>
      getShortText({
        list: x.latestEmployments,
        mapper: e => e.company.text,
      })
    ),
    O.getOrElse(() => "-")
  );

const getIsUndoed = (t: TrelloBoardTalentStatus): boolean => {
  return !!t.accepted && !!t.declined && t.accepted > t.declined;
};

export const hasDeclined = (t: TrelloBoardTalentStatus): boolean => {
  return !!t.declined && !getIsUndoed(t);
};

export const getTeamMembersForRecruitment = (v: {
  recruitmentId: string | null;
  allCompanyMembers: RecruitmentTeamMember[];
}): (RecruitmentTeamMember & { userId: string })[] => {
  return pipe(
    v.recruitmentId,
    O.fromNullable,
    O.map(recId =>
      pipe(
        v.allCompanyMembers,
        A.flatMap(m =>
          !!m.userId && m.recruitmentIds.includes(recId)
            ? [{ ...m, userId: m.userId }]
            : []
        )
      )
    ),
    O.getOrElseW(() => [])
  );
};

const putAllOptionsInTuple = sequenceT(O.Monad);

export const getTeamMemberName = (m: {
  firstName: string | null;
  lastName: string | null;
  email: string;
}): string => {
  return pipe(
    putAllOptionsInTuple(
      O.fromNullable(m.firstName),
      O.fromNullable(m.lastName)
    ),
    O.map(([fn, ln]) => `${fn} ${ln}`),
    O.getOrElse(() => m.email)
  );
};

export const getCanMoveLaneToLeft = (v: {
  sortedLanes: { id: string; isFixedLane: boolean }[];
  laneId: string;
}): boolean => {
  const index = v.sortedLanes
    .filter(x => !x.isFixedLane)
    .findIndex(l => l.id === v.laneId);
  return index > 0;
};

export const getCanMoveLaneToRight = (v: {
  sortedLanes: { id: string; isFixedLane: boolean }[];
  laneId: string;
}): boolean => {
  const movableLanes = v.sortedLanes.filter(x => !x.isFixedLane);
  const indexOfMovable = movableLanes.findIndex(l => l.id === v.laneId);
  return indexOfMovable < movableLanes.length - 1;
};

export const getOfficeText = (v: {
  recruitmentId: string;
  allRecruitmentListItems: RecruitmentListItem[];
  allOffices: Office[];
  remoteString: string;
}): string =>
  pipe(
    v.recruitmentId,
    tryGetById(v.allRecruitmentListItems),
    O.chain(r => O.fromNullable(r.officeId)),
    O.chain(tryGetById(v.allOffices)),
    O.map(office => office.name),
    O.getOrElse(() => v.remoteString)
  );

export const getHasActiveTalents = (v: {
  recTalents: (TrelloBoardTalentStatus & { laneId: string | null })[];
  lanes: { id: string; laneType: LaneType }[];
}): boolean => {
  return !!v.recTalents.some(t => {
    const lane = v.lanes.find(x => x.id === t.laneId);

    const isHired = lane?.laneType === LaneType.Hired;
    return (
      !!t.accepted &&
      !!t.invited &&
      !t.removed &&
      !FilterHelpers.hasDeclined(t) &&
      !isHired
    );
  });
};

export const getCanInviteTalent = (v: {
  talentId: string;
  recruitmentId: string;
  talentProfile: { pausedTo: Date | null };
  allRecruitments: RecruitmentListItem[];
  profileIsPublished: boolean;
  hasLogo: boolean;
  now: Date;
}):
  | { type: "CanInvite" }
  | {
      type: "CanNotInvited";
      reason: TalentInviteFailReason;
    } => {
  if (!v.profileIsPublished) {
    return {
      type: "CanNotInvited",
      reason: "CompanyProfileIsNotDone",
    };
  }
  if (!v.hasLogo) {
    return {
      type: "CanNotInvited",
      reason: "NoLogo",
    };
  }
  if (v.talentProfile.pausedTo !== null && v.talentProfile.pausedTo > v.now) {
    return {
      type: "CanNotInvited",
      reason: "TalentIsPaused",
    };
  }
  const recListItem = v.allRecruitments.find(x => x.id === v.recruitmentId);

  if (!recListItem) {
    return {
      type: "CanNotInvited",
      reason: "RecruitmentDoesNotExist",
    };
  }

  if (recListItem.closedDate) {
    return {
      type: "CanNotInvited",
      reason: "RecruitmentIsClosed",
    };
  }

  if (!recListItem.startedDate) {
    return {
      type: "CanNotInvited",
      reason: "RecruitmentIsNotStarted",
    };
  }

  return {
    type: "CanInvite",
  };
};

export const upperJobPriceLimitString = () => {
  return fixLargeNumbers(50000);
};

export const mapRecruitmentListItem = (
  r: GetRecruitmentListItemDto
): RecruitmentListItem => ({
  ...r,
  workFromHome: r.workFromHome ?? WorkFromHome.No,
  rolesResponsibilities: r.rolesResponsibilities ?? [],
  closedDate: r.closedDate ? new Date(r.closedDate) : null,
  startedDate: r.startedDate ? new Date(r.startedDate) : null,
  createdDate: new Date(r.createdDate),
  numberOfSavedTalents: r.numberOfSavedTalents || 0,
  numberOfActiveOpportunityTalent: r.numberOfActiveOpportunityTalent || 0,
  numberOfActiveTalents: r.numberOfActiveTalents || 0,
  opportunityActive: r.opportunityActive || false,
  departmentId: r.departmentId || null,
  managerCoworkerId: r.managerCoworkerId || null,
  visualCoworkersIds: r.visualCoworkersIds || [],
  savedTalentIds: r.savedTalentIds || [],
  recTalentIsOpened: r.recTalentIsOpened ? new Date(r.recTalentIsOpened) : null,
  numberOfTalentsNotHandled: r.numberOfTalentsNotHandled ?? 0,
  succesPrice: r.succesPrice ?? null,
  hasReachedRecapDate: r.hasReachedRecapDate
    ? new Date(r.hasReachedRecapDate)
    : null,
});

export const getMonthlyAccessFee = (yearlyFee: number) => {
  return Math.floor(yearlyFee / 12);
};
