import axios from "axios";
import { gritifyApiUrl } from "./ServiceHelper";
import { CoWorkerDto, CoWokerToAdd } from "~/models/types";

export default {
  getCoWorkers(accessToken: string): Promise<CoWorkerDto[]> {
    return axios
      .get<CoWorkerDto[]>(`${gritifyApiUrl}/GetCoWorker`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(r => {
        return r.data.map(cw => ({
          ...cw,
        }));
      });
  },
  createCoWorker(coWorker: CoWokerToAdd, accessToken: string): Promise<string> {
    return axios
      .post<string>(`${gritifyApiUrl}/CreateCoWorker`, coWorker, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(r => {
        return r.data;
      });
  },
  updateCoWorker(coWorker: CoWorkerDto, accessToken: string): Promise<void> {
    return axios
      .put<void>(
        `${gritifyApiUrl}/UpdateCoWorker?coWorkerId=${coWorker.id}`,
        coWorker,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },
};
