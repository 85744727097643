





















import { Vue, Component, Prop } from "nuxt-property-decorator";
import gsap from "gsap";

@Component({
  components: {},
})
export default class GrScrollPanel extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly disableScroll!: boolean;

  scrollTop() {
    const scrollmenu = this.$refs.scrollmenu as any;
    if (scrollmenu) {
      gsap.to(scrollmenu, {
        scrollTop: 0,
        duration: 1,
        ease: "power4.out",
      });
    }
  }
}
