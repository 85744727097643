import { $i18n } from "~/utils/i18n";
import {
  CandidateListViews,
  CompanyTalentGetDto,
  CompanyTalentRecuritmentStatus,
  TalentPoolTalent,
} from "~/models/types";

export default class TalentPoolHelpers {
  static canAccessTalentPool(): boolean {
    return false;
    // return authStore.userAccessLevel >= SortHelper.getAccessLevel("Full");
  }

  static getTalentName(
    poolTalent?: {
      firstName: string | null;
      lastName: string | null;
      number: number;
    } | null
  ): string | null {
    if (!poolTalent) {
      return null;
    }

    return poolTalent.firstName && poolTalent.lastName
      ? `${poolTalent.firstName} ${poolTalent.lastName}`
      : `${$i18n.t("common.poolCandidateNamePrefix").toString()} ${
          poolTalent.number
        }`;
  }

  static getIsInPoolExpression(v: {
    talentId: string;
  }): (t: TalentPoolTalent) => boolean {
    return x => x.talentId === v.talentId && !x.declined && !x.removed;
  }

  static getIsInPool(v: {
    poolTalents: TalentPoolTalent[];
    talentId: string;
  }): boolean {
    return v.poolTalents.some(
      this.getIsInPoolExpression({ talentId: v.talentId })
    );
  }

  static getRecTalentKey(v: {
    talentId: string;
    recruitmentId: string;
  }): string {
    return `${v.recruitmentId}|${v.talentId}`;
  }

  static getTalentConversationKey(v: {
    talentId: string;
    recruitmentId: string | null;
  }): string {
    return `${v.recruitmentId ?? ""}|${v.talentId}`;
  }

  static getTalentObjectsForView<T extends { talentId: string }>(v: {
    allCompanyTalents: CompanyTalentGetDto[];
    talentIdObjects: T[];
    poolTalents: {
      talentId: string;
      accepted: Date | null;
      removed: Date | null;
      declined: Date | null;
    }[];
    view: CandidateListViews;
  }): T[] {
    switch (v.view) {
      case "All":
        return v.talentIdObjects;
      case "PoolTalents":
        return v.talentIdObjects.filter(tidO => {
          const poolTalent = v.poolTalents.find(
            pt => tidO.talentId === pt.talentId
          );
          if (!poolTalent) {
            return false;
          }
          const ct = v.allCompanyTalents.find(
            t => t.talentId === tidO.talentId
          );

          const isHired = ct?.isHired ?? false;
          return !poolTalent.removed && !poolTalent.declined && !isHired;
        });
      case "Active": {
        const activeStatuses: CompanyTalentRecuritmentStatus[] = [
          "Accepted",
          "Invited",
        ];
        return v.talentIdObjects.flatMap(tidO => {
          const t = v.allCompanyTalents.find(t => t.talentId === tidO.talentId);
          if (!t) {
            return [];
          }
          const recs = Object.values(t.recruitmentsByRecId);

          return recs.some(r => activeStatuses.includes(r.status))
            ? [tidO]
            : [];
        });
      }
      case "Available": {
        const unavailableStatuses: CompanyTalentRecuritmentStatus[] = [
          "Accepted",
          "Invited",
          "Hired",
        ];
        return v.talentIdObjects.flatMap(tidO => {
          const t = v.allCompanyTalents.find(t => t.talentId === tidO.talentId);
          if (!t) {
            return [];
          }

          const isHired = t?.isHired ?? false;

          if (isHired) {
            return [];
          }

          const recs = Object.values(t.recruitmentsByRecId);

          return recs.every(r => !unavailableStatuses.includes(r.status))
            ? [tidO]
            : [];
        });
      }
    }
  }
}
