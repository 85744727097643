import axios from "axios";
import { publicApiUrl } from "./ServiceHelper";
import { GetPublicTalentJobOfferDto, PublicJobOfferDto } from "~/models/types";

export default class JobOfferPublicService {
  static acceptInvitePublicOld(v: {
    token: string;
    firstName: string;
    lastName: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${publicApiUrl}/AcceptPublicJobOfferOld`,
        { firstName: v.firstName, lastName: v.lastName },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static acceptInvitePublic(v: {
    token: string;
    firstName: string;
    lastName: string;
    recruitmentId: string;
    companyId: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${publicApiUrl}/AcceptPublicJobOffer`,
        {
          firstName: v.firstName,
          lastName: v.lastName,
          recruitmentId: v.recruitmentId,
          companyId: v.companyId,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static updateTalentContactInfoOld(v: {
    token: string;
    phoneNumber: string | null;
    contactEmail: string | null;
  }): Promise<void> {
    return axios
      .post<void>(
        `${publicApiUrl}/UpdateTalentContactInfoOld`,
        { phoneNumber: v.phoneNumber, contactEmail: v.contactEmail },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static updateTalentContactInfo(v: {
    token: string;
    phoneNumber: string | null;
    contactEmail: string | null;
  }): Promise<void> {
    return axios
      .post<void>(
        `${publicApiUrl}/UpdateTalentContactInfo`,
        { phoneNumber: v.phoneNumber, contactEmail: v.contactEmail },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static updateJobOfferDeclineReasonsOld(v: {
    token: string;
    declineReasonIds: string[];
    otherReason: string | null;
  }): Promise<void> {
    return axios
      .post<void>(
        `${publicApiUrl}/UpdateJobOfferDeclineReasonsOld`,
        {
          declineReasonIds: v.declineReasonIds,
          declineReasonTypes: [],
          otherReason: v.otherReason,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static updateJobOfferDeclineReasons(v: {
    token: string;
    recruitmentId: string;
    declineReasonIds: string[];
    declineReasonTypes: string[];
    ratingJobProcent: number;
    ratingCompanyProcent: number;
    otherReason: string | null;
  }): Promise<void> {
    return axios
      .post<void>(
        `${publicApiUrl}/UpdateJobOfferDeclineReasons`,
        {
          declineReasonIds: v.declineReasonIds,
          declineReasonTypes: v.declineReasonTypes,
          otherReason: v.otherReason,
          recruitmentId: v.recruitmentId,
          ratingJobProcent: v.ratingJobProcent,
          ratingCompanyProcent: v.ratingCompanyProcent,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static rejectCompanyFromTalentJobOfferOld(v: {
    token: string;
    setRejected: boolean;
  }): Promise<void> {
    return axios
      .post<void>(
        `${publicApiUrl}/RejectCompanyFromTalentJobOfferOld`,
        {
          setRejected: v.setRejected,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static addPoolInterest(v: {
    token: string;
    firstName: string;
    lastName: string;
    companyId: string;
    recruitmentId: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${publicApiUrl}/AddPoolInterest`,
        {
          firstName: v.firstName,
          lastName: v.lastName,
          companyId: v.companyId,
          recruitmentId: v.recruitmentId,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static updateSalaryPreference(v: {
    token: string;
    salary: number;
  }): Promise<void> {
    return axios
      .post<void>(
        `${publicApiUrl}/UpdateSalaryPreference`,
        {
          salary: v.salary,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static updateCompanyTypeExpectations(v: {
    token: string;
    requireNoConsultants: boolean;
    requireNoPublicSectors: boolean;
    requireNoStartups: boolean;
  }): Promise<void> {
    return axios
      .post<void>(
        `${publicApiUrl}/UpdateCompanyTypeExpectations`,
        {
          requireNoConsultants: v.requireNoConsultants,
          requireNoPublicSectors: v.requireNoPublicSectors,
          requireNoStartups: v.requireNoStartups,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static rejectCompanyFromTalentJobOffer(v: {
    token: string;
    setRejected: boolean;
    recruitmentId: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${publicApiUrl}/RejectCompanyFromTalentJobOffer`,
        {
          setRejected: v.setRejected,
          recruitmentId: v.recruitmentId,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static UpdateLastReadPublicOld(v: { token: string }): Promise<void> {
    return axios
      .post<void>(
        `${publicApiUrl}/UpdateLastReadOld`,
        {},
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static UpdateLastReadPublic(v: {
    token: string;
    recruitmentId: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${publicApiUrl}/UpdateLastRead`,
        {
          recruitmentId: v.recruitmentId,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static declinePublicJobOfferOld(v: {
    token: string;
    declineReasonIds: string[];
    otherReason: string | null;
  }): Promise<void> {
    return axios
      .post<void>(
        `${publicApiUrl}/DeclinePublicJobOfferOld`,
        { declineReasonIds: v.declineReasonIds, otherReason: v.otherReason },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static declinePublicJobOffer(v: {
    recruitmentId: string;
    companyId: string;
    token: string;
    declineReasonIds: string[];
    declineReasonTypes: string[];
    ratingCompanyProcent: number;
    ratingJobProcent: number;
    otherReason: string | null;
  }): Promise<void> {
    return axios
      .post<void>(
        `${publicApiUrl}/DeclinePublicJobOffer`,
        {
          declineReasonIds: v.declineReasonIds,
          declineReasonTypes: v.declineReasonTypes,
          otherReason: v.otherReason,
          recruitmentId: v.recruitmentId,
          companyId: v.companyId,
          ratingJobProcent: v.ratingJobProcent,
          ratingCompanyProcent: v.ratingCompanyProcent,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static getTalentJobOfferPublicOld(v: {
    token: string;
  }): Promise<PublicJobOfferDto> {
    return axios
      .get<PublicJobOfferDto>(`${publicApiUrl}/GetTalentJobOfferPublicOld`, {
        headers: {
          Authorization: `Bearer ${v.token}`,
        },
      })
      .then(r => {
        return r.data;
      });
  }

  static getTalentJobOfferPublic(v: {
    token: string;
    recruitmentId: string;
  }): Promise<GetPublicTalentJobOfferDto> {
    return axios
      .get<GetPublicTalentJobOfferDto>(
        `${publicApiUrl}/GetTalentJobOfferPublic?recruitmentId=${v.recruitmentId}`,
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }
}
