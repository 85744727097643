import axios from "axios";
import { gritifyApiUrl } from "./ServiceHelper";
import {
  LastVisitedItem,
  LastVisitedItemToAdd,
  GritifyNewsItem,
  GetNotificationsResponse,
  CompanyObject,
  TalentConversation,
  TalentConversationRaw,
  JobylonIntegrationRequest,
  DateReplacer,
} from "~/models/types";

export default {
  postVisit(v: { item: LastVisitedItemToAdd; token: string }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/CreateVisit`,
        {
          ...v.item,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },
  getLastVisits(v: { accessToken: string }): Promise<LastVisitedItem[]> {
    return axios
      .get<DateReplacer<LastVisitedItem>[]>(`${gritifyApiUrl}/GetVisits`, {
        headers: {
          Authorization: `Bearer ${v.accessToken}`,
        },
      })
      .then(r => {
        return r.data.map(i => ({ ...i, date: new Date(i.date) }));
      });
  },
  getGritifyNews(v: { accessToken: string }): Promise<GritifyNewsItem[]> {
    return axios
      .get<DateReplacer<GritifyNewsItem>[]>(`${gritifyApiUrl}/GetNews`, {
        headers: {
          Authorization: `Bearer ${v.accessToken}`,
        },
      })
      .then(r => {
        return r.data.map(i => ({ ...i, date: new Date(i.date) }));
      });
  },
  removeNewsItem(v: { newsId: string; token: string }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/RemoveNewsItem`,
        {
          newsId: v.newsId,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => r.data);
  },
  getNotifications(v: {
    pageSize: number;
    accessToken: string;
    continuationToken?: any;
  }): Promise<GetNotificationsResponse> {
    return axios
      .get<GetNotificationsResponse>(
        `${gritifyApiUrl}/GetNotifications?pageSize=${v.pageSize}`,
        {
          params: {
            continuationToken: v.continuationToken,
          },
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },

  getUnSeenNotificationsCount(v: {
    accessToken: string;
  }): Promise<{ numberOfNofitications: number; companyId: string }[]> {
    return axios
      .get<{ numberOfNofitications: number; companyId: string }[]>(
        `${gritifyApiUrl}/GetAllUnSeenNotificationsCount`,
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },

  setNotificationRead(v: {
    token: string;
    notificationId: string;
    isRead: boolean;
  }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/SetNotificationRead`,
        {
          notificationId: v.notificationId,
          isRead: v.isRead,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },

  setNotificationsSeen(v: { token: string }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/SetNotificationsSeen`,
        {},
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },

  getCompanyDetails(v: { accessToken: string }): Promise<CompanyObject> {
    return axios
      .get<DateReplacer<CompanyObject>>(`${gritifyApiUrl}/GetCompanyDetails`, {
        headers: {
          Authorization: `Bearer ${v.accessToken}`,
        },
      })
      .then(r => {
        return {
          ...r.data,
          accountInfo: r.data.accountInfo
            ? {
                ...r.data.accountInfo,
                agreeDate: new Date(r.data.accountInfo.agreeDate),
              }
            : null,
        };
      });
  },

  setDoneWithCompanyStepper(v: { token: string }): Promise<void> {
    return axios
      .put<void>(
        `${gritifyApiUrl}/SetDoneWithCompanyStepper`,
        {},
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },

  getTitleRecommendationsForCompany(v: {
    accessToken: string;
  }): Promise<{ titleText: string; roleId: string }[]> {
    return axios
      .get<{ titleText: string; roleId: string }[]>(
        `${gritifyApiUrl}/GetTitleRecommendationsForCompany`,
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },

  upsertTeamTailorApiKey(v: {
    token: string;
    apiKey: string | null;
  }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/UpsertTeamTailorApiKey`,
        {
          apiKey: v.apiKey,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },

  // TODO NotInUse
  upsertJobylonKeys(v: {
    token: string;
    feedHash: string;
    appKey: string;
    appId: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/UpsertJobylonKeys`,
        {
          feedHash: v.feedHash,
          appKey: v.appKey,
          appId: v.appId,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },

  addJobylonIntegrationRequest(v: {
    token: string;
  }): Promise<JobylonIntegrationRequest> {
    return axios
      .post<JobylonIntegrationRequest>(
        `${gritifyApiUrl}/AddJobylonIntegrationRequest`,
        {},
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },

  // TODO NotInUse
  removeJobylonKeys(v: { token: string }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/RemoveJobylonKeys`,
        {},
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },

  getTalentConversations(v: {
    accessToken: string;
  }): Promise<TalentConversation[]> {
    return axios
      .get<TalentConversationRaw[]>(`${gritifyApiUrl}/GetTalentConversations`, {
        headers: {
          Authorization: `Bearer ${v.accessToken}`,
        },
      })
      .then(r => {
        return r.data.map(c => ({
          ...c,
          lastMessageDateTimeUtc: new Date(c.lastMessageDateTimeUtc),
        }));
      });
  },

  getTalentConversationsUnreadCount(v: {
    accessToken: string;
  }): Promise<number> {
    return axios
      .get<number>(`${gritifyApiUrl}/GetTalentConversationsUnreadCount`, {
        headers: {
          Authorization: `Bearer ${v.accessToken}`,
        },
      })
      .then(r => {
        return r.data;
      });
  },

  setTalentConversationRead(v: {
    token: string;
    talentId: string;
    recruitmentId: string | null;
    isRead: boolean;
  }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/SetTalentConversationRead`,
        {
          talentId: v.talentId,
          recruitmentId: v.recruitmentId,
          isRead: v.isRead,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },
};
