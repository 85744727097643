import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
// import rg4js from "raygun4js";
import { notificationsStore } from "~/utils/store-accessor";
import { $msal } from "~/utils/msal";
import { SnackBarMessageType, NotificationItem } from "~/models/types";

@Module({
  name: "notifications",
  stateFactory: true,
  namespaced: true,
})
export default class Notifications extends VuexModule {
  message: string | null = null;
  showSnackbar: boolean = false;
  type: SnackBarMessageType = "success";
  currentNotification: NotificationItem | null = null;

  @Mutation
  SET_CURRENT_NOTIFICATION(v: NotificationItem | null) {
    this.currentNotification = v;
  }

  @Mutation
  SET_MESSAGE(v: string | null) {
    this.message = v;
    this.showSnackbar = true;
  }

  @Mutation
  SET_MESSAGE_TYPE(v: SnackBarMessageType) {
    this.type = v;
  }

  @Action
  setSuccessMessage(v: string | null) {
    // this.context.commit("SET_SHOW_SNACKBAR", false);
    notificationsStore.SET_SHOW_SNACKBAR(false);
    setTimeout(() => {
      notificationsStore.SET_MESSAGE(v);
      notificationsStore.SET_MESSAGE_TYPE("success");
      // this.context.commit("SET_MESSAGE", v);
      // this.context.commit("SET_MESSAGE_TYPE", "success");
    }, 200);
  }

  @Action
  setNotificationMessage(v: {
    message: string | null;
    notification: NotificationItem;
  }) {
    notificationsStore.SET_SHOW_SNACKBAR(false);
    setTimeout(() => {
      notificationsStore.SET_MESSAGE(v.message);
      notificationsStore.SET_CURRENT_NOTIFICATION(v.notification);
      notificationsStore.SET_MESSAGE_TYPE("notification");
    }, 200);
  }

  @Action
  setWarningMessage(v: string | null) {
    // this.context.commit("SET_SHOW_SNACKBAR", false);
    notificationsStore.SET_SHOW_SNACKBAR(false);
    setTimeout(() => {
      notificationsStore.SET_MESSAGE(v);
      notificationsStore.SET_MESSAGE_TYPE("warning");
      // this.context.commit("SET_MESSAGE", v);
      // this.context.commit("SET_MESSAGE_TYPE", "success");
    }, 200);
  }

  @Action
  setErrorMessage(obj: { errorMessage?: string; error: any }) {
    notificationsStore.SET_SHOW_SNACKBAR(false);
    // this.context.commit("SET_SHOW_SNACKBAR", false);

    console.error(obj);

    if (process.env.ENVIRONMENT !== "Dev") {
      if ((process as any).client) {
        const rg4js = require("raygun4js");
        if ($msal && $msal.data) {
          const userId =
            ($msal.data.idTokenClaims as any)?.sub ||
            ($msal.data?.account?.idTokenClaims as any)?.sub;
          rg4js("setUser", {
            identifier: userId,
            isAnonymous: false,
            uuid: userId,
          });
        }
        rg4js("send", {
          error: obj.error,
          customData: [{ errorMessage: obj.errorMessage }],
        });
      }
    }
    setTimeout(() => {
      notificationsStore.SET_MESSAGE(obj.errorMessage || null);
      notificationsStore.SET_MESSAGE_TYPE("error");
      // this.context.commit("SET_MESSAGE", v);
      // this.context.commit("SET_MESSAGE_TYPE", "error");
    }, 200);
  }

  @Mutation
  SET_SHOW_SNACKBAR(v: boolean) {
    this.showSnackbar = v;
    this.message = !v ? null : this.message;
  }
}
