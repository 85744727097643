export default class LocalStorageHelpers {
  static setString(key: string, value: string): boolean {
    if (this.storageAvailable("localStorage")) {
      localStorage.setItem(key, value);
      return true;
    }
    return false;
  }

  static getString(key: string): string | null {
    if (this.storageAvailable("localStorage")) {
      return localStorage.getItem(key);
    }
    return null;
  }

  static setInviterId(inviterId: string) {
    if (this.storageAvailable("localStorage")) {
      localStorage.setItem("inviterId", inviterId);
    }
  }

  static getInviterId(): string | null {
    if (this.storageAvailable("localStorage")) {
      return localStorage.getItem("inviterId");
    }
    return null;
  }

  private static GetLowInviteWarningKey(recruitmentId: string): string {
    return `LowInvite|${recruitmentId}`;
  }

  static valueIfTrue = "true";

  static blockLowInviteWarning(recruitmentId: string) {
    if (this.storageAvailable("localStorage")) {
      localStorage.setItem(
        this.GetLowInviteWarningKey(recruitmentId),
        this.valueIfTrue
      );
    }
  }

  static removeBlockLowInviteWarning(recruitmentId: string) {
    if (this.storageAvailable("localStorage")) {
      localStorage.removeItem(this.GetLowInviteWarningKey(recruitmentId));
    }
  }

  static getLowInviteWarningIsBlocked(recruitmentId: string): boolean {
    if (this.storageAvailable("localStorage")) {
      return (
        localStorage.getItem(this.GetLowInviteWarningKey(recruitmentId)) ===
        this.valueIfTrue
      );
    }
    return false;
  }

  static storageAvailable(type: "localStorage" | "sessionStorage"): boolean {
    let storage;
    try {
      storage = window[type];
      const x = "__storage_test__";
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return (
        e instanceof DOMException &&
        // everything except Firefox
        (e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === "QuotaExceededError" ||
          // Firefox
          e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
        // acknowledge QuotaExceededError only if there's something already stored
        !!storage &&
        storage.length !== 0
      );
    }
  }
}

export const campaignCodeKey = "campaignCode";
