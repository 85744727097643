









import { Vue, Component, Prop } from "nuxt-property-decorator";
import NotificationsTagListItem from "./NotificationsTagListItem.vue";
import NotificationsTalentAcceptedListItem from "./NotificationsTalentAcceptedListItem.vue";
import NotificationsTalentInterestedListItem from "./NotificationsTalentInterestedListItem.vue";
import NotificationsTalentDeclinedListItem from "./NotificationsTalentDeclinedListItem.vue";
import NotificationsSharedTalentVisitedListItem from "./NotificationsSharedTalentVisitedListItem.vue";
import NotificationsMessageFromTalentListItem from "./NotificationsMessageFromTalentListItem.vue";
import CommentOnSharedTalentListItem from "./CommentOnSharedTalentListItem.vue";
import PoolTalentInterestedNotificationListItem from "./PoolTalentInterestedNotificationListItem.vue";
import NotAnsweredPoolMessageNotificationListItem from "./NotAnsweredPoolMessageNotificationListItem.vue";
import PoolMessageFromTalentNotificationListItem from "./PoolMessageFromTalentNotificationListItem.vue";
import PoolTeamCommentNotificationItem from "./PoolTeamCommentNotificationItem.vue";
import UnhandledRecruitmentNotificationItem from "./UnhandledRecruitmentNotificationItem.vue";
import { NotificationItem, NotificationTypeInternal } from "~/models/types";

@Component({
  components: {
    NotificationsTagListItem,
    NotificationsTalentAcceptedListItem,
    NotificationsTalentInterestedListItem,
    NotificationsTalentDeclinedListItem,
    NotificationsSharedTalentVisitedListItem,
    NotificationsMessageFromTalentListItem,
    CommentOnSharedTalentListItem,
    PoolTalentInterestedNotificationListItem,
    NotAnsweredPoolMessageNotificationListItem,
    PoolMessageFromTalentNotificationListItem,
    PoolTeamCommentNotificationItem,
    UnhandledRecruitmentNotificationItem,
  },
  layout: "companyLayout",
})
export default class NotificationsListItem extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: NotificationItem;

  get itemComponent(): {} | null {
    switch (this.item.type) {
      case NotificationTypeInternal.TagOnTalent:
        return NotificationsTagListItem;
      case NotificationTypeInternal.TagOnRecruitment:
        return NotificationsTagListItem;
      case NotificationTypeInternal.TalentInterested:
        return NotificationsTalentInterestedListItem;
      case NotificationTypeInternal.TalentDeclined:
        return NotificationsTalentDeclinedListItem;
      case NotificationTypeInternal.SharedTalentVisited:
        return NotificationsSharedTalentVisitedListItem;
      case NotificationTypeInternal.MessageFromTalent:
        return NotificationsMessageFromTalentListItem;
      case NotificationTypeInternal.TalentAccepted:
        return NotificationsTalentAcceptedListItem;
      case NotificationTypeInternal.CommentOnSharedTalent:
        return CommentOnSharedTalentListItem;
      case NotificationTypeInternal.PoolTalentInterested:
        return PoolTalentInterestedNotificationListItem;
      case NotificationTypeInternal.NotAnsweredPoolMessage:
        return NotAnsweredPoolMessageNotificationListItem;
      case NotificationTypeInternal.PoolMessageFromTalent:
        return PoolMessageFromTalentNotificationListItem;
      case NotificationTypeInternal.TalentPoolTeamComment:
        return PoolTeamCommentNotificationItem;
      case NotificationTypeInternal.UnhandledRecruitment:
        return UnhandledRecruitmentNotificationItem;
    }
  }
}
