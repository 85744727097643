import axios from "axios";
import { gritifyApiUrl } from "./ServiceHelper";

export const getTranslatedTextFromAI = (v: {
  accessToken: string;
  text: string;
  fromLang: string;
  toLang: string;
}): Promise<string | null> => {
  return axios
    .post<string | null>(
      `${gritifyApiUrl}/GetTranslatedTextFromAI`,
      {
        text: v.text,
        fromLang: v.fromLang,
        toLang: v.toLang,
      },
      {
        headers: {
          Authorization: `Bearer ${v.accessToken}`,
        },
      }
    )
    .then(r => r.data);
};

export default class AiDataService {
  public static getVisualIngressAiHelp(v: {
    accessToken: string;
    recruitmentId: string;
    lang: string;
  }): Promise<string> {
    return axios
      .get<string>(
        `${gritifyApiUrl}/GetVisualIngressAiHelp?recruitmentId=${v.recruitmentId}&lang=${v.lang}`,
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static getVisualHeadingAiHelp(v: {
    accessToken: string;
    recruitmentId: string;
    lang: string;
  }): Promise<string> {
    return axios
      .get<string>(
        `${gritifyApiUrl}/GetVisualHeadingAiHelp?recruitmentId=${v.recruitmentId}&lang=${v.lang}`,
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static getVisualHeadingAiFromText(v: {
    accessToken: string;
    recruitmentId: string;
    lang: string;
    text: string;
  }): Promise<string> {
    return axios
      .post<string>(
        `${gritifyApiUrl}/GetVisualHeadingAiFromText?recruitmentId=${v.recruitmentId}&lang=${v.lang}`,
        {
          recruitmentId: v.recruitmentId,
          lang: v.lang,
          Text: v.text,
        },
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static getVisualTasksAiHelp(v: {
    accessToken: string;
    recruitmentId: string;
  }): Promise<string[]> {
    return axios
      .get<string[]>(
        `${gritifyApiUrl}/GetVisualTasksAiHelp?recruitmentId=${v.recruitmentId}`,
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static getChatMessageAIHelp(v: {
    accessToken: string;
    recruitmentId: string | null;
    talentId: string;
    userName: string;
  }): Promise<string> {
    return axios
      .post<string>(
        `${gritifyApiUrl}/GetChatMessageAIHelp`,
        {
          recruitmentId: v.recruitmentId,
          talentId: v.talentId,
          userName: v.userName,
        },
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }
}
