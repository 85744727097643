import axios from "axios";
import { gritifyApiUrl } from "./ServiceHelper";
import {
  CompanyVideoProgress,
  CompanyVideoDTO,
  CompanyVideoDTODB,
  YoutubeVideoDto,
} from "~/models/types";

export default class CompanyVideoService {
  static getCompanyVideos(v: { token: string }): Promise<CompanyVideoDTO[]> {
    return axios
      .get<CompanyVideoDTODB[]>(`${gritifyApiUrl}/GetVideoLibrary`, {
        headers: {
          Authorization: `Bearer ${v.token}`,
        },
      })
      .then(r =>
        r.data.map(x => ({
          ...x,
          progress: x.progress || 0,
          state: x.state || "Finished",
          isRemoved: x.isRemoved || false,
        }))
      );
  }

  static getCompanyYoutubeVideos(v: {
    token: string;
  }): Promise<YoutubeVideoDto[]> {
    return axios
      .get<YoutubeVideoDto[]>(`${gritifyApiUrl}/GetYoutubeVideo`, {
        headers: {
          Authorization: `Bearer ${v.token}`,
        },
      })
      .then(x => x.data || []);
  }

  static updateYoutubeVideo(v: {
    token: string;
    name: string;
    url: string;
  }): Promise<void> {
    return axios
      .put<void>(
        `${gritifyApiUrl}/UpdateYoutubeVideo`,
        {
          name: v.name,
          url: v.url,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(x => x.data);
  }

  static addYoutubeVideo(v: {
    token: string;
    name: string;
    url: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/CreateYouTubeVideo`,
        {
          name: v.name,
          url: v.url,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(x => x.data);
  }

  static removeYoutubeVideo(v: { token: string; url: string }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/DeleteYoutubeVideo`,
        {
          url: v.url,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(x => x.data);
  }

  static updateVideo(v: {
    token: string;
    id: string;
    name: string;
    isRemoved: boolean;
  }): Promise<void> {
    return axios
      .put<void>(
        `${gritifyApiUrl}/UpdateAsset?id=${v.id}`,
        {
          name: v.name,
          isRemoved: v.isRemoved,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(x => x.data);
  }

  static getProgressForVideoUpload(v: {
    id: string;
    token: string;
  }): Promise<CompanyVideoProgress> {
    return axios
      .get<CompanyVideoProgress | string>(
        `${gritifyApiUrl}/GetMediaAssetProgress?id=${v.id}`,
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        if (typeof r.data === "string") {
          return {
            progress: 0,
            state: "NoVideo",
            forId: null,
          };
        }
        return r.data;
      });
  }

  static async createAzureResource(v: {
    name: string;
    token: string;
  }): Promise<{ baseUrl: string; sasToken: string; id: string }> {
    const response = await axios
      .post<{ baseUrl: string; id: string }>(
        `${gritifyApiUrl}/CreateAsset`,
        {
          name: v.name,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });

    const indexOf = response.baseUrl.indexOf("?");

    return {
      baseUrl: response.baseUrl.substring(0, indexOf),
      sasToken: response.baseUrl.substring(indexOf),
      id: response.id,
    };
  }

  static startVideoEncoding(v: { id: string; token: string }) {
    return axios
      .post<string>(
        `${gritifyApiUrl}/PublishAsset`,
        {
          id: v.id,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  private static getBinaryFromFile(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.addEventListener("load", () => resolve(reader.result));
      reader.addEventListener("error", err => reject(err));

      reader.readAsBinaryString(file);
    });
  }
}
