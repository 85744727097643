/* eslint-disable import/no-mutable-exports */
import GritifyMsal from "~/plugins/gritifyMsal";

let $msal: GritifyMsal;

export function initializeMsal(msalCompany: GritifyMsal) {
  $msal = msalCompany;
}

export { $msal };
