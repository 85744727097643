import TalentStatusHelpers from "./talentStatusHelpers";
import EducationHelpers from "./EducationHelpers";
import TalentPoolHelpers from "./TalentPoolHelpers";
import FilterHelpers from "./filterHelpers";
import {
  GetRolesDto,
  EmploymentSaved,
  TrelloBoardTalent,
  GetJobbOfferListDto,
  JobbOfferVisualForView,
  TalentCoWorker,
  JobbOfferInterviewType,
  TrelloBoardTalentStatus,
  RecruitmentTalentWithDaysLeftDto,
  TalentProfileForCompanyDto,
  CompanyTalentGetDto,
  TalentPoolTalent,
  CompanyObject,
  GetTeamTailorTalentIdsDto,
  DeclineReasonSingle,
} from "~/models/types";
import { $i18n } from "~/utils/i18n";
import { ListObject } from "~/models/ListObject";
import { TalentExpectations } from "~/models/TalentExpectations";
import { WorkFromHome } from "~/models/WorkFromHome";

export default class TalentHelpers {
  static getAskForBranchExperience(
    allRoles: GetRolesDto[],
    employments: EmploymentSaved[]
  ): boolean {
    const branchRoleIds = allRoles.reduce((acc: string[], r) => {
      if (r.branchExperience) {
        return [...acc, r.id];
      }
      return acc;
    }, []);

    return employments.some(e => branchRoleIds.includes(e.roleId));
  }

  static isAnonymized(v: { name: string | null }): boolean {
    return v.name === "Anonymized";
  }

  static getNameForTalent(v: {
    recTalent: {
      name: string | null;
      talentId: string;
      accepted: Date | null;
    };
    poolTalents: {
      firstName: string | null;
      lastName: string | null;
      number: number;
      talentId: string;
      declined: Date | null;
      removed: Date | null;
    }[];
    companyTalents: CompanyTalentGetDto[];
  }): string {
    const companyTalent = v.companyTalents.find(
      ct => ct.talentId === v.recTalent.talentId
    );

    if (companyTalent && companyTalent.nonAnonymouseProfileDetails !== null) {
      const ctName = TalentPoolHelpers.getTalentName({
        ...companyTalent.nonAnonymouseProfileDetails,
        number: 0,
      });
      if (ctName) {
        return ctName;
      }
    }

    const poolTalent =
      v.poolTalents.find(
        x =>
          x.declined === null &&
          x.talentId === v.recTalent.talentId &&
          x.removed === null
      ) ?? null;
    const poolTalentName = poolTalent
      ? TalentPoolHelpers.getTalentName(poolTalent)
      : null;
    if (poolTalentName) {
      return poolTalentName;
    }
    if (this.isAnonymized(v.recTalent)) {
      return $i18n.t("Anonym").toString();
    }
    return TalentStatusHelpers.isAnonymous(v.recTalent)
      ? `${$i18n.t("Anonym")} ${v.recTalent.name || ""}`
      : v.recTalent.name || "";
  }

  static mapToPoolTalent(ct: CompanyTalentGetDto): TalentPoolTalent {
    return {
      talentId: ct.talentId,

      number: 0,
      removed: null,
      declined: null,
      accepted: new Date(),
      invited: new Date(),
      dateCreated: new Date(),
      declinedInRecruitmentId: null,
      highestEducationDegree: null,
      id: ct.talentId,
      languageIds: [],
      latestRoleId: null,
      rating: null,
      responsibilityExperience: null,
      firstName: ct.nonAnonymouseProfileDetails?.firstName || null,
      lastName: ct.nonAnonymouseProfileDetails?.lastName || null,
      rejectedCompanyIds:
        ct.nonAnonymouseProfileDetails?.filterProps?.requirements
          .rejectedCompanyIds ?? [],
      latestEmployments:
        ct.nonAnonymouseProfileDetails?.latestEmployments || [],
      locationIds:
        ct.nonAnonymouseProfileDetails?.filterProps?.requirements.locationIds ||
        [],
      preferenceRoleIds:
        ct.nonAnonymouseProfileDetails?.filterProps?.requirements.roleIds || [],
      requireNoTrips:
        ct.nonAnonymouseProfileDetails?.filterProps?.requirements
          .requireNoTrips || false,
      requireNoConsultants:
        ct.nonAnonymouseProfileDetails?.filterProps?.requirements
          .requireNoConsultants || false,
      requireNoStartups:
        ct.nonAnonymouseProfileDetails?.filterProps?.requirements
          .requireNoStartups || false,
      requireNoPublicSectors:
        ct.nonAnonymouseProfileDetails?.filterProps?.requirements
          .requireNoPublicSectors || false,
      requireStableSalary:
        ct.nonAnonymouseProfileDetails?.filterProps?.requirements
          .requireStableSalary || false,
      requireWorkFromHome:
        ct.nonAnonymouseProfileDetails?.filterProps?.requirements
          .requireWorkFromHome || WorkFromHome.No,
      responsibilityMusts:
        ct.nonAnonymouseProfileDetails?.filterProps?.requirements
          .responsibilityMustIds ?? [],
      salary:
        ct.nonAnonymouseProfileDetails?.filterProps?.requirements.salary ?? 0,
      skillIds: ct.nonAnonymouseProfileDetails?.filterProps?.skillIds ?? [],
      taskIds: ct.nonAnonymouseProfileDetails?.filterProps?.taskIds ?? [],
      yearsOfExperience:
        ct.nonAnonymouseProfileDetails?.filterProps?.yearsOfExperience ?? 0,
      phoneNumber: ct.nonAnonymouseProfileDetails?.phoneNumer || null,
    };
  }

  static mapTalentProfileForCompany(
    dto: TalentProfileForCompanyDto
  ): TalentProfileForCompanyDto {
    if (dto.isAnonymous) {
      const anonProfile = dto.talentProfile;
      return {
        ...dto,
        talentProfile: {
          ...anonProfile,
          isHiredCopy: dto.isHiredCopy,
          pausedTo: anonProfile.pausedTo
            ? new Date(anonProfile.pausedTo)
            : null,
          experience: {
            ...anonProfile.experience,
            employments: anonProfile.experience.employments.map(e => {
              return {
                ...e,
                companyAnonymousId: e.companyAnonymousId || null,
              };
            }),
            education: anonProfile.experience.education.map(
              EducationHelpers.getEducationSaved
            ),
          },
        },
      };
    }
    const nonAnonProfile = dto.talentProfile;
    return {
      ...dto,
      talentProfile: {
        ...nonAnonProfile,
        isHiredCopy: dto.isHiredCopy,
        pausedTo: nonAnonProfile.pausedTo
          ? new Date(nonAnonProfile.pausedTo)
          : null,
        experience: {
          ...nonAnonProfile.experience,
          employments: nonAnonProfile.experience.employments.map(e => {
            return {
              ...e,
              companyAnonymousId: e.companyAnonymousId || null,
            };
          }),
          education: nonAnonProfile.experience.education.map(
            EducationHelpers.getEducationSaved
          ),
        },
      },
    };
  }

  static getCanBeReused(rt: TrelloBoardTalentStatus) {
    const reuseMaxDate = new Date();
    reuseMaxDate.setMonth(reuseMaxDate.getMonth() - 6);

    const canBeReused =
      !rt.accepted &&
      !rt.removed &&
      !!rt.declined &&
      rt.declined < reuseMaxDate;

    return canBeReused;
  }

  static mapFromServerDto(
    rt: RecruitmentTalentWithDaysLeftDto,
    locations: ListObject[]
  ): TrelloBoardTalent {
    const t = rt.recruitmentTalent;
    const prefs: TalentExpectations | null = t.expectations
      ? {
          ...t.expectations,
          requiredCities: t.expectations!.requiredCities.map(c => {
            const location = locations.find(l => l.id === c.id);
            return location || c;
          }),
        }
      : null;
    return {
      ...t,
      declineReasonIds: t.declineReasonIds || [],
      otherReason: t.otherReason || null,
      accepted: t.accepted ? new Date(t.accepted) : null,
      declined: t.declined ? new Date(t.declined) : null,
      removed: t.removed ? new Date(t.removed) : null,
      invited: t.invited ? new Date(t.invited) : null,
      lastUpdatedDate: new Date(t.lastUpdatedDate),
      contacted: t.contacted ? new Date(t.contacted) : null,
      saved: t.saved ? new Date(t.saved) : null,
      percent: t.percent || null,
      expectations: prefs,
      daysLeftDto: rt.daysLeftDto,
      isHandledDate:
        t.isHandledDate !== null ? new Date(t.isHandledDate) : null,
    };
  }

  static canAddToJobylon(v: {
    talentId: string;
    companyObject: Pick<CompanyObject, "jobylonKeys">;
    companyTalents: Pick<
      CompanyTalentGetDto,
      "talentId" | "jobylonObject" | "nonAnonymouseProfileDetails"
    >[];
  }): boolean {
    if (v.companyObject.jobylonKeys === null) {
      return false;
    }

    const companyTalent = v.companyTalents.find(
      ct => ct.talentId === v.talentId
    );

    if (!companyTalent) {
      return false;
    }

    if (companyTalent.jobylonObject?.jobylonApplicationId) {
      return false;
    }

    if (companyTalent.nonAnonymouseProfileDetails === null) {
      return false;
    }

    return true;
  }

  static canAddToTeamTailer(v: {
    talentId: string;
    companyObject: Pick<CompanyObject, "teamTailorApiKey">;
    teamTailerTalentIds: GetTeamTailorTalentIdsDto[];
    companyTalents: Pick<
      CompanyTalentGetDto,
      "talentId" | "nonAnonymouseProfileDetails"
    >[];
  }): boolean {
    if (v.companyObject.teamTailorApiKey === null) {
      return false;
    }

    const companyTalent = v.companyTalents.find(
      ct => ct.talentId === v.talentId
    );

    if (!companyTalent) {
      return false;
    }

    if (v.teamTailerTalentIds.some(x => x.talentId === v.talentId)) {
      return false;
    }

    if (companyTalent.nonAnonymouseProfileDetails === null) {
      return false;
    }

    return true;
  }

  static getReusedRecTalent(recTalent: TrelloBoardTalent): TrelloBoardTalent {
    return {
      ...recTalent,
      invited: null,
      declined: null,
      removed: null,
      saved: null,
      laneId: null,
      contacted: null,
      declineReasonIds: [],
      otherReason: null,
      sortOrder: -1,
    };
  }

  static mapToJobOfferToView(
    x: GetJobbOfferListDto
  ): JobbOfferVisualForView | null {
    const coworkers = (x.jobOffer.visual.coWorkers || []).map((cw: any) => {
      const result: TalentCoWorker = {
        imageUrl: cw.imageUrl || "",
        interview: {
          interviewType:
            (cw.interview || {}).interviewType || JobbOfferInterviewType.text,
          textInterview: cw.interview.textInterview || [],
          uploadInProgress: false,
          videoUrl: cw.interview.videoUrl,
          youtubeUrl: cw.interview.youtubeUrl,
        },
        isManager: cw.isManager || false,
        name: cw.name,
        role: cw.role,
        socialMedia: cw.socialMedia,
      };
      return result;
    });

    const jo = {
      ...x,
      invited: x.invited ? new Date(x.invited) : null,
      accepted: x.accepted ? new Date(x.accepted) : null,
      removed: x.removed ? new Date(x.removed) : null,
      declined: x.declined ? new Date(x.declined) : null,
      jobOffer: {
        ...x.jobOffer,
        logoUrl:
          x.jobOffer.logoUrl ||
          "https://image-test.gritify.io/image/8541b0d2-81fa-45f2-af00-30cac8f4b5dc/4d9d1f43-ba66-4864-8abd-61cab09df61b",
        companyName: x.jobOffer.companyName || "",
        baseInfo: {
          ...x.jobOffer.baseInfo,
          office: x.jobOffer.baseInfo.office
            ? {
                ...x.jobOffer.baseInfo.office,
                streetAddress: x.jobOffer.baseInfo.office.streetAddress || "",
                postalCode: x.jobOffer.baseInfo.office.postalCode || "",
                city: x.jobOffer.baseInfo.office.city || "",
                country: x.jobOffer.baseInfo.office.country || "",
              }
            : null,
        },
        visual: {
          ...x.jobOffer.visual,
          announcementTitle: x.jobOffer.visual.announcementTitle || "",
          department: x.jobOffer.visual.department
            ? {
                ...x.jobOffer.visual.department,
                description: x.jobOffer.visual.department.description ?? "",
              }
            : null,
          taskIds: x.jobOffer.visual.taskIds || [],
          coWorkers: coworkers,
          manager: x.jobOffer.visual.manager
            ? {
                ...x.jobOffer.visual.manager,
                name: x.jobOffer.visual.manager.name || "",
              }
            : null,
        },
      },
    };

    const result: JobbOfferVisualForView = {
      companyId: jo.jobOffer.companyId,
      recruitmentId: jo.recruitmentId,
      accepted: jo.accepted || null,
      declined: jo.declined || null,
      invited: jo.invited || null,
      removed: jo.removed || null,
      aIMatchDescription: jo.aIMatchDescription,
      lastRead: jo.lastRead ? new Date(jo.lastRead) : null,
      numberOfNewMessages: jo.newMessages || 0,
      salaryMatched: jo.salaryMatched || false,
      isRemovedBeforeAnswered: jo.isRemovedBeforeAnswered,
      baseInfo: jo.jobOffer.baseInfo,
      branchId: jo.jobOffer.branchId,
      companyName: jo.jobOffer.companyName,
      logoUrl: jo.jobOffer.logoUrl,
      visual: jo.jobOffer.visual,
      recruitmentCloseDate: jo.jobOffer.recruitmentCloseDate
        ? new Date(jo.jobOffer.recruitmentCloseDate)
        : null,
      isHired: jo.isHired,
    };
    return result;
  }
}

export const getNewExpectionsFromReasons = (v: {
  expectations: TalentExpectations | null;
  reasons: DeclineReasonSingle[];
  companyProfile: { isStartUpScaleUp?: boolean } | null;
}):
  | { type: "NoChange" }
  | { type: "Changed"; newExpectations: TalentExpectations } => {
  if (v.expectations === null) {
    return { type: "NoChange" };
  }

  const newExpectations = { ...v.expectations };
  let isChanged = false;
  if (
    v.reasons.some(x => x.type === "NoConsult") &&
    !v.expectations.requireNoConsultants
  ) {
    isChanged = true;
    newExpectations.requireNoConsultants = true;
  }

  if (
    v.reasons.some(x => x.type === "NoPublicSector") &&
    !v.expectations.requireNoPublicSectors
  ) {
    isChanged = true;
    newExpectations.requireNoPublicSectors = true;
  }

  if (
    v.reasons.some(x => x.type === "CompanySize") &&
    v.companyProfile?.isStartUpScaleUp &&
    !v.expectations.requireNoStartups
  ) {
    isChanged = true;
    newExpectations.requireNoStartups = true;
  }

  if (isChanged) {
    return { type: "Changed", newExpectations };
  }

  return { type: "NoChange" };
};

export const numberOfDaysSinceHandled = (
  recTalent: TrelloBoardTalent | null
) => {
  const date = recTalent?.isHandledDate ?? recTalent?.accepted;

  if (!date) {
    return null;
  }

  const result = FilterHelpers.getNumberOfDaysFromNow(date);

  return result;
};

export const isNewRecTalent = (t: TrelloBoardTalent): boolean => {
  const isActive =
    !!t.accepted && !t.removed && !FilterHelpers.hasDeclined(t) && !!t.invited;
  return isActive && t.isHandledDate === null;
};
