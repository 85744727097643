var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"keep"}},[_c('v-app-bar',{staticClass:"pe-0 company-navbar",attrs:{"color":"white","clipped-left":"","app":"","elevation":"0"}},[_c('div',{staticClass:"flex-grow-1"},[_c('nuxt-link',{staticClass:"d-flex align-center",attrs:{"to":_vm.localePath({
            name: 'company-companyId',
            params: {
              companyId: _vm.companyId,
            },
          })}},[_c('GritifyLogo')],1)],1),_vm._v(" "),(_vm.$vuetify.breakpoint.mdAndUp && _vm.showCompanyComponentsMenu)?[_c('div',{staticClass:"d-flex",staticStyle:{"gap":"40px"}},_vm._l((_vm.companyComponentsMenuItems),function(c){return _c('GrNavLinkDense',{key:c.url,attrs:{"to":c.url,"is-active":c.isActive}},[_vm._v("\n          "+_vm._s(c.text)+"\n        ")])}),1),_vm._v(" "),_c('v-spacer')]:(!!_vm.recruitmentId && _vm.$vuetify.breakpoint.mdAndUp)?[_c('RecruitmentPickerMenu',{attrs:{"recruitment-id":_vm.recruitmentId}}),_vm._v(" "),_c('v-spacer')]:_vm._e(),_vm._v(" "),(_vm.isFreeAccount)?[_c('GrButton',{attrs:{"small":true,"color":"outlinedBlack"},on:{"click":_vm.openStartPricingModelModal}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"8","height":"8","viewBox":"0 0 8 8","fill":"none"}},[_c('circle',{attrs:{"cx":"4","cy":"4","r":"4","fill":"#0034FF"}})]),_vm._v(" "),_c('span',{staticClass:"ms-1"},[_vm._v(_vm._s(_vm.$t("Gratiskonto")))])])]:_vm._e(),_vm._v(" "),(_vm.companies.length > 1 && _vm.$vuetify.breakpoint.smAndUp)?_c('div',[_c('v-menu',{attrs:{"left":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('button',[_c('div',_vm._g({staticClass:"d-flex gap-2 align-center px-6",staticStyle:{"cursor":"pointer"}},on),[_c('v-badge',{attrs:{"content":_vm.numberOfUnseenNotificationsOnOtherCompanies,"value":_vm.numberOfUnseenNotificationsOnOtherCompanies,"color":"accent","overlap":"","offset-y":"6","offset-x":"10"}},[_c('span',[_vm._v(_vm._s(_vm.companyName))])])],1)])]}}],null,false,3392133487)},[_vm._v(" "),_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"470px"}},_vm._l((_vm.companies),function(c){return _c('v-list-item',{key:c.id,attrs:{"disabled":_vm.logginCompanyId === c.id},on:{"click":function($event){return _vm.changeCompany(c.id)}}},[_c('v-list-item-title',[_c('v-badge',{attrs:{"content":c.numberOfUnseenNotification,"value":c.numberOfUnseenNotification,"color":"accent"}},[_vm._v("\n                "+_vm._s(c.text)+"\n              ")])],1)],1)}),1)],1)],1):(_vm.$vuetify.breakpoint.smAndUp)?[_c('div',{staticClass:"px-6 d-flex gap-2"},[_vm._v("\n        "+_vm._s(_vm.companyName)+"\n        "),(_vm.logoImageUrl)?_c('div',{staticClass:"white-card"},[_c('v-img',{attrs:{"src":_vm.logoImageUrl,"width":"24px","height":"24px"}})],1):_vm._e()])]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-flex align-center gap-1 px-1 px-sm-2",staticStyle:{"align-self":"stretch"},style:({
        backgroundColor: _vm.$vuetify.breakpoint.smAndUp ? '#0C2481' : 'white',
      })},[_c('v-btn',{attrs:{"icon":"","color":_vm.$vuetify.breakpoint.smAndUp ? 'white' : 'black'},on:{"click":_vm.openTalentConversations}},[_c('v-badge',{attrs:{"content":_vm.numberOfUnreadTalentConversations,"value":_vm.numberOfUnreadTalentConversations,"color":"accent","overlap":""}},[_c('v-icon',[_vm._v("mdi-chat")])],1)],1),_vm._v(" "),_c('v-btn',{attrs:{"icon":"","color":_vm.$vuetify.breakpoint.smAndUp ? 'white' : 'black'},on:{"click":_vm.openNotifications}},[_c('v-badge',{attrs:{"content":_vm.numberOfUnseenNotifications,"value":_vm.numberOfUnseenNotifications,"color":"accent","overlap":""}},[_c('v-icon',[_vm._v("mdi-bell")])],1)],1),_vm._v(" "),_c('v-menu',{attrs:{"left":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"d-none d-sm-block",attrs:{"icon":"","color":"white","text":""}},on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}])},[_vm._v(" "),_c('v-list',[_c('v-list-item',{attrs:{"to":_vm.localePath({
                name: 'company-companyId-account',
                params: {
                  companyId: _vm.companyId,
                },
              })}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Konto")))])],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Logga ut")))])],1)],1)],1),_vm._v(" "),_c('v-btn',{staticClass:"d-block d-sm-none",attrs:{"icon":"","color":_vm.$vuetify.breakpoint.smAndUp ? 'white' : 'black'},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-icon',[_vm._v("mdi-menu")])],1)],1)],2),_vm._v(" "),(_vm.isMobile)?_c('v-navigation-drawer',{attrs:{"touchless":"","floating":"","app":"","clipped":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',[(_vm.companies.length > 1)?[_c('v-menu',{attrs:{"left":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"loading":_vm.loadingCompanySwitch}},on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-office-building")])],1),_vm._v(" "),_c('v-list-item-title',[_c('div',[_c('v-badge',{attrs:{"content":_vm.numberOfUnseenNotificationsOnOtherCompanies,"value":_vm.numberOfUnseenNotificationsOnOtherCompanies,"color":"accent","inline":"","offset-y":"6","offset-x":"6"}},[_vm._v("\n                    "+_vm._s(_vm.companyName)+"\n                  ")])],1)])],1)]}}],null,false,1853051408)},[_vm._v(" "),_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"470px"}},_vm._l((_vm.companies),function(c){return _c('v-list-item',{key:c.id,attrs:{"disabled":_vm.logginCompanyId === c.id},on:{"click":function($event){return _vm.changeCompany(c.id)}}},[_c('v-list-item-title',[_c('v-badge',{attrs:{"content":c.numberOfUnseenNotification,"value":c.numberOfUnseenNotification,"color":"accent"}},[_vm._v("\n                  "+_vm._s(c.text)+"\n                ")])],1)],1)}),1)],1)]:_vm._e(),_vm._v(" "),_c('v-list-item',{attrs:{"to":_vm.localePath({
            name: 'company-companyId',
            params: { companyId: _vm.companyId },
          }),"exact":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-home")])],1),_vm._v(" "),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Start")))])],1),_vm._v(" "),(_vm.isDoneWithCompanyStepper)?_c('v-list-item',{attrs:{"to":_vm.localePath({
            name: 'company-companyId-recruitments',
            params: { companyId: _vm.companyId },
          }),"exact":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-box-multiple")])],1),_vm._v(" "),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Rekryteringar")))])],1):_vm._e(),_vm._v(" "),(_vm.canViewTalentPool && _vm.isDoneWithCompanyStepper)?_c('v-list-item',{attrs:{"to":_vm.localePath({
            name: 'company-companyId-talentPool',
            params: { companyId: _vm.companyId },
          })}},[_c('v-list-item-icon',[_c('TalentPoolIcon',{attrs:{"color":"grey"}})],1),_vm._v(" "),_c('v-list-item-title',[_vm._v(_vm._s(_vm.talentPoolTitle))])],1):_vm._e(),_vm._v(" "),(_vm.isDoneWithCompanyStepper)?_c('v-list-item',{class:[_vm.showCompanyComponentsMenu ? 'v-list-item--active' : ''],attrs:{"to":_vm.localePath({
            name: 'company-companyId-companyComponents',
            params: { companyId: _vm.companyId },
          }),"exact":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-view-grid-outline")])],1),_vm._v(" "),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Företagskomponenter")))])],1):_vm._e(),_vm._v(" "),_c('v-divider'),_vm._v(" "),(_vm.hasFullAccess && _vm.isDoneWithCompanyStepper)?_c('v-list-item',{attrs:{"to":_vm.localePath({
            name: 'company-companyId-settings',
            params: { companyId: _vm.companyId },
          })}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cog")])],1),_vm._v(" "),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Inställningar")))])],1):_vm._e(),_vm._v(" "),_c('v-list-item',{attrs:{"to":_vm.localePath({
            name: 'company-companyId-account',
            params: {
              companyId: _vm.companyId,
            },
          })}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account")])],1),_vm._v(" "),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Konto")))])],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout")])],1),_vm._v(" "),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Logga ut")))])],1)],2)],1):(_vm.isDoneWithCompanyStepper)?_c('v-navigation-drawer',{staticClass:"company-side-panel",attrs:{"width":300,"color":"primary","dark":"","expand-on-hover":"","permanent":"","app":"","clipped":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-list',{attrs:{"nav":""}},[(_vm.hasFullAccess)?_c('v-list-item',{attrs:{"to":_vm.localePath({
              name: 'company-companyId-settings',
              params: { companyId: _vm.companyId },
            })}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cog")])],1),_vm._v(" "),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Inställningar")))])],1):_vm._e()],1)]},proxy:true}])},[_c('v-list',{attrs:{"nav":""}},[_c('v-list-item',{attrs:{"to":_vm.localePath({
            name: 'company-companyId',
            params: { companyId: _vm.companyId },
          }),"exact":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-home")])],1),_vm._v(" "),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Start")))])],1),_vm._v(" "),_c('v-list-item',{attrs:{"to":_vm.localePath({
            name: 'company-companyId-recruitments',
            params: { companyId: _vm.companyId },
          })}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-box-multiple")])],1),_vm._v(" "),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Rekryteringar")))])],1),_vm._v(" "),(_vm.canViewTalentPool)?_c('v-list-item',{attrs:{"to":_vm.localePath({
            name: 'company-companyId-talentPool',
            params: { companyId: _vm.companyId },
          })}},[_c('v-list-item-icon',[_c('TalentPoolIcon')],1),_vm._v(" "),_c('v-list-item-title',[_vm._v(_vm._s(_vm.talentPoolTitle))])],1):_vm._e(),_vm._v(" "),_c('v-list-item',{class:[_vm.showCompanyComponentsMenu ? 'v-list-item--active' : ''],attrs:{"exact":"","to":_vm.localePath({
            name: 'company-companyId-companyComponents',
            params: { companyId: _vm.companyId },
          })}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-view-grid-outline")])],1),_vm._v(" "),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Företagskomponenter")))])],1)],1)],1):_vm._e(),_vm._v(" "),_c('ConversationDrawerContent',{model:{value:(_vm.conversationDraweOpen),callback:function ($$v) {_vm.conversationDraweOpen=$$v},expression:"conversationDraweOpen"}}),_vm._v(" "),_c('CompanyNotificationDrawer',{model:{value:(_vm.rigthDrawerOpen),callback:function ($$v) {_vm.rigthDrawerOpen=$$v},expression:"rigthDrawerOpen"}}),_vm._v(" "),_c('v-main',[(_vm.isDisconnectedFromSignalR && !_vm.ignoredDisconnected)?_c('GrScrollPanel',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-alert',{staticClass:"mb-0 info black--text"},[_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('div',[_c('b',[_vm._v(_vm._s(_vm.$t("Nätverksproblem")))]),_vm._v(" "),_c('div',[_vm._v("\n                "+_vm._s(_vm.$t("Liveuppdateringar är just nu inte..."))+"\n              ")])]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('div',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.cancelReloadPage}},[_vm._v("\n                "+_vm._s(_vm.$t("Ignorera"))+"\n              ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.reloadSite}},[_vm._v("\n                "+_vm._s(_vm.$t("Ladda om sidan"))+"\n              ")])],1)],1)])]},proxy:true}],null,false,2594986541)},[_vm._v(" "),_c('nuxt')],1):_c('nuxt',{staticStyle:{"height":"100%"}})],1),_vm._v(" "),_c('Confirm',{ref:"confirm"}),_vm._v(" "),_c('SuccessErrorSnackbar',{on:{"showNotifications":_vm.openNotifications}}),_vm._v(" "),_c('IsOnIEModal',{ref:"IsOnIEModal"}),_vm._v(" "),_c('StartAccessSuccessModal',{ref:"StartAccessSuccessModal"}),_vm._v(" "),_c('StartFixedPriceAccountModal',{ref:"StartFixedPriceAccountModal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }