import { render, staticRenderFns } from "./GritifyAILogo.vue?vue&type=template&id=d4dd3de8&scoped=true&"
import script from "./GritifyAILogo.vue?vue&type=script&lang=ts&"
export * from "./GritifyAILogo.vue?vue&type=script&lang=ts&"
import style0 from "./GritifyAILogo.vue?vue&type=style&index=0&id=d4dd3de8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4dd3de8",
  null
  
)

/* custom blocks */
import block0 from "./GritifyAILogo.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5Ca%5C1%5Cs%5Ccomponents%5CGritifyAILogo.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports