




























































import { Vue, Component } from "nuxt-property-decorator";
import { debounce } from "lodash";
import { SuperSearchItem, SuperSearchItemType } from "~/models/types";
import superSearchService from "~/services/superSearchService";

@Component
export default class CompanySuperSearch extends Vue {
  items: SuperSearchItem[] = [];

  searchTerm: string | null = null;

  menuOpen = false;

  get itemsFiltered() {
    return this.items;
  }

  async searchChanged() {
    this.menuOpen = true;
    this.items = await superSearchService.getSuperSearchResults(
      this.searchTerm || ""
    );
  }

  created() {
    this.searchChanged = debounce(this.searchChanged, 200) as any;
  }

  getIconForType(type: SuperSearchItemType): string {
    switch (type) {
      case SuperSearchItemType.Talent:
        return "mdi-school";
      case SuperSearchItemType.User:
        return "mdi-account";
      case SuperSearchItemType.offices:
        return "mdi-home";
      case SuperSearchItemType.RequitmentProcess:
        return "mdi-auto-fix";
      case SuperSearchItemType.Department:
        return "mdi-account-multiple";
      case SuperSearchItemType.Manager:
        return "mdi-bow-tie";
    }
  }

  inputFocused(scope: any) {
    if (this.searchTerm) {
      scope.value = true;
    }
  }
}
