import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import {
  Office,
  OfficeToCreate,
  OfficeToUpdate,
  ImageEntityType,
} from "~/models/types";
import officeService from "~/services/officeService";
import { officesStore } from "~/store";
import CompanyPhotosService from "~/services/CompanyPhotosService";
import jobOfferService from "~/services/jobOfferService";
import TokenHelpers from "~/helpers/tokenHelpers";

@Module({
  name: "offices",
  stateFactory: true,
  namespaced: true,
})
export default class Offices extends VuexModule {
  items: Office[] = [];
  itemsAreSet = false;
  removingIds: string[] = [];
  loadingCanEditAddress: boolean = false;
  canEditAddress: boolean = false;

  @Mutation
  SET_LOADING_CAN_EDIT_ADDRESS(v: boolean) {
    this.loadingCanEditAddress = v;
  }

  @Mutation
  SET_CAN_EDIT_ADDRESS(v: boolean) {
    this.canEditAddress = v;
  }

  @Action
  async loadCanEditAddress(v: { officeId: string }) {
    officesStore.SET_LOADING_CAN_EDIT_ADDRESS(true);
    const token = await TokenHelpers.getToken();
    const canEditOffice = await jobOfferService.getCanEditOfficeAddress({
      accessToken: token,
      officeId: v.officeId,
    });

    officesStore.SET_LOADING_CAN_EDIT_ADDRESS(false);

    officesStore.SET_CAN_EDIT_ADDRESS(canEditOffice);
  }

  @Mutation
  SET_REMOVEING_IDS(v: string[]) {
    this.removingIds = v;
  }

  showRemoved: boolean = false;

  @Mutation
  SET_SHOW_REMOVED(v: boolean) {
    this.showRemoved = v;
  }

  @Mutation
  SET_OFFICES(offices: Office[]) {
    this.items = offices;
    this.itemsAreSet = true;
  }

  @Action
  async loadOffices() {
    if (!this.itemsAreSet) {
      const token = await TokenHelpers.getToken();
      const offices = await officeService.getOffices(token);
      officesStore.SET_OFFICES(offices);
    }
  }

  @Action
  async addOffice(v: {
    office: OfficeToCreate;
    imageFile: any;
  }): Promise<Office> {
    const token = await TokenHelpers.getToken();

    let newUrl: string | null = null;

    if (v.imageFile) {
      newUrl = await CompanyPhotosService.uploadImageToEntity({
        entityType: ImageEntityType.Office,
        file: v.imageFile,
        token,
      });
    }

    const officeToCreate = {
      ...v.office,
      officeImage: newUrl || v.office.officeImage,
    };

    const responseObj = await officeService.createOffice(officeToCreate, token);
    const newOffice: Office = {
      ...officeToCreate,
      id: responseObj.officeId,
      areaId: responseObj.areaId,
    };
    officesStore.SET_OFFICES([...this.items, newOffice]);

    return newOffice;
  }

  updatingIds: string[] = [];

  @Mutation
  SET_OFFICE_UPDATING(v: { officeId: string; isLoading: boolean }) {
    if (!v.isLoading) {
      this.updatingIds = this.updatingIds.filter(x => x !== v.officeId);
    } else {
      this.updatingIds = [...this.updatingIds, v.officeId];
    }
  }

  @Action
  async updateOffice(v: {
    office: OfficeToUpdate;
    imageFile: any;
  }): Promise<{ officeId: string; imageUrl: string | null }> {
    officesStore.SET_OFFICE_UPDATING({
      officeId: v.office.id,
      isLoading: true,
    });

    const token = await TokenHelpers.getToken();
    let newUrl: string | null = null;

    if (v.imageFile) {
      newUrl = await CompanyPhotosService.uploadImageToEntity({
        entityType: ImageEntityType.Office,
        file: v.imageFile,
        token,
      });
    }

    const officeToUpdate: OfficeToUpdate = {
      ...v.office,
      officeImage: newUrl || v.office.officeImage,
    };

    const response = await officeService.updateOffice(officeToUpdate, token);

    const newOffices: Office[] = this.items.map(o => {
      if (o.id === officeToUpdate.id) {
        return { ...officeToUpdate, areaId: response.areaId };
      }
      return o;
    });
    officesStore.SET_OFFICES(newOffices);

    officesStore.SET_OFFICE_UPDATING({
      isLoading: false,
      officeId: v.office.id,
    });

    return {
      officeId: response.officeId,
      imageUrl: newUrl ?? v.office.officeImage,
    };
  }

  @Action
  async removeOffice(officeId: string) {
    const office = this.items.find(x => x.id === officeId);
    if (office) {
      officesStore.SET_REMOVEING_IDS([...this.removingIds, officeId]);
      const token = await TokenHelpers.getToken();
      await officeService.updateOffice({ ...office, isRemoved: true }, token);
      officesStore.SET_REMOVEING_IDS(
        this.removingIds.filter(x => x !== officeId)
      );

      officesStore.SET_OFFICES(
        this.items.map(d => {
          if (d.id === office.id) {
            return { ...office, isRemoved: true };
          }
          return d;
        })
      );
    }
  }

  @Action
  async unremoveOffice(officeId: string) {
    const office = this.items.find(x => x.id === officeId);
    if (office) {
      officesStore.SET_REMOVEING_IDS([...this.removingIds, officeId]);
      const token = await TokenHelpers.getToken();
      await officeService.updateOffice({ ...office, isRemoved: false }, token);
      officesStore.SET_REMOVEING_IDS(
        this.removingIds.filter(x => x !== officeId)
      );

      officesStore.SET_OFFICES(
        this.items.map(d => {
          if (d.id === office.id) {
            return { ...office, isRemoved: false };
          }
          return d;
        })
      );
    }
  }
}
