



















































































































import { Vue, Component } from "vue-property-decorator";
import GrNavLinkDense from "./GrNavLinkDense.vue";

@Component({
  components: {
    GrNavLinkDense,
  },
})
export default class GrPublicNavBar extends Vue {
  get isOnStartPage() {
    return this.$route.name?.includes("index_");
  }

  get showAlternativeLogo(): boolean {
    return !this.isOnStartPage && this.$vuetify.breakpoint.xsOnly;
  }

  get navBarHeight(): number {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return 56;
      case "sm":
      case "md":
      case "lg":
        return 72;
      case "xl":
        return 104;
    }
  }
}
