































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import GrNavLinkDense from "./GrNavLinkDense.vue";
import GrButton from "./GrButton.vue";
import CrispHelper from "~/helpers/CrispHelper";

@Component({
  components: {
    GrNavLinkDense,
    GrButton,
  },
})
export default class GrPublicNavBarCompany extends Vue {
  @Prop({ default: false }) readonly greyBackground!: boolean;

  get showAlternativeLogo(): boolean {
    return this.$vuetify.breakpoint.xsOnly;
  }

  mobileMenuOpen = false;

  @Watch("mobileMenuOpen")
  modalOpenChanged(v: boolean) {
    if (v) {
      if (this.$vuetify.breakpoint.smAndDown) {
        CrispHelper.hideChat();
      }
    } else {
      CrispHelper.showChat();
    }
  }

  get companyStartIsActive(): boolean {
    return this.$route.name?.includes("companyStart__") ?? false;
  }

  get companyStartPassiveCandidatesIsActive(): boolean {
    return (
      this.$route.name?.includes("companyStart-passiveCandidates__") ?? false
    );
  }

  get companyStartFordomsfrihetIsActive(): boolean {
    return this.$route.name?.includes("companyStart-fordomsfrihet__") ?? false;
  }

  get companyStartPricingIsActive(): boolean {
    return this.$route.name?.includes("companyStart-pricing__") ?? false;
  }

  get navBarHeight(): number {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return 56;
      case "sm":
      case "md":
        return 72;
      case "lg":
      case "xl":
        return 104;
    }
  }
}
