





































import { Vue, Component, Prop } from "nuxt-property-decorator";
import { NotificationItem, NotificationTypeInternal } from "~/models/types";
import { authStore } from "~/store";

@Component({
  components: {},
  layout: "companyLayout",
})
export default class NotificationsTalentDeclinedListItem extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: NotificationItem & {
    type: NotificationTypeInternal.TalentDeclined;
  };

  get recruitmentTitle(): string {
    return this.item.recruitmentTitle;
  }

  get talentName(): string {
    return this.item.talentName;
  }

  get date(): Date {
    return this.item.date;
  }

  get companyId() {
    return authStore.companyId!;
  }

  get recruitmentLink(): string {
    return this.localePath({
      name: "company-companyId-recruitments-recruitmentId",
      params: {
        recruitmentId: this.item.recruitmentId,
        companyId: this.companyId,
      },
    });
  }

  get talentLink(): string {
    return this.localePath({
      name: "company-companyId-recruitments-recruitmentId-candidate-talentId",
      params: {
        talentId: this.item.talentId,
        recruitmentId: this.item.recruitmentId,
        companyId: this.companyId,
      },
    });
  }
}
