


















































import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import NotificationsList from "./company/NotificationsList.vue";
import { NotificationItem } from "~/models/types";
import { companyBaseStore } from "~/store";

@Component({
  components: {
    NotificationsList,
  },
})
export default class CompanyNotificationDrawer extends Vue {
  @Prop({ type: Boolean, required: true })
  readonly value!: boolean;

  @Emit("input")
  inputEmit(v: boolean) {
    return v;
  }

  notificationTab = 0;

  get showNews(): boolean {
    return !!this.newsCount;
  }

  get newsCount(): number {
    return companyBaseStore.gritifyNews.length;
  }

  get notifications(): NotificationItem[] {
    return companyBaseStore.notifications;
  }

  get rigthDrawerOpen(): boolean {
    return this.value;
  }

  set rigthDrawerOpen(v: boolean) {
    this.inputEmit(v);
  }

  get hasMore(): boolean {
    return !!companyBaseStore.loadMoreNotificationsToken;
  }

  get loadingMore() {
    return companyBaseStore.loadingNotifications;
  }

  async loadMoreNotifications() {
    await companyBaseStore.loadMoreNotifications();
  }
}
