import locale950f9a80 from '../..\\lang\\sv-SV.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"sv","silentFallbackWarn":true},
  vueI18nLoader: true,
  locales: [{"code":"sv","file":"sv-SV.js","name":"Svenska"},{"code":"en","file":"en-US.js","name":"English"}],
  defaultLocale: "sv",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix_and_default",
  lazy: true,
  langDir: "D:\\a\\1\\s\\lang",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"sv","file":"sv-SV.js","name":"Svenska"},{"code":"en","file":"en-US.js","name":"English"}],
  localeCodes: ["sv","en"],
}

export const localeMessages = {
  'sv-SV.js': () => Promise.resolve(locale950f9a80),
  'en-US.js': () => import('../..\\lang\\en-US.js' /* webpackChunkName: "lang-en-US.js" */),
}
