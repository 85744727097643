





















import { Vue, Component, Prop } from "vue-property-decorator";

interface StringWithType {
  type: "Wrapped" | "Normal";
  text: string;
}

@Component
export default class TextWrapperReplacer extends Vue {
  @Prop({ type: String, required: true })
  readonly text!: string;

  get parts(): StringWithType[] {
    const text = this.text ?? "";

    return text.split("$").map((s, index) => ({
      text: s,
      type: index % 2 === 0 ? "Normal" : "Wrapped",
    }));
  }
}
