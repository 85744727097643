import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { sourcingStore } from "~/store";
import TokenHelpers from "~/helpers/tokenHelpers";
import ManualSourcingService from "~/services/ManualSourcingService";
import { SavedManualSourcing } from "~/models/types";
import SignalRHelper from "~/helpers/SignalRHelper";

@Module({
  name: "sourcing",
  stateFactory: true,
  namespaced: true,
})
export default class SourcingStore extends VuexModule {
  sourcingsByRecruitmentIdInternal: {
    [key: string]: "loading" | SavedManualSourcing[];
  } = {};

  get sourcingsByRecruitmentIdMap() {
    return new Map(Object.entries(this.sourcingsByRecruitmentIdInternal));
  }

  @Mutation
  SET_SOURCING_BY_RECRUITMENT_ID(v: {
    recruitmentId: string;
    value: "loading" | SavedManualSourcing[];
  }) {
    this.sourcingsByRecruitmentIdInternal = {
      ...this.sourcingsByRecruitmentIdInternal,
      [v.recruitmentId]: v.value,
    };
  }

  @Action
  async upsertSavedSourcing(v: { savedSourcing: SavedManualSourcing }) {
    const token = await TokenHelpers.getToken();

    await ManualSourcingService.upsertSavedManualSourcing({
      accessToken: token,
      savedManualSourcing: v.savedSourcing,
    });

    const existingValue = this.sourcingsByRecruitmentIdMap.get(
      v.savedSourcing.recruitmentId
    );

    const existingList =
      existingValue === "loading" || existingValue === undefined
        ? []
        : existingValue;

    sourcingStore.SET_SOURCING_BY_RECRUITMENT_ID({
      recruitmentId: v.savedSourcing.recruitmentId,
      value: SignalRHelper.getListWithNewItem({
        items: existingList,
        newItem: v.savedSourcing,
        getId: x => x.id,
      }),
    });
  }

  @Action
  async setSourcingOpened(v: {
    recruitmentId: string;
    sourcingId: string;
    userId: string;
  }) {
    const token = await TokenHelpers.getToken();

    await ManualSourcingService.setSourcingOpened({
      accessToken: token,
      recruitmentId: v.recruitmentId,
      sourcingId: v.sourcingId,
    });

    const sourcings = this.sourcingsByRecruitmentIdMap.get(v.recruitmentId);
    if (sourcings && sourcings !== "loading") {
      const sourcing = sourcings.find(s => s.id === v.sourcingId);
      if (sourcing) {
        sourcingStore.SET_SOURCING_BY_RECRUITMENT_ID({
          recruitmentId: v.recruitmentId,
          value: SignalRHelper.getListWithNewItem({
            items: sourcings,
            newItem: {
              ...sourcing,
              openedDate: new Date(),
            },
            getId: x => x.id,
          }),
        });
      }
    }
  }

  @Action
  async loadSavedSourcings(v: { recruitmentId: string }) {
    if (this.sourcingsByRecruitmentIdMap.has(v.recruitmentId)) {
      return;
    }
    sourcingStore.SET_SOURCING_BY_RECRUITMENT_ID({
      recruitmentId: v.recruitmentId,
      value: "loading",
    });
    const token = await TokenHelpers.getToken();

    const result = await ManualSourcingService.getSavedManualSourcings({
      accessToken: token,
      recruitmentId: v.recruitmentId,
    });

    sourcingStore.SET_SOURCING_BY_RECRUITMENT_ID({
      recruitmentId: v.recruitmentId,
      value: result,
    });
  }
}
