







































































import { Vue, Component, Prop } from "vue-property-decorator";
import MaxWidthSection from "./MaxWidthSection.vue";
import CommonQuestion from "./CommonQuestion.vue";

@Component({
  components: {
    MaxWidthSection,
    CommonQuestion,
  },
})
export default class CommonQuestionsPricingList extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly removeQuestionsAboutConverting!: boolean;

  toggleOpen(v: any) {
    v.isOpen = !(v.isOpen ?? false);
  }

  async goToPath(path: string) {
    if (this.$route.hash) {
      await this.$router.replace(path.split("#")[0]!);
    }
    this.$router.replace(path);
  }

  question1Open = false;
  question2Open = false;
  question3Open = false;
  question4Open = false;
  question5Open = false;
}
