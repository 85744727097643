



































import { Vue, Component, Prop } from "nuxt-property-decorator";
import { NotificationItem, NotificationTypeInternal } from "~/models/types";
import { authStore } from "~/store";

@Component({
  components: {},
  layout: "companyLayout",
})
export default class NotificationsTagListItem extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: NotificationItem & {
    type:
      | NotificationTypeInternal.TagOnRecruitment
      | NotificationTypeInternal.TagOnTalent;
  };

  get tagDestinationDescription(): string {
    switch (this.item.type) {
      case NotificationTypeInternal.TagOnTalent:
        return this.$t("på kandidaten").toString();

      case NotificationTypeInternal.TagOnRecruitment:
        return this.$t("i jobbet").toString();
    }
  }

  get isTalentTag(): boolean {
    return this.item.type === NotificationTypeInternal.TagOnTalent;
  }

  get htmlComment(): string {
    return this.item.commentHtml;
  }

  get locationName(): string {
    switch (this.item.type) {
      case NotificationTypeInternal.TagOnTalent:
        return this.item.talentName;

      case NotificationTypeInternal.TagOnRecruitment:
        return this.item.recruitmentTitle;
    }
  }

  get date(): Date {
    return this.item.date;
  }

  get senderName(): string {
    return this.item.sender;
  }

  get recruitmentTitle(): string {
    return this.item.recruitmentTitle;
  }

  get companyId() {
    return authStore.companyId!;
  }

  get locationLink(): string {
    switch (this.item.type) {
      case NotificationTypeInternal.TagOnTalent:
        return this.localePath({
          name:
            "company-companyId-recruitments-recruitmentId-candidate-talentId",
          params: {
            talentId: this.item.talentId,
            recruitmentId: this.item.recruitmentId,
            companyId: this.companyId,
          },
          query: { tab: "4" },
        });

      case NotificationTypeInternal.TagOnRecruitment:
        return this.localePath({
          name: "company-companyId-recruitments-recruitmentId-communication",
          params: {
            recruitmentId: this.item.recruitmentId,
            companyId: this.companyId,
          },
          query: { tab: "2" },
        });
    }
  }
}
