




























































































































import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import ConversationListItem from "./ConversationListItem.vue";
import GrScrollPanel from "./styling/GrScrollPanel.vue";
import CommentList from "./company/CommentList.vue";
import ConversationTalentInfo from "./ConversationTalentInfo.vue";
import GritifyAiHelpCard from "./GritifyAiHelpCard.vue";
import {
  TalentConversation,
  TalentMessage,
  TrelloBoardTalent,
} from "~/models/types";
import {
  authStore,
  companyBaseStore,
  jobbOfferStore,
  notificationsStore,
  sourcingRobotStore,
  talentPoolStore,
} from "~/store";
import SortHelper from "~/helpers/sortHelper";
import TalentPoolHelpers from "~/helpers/TalentPoolHelpers";
import { LoadedRecTalentListItem } from "~/store/jobbOffer";
import AiDataService from "~/services/aiDataService";
import TokenHelpers from "~/helpers/tokenHelpers";

@Component({
  components: {
    ConversationListItem,
    GrScrollPanel,
    CommentList,
    ConversationTalentInfo,
    GritifyAiHelpCard,
  },
})
export default class ConversationDrawerContent extends Vue {
  get talentConversationKey() {
    if (!this.selectedConversation) {
      return null;
    }

    const recTalentId = TalentPoolHelpers.getTalentConversationKey({
      recruitmentId: this.selectedConversation.recruitmentIdOrNull,
      talentId: this.selectedConversation.talentId,
    });

    return recTalentId;
  }

  get newMessage(): string | null {
    if (!this.talentConversationKey) {
      return null;
    }

    const message =
      jobbOfferStore.chatMessagesByTalentConversationKey[
        this.talentConversationKey
      ];

    return message ?? null;
  }

  set newMessage(v: string | null) {
    if (!this.talentConversationKey) {
      return;
    }

    jobbOfferStore.SET_CHAT_MESSAGES_BY_CONVERSATION_KEY({
      ...jobbOfferStore.chatMessagesByTalentConversationKey,
      [this.talentConversationKey]: v ?? undefined,
    });
  }

  loadingAddMessage = false;

  @Prop({ type: Boolean, required: true })
  readonly value!: boolean;

  @Emit("input")
  inputEmit(v: boolean) {
    return v;
  }

  searchText: string | null = null;
  loadingAiHelp = false;

  get userName() {
    const user = jobbOfferStore.companyMembers.find(
      m => m.userId === authStore.userId
    );
    return (user && `${user.firstName} ${user.lastName}`) || null;
  }

  get showAiChatMessageHelp(): boolean {
    return sourcingRobotStore.showAiChatMessageHelp;
  }

  async getHelpFromAI() {
    if (!this.selectedConversation) {
      return;
    }
    this.newMessage = null;
    try {
      this.loadingAiHelp = true;
      const token = await TokenHelpers.getToken();
      const newMessage = await AiDataService.getChatMessageAIHelp({
        accessToken: token,
        recruitmentId: this.selectedConversation.recruitmentIdOrNull,
        talentId: this.selectedConversation.talentId,
        userName: this.userName ?? "",
      });

      if (this.newMessage === null) {
        this.newMessage = newMessage;
      }
    } catch (error) {
    } finally {
      this.loadingAiHelp = false;
    }
  }

  async addMessage(): Promise<boolean> {
    const conv = this.selectedConversation;

    if (!conv) {
      return false;
    }

    if (!this.newMessage || this.newMessage.trim() === "") {
      return false;
    }

    try {
      this.loadingAddMessage = true;
      if (conv.recruitmentIdOrNull) {
        await jobbOfferStore.addMessageToTalent({
          message: this.newMessage,
          talentId: conv.talentId,
          recruitmentId: conv.recruitmentIdOrNull,
        });
      } else {
        await talentPoolStore.addPoolMessageFromCompany({
          messageText: this.newMessage,
          talentId: conv.talentId,
        });
      }
      this.newMessage = null;
      return true;
    } catch (error) {
      notificationsStore.setErrorMessage({ error });
      return false;
    } finally {
      this.loadingAddMessage = false;
    }
  }

  get numberOfUnreadTalentConversations(): number {
    return this.allItems.reduce((acc: number, c) => {
      return acc + (c.isRead ? 0 : 1);
    }, 0);
  }

  get conversationDraweOpen(): boolean {
    return this.value;
  }

  set conversationDraweOpen(v: boolean) {
    this.inputEmit(v);
  }

  get allItems() {
    if (companyBaseStore.talentConversations.type === "Loaded") {
      return SortHelper.sortItemsBy(
        companyBaseStore.talentConversations.conversations,
        [
          {
            sortBy: x => x.lastMessageDateTimeUtc,
            desc: true,
          },
        ]
      );
    }
    return [];
  }

  get itemsInView(): TalentConversation[] {
    if (companyBaseStore.talentConversations.type === "Loaded") {
      return SortHelper.sortItemsBy(
        companyBaseStore.talentConversations.conversations.filter(x => {
          if (this.searchText === null) {
            return true;
          }
          if ((this.searchText ?? "").trim() === "") {
            return true;
          }

          return x.talentName
            .toLowerCase()
            .includes(this.searchText.trim().toLowerCase());
        }),
        [
          {
            sortBy: x => x.lastMessageDateTimeUtc,
            desc: true,
          },
        ]
      );
    }
    return [];
  }

  get drawerWidth(): number {
    return this.selectedConversation ? 1200 : 350;
  }

  selectedConversation: TalentConversation | null = null;

  get isLoadingConversation(): boolean {
    if (this.isLoadingRecTalent) {
      return true;
    }
    if (this.selectedConversation === null) {
      return false;
    }

    if (this.selectedConversation.recruitmentIdOrNull !== null) {
      const message = talentPoolStore.messagesByRecTalentMap.get(
        TalentPoolHelpers.getRecTalentKey({
          recruitmentId: this.selectedConversation.recruitmentIdOrNull,
          talentId: this.selectedConversation.talentId,
        })
      );

      return message?.type === "Loading";
    }

    return (
      talentPoolStore.poolMessagesByTalentIdMap.get(
        this.selectedConversation.talentId
      )?.type === "Loading"
    );
  }

  get talentMessages(): (TalentMessage & {
    recruitmentId?: string;
  })[] {
    const conv = this.selectedConversation;
    if (conv === null) {
      return [];
    }
    if (conv.recruitmentIdOrNull === null) {
      const poolMessagesObj = talentPoolStore.poolMessagesByTalentIdMap.get(
        conv.talentId
      );

      if (poolMessagesObj?.type === "Loaded") {
        return poolMessagesObj.messages.map(m => {
          const result: TalentMessage = {
            comment: m.messageText,
            date: m.date,
            fromTalent: m.type === "FromTalent",
            id: m.id,
            sender:
              m.type === "FromCompany"
                ? m.companySender
                : {
                    firstName: conv?.talentName,
                    lastName: "",
                    userId: conv?.talentId,
                  },
          };

          return result;
        });
      }

      return [];
    }

    const recTalentMessageObj = talentPoolStore.messagesByRecTalentMap.get(
      TalentPoolHelpers.getRecTalentKey({
        recruitmentId: conv.recruitmentIdOrNull,
        talentId: conv.talentId,
      })
    );

    if (recTalentMessageObj?.type === "Loaded") {
      return recTalentMessageObj.comments.map(c => ({
        ...c,
        recruitmentId: undefined,
      }));
    }

    return [];
  }

  getIsSelected(v: TalentConversation): boolean {
    return this.selectedConversation?.id === v.id;
  }

  get recTalentOrLoading(): LoadedRecTalentListItem | null {
    const conv = this.selectedConversation;
    if (conv === null || conv.recruitmentIdOrNull === null) {
      return null;
    }

    const recTalentOrUndefined =
      jobbOfferStore.loadedRecTalentsRecord[
        TalentPoolHelpers.getRecTalentKey({
          recruitmentId: conv.recruitmentIdOrNull,
          talentId: conv?.talentId,
        })
      ];

    return recTalentOrUndefined ?? null;
  }

  get recTalent(): TrelloBoardTalent | null {
    if (this.recTalentOrLoading && this.recTalentOrLoading.type === "Loaded") {
      return this.recTalentOrLoading.recTalent;
    }

    return null;
  }

  get isLoadingRecTalent(): boolean {
    return this.recTalentOrLoading?.type === "Loading";
  }

  handleConversationSelected(v: TalentConversation) {
    companyBaseStore.setTalentConversationRead({
      conversationId: v.id,
      isRead: true,
      recruitmentId: v.recruitmentIdOrNull,
      talentId: v.talentId,
    });

    this.selectedConversation = v;

    if (v.recruitmentIdOrNull !== null) {
      talentPoolStore.loadMessagesByRecTalent({
        recruitmentId: v.recruitmentIdOrNull,
        talentId: v.talentId,
      });

      jobbOfferStore.loadOtherRecruitments({
        recruitmentIds: [v.recruitmentIdOrNull],
        talentId: v.talentId,
      });
    } else {
      talentPoolStore.loadPoolMessagesForCompany({
        talentId: v.talentId,
      });
    }
  }

  get loadingItems(): boolean {
    return companyBaseStore.talentConversations.type === "Loading";
  }
}
