import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { JobOfferDepartment, JobOfferDepartmentToAdd } from "~/models/types";
import { departmentsStore } from "~/store";
import departmentsService from "~/services/departmentsService";
import { jobbOfferVisualStore } from "~/utils/store-accessor";
import TokenHelpers from "~/helpers/tokenHelpers";

@Module({
  name: "departments",
  stateFactory: true,
  namespaced: true,
})
export default class DepartmentsStore extends VuexModule {
  departments: JobOfferDepartment[] = [];
  departmentsLoaded: boolean = false;
  removingIds: string[] = [];
  showRemoved: boolean = false;

  @Mutation
  SET_SHOW_REMOVED(v: boolean) {
    this.showRemoved = v;
  }

  @Mutation
  SET_REMOVEING_IDS(v: string[]) {
    this.removingIds = v;
  }

  @Mutation
  SET_DEPARTMENTS(deps: JobOfferDepartment[]) {
    this.departments = deps;
  }

  @Mutation
  SET_DEPARTMENTS_LOEADED(loaded: boolean) {
    this.departmentsLoaded = loaded;
  }

  @Action
  async loadDepartments() {
    if (!this.departmentsLoaded) {
      const token = await TokenHelpers.getToken();
      const departments = await departmentsService.getDepartments(token);
      departmentsStore.SET_DEPARTMENTS_LOEADED(true);
      departmentsStore.SET_DEPARTMENTS(departments);
    }
  }

  @Action
  async addDepartment(department: JobOfferDepartmentToAdd): Promise<string> {
    const token = await TokenHelpers.getToken();
    const newId = await departmentsService.createDepartment(department, token);
    departmentsStore.SET_DEPARTMENTS([
      ...this.departments,
      {
        ...department,
        id: newId,
      },
    ]);

    return newId;
  }

  updatingIds: string[] = [];

  @Mutation
  SET_UPDATING_ON_DEPARTMENT(v: { departmentId: string; isLoading: boolean }) {
    if (v.isLoading) {
      this.updatingIds = [...this.updatingIds, v.departmentId];
    } else {
      this.updatingIds = this.updatingIds.filter(x => x !== v.departmentId);
    }
  }

  @Action
  async updateDepartment(department: JobOfferDepartment) {
    try {
      departmentsStore.SET_UPDATING_ON_DEPARTMENT({
        departmentId: department.id,
        isLoading: true,
      });
      const token = await TokenHelpers.getToken();
      await departmentsService.updateDepartment(department, token);

      const visual = jobbOfferVisualStore.visual;
      if (visual.department && visual.department.id === department.id) {
        jobbOfferVisualStore.SET_JOB_OFFER_VISUAL({
          ...visual,
          department,
        });
      }

      departmentsStore.SET_DEPARTMENTS(
        this.departments.map(d => {
          if (d.id === department.id) {
            return department;
          }
          return d;
        })
      );
    } finally {
      departmentsStore.SET_UPDATING_ON_DEPARTMENT({
        departmentId: department.id,
        isLoading: false,
      });
    }
  }

  @Action
  async removeDepartment(departmentId: string) {
    const department = this.departments.find(x => x.id === departmentId);
    if (department) {
      departmentsStore.SET_REMOVEING_IDS([...this.removingIds, departmentId]);
      const token = await TokenHelpers.getToken();
      await departmentsService.updateDepartment(
        { ...department, isRemoved: true },
        token
      );
      departmentsStore.SET_REMOVEING_IDS(
        this.removingIds.filter(x => x !== departmentId)
      );

      departmentsStore.SET_DEPARTMENTS(
        this.departments.map(d => {
          if (d.id === department.id) {
            return { ...department, isRemoved: true };
          }
          return d;
        })
      );
    }
  }

  @Action
  async unremoveDepartment(departmentId: string) {
    const department = this.departments.find(x => x.id === departmentId);
    if (department) {
      departmentsStore.SET_REMOVEING_IDS([...this.removingIds, departmentId]);
      const token = await TokenHelpers.getToken();
      await departmentsService.updateDepartment(
        { ...department, isRemoved: false },
        token
      );
      departmentsStore.SET_REMOVEING_IDS(
        this.removingIds.filter(x => x !== departmentId)
      );

      departmentsStore.SET_DEPARTMENTS(
        this.departments.map(d => {
          if (d.id === department.id) {
            return { ...department, isRemoved: false };
          }
          return d;
        })
      );
    }
  }
}
