import TalentHelpers from "./talentHelpers";
import NotificationMapper from "./notificationMapper";
import CompanyProfileHelper from "./CompanyProfileHelper";
import VisualJobOfferHelper from "./visualJobOfferHelper";
import TalentPoolHelpers from "./TalentPoolHelpers";
import FilterHelpers from "./filterHelpers";
import { mapRecruitmentListItem } from "./RecruitmentHelperFunctions";
import { $i18n } from "~/utils/i18n";
import {
  SingalRMessage,
  SignalRTrelloTalent,
  SignalRTalentMessage,
  SignalRTeamComment,
  SignalRRecruitment,
  SignalRNotification,
  NotificationTypeInternal,
  SignalROffice,
  SignalRDepartment,
  SignalRCoWorker,
  SignalRLane,
  SignalRCompanyVideo,
  SignalRYoutubeVideo,
  SignalRLibraryPhoto,
  ImageLibraryPhoto,
  SignalRCompanyTeamMember,
  RecruitmentTeamMember,
  SignalRWorkingCompanyProfile,
  SignalPublishedCompanyProfile,
  SignalRBaseInfo,
  SignalRVisual,
  SignalRForTalent,
  SignalRTalentJobOffer,
  SignalROpportunitySourcing,
  SignalRTalentSearchProfile,
  SignalRPoolMessage,
  SignalRTalentPoolMessage,
  SignalRPoolTeamMessage,
  SignalRTrelloTalentWithDaysLeft,
  TrelloBoardTalent,
  TalentConversationRaw,
  SignalRCompanyTalent,
  TalentMessage,
} from "~/models/types";
import {
  jobbOfferStore,
  notificationsStore,
  companyBaseStore,
  officesStore,
  departmentsStore,
  coWorkersStore,
  lanesStore,
  companyVideoStore,
  companyPhotosStore,
  companyProfileStore,
  jobbOfferVisualStore,
  talentProfileStore,
  talentPoolStore,
} from "~/store";
import TalentPoolService from "~/services/talentPoolService";

export default class SignalRHelper {
  static handleMessage(v: {
    message: SingalRMessage;
    lang: string;
    userId: string;
    companyId: string | null;
  }): boolean | PromiseLike<boolean> {
    console.log(v.message);

    switch (v.message.type) {
      case "Comment":
        return this.handleTeamComment(v.message);
      case "BaseInfo":
        return this.handleBaseInfo(v.message);
      case "Visual":
        return this.handleVisual(v.message);
      case "Talent":
        return this.handleTrelloTalent(v.message, v.userId);
      case "TalentWithDaysLeft":
        return this.handleTrelloTalentWithDaysLeft(v.message, v.userId);
      case "Message":
        return this.handleTalentMessage(v.message);
      case "CoWorker":
        return this.handleCoWorker({
          message: v.message,
          companyId: v.companyId,
        });
      case "Department":
        return this.handleDepartment({
          message: v.message,
          companyId: v.companyId,
        });
      case "Office":
        return this.handleOffice({
          message: v.message,
          companyId: v.companyId,
        });
      case "Recruitment":
        return this.handleRecruitment({
          message: v.message,
          companyId: v.companyId,
        });
      case "PublishedProfile":
        return this.handlePublishedCompanyProfile({
          message: v.message,
          companyId: v.companyId,
        });
      case "WorkingProfile":
        return this.handleWorkingCompanyProfile({
          message: v.message,
          companyId: v.companyId,
        });
      case "YoutubeVideo":
        return this.handleYoutubeVideo({
          message: v.message,
          companyId: v.companyId,
        });
      case "Video":
        return this.handleCompanyVideo({
          message: v.message,
          companyId: v.companyId,
        });
      case "User":
        return this.handleCompanyTeamMember({
          message: v.message,
          companyId: v.companyId,
        });
      case "Notification":
        return this.handleNotification({
          message: v.message,
          companyId: v.companyId,
        });
      case "Lane":
        return this.handleLane({
          lane: v.message,
          lang: v.lang,
          companyId: v.companyId,
        });
      case "Photo":
        return this.handleLibraryPhoto({
          message: v.message,
          companyId: v.companyId,
        });
      case "TalentPoolMessage":
        return this.handlePoolMessage({
          message: v.message,
          companyId: v.companyId,
        });
      case "OpportunitySourcing":
        return this.handleOpportunitySourcing(v.message);

      case "TalentPoolTeamComment":
        return this.handlePoolTeamMessage(v.message);
      case "TalentConversation":
        return this.handleTalentConversation({
          message: v.message,
          companyId: v.companyId,
        });

      case "CompanyTalent":
        return this.handleCompanyTalent({
          message: v.message,
          companyId: v.companyId,
        });
      case "GetVisualIngressAiHelpChunk":
        if (v.message.document.text === "[START]") {
          jobbOfferVisualStore.SET_INGRESS_BY_REC_ID({
            recId: v.message.document.recruitmentId,
            ingress: "",
          });
        } else if (v.message.document.text === "[END]") {
          return true;
        } else {
          jobbOfferVisualStore.SET_INGRESS_BY_REC_ID({
            recId: v.message.document.recruitmentId,
            ingress:
              (jobbOfferVisualStore.ingressByRecId[
                v.message.document.recruitmentId
              ] ?? "") + v.message.document.text ?? "",
          });
        }

        return true;
      case "GetVisualHeadingAiHelpChunk":
        if (v.message.document.text === "[START]") {
          jobbOfferVisualStore.SET_HEADING_BY_REC_ID({
            recId: v.message.document.recruitmentId,
            heading: "",
          });
        } else if (v.message.document.text === "[END]") {
          return true;
        } else {
          jobbOfferVisualStore.SET_HEADING_BY_REC_ID({
            recId: v.message.document.recruitmentId,
            heading: (
              (jobbOfferVisualStore.headingByRecId[
                v.message.document.recruitmentId
              ] ?? "") + (v.message.document.text ?? "")
            ).trimStart(),
          });
        }

        return true;
      case "GetVisualTasksAiHelpChunk":
        if (v.message.document.text === "[START]") {
          jobbOfferVisualStore.SET_TASK_IDS([]);
        } else if (v.message.document.text === "[END]") {
          return true;
        } else {
          jobbOfferVisualStore.SET_TASK_IDS(v.message.document.text.split("|"));
        }

        return true;
      case "GetChatMessageAIHelpChunk": {
        const conversationKey = TalentPoolHelpers.getTalentConversationKey(
          v.message.document
        );
        if (v.message.document.text === "[START]") {
          jobbOfferStore.SET_CHAT_MESSAGES_BY_CONVERSATION_KEY({
            ...jobbOfferStore.chatMessagesByTalentConversationKey,
            [conversationKey]: undefined,
          });
        } else if (v.message.document.text === "[END]") {
          return true;
        } else {
          jobbOfferStore.SET_CHAT_MESSAGES_BY_CONVERSATION_KEY({
            ...jobbOfferStore.chatMessagesByTalentConversationKey,
            [conversationKey]:
              (jobbOfferStore.chatMessagesByTalentConversationKey[
                conversationKey
              ] ?? "") + v.message.document.text,
          });
        }

        return true;
      }
    }
  }

  static handleTalentConversation(v: {
    message: {
      type: "TalentConversation";
      document: TalentConversationRaw;
    };
    companyId: string | null;
  }): boolean | PromiseLike<boolean> {
    if (
      !this.GetIsRightCompany({
        companyId: v.companyId,
        message: {
          companyId: v.message.document.companyId,
        },
      })
    ) {
      return true;
    }

    if (companyBaseStore.talentConversations.type === "Loaded") {
      companyBaseStore.SET_TALENT_CONVERSATIONS({
        type: "Loaded",
        conversations: SignalRHelper.getListWithNewItem({
          items: companyBaseStore.talentConversations.conversations,
          getId: x => x.id,
          newItem: {
            ...v.message.document,
            lastMessageDateTimeUtc: new Date(
              v.message.document.lastMessageDateTimeUtc
            ),
          },
        }),
      });
    } else {
      companyBaseStore.loadTalentConversationsUnreadCount({
        force: true,
      });
    }

    return true;
  }

  static handleCompanyTalent(v: {
    message: SignalRCompanyTalent;
    companyId: string | null;
  }): boolean | PromiseLike<boolean> {
    if (!this.GetIsRightCompany(v)) {
      return true;
    }

    SignalRHelper.reloadTalentIfWasAnonymousBefore(v);

    const comTalentsTtL = companyBaseStore.companyTalentsInternal;

    if (comTalentsTtL.type === "notFetched") {
      return true;
    }

    companyBaseStore.SET_COMPANY_TALENTS({
      ...comTalentsTtL,
      value: SignalRHelper.getListWithNewItem({
        items: comTalentsTtL.value ?? [],
        getId: x => x.talentId,
        newItem: {
          experienceLanguageIds: v.message.document.experienceLanguageIds,
          isHired: !!v.message.document.hiredTalentInfoObject,
          lang: v.message.document.lang,
          nonAnonymouseProfileDetails:
            v.message.document.nonAnonymouseProfileDetails,
          recruitmentsByRecId: v.message.document.recruitmentsByRecId,
          talentId: v.message.document.talentId,
          teamTailorObject: v.message.document.teamTailorObject,
          jobylonObject: v.message.document.jobylonObject,
        },
      }),
    });

    return true;
  }

  private static reloadTalentIfWasAnonymousBefore(v: {
    message: SignalRCompanyTalent;
    companyId: string | null;
  }) {
    const existingCompanyTalent = companyBaseStore.companyTalents.find(
      x => x.talentId === v.message.document.talentId
    );

    if (existingCompanyTalent) {
      if (
        existingCompanyTalent.nonAnonymouseProfileDetails === null &&
        v.message.document.nonAnonymouseProfileDetails !== null
      ) {
        const existingLoadedTalent =
          jobbOfferStore.loadedTalentProfilesByTalentId[
            v.message.document.talentId
          ];

        if (existingLoadedTalent) {
          jobbOfferStore.loadTalentProfileById({
            talentId: v.message.document.talentId,
            force: true,
          });
        }
      }
    }
  }

  static handleOpportunitySourcing(
    message: SignalROpportunitySourcing
  ): boolean | PromiseLike<boolean> {
    const youDidIt = this.getYouDidIt(
      jobbOfferStore.opportunitySourcing.etag,
      message.etag
    );
    if (
      jobbOfferStore.loadedOpportunitySourcing === message.recruitmentId &&
      !youDidIt
    ) {
      jobbOfferStore.loadOpportunitySourcing({
        recruitmentId: message.recruitmentId,
        refresh: true,
      });
    }

    return true;
  }

  static handlePoolTeamMessage(
    message: SignalRPoolTeamMessage
  ): boolean | PromiseLike<boolean> {
    const existingTalent = talentPoolStore.poolTeamComments.find(
      x => x.talentId === message.document.talentId
    );

    if (existingTalent?.type === "Loaded") {
      talentPoolStore.SET_POOL_TEAM_COMMENTS(
        SignalRHelper.getListWithNewItem({
          items: talentPoolStore.poolTeamComments,
          getId: x => x.talentId,
          newItem: {
            talentId: message.document.talentId,
            type: "Loaded",
            comments: SignalRHelper.getListWithNewItem({
              items: existingTalent.comments,
              getId: c => c.id,
              newItem: {
                ...message.document,
                date: new Date(message.document.date),
              },
            }),
          },
        })
      );
    }

    return true;
  }

  static handleMessageForTalent(
    v: SignalRForTalent
  ): boolean | PromiseLike<boolean> {
    console.log(v);
    switch (v.type) {
      case "JobOffer":
        return this.handleTalentJobOffer(v);
      case "Message":
        return this.handleNewMessageForTalent(v);
      case "TalentPoolMessage":
        return this.handleNewPoolMessageForTalent(v);
      case "SearchProfile":
        return this.handleTalentSearchProfile(v);
    }
  }

  static handleNewPoolMessageForTalent(
    v: SignalRTalentPoolMessage
  ): boolean | PromiseLike<boolean> {
    talentPoolStore.SET_POOL_MESSAGES_BY_COMPANY_ID({
      companyId: v.document.companyId,
      messages: this.getListWithNewItem({
        items:
          talentPoolStore.poolMessagesByCompanyId[v.document.companyId] ?? [],
        getId: x => x.id,
        newItem: TalentPoolService.mapToGetPoolMessagesDto(v.document),
      }),
    });

    return true;
  }

  static handleNewMessageForTalent(
    v: SignalRTalentMessage
  ): boolean | PromiseLike<boolean> {
    const loadedMessages = talentProfileStore.messagesByRecruitmentId[
      v.recruitmentId
    ] ?? {
      type: "NotFetched",
    };

    console.log({ loadedMessages });

    if (loadedMessages.type !== "loaded") {
      return true;
    }

    const message: TalentMessage = {
      ...v.document,
      date: new Date(v.document.date),
    };

    talentProfileStore.SET_MESSAGES_BY_RECRUITMENT_ID({
      recruitmentId: v.recruitmentId,
      messages: {
        type: "loaded",
        value: this.getListWithNewItem({
          items: loadedMessages.value,
          getId: x => x.id,
          newItem: message,
        }),
      },
    });
    return true;
  }

  static handleTalentJobOffer(
    v: SignalRTalentJobOffer
  ): boolean | PromiseLike<boolean> {
    const newJobOffer = TalentHelpers.mapToJobOfferToView(v.document);

    if (newJobOffer) {
      talentProfileStore.SET_JOB_OFFERS(
        this.getListWithNewItem({
          items: talentProfileStore.jobOffers,
          getId: x => x.recruitmentId,
          newItem: newJobOffer,
        })
      );
    }
    return true;
  }

  static handleTalentSearchProfile(
    _v: SignalRTalentSearchProfile
  ): boolean | PromiseLike<boolean> {
    return true;
  }

  private static getYouDidIt(
    oldEtag: string | undefined,
    newEtag: string
  ): boolean {
    const currentEtag = (oldEtag || "").replace(/"/g, "");
    const newEtagFixed = (newEtag || "").replace(/"/g, "");
    const youDidIt = currentEtag === newEtagFixed;

    return youDidIt;
  }

  static handleVisual(v: SignalRVisual): boolean | PromiseLike<boolean> {
    const youDidIt = this.getYouDidIt(jobbOfferVisualStore.visual.etag, v.etag);
    if (jobbOfferStore.loadedBaseInfoId === v.recruitmentId && !youDidIt) {
      jobbOfferVisualStore.SET_JOB_OFFER_VISUAL(
        VisualJobOfferHelper.getMappedVisual(v.document)
      );
    }

    return true;
  }

  static handleBaseInfo(v: SignalRBaseInfo): boolean | PromiseLike<boolean> {
    if (jobbOfferStore.loadedBaseInfoId === v.recruitmentId) {
      jobbOfferStore.SET_BASE_INFO({
        baseInfo: v.document,
        id: v.recruitmentId,
      });
    }

    return true;
  }

  static handlePublishedCompanyProfile(v: {
    message: SignalPublishedCompanyProfile;
    companyId: string | null;
  }): boolean | PromiseLike<boolean> {
    if (!this.GetIsRightCompany(v)) {
      return true;
    }
    if (v.message.document) {
      companyProfileStore.SET_PUBLIC_PROFILE(v.message.document);
    }
    return true;
  }

  static handleWorkingCompanyProfile(v: {
    message: SignalRWorkingCompanyProfile;
    companyId: string | null;
  }): boolean | PromiseLike<boolean> {
    if (!this.GetIsRightCompany(v)) {
      return true;
    }
    if (v.message.document) {
      companyProfileStore.setProfile(
        CompanyProfileHelper.getMappedProfile(v.message.document)
      );
    }

    return true;
  }

  static handleCompanyTeamMember(v: {
    message: SignalRCompanyTeamMember;
    companyId: string | null;
  }): boolean | PromiseLike<boolean> {
    if (!this.GetIsRightCompany(v)) {
      return true;
    }
    const newMember: RecruitmentTeamMember = {
      ...v.message.document,
      recruitmentIds: v.message.document.recruitmentIds || [],
      isRemoved: v.message.document.isRemoved || false,
    };

    jobbOfferStore.SET_COMPANY_TEAM_MEMBERS(
      this.getListWithNewItem({
        items: jobbOfferStore.companyMembers,
        getId: x => (x.email ?? "").trim().toLowerCase(),
        newItem: newMember,
      })
    );
    return true;
  }

  static handleLibraryPhoto(v: {
    message: SignalRLibraryPhoto;
    companyId: string | null;
  }): boolean | PromiseLike<boolean> {
    if (v.message.companyId) {
      if (
        !this.GetIsRightCompany({
          message: {
            companyId: v.message.companyId,
          },
          companyId: v.companyId,
        })
      ) {
        return true;
      }
    }

    if (!v.message.document.uniqueName) {
      return true;
    }
    const newPhoto: ImageLibraryPhoto = {
      deleted: v.message.document.deleted
        ? new Date(v.message.document.deleted)
        : null,
      fileName: v.message.document.fileName,
      loading: false,
      uniqueName: v.message.document.uniqueName,
      url: v.message.document.url,
    };

    companyPhotosStore.setPhotos(
      this.getListWithNewItem({
        items: companyPhotosStore._photos,
        getId: x => x.url,
        newItem: newPhoto,
      })
    );
    return true;
  }

  static handleYoutubeVideo(v: {
    message: SignalRYoutubeVideo;
    companyId: string | null;
  }): boolean | PromiseLike<boolean> {
    if (!this.GetIsRightCompany(v)) {
      return true;
    }
    companyVideoStore.SET_YOUTUBE_VIDEOS(
      this.getListWithNewItem({
        items: companyVideoStore.youtubeVideos,
        getId: x => x.url,
        newItem: v.message.document,
      })
    );
    return true;
  }

  static handlePoolMessage(v: {
    message: SignalRPoolMessage;
    companyId: string | null;
  }): boolean | PromiseLike<boolean> {
    if (!this.GetIsRightCompany(v)) {
      return true;
    }

    const hej = talentPoolStore.poolMessagesByTalentIdMap.get(
      v.message.document.talentId
    );

    if (hej?.type === "Loaded") {
      talentPoolStore.SET_POOL_MESSAGES_INTERNAL(
        this.getListWithNewItem({
          items: talentPoolStore.poolMessagesInternal,
          getId: x => x.talentId,
          newItem: {
            type: "Loaded",
            talentId: v.message.document.talentId,
            messages: this.getListWithNewItem({
              items: hej.messages,
              getId: x => x.id,
              newItem: TalentPoolService.mapToGetPoolMessagesDto(
                v.message.document
              ),
            }),
          },
        })
      );
    }

    return true;
  }

  static handleCompanyVideo(v: {
    message: SignalRCompanyVideo;
    companyId: string | null;
  }): boolean | PromiseLike<boolean> {
    if (!this.GetIsRightCompany(v)) {
      return true;
    }
    companyVideoStore.SET_COMPANY_VIDEOS(
      this.getListWithNewItem({
        items: companyVideoStore.companyVideosAll,
        getId: x => x.id,
        newItem: {
          ...v.message.document,
          progress: v.message.document.progress || 0,
          state: v.message.document.state || "Finished",
          isRemoved: v.message.document.isRemoved || false,
        },
      })
    );
    return true;
  }

  private static handleLane(v: {
    lane: SignalRLane;
    lang: string;
    companyId: string | null;
  }): boolean | PromiseLike<boolean> {
    lanesStore.loadLibraryLanes({
      lang: v.lang,
      reload: true,
    });

    return true;
  }

  private static GetIsRightCompany(v: {
    message: { companyId: string };
    companyId: string | null;
  }): boolean {
    return v.companyId === null || v.companyId === v.message.companyId;
  }

  private static handleCoWorker(v: {
    message: SignalRCoWorker;
    companyId: string | null;
  }): boolean | PromiseLike<boolean> {
    if (!this.GetIsRightCompany(v)) {
      return true;
    }
    const visualCopy = { ...jobbOfferVisualStore.visual };
    if (visualCopy.manager && visualCopy.manager.id === v.message.document.id) {
      visualCopy.manager = {
        ...v.message.document,
        interview: visualCopy.useSeparateInterview
          ? visualCopy.manager.interview
          : v.message.document.interview,
      };
    }

    visualCopy.coWorkers = visualCopy.coWorkers.map(cw => {
      if (cw.id === v.message.document.id) {
        return v.message.document;
      }
      return cw;
    });

    jobbOfferVisualStore.SET_JOB_OFFER_VISUAL(visualCopy);

    coWorkersStore.SET_COWORKERS(
      this.getListWithNewItem({
        items: coWorkersStore.coWorkers,
        getId: x => x.id,
        newItem: v.message.document,
      })
    );

    return true;
  }

  private static handleDepartment(v: {
    message: SignalRDepartment;
    companyId: string | null;
  }): boolean | PromiseLike<boolean> {
    if (!this.GetIsRightCompany(v)) {
      return true;
    }
    const visualCopy = { ...jobbOfferVisualStore.visual };
    if (
      visualCopy.department &&
      visualCopy.department.id === v.message.document.id
    ) {
      visualCopy.department = v.message.document;
    }

    jobbOfferVisualStore.SET_JOB_OFFER_VISUAL(visualCopy);

    departmentsStore.SET_DEPARTMENTS(
      this.getListWithNewItem({
        items: departmentsStore.departments,
        getId: x => x.id,
        newItem: v.message.document,
      })
    );

    return true;
  }

  private static handleOffice(v: {
    message: SignalROffice;
    companyId: string | null;
  }): boolean {
    if (!this.GetIsRightCompany(v)) {
      return true;
    }
    officesStore.SET_OFFICES(
      this.getListWithNewItem({
        items: officesStore.items,
        getId: x => x.id,
        newItem: v.message.document,
      })
    );

    return true;
  }

  private static async handleNotification(v: {
    message: SignalRNotification;
    companyId: string | null;
  }): Promise<boolean> {
    if (!v.message.document.isSeen) {
      companyBaseStore.loadNumberOfUnseenNotifications({ force: true });
    }
    if (!this.GetIsRightCompany(v)) {
      return true;
    }
    await jobbOfferStore.loadCompanyTeamMembers();

    await jobbOfferStore.loadRecruitmentList();

    const users = jobbOfferStore.companyMembers;

    const mapper = NotificationMapper.mapFromDbDto(
      users,
      jobbOfferStore.recruitmentList
    );

    const notification = mapper(v.message.document);

    companyBaseStore.SET_NOTIFICATIONS(
      SignalRHelper.getListWithNewItem({
        items: companyBaseStore.notifications,
        getId: x => x.id,
        newItem: notification,
      })
    );

    if (v.message.document.isSeen) {
      return true;
    }

    switch (notification.type) {
      case NotificationTypeInternal.TagOnRecruitment:
        notificationsStore.setNotificationMessage({
          message: $i18n
            .t("notificationTexts.TagOnRecruitment", {
              recruitmentTitle: notification.recruitmentTitle,
            })
            .toString(),
          notification,
        });
        return true;
      case NotificationTypeInternal.TagOnTalent:
        notificationsStore.setNotificationMessage({
          message: $i18n
            .t("notificationTexts.TagOnTalent", {
              talentName: notification.talentName,
            })
            .toString(),
          notification,
        });
        return true;
      case NotificationTypeInternal.SharedTalentVisited:
      case NotificationTypeInternal.PoolTalentInterested:
      case NotificationTypeInternal.NotAnsweredPoolMessage:
      case NotificationTypeInternal.UnhandledRecruitment:
        return true;
      case NotificationTypeInternal.TalentAccepted:
        notificationsStore.setNotificationMessage({
          message: $i18n
            .t("notificationTexts.TalentAccepted", {
              recruitmentTitle: notification.recruitmentTitle,
            })
            .toString(),
          notification,
        });
        return true;
      case NotificationTypeInternal.TalentDeclined:
        notificationsStore.setNotificationMessage({
          message: $i18n
            .t("notificationTexts.TalentDeclined", {
              recruitmentTitle: notification.recruitmentTitle,
            })
            .toString(),
          notification,
        });
        return true;
      case NotificationTypeInternal.TalentInterested:
        notificationsStore.setNotificationMessage({
          message: $i18n
            .t("notificationTexts.TalentInterested", {
              recruitmentTitle: notification.recruitmentTitle,
            })
            .toString(),
          notification,
        });
        return true;
      case NotificationTypeInternal.MessageFromTalent:
        notificationsStore.setNotificationMessage({
          message: $i18n
            .t("notificationTexts.MessageFromTalent", {
              recruitmentTitle: notification.recruitmentTitle,
            })
            .toString(),
          notification,
        });
        return true;
      case NotificationTypeInternal.PoolMessageFromTalent:
        notificationsStore.setNotificationMessage({
          message: $i18n
            .t("notificationTexts.PoolMessageFromTalent", {
              talentName: notification.talentName,
            })
            .toString(),
          notification,
        });
        return true;
      case NotificationTypeInternal.TalentPoolTeamComment:
        notificationsStore.setNotificationMessage({
          message: $i18n.t("notificationTexts.PoolTeamComment").toString(),
          notification,
        });
        return true;
      case NotificationTypeInternal.CommentOnSharedTalent:
        notificationsStore.setNotificationMessage({
          message: $i18n
            .t("notificationTexts.CommentOnSharedTalent", {
              recruitmentTitle: notification.recruitmentTitle,
            })
            .toString(),
          notification,
        });
        return true;
    }
  }

  private static handleTrelloTalent(
    v: SignalRTrelloTalent,
    userId: string
  ): boolean {
    const trelloBoardTalent = TalentHelpers.mapFromServerDto(
      { recruitmentTalent: v.document, daysLeftDto: { level: "Level0" } },
      jobbOfferStore.locations
    );
    return this.handleTrelloBoardTalentChanged({
      lastUpdatedBy: v.document.lastUpdatedBy,
      trelloBoardTalent,
      userId,
    });
  }

  private static handleTrelloTalentWithDaysLeft(
    v: SignalRTrelloTalentWithDaysLeft,
    userId: string
  ): boolean {
    const trelloBoardTalent = TalentHelpers.mapFromServerDto(
      v.document,
      jobbOfferStore.locations
    );
    return this.handleTrelloBoardTalentChanged({
      lastUpdatedBy: v.document.recruitmentTalent.lastUpdatedBy,
      trelloBoardTalent,
      userId,
    });
  }

  private static handleTrelloBoardTalentChanged(v: {
    trelloBoardTalent: TrelloBoardTalent;
    userId: string;
    lastUpdatedBy: string | null | undefined;
  }): boolean {
    jobbOfferStore.SET_LOADED_REC_TALENTS([
      {
        type: "Loaded",
        recruitmentId: v.trelloBoardTalent.recruitmentId,
        talentId: v.trelloBoardTalent.talentId,
        recTalent: v.trelloBoardTalent,
      },
    ]);
    const currentRecId = jobbOfferStore.loadedRecTalentsForId;

    if (currentRecId !== v.trelloBoardTalent.recruitmentId) {
      return true;
    }

    if (v.userId === v.lastUpdatedBy) {
      const currentRecTalent = jobbOfferStore.recruitmentTalentsUnmapped.find(
        x => x.talentId === v.trelloBoardTalent.talentId
      );

      if (currentRecTalent) {
        if (
          currentRecTalent.accepted === v.trelloBoardTalent.accepted &&
          currentRecTalent.declined === v.trelloBoardTalent.declined &&
          true
        ) {
          return true;
        }
      }
    }
    jobbOfferStore.SET_RECRUITMENT_TALENTS(
      this.getListWithNewItem({
        items: jobbOfferStore.recruitmentTalentsUnmapped,
        getId: x => x.talentId,
        newItem: v.trelloBoardTalent,
        addInBeginningOfArray: true,
      })
    );

    return true;
  }

  private static async handleTalentMessage(
    v: SignalRTalentMessage
  ): Promise<boolean> {
    await jobbOfferStore.loadCompanyTeamMembers();

    const loadedMessages = talentPoolStore.messagesByRecTalentMap.get(
      TalentPoolHelpers.getRecTalentKey(v)
    );

    if (loadedMessages?.type === "Loaded") {
      talentPoolStore.SET_MESSAGE_BY_REC_TALENT(
        SignalRHelper.getListWithNewItem({
          items: talentPoolStore.messagesByRecTalent,
          getId: x => TalentPoolHelpers.getRecTalentKey(x),
          newItem: {
            type: "Loaded",
            comments: SignalRHelper.getListWithNewItem({
              items: loadedMessages.comments,
              getId: x => x.id,
              newItem: {
                ...v.document,
                date: new Date(v.document.date),
              },
            }),
            recruitmentId: v.recruitmentId,
            talentId: v.talentId,
          },
        })
      );
    }

    return true;
  }

  private static async handleTeamComment(
    v: SignalRTeamComment
  ): Promise<boolean> {
    const currentRecId = jobbOfferStore.latestRecruitmentPageId;

    let sender:
      | {
          firstName: string;
          lastName: string;
          userId: string | null;
        }
      | null
      | undefined = null;

    await jobbOfferStore.loadCompanyTeamMembers();

    if (v.document.senderId === null) {
      if (v.document.senderEmail !== null) {
        sender = {
          firstName: v.document.senderEmail,
          lastName: "",
          userId: v.document.senderEmail,
        };
      }
    } else {
      sender = jobbOfferStore.companyMembers
        .map(x => ({
          ...x,
          firstName: x.firstName || x.email,
          lastName: x.lastName || "",
        }))
        .find(x => x.userId === v.document.senderId);
    }

    if (!sender || sender.userId === null) {
      return true;
    }

    if (v.talentId === null) {
      if (currentRecId !== v.recruitmentId) {
        return true;
      }

      jobbOfferStore.SET_TEAM_COMMENTS(
        this.getListWithNewItem({
          items: jobbOfferStore.teamComments,
          getId: x => x.id,
          newItem: {
            comment: v.document.comment,
            date: new Date(v.document.date),
            id: v.document.id,
            sharedToEmails: v.document.sharedToEmails ?? [],
            sender: {
              firstName: sender.firstName,
              lastName: sender.lastName,
              userId: sender.userId!,
            },
          },
        })
      );

      return true;
    } else {
      const loadedMessages = talentPoolStore.teamCommentsByRecTalentMap.get(
        TalentPoolHelpers.getRecTalentKey({
          recruitmentId: v.recruitmentId,
          talentId: v.talentId,
        })
      );

      if (loadedMessages?.type === "Loaded") {
        talentPoolStore.SET_TEAM_COMMENTS_BY_REC_TALENT(
          SignalRHelper.getListWithNewItem({
            items: talentPoolStore.teamCommentsByRecTalent,
            getId: x => TalentPoolHelpers.getRecTalentKey(x),
            newItem: {
              type: "Loaded",
              comments: SignalRHelper.getListWithNewItem({
                items: loadedMessages.comments,
                getId: x => x.id,
                newItem: {
                  ...v.document,
                  date: new Date(v.document.date),
                  sharedToEmails: v.document.sharedToEmails ?? [],
                  sender: FilterHelpers.getCommentSender({
                    c: v.document,
                    companyCommentSenders: jobbOfferStore.companyCommentSenders,
                  }),
                },
              }),
              recruitmentId: v.recruitmentId,
              talentId: v.talentId,
            },
          })
        );
      }

      return true;
    }
  }

  private static handleRecruitment(v: {
    message: SignalRRecruitment;
    companyId: string | null;
  }) {
    if (!this.GetIsRightCompany(v)) {
      return true;
    }

    const currentItem = jobbOfferStore.recruitmentList.find(
      x => x.id === v.message.document.id
    );

    const isStarted = (v: { startedDate: Date | string | null }) =>
      v.startedDate !== null;

    if (
      currentItem &&
      isStarted(currentItem) &&
      !isStarted(v.message.document)
    ) {
      return true;
    }

    jobbOfferStore.SET_RECRUITMENT_LIST(
      this.getListWithNewItem({
        items: jobbOfferStore.recruitmentList,
        getId: x => x.id,
        newItem: mapRecruitmentListItem(v.message.document),
      })
    );
    return true;
  }

  static getListWithNewItem<T>(v: {
    items: T[];
    newItem: T;
    getId: (v: T) => string;
    addInBeginningOfArray?: boolean;
  }): T[] {
    const itemOrNull = v.items.find(x => v.getId(x) === v.getId(v.newItem));
    return itemOrNull
      ? v.items.map(x => {
          if (v.getId(x) === v.getId(v.newItem)) {
            return v.newItem;
          }
          return x;
        })
      : v.addInBeginningOfArray
      ? [v.newItem, ...v.items]
      : [...v.items, v.newItem];
  }
}
