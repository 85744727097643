















import { Vue, Component, Prop } from "nuxt-property-decorator";

@Component
export default class GritifyLogo extends Vue {
  @Prop({
    validator: v => ["color", "black", "white"].includes(v),
    default: "color",
  })
  readonly color!: "color" | "black" | "white";

  get isColor() {
    return this.color === "color";
  }

  get isBlack() {
    return this.color === "black";
  }

  get isWhite() {
    return this.color === "white";
  }

  get logoWidth(): string {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return "86px";
      case "sm":
      case "md":
        return "103px";
      case "lg":
      case "xl":
        return "129px";
    }
  }
}
