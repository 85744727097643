







































/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */

import { Vue, Component } from "nuxt-property-decorator";

export interface ConfirmOptions {
  width?: number;
  zIndex?: number;
}

@Component
export default class Confirm extends Vue {
  dialog = false;
  reject: any = null;
  resolve: any = null;
  message: string | null = null;
  title: string | null = null;
  options: ConfirmOptions = {
    width: 290,
    zIndex: 200,
  };

  open(title: string, message: string, options?: ConfirmOptions) {
    this.dialog = true;
    this.title = title;
    this.message = message;
    this.options = { ...this.options, ...options };
    return new Promise<boolean>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  private agree() {
    this.resolve(true);
    this.dialog = false;
  }

  private cancel() {
    this.resolve(false);
    this.dialog = false;
  }
}
