var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"id":"grAppBar","color":"white","clipped-left":"","app":"","elevate-on-scroll":"","height":_vm.navBarHeight}},[_c('v-row',{staticClass:"app-bar-row",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center px-4 px-md-16",staticStyle:{"position":"relative"}},[(_vm.isOnStartPage)?[_c('v-spacer'),_vm._v(" "),_c('nuxt-link',{staticClass:"d-flex align-center",attrs:{"to":_vm.localePath({
                  name: 'index',
                })}},[(_vm.showAlternativeLogo)?_c('img',{attrs:{"height":"18.48","width":"24.64","src":"/alternative-logo.png"}}):_c('GritifyLogo')],1)]:_c('nuxt-link',{staticClass:"d-flex align-center",staticStyle:{"position":"absolute","left":"0px","padding":"inherit"},attrs:{"to":_vm.localePath({
                name: 'index',
              })}},[(_vm.showAlternativeLogo)?_c('img',{attrs:{"height":"18.48","width":"24.64","src":"/alternative-logo.png"}}):_c('GritifyLogo')],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(!_vm.isOnStartPage)?[_c('GrNavLinkDense',{attrs:{"link-object":{
                name: 'talentStart',
              }}},[_vm._v("\n              "+_vm._s(_vm.$t("För talanger"))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"px-3 pe-sm-5 pe-xl-7"}),_vm._v(" "),_c('GrNavLinkDense',{attrs:{"link-object":{
                name: 'companyStart',
              }}},[_vm._v("\n              "+_vm._s(_vm.$t("För företag"))+"\n            ")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(!_vm.isOnStartPage)?_c('div',{staticClass:"d-sm-none px-2"}):_vm._e()]:_vm._e(),_vm._v(" "),_c('GrNavLinkDense',{staticStyle:{"position":"absolute","right":"0px","padding":"inherit"},attrs:{"link-object":{
              name: 'talent',
            }}},[_vm._v("\n            "+_vm._s(_vm.$t("Logga in"))+"\n          ")])],2)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }