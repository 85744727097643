






import { Vue, Component, Prop } from "nuxt-property-decorator";

@Component
export default class GrHeadline extends Vue {
  @Prop({
    validator: v => ["mega", "large", "medium", "small", "mini"].includes(v),
    required: true,
  })
  readonly type!: "mega" | "large" | "medium" | "small" | "mini";

  get styleObj() {
    return {
      fontSize: `${this.fontSize}px`,
      letterSpacing: `${this.letterSpacing}px`,
      lineHeight: `${this.lineHeight}px`,
    };
  }

  get fontSize(): number {
    switch (this.type) {
      case "mega":
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 34;
          case "sm":
            return 50;
          case "md":
            return 72;
          case "lg":
          case "xl":
            return 100;
          default:
            return 100;
        }
      case "large":
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 28;
          case "sm":
            return 36;
          case "md":
            return 50;
          case "lg":
          case "xl":
            return 60;
          default:
            return 60;
        }
      case "medium":
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 20;
          case "sm":
          case "md":
            return 28;
          case "lg":
          case "xl":
            return 32;
          default:
            return 32;
        }
      case "small":
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 16;
          case "sm":
          case "md":
            return 20;
          case "lg":
          case "xl":
            return 24;
          default:
            return 24;
        }
      case "mini":
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
          case "sm":
          case "md":
            return 14;
          case "lg":
          case "xl":
            return 18;
          default:
            return 18;
        }
    }
  }

  get letterSpacing(): number {
    const breakpoint = this.$vuetify.breakpoint.name;

    switch (this.type) {
      case "mega":
        switch (breakpoint) {
          case "xs":
          case "sm":
          case "md":
          case "lg":
          case "xl":
            return 0;
          default:
            return 0;
        }
      case "large":
        switch (breakpoint) {
          case "xs":
          case "sm":
          case "md":
          case "lg":
          case "xl":
            return 0;
          default:
            return 0;
        }
      case "medium":
        switch (breakpoint) {
          case "xs":
          case "sm":
          case "md":
          case "lg":
          case "xl":
            return 0;
          default:
            return 0;
        }
      case "small":
        switch (breakpoint) {
          case "xs":
          case "sm":
          case "md":
          case "lg":
          case "xl":
            return 0;
          default:
            return 0;
        }
      case "mini":
        switch (breakpoint) {
          case "xs":
          case "sm":
          case "md":
            return 0.2;
          case "lg":
          case "xl":
            return 0.4;
          default:
            return 0.4;
        }
    }
  }

  get lineHeight(): number {
    const breakpoint = this.$vuetify.breakpoint.name;

    switch (this.type) {
      case "mega":
        switch (breakpoint) {
          case "xs":
            return 40;
          case "sm":
            return 60;
          case "md":
            return 80;
          case "lg":
          case "xl":
            return 110;
          default:
            return 110;
        }
      case "large":
        switch (breakpoint) {
          case "xs":
            return 32;
          case "sm":
            return 52;
          case "md":
            return 60;
          case "lg":
          case "xl":
            return 80;
          default:
            return 80;
        }
      case "medium":
        switch (breakpoint) {
          case "xs":
            return 24;
          case "sm":
          case "md":
            return 36;
          case "lg":
          case "xl":
            return 40;
          default:
            return 40;
        }
      case "small":
        switch (breakpoint) {
          case "xs":
            return 20;
          case "sm":
          case "md":
            return 24;
          case "lg":
          case "xl":
            return 30;
          default:
            return 30;
        }
      case "mini":
        switch (breakpoint) {
          case "xs":
          case "sm":
          case "md":
            return 20;
          case "lg":
          case "xl":
            return 24;
          default:
            return 24;
        }
    }

    // return 20;
  }
}
