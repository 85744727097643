declare let $crisp: {
  push: (v: any) => void;
} | null;

export default class CrispHelper {
  static hideChat() {
    $crisp?.push(["do", "chat:hide"]);
  }

  static showChat() {
    if (!canShowChat()) return;
    $crisp?.push(["do", "chat:show"]);
  }
}

export const canShowChat = () => {
  return true;
  // return process.env.ENVIRONMENT === "Production";
};
