























import { Vue, Component, Prop } from "nuxt-property-decorator";
import { PoolTalentInterestedNotification } from "~/models/types";
import { authStore } from "~/store";

@Component({
  components: {},
  layout: "companyLayout",
})
export default class PoolTalentInterestedNotificationListItem extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: PoolTalentInterestedNotification;

  get talentName(): string {
    return this.item.talentName;
  }

  get companyId() {
    return authStore.companyId!;
  }

  get talentLink(): string {
    return this.localePath({
      name: "company-companyId-talentPool",
      params: {
        companyId: this.companyId,
      },
      query: {
        talentId: this.item.talentId,
      },
    });
  }
}
