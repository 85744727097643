import { LaneType, LaneForLibrary } from "~/models/types";

export default class LaneHelper {
  static getLaneWithSortedRemoves(
    incomingLanes: LaneForLibrary[]
  ): LaneForLibrary[] {
    const newLanes = incomingLanes;

    const lanesWithNewOrder = [
      ...newLanes.filter(l => !l.removed && l.laneType !== LaneType.Hired),
      ...newLanes.filter(l => !!l.removed),
      ...newLanes.filter(l => l.laneType === LaneType.Hired),
    ];

    return lanesWithNewOrder.map((l, index) => ({
      ...l,
      initialSortOrder: (index + 1) * 100,
    }));
  }

  static getRealLaneId(laneId: string | null): string | null {
    const fakeLaneIds = ["fish", "review", "inbox"];

    return laneId && fakeLaneIds.includes(laneId) ? null : laneId;
  }
}
