import { Middleware } from "@nuxt/types";
import { talentProfileStore } from "~/store";

// eslint-disable-next-line space-before-function-paren
const myMiddleware: Middleware = async ({ app, redirect }) => {
  await talentProfileStore.loadTalentProfile(app.i18n.locale);
  if (!talentProfileStore.profile.isDone) {
    return redirect(
      `${
        app.i18n.locale === "sv" ? "" : "/" + app.i18n.locale
      }/talent/createProfile`
    );
  }
};

export default myMiddleware;
