














































import { Vue, Component, Emit } from "nuxt-property-decorator";
import { authStore, notificationsStore } from "../../store";
import { NotificationItem, SnackBarMessageType } from "../../models/types";

@Component
export default class SuccessErrorSnackbar extends Vue {
  get message(): string {
    return notificationsStore.message || this.defaultMessage;
  }

  get type(): SnackBarMessageType {
    return notificationsStore.type;
  }

  get style() {
    return {
      color: this.textColor,
    };
  }

  @Emit("showNotifications")
  showNotificationsEmit() {}

  get textColor(): string {
    switch (this.type) {
      case "success":
      case "notification":
      case "error":
        return "white";
      case "warning":
        return "black";
    }
  }

  get color(): string {
    switch (this.type) {
      case "success":
      case "notification":
        return "success";
      case "error":
        return "error";
      case "warning":
        return "warning";
    }
  }

  get currentNotification(): NotificationItem | null {
    return notificationsStore.currentNotification;
  }

  get showNotificationLink(): boolean {
    return this.type === "notification" && !!this.notificationRoutePath;
  }

  get companyId() {
    return authStore.companyId!;
  }

  get notificationRoutePath(): string | null {
    return this.localePath({
      name: "company-companyId-notifications",
      params: { companyId: this.companyId },
    });
  }

  goToNotifications() {
    this.showDialog = false;
    // if (this.notificationRoutePath) {
    //   this.$router.push(this.notificationRoutePath);
    // }
    this.showNotificationsEmit();
  }

  get defaultMessage(): string {
    switch (this.type) {
      case "notification":
        return this.$t("Ny avisering").toString();
      case "success":
        return this.$t("Klart!").toString();
      case "error":
        return this.$t("Något gick fel! Försök igen lite senare.").toString();
      case "warning":
        return this.$t("Info!").toString();
    }
  }

  get showDialog(): boolean {
    return notificationsStore.showSnackbar;
  }

  set showDialog(v: boolean) {
    notificationsStore.SET_SHOW_SNACKBAR(v);
  }
}
