
































import { Vue, Component, Prop } from "nuxt-property-decorator";
import { ListObject } from "~/models/ListObject";
import { NotificationItem, NotificationTypeInternal } from "~/models/types";
import { authStore, jobbOfferStore } from "~/store";

@Component({
  components: {},
  layout: "companyLayout",
})
export default class UnhandledRecruitmentNotificationItem extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: NotificationItem & {
    type: NotificationTypeInternal.UnhandledRecruitment;
  };

  get recruitmentTitle(): string {
    const rec = jobbOfferStore.recruitmentList.find(
      r => r.id === this.item.recruitmentId
    );

    if (!rec) {
      return "";
    }
    return rec.title;
  }

  get companyId() {
    return authStore.companyId!;
  }

  get linkToRecruitment(): string {
    return this.localePath({
      name: "company-companyId-recruitments-recruitmentId",
      params: {
        recruitmentId: this.item.recruitmentId,
        companyId: this.companyId,
      },
    });
  }

  get talents() {
    return this.item.unhandledTalents;
  }

  getLinkToTalent(t: ListObject): string {
    return this.localePath({
      name: "company-companyId-recruitments-recruitmentId-candidate-talentId",
      params: {
        talentId: t.id,
        recruitmentId: this.item.recruitmentId,
        companyId: this.companyId,
      },
    });
  }
}
