import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { CompanyInterestObject } from "~/models/types";
import PublicApisService from "~/services/publicApisService";
import { publicDataStore } from "~/utils/store-accessor";

@Module({
  name: "publicData",
  stateFactory: true,
  namespaced: true,
})
export default class PublicDataStore extends VuexModule {
  appliedData: CompanyInterestObject | null = null;

  @Mutation
  SET_APPLIED_DATA(v: CompanyInterestObject | null) {
    this.appliedData = v;
  }

  @Action
  async applyForCompanyAccount(v: {
    companyInterestObject: CompanyInterestObject;
  }): Promise<string> {
    const id = await PublicApisService.upsertCompanyInterest({
      companyInterestObject: v.companyInterestObject,
    });

    publicDataStore.SET_APPLIED_DATA(v.companyInterestObject);

    return id;
  }
}
