






































import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class GritifyAILogo extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly column!: boolean;
}
