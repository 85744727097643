import EmploymentHelper from "./EmploymentHelper";
import {
  EducationSaved,
  EducationDegrees,
  LastVisitedType,
  LastVisitedItemToAdd,
  RecruitmentTeamMemberType,
  EmploymentPeriod,
  DeclineFollowUpActionType,
} from "~/models/types";

export default class SortHelper {
  static getLastVisitIsEqual(
    a: LastVisitedItemToAdd,
    b: LastVisitedItemToAdd
  ): boolean {
    if (a.visitType !== b.visitType) {
      return false;
    }
    switch (a.visitType) {
      case LastVisitedType.Sourcing:
      case LastVisitedType.Visual:
      case LastVisitedType.TrelloBoard:
      case LastVisitedType.OpportunitySourcing:
        return (
          a.visitType === b.visitType && a.recruitmentId === b.recruitmentId
        );
      case LastVisitedType.Media:
      case LastVisitedType.MyAccount:
      case LastVisitedType.Lanes:
      case LastVisitedType.RecruitmentList:
      case LastVisitedType.Settings:
      case LastVisitedType.CompanyProfile:
      case LastVisitedType.Offices:
      case LastVisitedType.Departments:
      case LastVisitedType.CoWorkers:
      case LastVisitedType.TalentPool:
      case LastVisitedType.Perks:
        return a.visitType === b.visitType;
      case LastVisitedType.Talent:
        return (
          a.visitType === b.visitType &&
          a.recruitmentId === b.recruitmentId &&
          a.talentId === b.talentId
        );
    }
  }

  static getMostFrequentLanguageIds(): string[] {
    return [
      "5035c3d7-77d2-43ff-9c35-d979865b6ff8",
      "b5a5356a-e1f3-4772-9c20-b49acc5a4a92",
      "ab3bca31-842c-4d89-90ca-056b4665ebb9",
      "d1fea28a-cef3-4907-b291-2094b789d8c6",
      "94dabb95-4619-4a37-8d27-bf382a623f76",
      "6672bd93-918b-4e0d-9d64-fa10ab906b66",
      "dade1ffc-e7a1-4943-8676-723c619ae3a6",
      "3d13e948-89b1-4bc9-acdd-45892cdce158",
      "99f3efab-5097-43b8-9ab8-6ed630f60d0a",
      "5e5d84d3-4382-419b-9346-75717433e32c",
    ];
  }

  static sortTalentsForSearch<
    T extends {
      numberOfMatches: number;
      matchingRequirements: any[];
      latestEmploymentRoleIds: string[];
      numberOfRolePreferences: number;
    }
  >(talents: T[], roleId: string | null): T[] {
    return this.sortItemsBy(talents, [
      { sortBy: t => t.numberOfMatches, desc: true },
      { sortBy: t => t.matchingRequirements.length, desc: true },
      {
        sortBy: t => !!roleId && t.latestEmploymentRoleIds.includes(roleId),
        desc: true,
      },
      { sortBy: t => t.numberOfRolePreferences !== 0, desc: true },
      { sortBy: t => t.numberOfRolePreferences },
    ]);
  }

  static getSortedTextsFromIds<T extends { text: string }>(
    ids: string[] | null,
    itemsById: { [key: string]: T }
  ): string[] {
    return (ids || []).reduce((acc: string[], id) => {
      const item = itemsById[id];
      if (!item) {
        return acc;
      }
      const nextIndex = acc.findIndex(i => item.text < i);
      const index = nextIndex > -1 ? nextIndex : acc.length;
      acc.splice(index, 0, item.text);
      return acc;
    }, []);
  }

  static sortAndMap<T, Y>(arr: T[], mapFunc: (x: T) => Y): Y[] {
    return arr.reduce((acc: Y[], item) => {
      const nextIndex = acc.findIndex(i => mapFunc(item) < i);
      const index = nextIndex > -1 ? nextIndex : acc.length;
      acc.splice(index, 0, mapFunc(item));
      return acc;
    }, []);
  }

  static moveDown<T extends { id: string }>(item: T, arr: T[]): T[] {
    const itemToMove = arr.find(f => f.id === item.id);
    if (itemToMove) {
      const index = arr.indexOf(itemToMove);
      if (index !== arr.length - 1) {
        return SortHelper.arrayMove(arr, index, index + 1);
      }
    }
    return arr;
  }

  static moveUp<T extends { id: string }>(item: T, arr: T[]) {
    const itemToMove = arr.find(f => f.id === item.id);
    if (itemToMove) {
      const index = arr.indexOf(itemToMove);
      if (index !== 0) {
        return SortHelper.arrayMove(arr, index, index - 1);
      }
    }
    return arr;
  }

  static arrayMove<T>(
    arr: (T | undefined)[],
    oldIndex: number,
    newIndex: number
  ): T[] {
    while (oldIndex < 0) {
      oldIndex += arr.length;
    }
    while (newIndex < 0) {
      newIndex += arr.length;
    }
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length;
      while (k-- + 1) {
        arr.push(undefined);
      }
    }

    const isType = (x: T | undefined): x is T => {
      return x !== undefined;
    };

    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr.filter(isType);
  }

  static getEducationDegreeWithHighestDegree(
    educations: EducationSaved[]
  ): EducationDegrees {
    const education = [...educations].sort(
      (a, b) =>
        SortHelper.getDegreePrio(b.degree) - SortHelper.getDegreePrio(a.degree)
    )[0];

    return (education && education.degree) || EducationDegrees.NoDegree;
  }

  public static getEducationWithHighestDegree(
    educations: EducationSaved[]
  ): EducationSaved | null {
    const education = [...educations].sort(
      (a, b) =>
        SortHelper.getDegreePrio(b.degree) - SortHelper.getDegreePrio(a.degree)
    )[0];

    return education || null;
  }

  static getDegreePrio(degree: EducationDegrees): number {
    switch (degree) {
      case EducationDegrees.NoDegree:
        return 1;
      case EducationDegrees.HigherVocational:
        return 2;
      case EducationDegrees.AdvancedHigherVocational:
        return 3;
      case EducationDegrees.UniversityDiploma:
        return 4;
      case EducationDegrees.Bachelor:
        return 5;
      case EducationDegrees.Master1Year:
        return 6;
      case EducationDegrees.Master2Year:
        return 7;
      case EducationDegrees.Licentiate:
        return 8;
      case EducationDegrees.Doctorate:
        return 9;
    }
  }

  static sortItemsBy<T>(
    items: T[],
    getPropFunctions: Array<{
      sortBy: (v: T) => string | number | boolean | (Date | null);
      desc?: boolean;
    }> = []
  ): T[] {
    return [...items].sort((a, b) => {
      for (let index = 0; index < getPropFunctions.length; index++) {
        const x = getPropFunctions[index]!;
        const aProp = x.sortBy(a);
        const bProp = x.sortBy(b);
        if (aProp === null || bProp === null) {
          return aProp === bProp
            ? 0
            : aProp === null
            ? !x.desc
              ? -1
              : 1
            : !x.desc
            ? 1
            : -1;
        }

        if (typeof aProp === "string" && typeof bProp === "string") {
          if (aProp.toLowerCase() < bProp.toLowerCase()) {
            return !x.desc ? -1 : 1;
          }
          if (aProp.toLowerCase() > bProp.toLowerCase()) {
            return !x.desc ? 1 : -1;
          }
        } else {
          if (aProp < bProp) {
            return !x.desc ? -1 : 1;
          }
          if (aProp > bProp) {
            return !x.desc ? 1 : -1;
          }
        }
      }

      return 0;
    });
  }

  static byLatestEmployment():
    | ((a: EmploymentPeriod, b: EmploymentPeriod) => number)
    | undefined {
    return (a: EmploymentPeriod, b: EmploymentPeriod) =>
      this.byLatestEmploymentWithNow(a, b, new Date());
  }

  static getSortOrderInRecord<T extends string | number | symbol>(v: {
    record: Record<T, T>;
    type: T;
  }) {
    return (Object.values(v.record) as T[]).indexOf(v.type) + 1;
  }

  static getSortOrderForDeclineFollowupAction(
    v: DeclineFollowUpActionType
  ): number {
    const sortOrder: Record<
      DeclineFollowUpActionType,
      DeclineFollowUpActionType
    > = {
      [DeclineFollowUpActionType.TalentPool]:
        DeclineFollowUpActionType.TalentPool,
      [DeclineFollowUpActionType.InviteFriend]:
        DeclineFollowUpActionType.InviteFriend,
      [DeclineFollowUpActionType.UpdateSalary]:
        DeclineFollowUpActionType.UpdateSalary,
      [DeclineFollowUpActionType.RejectCompany]:
        DeclineFollowUpActionType.RejectCompany,
      [DeclineFollowUpActionType.UpdateProfileExperience]:
        DeclineFollowUpActionType.UpdateProfileExperience,
    };

    return this.getSortOrderInRecord({
      record: sortOrder,
      type: v,
    });
  }

  static byLatestEmploymentWithNow(
    a: EmploymentPeriod,
    b: EmploymentPeriod,
    now: Date
  ): number {
    if (
      [a, b].some(x => x.toYear === null) &&
      ![a, b].every(x => x.toYear === null)
    ) {
      return a.toYear === null ? -1 : 1;
    }
    const aMonths = EmploymentHelper.MapToPeriodInMonths(a, now);
    const bMonths = EmploymentHelper.MapToPeriodInMonths(b, now);

    const fromDiff = bMonths.fromInMonths - aMonths.fromInMonths;

    if (fromDiff !== 0) {
      return fromDiff;
    } else {
      return bMonths.toInMonths - aMonths.toInMonths;
    }
  }

  static getMonthsFromDateString(str: string | null, now: Date): number {
    const regex = /([0-9]){4}-([0-9]){2}/g;
    if (!str || !regex.test(str)) {
      const yearMonths = now.getFullYear() * 12;
      const months = now.getMonth() + 1;
      return yearMonths + months;
    } else {
      const yearMonths = parseInt(str.substr(0, 4)) * 12;
      const months = parseInt(str.substr(5, 2));
      return yearMonths + months;
    }
  }

  static getDateFromString(str: string | null): Date | null {
    const regex = /([0-9]){4}-([0-9]){2}/g;
    if (!str || !regex.test(str)) {
      return null;
    } else {
      const yearInt = parseInt(str.substr(0, 4));
      const monthInt = parseInt(str.substr(5, 2));
      return new Date(yearInt, monthInt, 1);
    }
  }

  static getAccessLevel(v: RecruitmentTeamMemberType): number {
    const accessLevels: {
      [key in RecruitmentTeamMemberType]: number;
    } = {
      Restricted: 1,
      SemiFull: 2,
      Full: 3,
      SuperAdmin: 4,
    };

    return accessLevels[v];
  }
}
