import {
  NotificationItem,
  RecruitmentTeamMember,
  GetNotificationsDto,
  TagLocation,
  NotificationTypeInternal,
  RecruitmentListItem,
  NotificationType,
} from "~/models/types";

export default class NotificationMapper {
  private static getTagNotification(
    n: GetNotificationsDto,
    users: RecruitmentTeamMember[],
    recruitmentList: RecruitmentListItem[]
  ): NotificationItem {
    const user = users.find(u => u.userId === n.senderId)!;

    const sender = `${user.firstName} ${user.lastName}`;

    const recruitment: {
      id: string;
      title: string;
    } = recruitmentList.find(r => r.id === n.recruitmentId) || {
      id: "123",
      title: "Okänt jobb",
    };

    switch (n.locationType) {
      case TagLocation.RequitmentProcess:
        return {
          commentHtml: n.text || "",
          recruitmentId: recruitment.id,
          recruitmentTitle: recruitment.title,
          sender,
          type: NotificationTypeInternal.TagOnRecruitment,
          date: new Date(n.date),
          isRead: n.isRead,
          isSeen: n.isSeen,
          id: n.id,
        };
      case TagLocation.Talent:
        return {
          type: NotificationTypeInternal.TagOnTalent,
          commentHtml: n.text || "",
          recruitmentId: recruitment.id,
          recruitmentTitle: recruitment.title,
          sender,
          talentId: n.talentId || "123",
          talentName: n.talentName || "Okänd kandidat",
          date: new Date(n.date),
          isRead: n.isRead,
          isSeen: n.isSeen,
          id: n.id,
        };
    }
  }

  private static getTalentAcceptedNotification(
    n: GetNotificationsDto,
    recruitmentList: RecruitmentListItem[]
  ): NotificationItem {
    const recruitment: {
      id: string;
      title: string;
    } = recruitmentList.find(r => r.id === n.recruitmentId) || {
      id: "123",
      title: "Okänt jobb",
    };

    return {
      type: NotificationTypeInternal.TalentAccepted,
      recruitmentId: recruitment.id,
      recruitmentTitle: recruitment.title,
      talentId: n.talentId || "123",
      talentName: n.talentName || "Okänd kandidat",
      date: new Date(n.date),
      isRead: n.isRead,
      isSeen: n.isSeen,
      id: n.id,
    };
  }

  private static getTalentInterestedNotification(
    n: GetNotificationsDto,
    recruitmentList: RecruitmentListItem[]
  ): NotificationItem {
    const recruitment: {
      id: string;
      title: string;
    } = recruitmentList.find(r => r.id === n.recruitmentId) || {
      id: "123",
      title: "Okänt jobb",
    };

    return {
      type: NotificationTypeInternal.TalentInterested,
      recruitmentId: recruitment.id,
      recruitmentTitle: recruitment.title,
      talentId: n.talentId || "123",
      talentName: n.talentName || "Okänd kandidat",
      date: new Date(n.date),
      isRead: n.isRead,
      isSeen: n.isSeen,
      id: n.id,
    };
  }

  private static getTalentDeclinedNotification(
    n: GetNotificationsDto,
    recruitmentList: RecruitmentListItem[]
  ): NotificationItem {
    const recruitment: {
      id: string;
      title: string;
    } = recruitmentList.find(r => r.id === n.recruitmentId) || {
      id: "123",
      title: "Okänt jobb",
    };

    return {
      type: NotificationTypeInternal.TalentDeclined,
      recruitmentId: recruitment.id,
      recruitmentTitle: recruitment.title,
      talentId: n.talentId || "123",
      talentName: n.talentName || "Okänd kandidat",
      date: new Date(n.date),
      isRead: n.isRead,
      isSeen: n.isSeen,
      id: n.id,
    };
  }

  private static getSharedTalentVisitedNotification(
    n: GetNotificationsDto,
    recruitmentList: RecruitmentListItem[]
  ): NotificationItem {
    const recruitment: {
      id: string;
      title: string;
    } = recruitmentList.find(r => r.id === n.recruitmentId) || {
      id: "123",
      title: "Okänt jobb",
    };

    return {
      type: NotificationTypeInternal.SharedTalentVisited,
      recruitmentId: recruitment.id,
      recruitmentTitle: recruitment.title,
      talentId: n.talentId || "123",
      talentName: n.talentName || "Okänd kandidat",
      date: new Date(n.date),
      email: n.email || "okänd epostadress",
      isRead: n.isRead,
      isSeen: n.isSeen,
      id: n.id,
    };
  }

  public static mapFromDbDto(
    users: RecruitmentTeamMember[],
    recruitmentList: RecruitmentListItem[]
  ): (notifications: GetNotificationsDto) => NotificationItem {
    return n => {
      switch (n.notificationType) {
        case NotificationType.Tag:
          return this.getTagNotification(n, users, recruitmentList);
        case NotificationType.TalentAccepted:
          return this.getTalentAcceptedNotification(n, recruitmentList);
        case NotificationType.TalentInterested:
          return this.getTalentInterestedNotification(n, recruitmentList);
        case NotificationType.TalentDeclined:
          return this.getTalentDeclinedNotification(n, recruitmentList);
        case NotificationType.SharedTalentVisited:
          return this.getSharedTalentVisitedNotification(n, recruitmentList);
        case NotificationType.MessageFromTalent:
          return this.getMessageFromTalentNotification(n, recruitmentList);
        case NotificationType.UnhandledRecruitment:
          return {
            type: NotificationTypeInternal.UnhandledRecruitment,
            companyId: n.companyId,
            date: new Date(n.date),
            recruitmentId: n.recruitmentId!,
            unhandledTalents: n.unhandledTalents,
            userId: n.userId,
            id: n.id,
            isRead: n.isRead,
            isSeen: n.isSeen,
          };
        case NotificationType.TalentPoolTeamComment:
          return {
            type: NotificationTypeInternal.TalentPoolTeamComment,
            date: new Date(n.date),
            commentText: n.text!,
            senderId: n.senderId!,
            talentId: n.talentId!,
            talentName: n.talentName!,
            isRead: n.isRead,
            isSeen: n.isSeen,
            id: n.id,
          };
        case NotificationType.PoolMessageFromTalent:
          return {
            type: NotificationTypeInternal.PoolMessageFromTalent,
            date: new Date(n.date),
            talentId: n.talentId ?? "123",
            talentName: n.talentName ?? "Okänd kandidat",
            message: n.text ?? "",
            isRead: n.isRead,
            isSeen: n.isSeen,
            id: n.id,
          };
        case NotificationType.NotAnsweredPoolMessage:
          return {
            type: NotificationTypeInternal.NotAnsweredPoolMessage,
            date: new Date(n.date),
            talentId: n.talentId ?? "123",
            talentName: n.talentName ?? "Okänd kandidat",
            message: n.text ?? "",
            isRead: n.isRead,
            isSeen: n.isSeen,
            id: n.id,
          };
        case NotificationType.PoolTalentInterested:
          return {
            type: NotificationTypeInternal.PoolTalentInterested,
            date: new Date(n.date),
            talentId: n.talentId ?? "123",
            talentName: n.talentName ?? "Okänd kandidat",
            recruitmentId: n.recruitmentId ?? "rec123",
            isRead: n.isRead,
            isSeen: n.isSeen,
            id: n.id,
          };
        case NotificationType.CommentOnSharedTalent: {
          const recruitment: {
            id: string;
            title: string;
          } = recruitmentList.find(r => r.id === n.recruitmentId) || {
            id: "123",
            title: "Okänt jobb",
          };
          const result: NotificationItem = {
            ...n,
            type: NotificationTypeInternal.CommentOnSharedTalent,
            recruitmentTitle: recruitment.title,
            recruitmentId: recruitment.id,
            talentId: n.talentId ?? "123",
            talentName: n.talentName ?? "Okänd kandidat",
            text: n.text ?? "",
            date: new Date(n.date),
            email: n.email ?? "",
          };
          return result;
        }
      }
    };
  }

  static getMessageFromTalentNotification(
    n: GetNotificationsDto,
    recruitmentList: RecruitmentListItem[]
  ): NotificationItem {
    const recruitment: {
      id: string;
      title: string;
    } = recruitmentList.find(r => r.id === n.recruitmentId) || {
      id: "123",
      title: "Okänt jobb",
    };

    return {
      type: NotificationTypeInternal.MessageFromTalent,
      recruitmentId: recruitment.id,
      recruitmentTitle: recruitment.title,
      talentId: n.talentId || "123",
      talentName: n.talentName || "Okänd kandidat",
      date: new Date(n.date),
      text: n.text || "",
      isRead: n.isRead,
      isSeen: n.isSeen,
      id: n.id,
    };
  }
}
