import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import companyVideoService from "~/services/companyVideoService";
import { companyVideoStore } from "~/utils/store-accessor";
import {
  CompanyVideoProgress,
  CompanyVideoDTO,
  YoutubeVideoDto,
} from "~/models/types";
import TokenHelpers from "~/helpers/tokenHelpers";

@Module({
  name: "companyVideo",
  stateFactory: true,
  namespaced: true,
})
export default class CompanyVideo extends VuexModule {
  videoProgress: CompanyVideoProgress = {
    progress: 0,
    state: "NoVideo",
    forId: null,
  };

  companyVideosAll: CompanyVideoDTO[] = [];

  youtubeVideos: YoutubeVideoDto[] = [];

  get companyVideos(): CompanyVideoDTO[] {
    return this.companyVideosAll.filter(vid => !vid.isRemoved);
  }

  @Mutation
  SET_YOUTUBE_VIDEOS(v: YoutubeVideoDto[]) {
    const distinctUrls = [...new Set(v.map(x => x.url))];
    this.youtubeVideos = distinctUrls.map(url => v.find(x => x.url === url)!);
  }

  @Mutation
  SET_COMPANY_VIDEOS(v: CompanyVideoDTO[]) {
    this.companyVideosAll = v;
  }

  progressCancelFunc: any = null;

  @Mutation
  SET_PROGRESS_CANCEL(v: any) {
    this.progressCancelFunc = v;
  }

  @Mutation
  SET_VIDEO_PROGRESS(v: CompanyVideoProgress) {
    this.videoProgress = v;

    this.companyVideosAll = this.companyVideosAll.map(vid => {
      if (v.forId === vid.id) {
        return {
          ...vid,
          progress: v.progress,
          state: v.state,
        };
      }
      return vid;
    });
  }

  companyVideosAreLoaded = false;

  @Mutation
  SET_COMPANY_VIDEOS_LOADED(v: boolean) {
    this.companyVideosAreLoaded = v;
  }

  @Action
  async loadCompanyVideos() {
    const token = await TokenHelpers.getToken();

    const videos = await companyVideoService.getCompanyVideos({
      token,
    });

    companyVideoStore.SET_COMPANY_VIDEOS(videos);
    companyVideoStore.SET_COMPANY_VIDEOS_LOADED(true);
  }

  youtubeVideosAreLoaded = false;

  @Mutation
  SET_YOUTUBE_VIDEOS_LOADED(v: boolean) {
    this.youtubeVideosAreLoaded = v;
  }

  @Action
  async loadYoutubeVideos() {
    if (!this.youtubeVideosAreLoaded) {
      const token = await TokenHelpers.getToken();

      const videos = await companyVideoService.getCompanyYoutubeVideos({
        token,
      });

      companyVideoStore.SET_YOUTUBE_VIDEOS(videos);

      companyVideoStore.SET_YOUTUBE_VIDEOS_LOADED(true);
    }
  }

  @Action
  async addYoutubeVideo(v: { video: YoutubeVideoDto }) {
    const token = await TokenHelpers.getToken();

    const newVideos = [...this.youtubeVideos, v.video];

    await companyVideoService.addYoutubeVideo({
      token,
      name: v.video.name,
      url: v.video.url,
    });

    companyVideoStore.SET_YOUTUBE_VIDEOS(newVideos);
  }

  @Action
  async removeYoutubeVideo(v: { url: string }) {
    const token = await TokenHelpers.getToken();

    const newVideos = this.youtubeVideos.filter(vid => vid.url !== v.url);

    await companyVideoService.removeYoutubeVideo({
      token,
      url: v.url,
    });

    companyVideoStore.SET_YOUTUBE_VIDEOS(newVideos);
  }

  @Action
  async updateYoutubeVideo(v: { name: string; url: string }) {
    const token = await TokenHelpers.getToken();
    const newVideos = this.youtubeVideos.map(vid => {
      if (vid.url === v.url) {
        return {
          ...vid,
          name: v.name,
        };
      }
      return vid;
    });
    await companyVideoService.updateYoutubeVideo({
      token,
      name: v.name,
      url: v.url,
    });

    companyVideoStore.SET_YOUTUBE_VIDEOS(newVideos);
  }

  @Action
  async loadProgressOnce(v: { id: string }): Promise<CompanyVideoProgress> {
    const token = await TokenHelpers.getToken();
    const progress = await companyVideoService.getProgressForVideoUpload({
      id: v.id,
      token,
    });

    companyVideoStore.SET_VIDEO_PROGRESS({ ...progress, forId: v.id });
    return progress;
  }

  @Action
  async updateVideo(v: { id: string; name: string; isRemoved: boolean }) {
    const token = await TokenHelpers.getToken();

    await companyVideoService.updateVideo({
      id: v.id,
      isRemoved: v.isRemoved,
      name: v.name,
      token,
    });

    companyVideoStore.SET_COMPANY_VIDEOS(
      companyVideoStore.companyVideosAll.map(vid => {
        if (vid.id === v.id) {
          return {
            ...vid,
            name: v.name,
            isRemoved: v.isRemoved,
          };
        }
        return vid;
      })
    );
  }

  @Action
  async loadVideoProgressForAll() {
    await new Promise<void>(resolve => {
      setTimeout(() => {
        resolve();
      }, 200);
    });

    const token = await TokenHelpers.getToken();

    const videos = await companyVideoService.getCompanyVideos({
      token,
    });

    companyVideoStore.SET_COMPANY_VIDEOS(videos);

    if (
      videos.every(
        vid => vid.isRemoved || ["Error", "Finished"].includes(vid.state)
      )
    ) {
      return;
    }

    // eslint-disable-next-line prettier/prettier
    const cancel = setInterval(async() => {
      const token = await TokenHelpers.getToken();
      const videos = await companyVideoService.getCompanyVideos({
        token,
      });

      companyVideoStore.SET_COMPANY_VIDEOS(videos);

      if (
        videos.every(
          vid => vid.isRemoved || ["Error", "Finished"].includes(vid.state)
        )
      ) {
        clearInterval(cancel);
      }
    }, 5000);
  }

  @Action
  loadVideoProgress(v: { id: string }) {
    if (this.progressCancelFunc) {
      clearInterval(this.progressCancelFunc);
    }
    // eslint-disable-next-line prettier/prettier
    const cancel = setInterval(async() => {
      const token = await TokenHelpers.getToken();
      const progress = await companyVideoService.getProgressForVideoUpload({
        id: v.id,
        token,
      });

      companyVideoStore.SET_VIDEO_PROGRESS({ ...progress, forId: v.id });

      if (["Error", "Finished"].includes(progress.state)) {
        clearInterval(cancel);
        companyVideoStore.loadCompanyVideos();
      }
    }, 5000);

    companyVideoStore.SET_PROGRESS_CANCEL(cancel);
  }
}
