import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import CompanyPhotosService from "../services/CompanyPhotosService";
import { companyPhotosStore } from "~/store";
import {
  ImageEntityType,
  ImageLibraryPhoto,
  RecruitmentImagePackObject,
} from "~/models/types";
import { authStore, notificationsStore } from "~/utils/store-accessor";
import TokenHelpers from "~/helpers/tokenHelpers";

@Module({
  name: "companyPhotos",
  stateFactory: true,
  namespaced: true,
})
export default class CompanyPhotos extends VuexModule {
  _photos: ImageLibraryPhoto[] = [];
  loaded = false;
  allLoading = false;

  recritmentImagePacks: RecruitmentImagePackObject[] = [];

  @Mutation
  SET_IMAGE_PACKS(v: RecruitmentImagePackObject[]) {
    this.recritmentImagePacks = v;
  }

  @Mutation
  setPhotos(photos: ImageLibraryPhoto[]) {
    this._photos = photos;
    this.loaded = true;
  }

  @Mutation
  setAllLoading(loading: boolean) {
    this.allLoading = loading;
  }

  @Mutation
  setLoading(obj: { clientSrc: string; fileName: string }) {
    this._photos.unshift({
      loading: true,
      url: obj.clientSrc,
      fileName: obj.fileName,
      uniqueName: null,
      deleted: null,
    });
  }

  imagePacksAreLoaded = false;

  @Mutation
  SET_IMAGE_PACKS_LOADED(v: boolean) {
    this.imagePacksAreLoaded = v;
  }

  @Action
  async loadRecruitmentImagePacks() {
    if (!this.imagePacksAreLoaded) {
      const token = await TokenHelpers.getToken();

      const imagePacks = await CompanyPhotosService.getRecruitmentImagePacks(
        token
      );

      companyPhotosStore.SET_IMAGE_PACKS(imagePacks);
      companyPhotosStore.SET_IMAGE_PACKS_LOADED(true);
    }
  }

  get imagePack(): RecruitmentImagePackObject | null {
    return this.recritmentImagePacks[0] || null;
  }

  @Action
  async updateRecruitmentImages(v: {
    cardImageFile: any;
    bannerImageFile: any;
    cardImageUrl: string | null;
    bannerImageUrl: string | null;
    defaultBannerColor: string | null;
  }) {
    if (!authStore.companyId) {
      return;
    }
    await companyPhotosStore.loadRecruitmentImagePacks();

    const token = await TokenHelpers.getToken();

    const packId = this.recritmentImagePacks[0]?.id || null;

    let cardImageUrl = v.cardImageUrl;

    if (v.cardImageFile) {
      cardImageUrl = await CompanyPhotosService.uploadImageToEntity({
        entityType: ImageEntityType.CompanyProfile,
        file: v.cardImageFile,
        token,
      });
    }

    let bannerImageUrl = v.bannerImageUrl;

    if (v.bannerImageFile) {
      bannerImageUrl = await CompanyPhotosService.uploadImageToEntity({
        entityType: ImageEntityType.CompanyProfile,
        file: v.bannerImageFile,
        token,
      });
    }

    const packToUpsert = {
      id: packId,
      bannerImageUrl,
      cardImageUrl,
      companyId: authStore.companyId,
      defaultBannerColor: v.defaultBannerColor,
    };

    const newId = await CompanyPhotosService.upsertRecruitmentImagePack({
      accessToken: token,
      imagePack: packToUpsert,
    });

    companyPhotosStore.SET_IMAGE_PACKS([
      {
        ...packToUpsert,
        id: newId,
      },
    ]);
  }

  @Action
  async undeletePhoto(uniqueName: string) {
    const token = await TokenHelpers.getToken();
    try {
      await CompanyPhotosService.undeleteImage(uniqueName, token);
      companyPhotosStore.setPhotos(
        this._photos.map(p => {
          if (p.uniqueName === uniqueName) {
            return {
              ...p,
              deleted: null,
            };
          }
          return p;
        })
      );
    } catch (error) {
      notificationsStore.setErrorMessage({
        error,
      });
      companyPhotosStore.loadPhotos(true);
    }
  }

  @Action
  async deletePhoto(uniqueName: string) {
    const token = await TokenHelpers.getToken();
    try {
      await CompanyPhotosService.deleteImage(uniqueName, token);
      companyPhotosStore.setPhotos(
        this._photos.map(p => {
          if (p.uniqueName === uniqueName) {
            return {
              ...p,
              deleted: new Date(),
            };
          }
          return p;
        })
      );
    } catch (error) {
      notificationsStore.setErrorMessage({
        error,
      });
      companyPhotosStore.loadPhotos(true);
    }
  }

  @Action
  async addPhoto(obj: { file: any; clientSrc: string }): Promise<string> {
    companyPhotosStore.setLoading({
      fileName: obj.file.name || obj.clientSrc,
      clientSrc: obj.clientSrc,
    });
    const token = await TokenHelpers.getToken();
    const newUrl = await CompanyPhotosService.uploadImage(obj.file, token);

    const photos = await CompanyPhotosService.getPhotosByCompanyId(token);

    const addedPhoto = photos.find(p => p.url === newUrl);

    companyPhotosStore.setPhotos(
      this._photos.map(p => {
        if (p.url === obj.clientSrc) {
          return {
            loading: false,
            url: newUrl,
            fileName: obj.file.name || obj.clientSrc,
            uniqueName: addedPhoto ? addedPhoto.uniqueName : null,
            deleted: null,
          };
        }
        return p;
      })
    );

    return newUrl;
  }

  @Action
  async loadPhotos(reload?: boolean) {
    if (reload || !this.loaded) {
      companyPhotosStore.setAllLoading(true);
      const token = await TokenHelpers.getToken();
      const allPhotos = await CompanyPhotosService.getPhotosByCompanyId(token);
      companyPhotosStore.setPhotos(
        allPhotos.map(x => ({ ...x, loading: false }))
      );
      companyPhotosStore.setAllLoading(false);
    }
  }

  get photos() {
    const result = [];
    const map = new Map();
    for (const item of this._photos.filter(p => !!p.fileName) || []) {
      if (!map.has(item.url)) {
        map.set(item.url, true);
        result.push(item);
      }
    }

    return result;
  }
}
