






















































































import { Vue, Component, Prop } from "vue-property-decorator";
import FilterHelpers from "~/helpers/filterHelpers";
import { jobbOfferStore } from "~/store";

export interface CommentListItemDto {
  id: string;
  comment: string;
  sender: string;
  date: Date;
  color: string;
  isYou: boolean;
  initials: string;
  sharedToEmails: string[];
  recruitmentId: string | null;
  isTeamComment: boolean;
  isFromCompany: boolean;
}

@Component
export default class CommentListItem extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: CommentListItemDto;

  @Prop({ type: Boolean, default: true })
  readonly isHtml!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isOnTalentSide!: boolean;

  get isYou(): boolean {
    return this.item.isYou;
  }

  get showOnTheRight(): boolean {
    if (this.isOnTalentSide) {
      return !!this.isYou;
    }
    return this.isYou || (!this.item.isTeamComment && this.item.isFromCompany);
  }

  get color(): string {
    return this.item.color;
  }

  get initials(): string {
    return this.item.initials;
  }

  get date(): Date {
    return this.item.date;
  }

  get fromNowDateString(): string {
    return FilterHelpers.getFromNowString(this.item.date);
  }

  get senderName(): string {
    return this.isYou ? this.$t("Du").toString() : this.item.sender;
  }

  get comment(): string {
    return this.item.comment;
  }

  get showSharedTo(): boolean {
    return !!this.item.sharedToEmails.length;
  }

  get sharedToEmailsString(): string {
    return this.item.sharedToEmails.join(", ");
  }

  get recruitmentTitle(): string | null {
    if (this.item.recruitmentId === null) {
      return null;
    }

    const recruitmentListItem = jobbOfferStore.recruitmentList.find(
      r => r.id === this.item.recruitmentId
    );

    return recruitmentListItem?.title ?? null;
  }
}
