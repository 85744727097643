import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { LaneForLibrary, LaneForLibraryToAdd, LaneType } from "~/models/types";
import CompanyLaneService from "~/services/CompanyLaneService";
import { jobbOfferStore, lanesStore } from "~/store";
import LaneHelper from "~/helpers/laneHelper";
import TokenHelpers from "~/helpers/tokenHelpers";

@Module({
  name: "lanes",
  stateFactory: true,
  namespaced: true,
})
export default class Lanes extends VuexModule {
  items: LaneForLibrary[] = [];
  itemsAreLoaded: string | null = null;

  @Mutation
  SET_LIBRARY_LANES(v: LaneForLibrary[]) {
    this.items = v;
  }

  @Mutation
  SET_LANES_ARE_LOADED(v: string | null) {
    this.itemsAreLoaded = v;
  }

  @Action
  async loadLibraryLanes(v: { lang: string; reload?: boolean }) {
    if (v.reload || this.itemsAreLoaded !== v.lang) {
      const token = await TokenHelpers.getToken();

      const lanes = await CompanyLaneService.getAllLanes({
        accessToken: token,
        lang: v.lang,
      });

      lanesStore.SET_LIBRARY_LANES(lanes);
      lanesStore.SET_LANES_ARE_LOADED(v.lang);
    }
  }

  @Action
  async updateLane(v: { lane: LaneForLibrary }) {
    const token = await TokenHelpers.getToken();

    await CompanyLaneService.updateLane({
      accessToken: token,
      lane: v.lane,
    });

    lanesStore.SET_LIBRARY_LANES(
      this.items.map(l => {
        if (l.id === v.lane.id) {
          return v.lane;
        }
        return l;
      })
    );
  }

  @Action
  async updateLaneSortOrder(v: { laneIds: string[] }) {
    const token = await TokenHelpers.getToken();

    await CompanyLaneService.updateLaneSortOrder({
      accessToken: token,
      laneIds: v.laneIds,
    });

    lanesStore.SET_LIBRARY_LANES(
      v.laneIds.reduce((acc: LaneForLibrary[], lid, index) => {
        const lane = this.items.find(l => l.id === lid);
        if (lane) {
          return [
            ...acc,
            {
              ...lane,
              initialSortOrder: (index + 1) * 100,
            },
          ];
        }
        return acc;
      }, [])
    );
  }

  @Action
  async removeLane(v: { laneId: string }) {
    const token = await TokenHelpers.getToken();

    const laneToRemove = this.items.find(l => l.id === v.laneId);
    if (laneToRemove) {
      const lane = {
        ...laneToRemove,
        removed: true,
        onNew: false,
      };

      await CompanyLaneService.updateLane({
        accessToken: token,
        lane,
      });

      const newLanes = this.items.map(l => {
        if (l.id === lane.id) {
          return lane;
        }
        return l;
      });

      const lanesWithNewSort = LaneHelper.getLaneWithSortedRemoves(newLanes);

      await CompanyLaneService.updateLaneSortOrder({
        accessToken: token,
        laneIds: lanesWithNewSort.map(l => l.id),
      });

      lanesStore.SET_LIBRARY_LANES(lanesWithNewSort);
    }
  }

  @Action
  async unremoveLane(v: { laneId: string }) {
    const token = await TokenHelpers.getToken();

    const laneToRemove = this.items.find(l => l.id === v.laneId);
    if (laneToRemove) {
      const lane = {
        ...laneToRemove,
        removed: false,
        onNew: false,
      };

      await CompanyLaneService.updateLane({
        accessToken: token,
        lane,
      });

      const newLanes = this.items.map(l => {
        if (l.id === lane.id) {
          return lane;
        }
        return l;
      });

      const lanesWithNewSort = LaneHelper.getLaneWithSortedRemoves(newLanes);

      await CompanyLaneService.updateLaneSortOrder({
        accessToken: token,
        laneIds: lanesWithNewSort.map(l => l.id),
      });

      lanesStore.SET_LIBRARY_LANES(lanesWithNewSort);
    }
  }

  @Action
  async updateRecruitmentLanes(v: {
    laneIds: string[];
    recruitmentId: string;
  }) {
    const token = await TokenHelpers.getToken();
    await CompanyLaneService.updateRecruitmentLanes({
      accessToken: token,
      laneIds: v.laneIds,
      recruitmentId: v.recruitmentId,
    });

    jobbOfferStore.SET_TRELLO_BOARD_LANES(v.laneIds);
  }

  @Action
  async addLane(v: { lane: LaneForLibraryToAdd }): Promise<string> {
    const token = await TokenHelpers.getToken();

    const newId = await CompanyLaneService.addLane({
      accessToken: token,
      lane: v.lane,
    });

    const orderedLanes = [...this.items].sort(
      (a, b) => a.initialSortOrder - b.initialSortOrder
    );

    const lanesWithNewLane: LaneForLibrary[] = [
      ...orderedLanes.filter(l => l.laneType !== LaneType.Hired),
      {
        ...v.lane,
        id: newId,
        initialSortOrder: 2,
      },
      ...orderedLanes.filter(l => l.laneType === LaneType.Hired),
    ];

    lanesStore.SET_LIBRARY_LANES(
      LaneHelper.getLaneWithSortedRemoves(lanesWithNewLane)
    );

    return newId;
  }
}
