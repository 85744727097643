import { Middleware } from "@nuxt/types";

// eslint-disable-next-line space-before-function-paren
const myMiddleware: Middleware = async ({ app, redirect }) => {
  if (app.$msal && (await app.$msal.isAuthenticated())) {
    return redirect(
      `${app.i18n.locale === "sv" ? "" : "/" + app.i18n.locale}/talent`
    );
  }
};

export default myMiddleware;
