import { ThingToLoad } from "~/models/types";
import { authStore } from "~/store";
import { $i18n } from "~/utils/i18n";
import { $msal } from "~/utils/msal";

export default class TokenHelpers {
  static getTokenClaims(
    token: string | null
  ): {
    [key: string]: string;
  } {
    if (token) {
      const base64Url = token.split(".")[1] ?? "";
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function(c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    }

    return {};
  }

  static async getToken(): Promise<string> {
    const token = await $msal.acquireToken();

    if (typeof token !== "string") {
      throw new TypeError("Ett fel inträffade!");
    }

    if (authStore.companyId) {
      const tokenClaims = this.getTokenClaims(token);
      if (tokenClaims.companyId !== authStore.companyId) {
        $msal.acquireTokenRedirect({
          scopes: [
            process.env.ENVIRONMENT === "Production"
              ? "https://gritifyauth.onmicrosoft.com/api/gritify"
              : process.env.ENVIRONMENT === "Stage"
              ? "https://gritifyauthstage.onmicrosoft.com/api/gritify"
              : "https://gritifytalentauthtest.onmicrosoft.com/api/gritify",
          ],
          extraQueryParameters: {
            policy_type: "Login",
            ui_locales: $i18n.locale,
            companyId: authStore.companyId,
          },
        });
        throw new Error("Multiple tabs open");
      }
    }

    return token;
  }

  static getThingToLoadOrExit<T>(
    o: ThingToLoad<T>,
    force: boolean
  ): { type: "exit" } | ThingToLoad<T> {
    switch (o.type) {
      case "loaded":
      case "loading":
        return !force
          ? {
              type: "exit",
            }
          : {
              type: "loading",
              value: o.value,
            };
      case "notFetched":
        return {
          type: "loading",
        };
    }
  }

  static GetArrayFrom<T>(o: ThingToLoad<T[]>) {
    return this.GetValueOrDefault(o, []);
  }

  static GetValueOrDefault<T, U>(o: ThingToLoad<T>, def: U) {
    switch (o.type) {
      case "loaded":
      case "loading":
        return o.value ?? def;
      case "notFetched":
        return def;
    }
  }

  static GetArrayFromRecord<T>(
    o: Record<string, ThingToLoad<T[]>>,
    key: string
  ) {
    const defaultArray: T[] = [];

    const value = o[key];
    if (!value) {
      return defaultArray;
    }
    return this.GetValueOrDefault(value, defaultArray);
  }
}
