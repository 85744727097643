export default class CommonHelpers {
  static getBaseUrl(): string {
    return process.env.ENVIRONMENT === "Production"
      ? "https://www.gritify.io"
      : process.env.ENVIRONMENT === "Stage"
      ? "https://stage.gritify.io"
      : process.env.ENVIRONMENT === "Test"
      ? "https://test.gritify.io"
      : "http://localhost:3000";
  }

  static getBaseThumbnailImageUrl() {
    return `${this.getBaseUrl()}/og-gritify-2.png`;
  }

  static getTextFromLangObject(v: {
    langObject: {
      textDict: Record<string, string>;
    };
    locale: string;
  }) {
    return v.langObject.textDict[v.locale] ?? v.langObject.textDict.en ?? "?";
  }
}
