import FilterHelpers from "./filterHelpers";
import {
  TrelloBoardTalentStatus,
  TrelloBoardLaneBase,
  TrelloBoardTalent,
  RecruitmentTalentStatus,
  LaneType,
} from "~/models/types";

export default class TalentStatusHelpers {
  static isAnonymous(v: { accepted: Date | null }): boolean {
    return !v.accepted;
  }

  static isOngoing(v: TrelloBoardTalentStatus): boolean {
    return !!v.accepted && !!v.invited;
  }

  static hasNoResponse(v: TrelloBoardTalentStatus): boolean {
    return !v.declined && !v.accepted && !v.removed;
  }

  static canMoveTalent(v: TrelloBoardTalentStatus): boolean {
    return (
      TalentStatusHelpers.isOngoing(v) &&
      !FilterHelpers.hasDeclined(v) &&
      !v.removed
    );
  }

  static askForResponseOnRouteLeave(jo: TrelloBoardTalentStatus): boolean {
    return !!jo.invited && !jo.accepted && !jo.declined && !jo.removed;
  }

  static isJobOffer(v: TrelloBoardTalentStatus): boolean {
    return !!v.invited && !v.accepted;
  }

  static stableLanes = {
    fish: {
      id: "fish",
      text: "Möjlighet",
    },
    inbox: {
      id: "inbox",
      text: "Inkorg",
    },
    review: {
      id: "review",
      text: "Granskning",
    },
  };

  public static getIconForLane(v: LaneType): string {
    switch (v) {
      case LaneType.Custom:
        return "mdi-account-box-multiple";
      case LaneType.Hired:
        return "mdi-account-check";
      case LaneType.Inbox:
        return "mdi-account-search";
      case LaneType.Interview:
        return "mdi-chat";
      case LaneType.Review:
        return "mdi-account-box-multiple";
      case LaneType.ReviewVirtaul:
        return "mdi-account-box-multiple";
      case LaneType.Test:
        return "mdi-check-decagram";
      case LaneType.Offers:
        return "mdi-send";
      case LaneType.References:
        return "mdi-check-decagram-outline";
    }
  }

  static getStatusSortOrder(v: TrelloBoardTalent): number {
    return this.getStatusSortOrderInternal(this.getStatus(v));
  }

  static getStatus(v: TrelloBoardTalent): RecruitmentTalentStatus {
    if (FilterHelpers.hasDeclined(v)) {
      return RecruitmentTalentStatus.Declined;
    }
    if (v.removed) {
      return RecruitmentTalentStatus.Removed;
    }
    if (v.isHired) {
      return RecruitmentTalentStatus.Hired;
    }
    if (v.accepted) {
      return RecruitmentTalentStatus.Accepted;
    }
    if (v.invited) {
      return RecruitmentTalentStatus.Invited;
    }
    if (v.saved) {
      return RecruitmentTalentStatus.Saved;
    }
    return RecruitmentTalentStatus.Other;
  }

  private static getStatusSortOrderInternal(
    v: RecruitmentTalentStatus
  ): number {
    switch (v) {
      case RecruitmentTalentStatus.Hired:
        return 1;
      case RecruitmentTalentStatus.Accepted:
        return 2;
      case RecruitmentTalentStatus.Invited:
        return 3;
      case RecruitmentTalentStatus.Saved:
        return 4;
      case RecruitmentTalentStatus.Declined:
        return 5;
      case RecruitmentTalentStatus.Removed:
        return 6;
      case RecruitmentTalentStatus.Other:
        return 7;
    }
  }

  static get preLanes(): TrelloBoardLaneBase[] {
    return [this.stableLanes.inbox, this.stableLanes.review];
  }

  static get unmovableLanes(): string[] {
    return [this.stableLanes.fish.id, this.stableLanes.inbox.id];
  }

  static getLaneIdForTalent(t: TrelloBoardTalent, dbLaneIds: string[]): string {
    if (t.accepted && t.invited) {
      if (
        !!t.laneId &&
        ![
          TalentStatusHelpers.stableLanes.review.id,
          TalentStatusHelpers.stableLanes.fish.id,
          TalentStatusHelpers.stableLanes.inbox.id,
        ].includes(t.laneId) &&
        dbLaneIds.includes(t.laneId)
      ) {
        return t.laneId;
      } else {
        return TalentStatusHelpers.stableLanes.review.id;
      }
    } else {
      return TalentStatusHelpers.stableLanes.inbox.id;
    }
  }
}
