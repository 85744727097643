<template>
  <v-app id="keep">
    <v-main>
      <v-sheet height="100%">
        <nuxt />
      </v-sheet>
    </v-main>
  </v-app>
</template>

<script>
export default {};
</script>

<style></style>
