/* eslint-disable import/no-mutable-exports */
import { Store } from "vuex";
import { getModule } from "vuex-module-decorators";
import Offices from "~/store/offices";
import Lanes from "~/store/lanes";
import JobbOffer from "~/store/jobbOffer";
import CompanyPhotos from "~/store/companyPhotos";
import CompanyVideo from "~/store/companyVideo";
import TalentProfile from "~/store/talentProfile";
import CompanyProfile from "~/store/companyProfile";
import SearchTalents from "~/store/searchTalents";
import ProfileLookupsStore from "~/store/profileLookups";
import NotificationsStore from "~/store/notifications";
import DepartmentsStore from "~/store/departments";
import CoWorkersStore from "~/store/coWorkers";
import JobbOfferVisualStore from "~/store/jobbOfferVisual";
import AuthStore from "~/store/auth";
import CompanyBaseStore from "~/store/companyBase";
import PublicDataStore from "~/store/publicData";
import ExternalTalents from "~/store/externalTalents";
import TalentPoolStore from "~/store/talentPool";
import SourcingRobotStore from "~/store/sourcingRobot";
import SourcingStore from "~/store/sourcing";

let officesStore: Offices;
let lanesStore: Lanes;
let jobbOfferStore: JobbOffer;
let companyPhotosStore: CompanyPhotos;
let talentProfileStore: TalentProfile;
let companyProfileStore: CompanyProfile;
let searchTalentsStore: SearchTalents;
let profileLookupsStore: ProfileLookupsStore;
let notificationsStore: NotificationsStore;
let departmentsStore: DepartmentsStore;
let coWorkersStore: CoWorkersStore;
let jobbOfferVisualStore: JobbOfferVisualStore;
let authStore: AuthStore;
let companyBaseStore: CompanyBaseStore;
let companyVideoStore: CompanyVideo;
let publicDataStore: PublicDataStore;
let externalTalentsStore: ExternalTalents;
let talentPoolStore: TalentPoolStore;
let sourcingRobotStore: SourcingRobotStore;
let sourcingStore: SourcingStore;

function initialiseStores(store: Store<any>): void {
  officesStore = getModule(Offices, store);
  lanesStore = getModule(Lanes, store);
  jobbOfferStore = getModule(JobbOffer, store);
  companyPhotosStore = getModule(CompanyPhotos, store);
  talentProfileStore = getModule(TalentProfile, store);
  companyProfileStore = getModule(CompanyProfile, store);
  searchTalentsStore = getModule(SearchTalents, store);
  profileLookupsStore = getModule(ProfileLookupsStore, store);
  notificationsStore = getModule(NotificationsStore, store);
  departmentsStore = getModule(DepartmentsStore, store);
  coWorkersStore = getModule(CoWorkersStore, store);
  jobbOfferVisualStore = getModule(JobbOfferVisualStore, store);
  authStore = getModule(AuthStore, store);
  companyBaseStore = getModule(CompanyBaseStore, store);
  companyVideoStore = getModule(CompanyVideo, store);
  publicDataStore = getModule(PublicDataStore, store);
  externalTalentsStore = getModule(ExternalTalents, store);
  talentPoolStore = getModule(TalentPoolStore, store);
  sourcingRobotStore = getModule(SourcingRobotStore, store);
  sourcingStore = getModule(SourcingStore, store);
}

export {
  initialiseStores,
  officesStore,
  lanesStore,
  jobbOfferStore,
  companyPhotosStore,
  talentProfileStore,
  companyProfileStore,
  searchTalentsStore,
  profileLookupsStore,
  notificationsStore,
  departmentsStore,
  coWorkersStore,
  jobbOfferVisualStore,
  authStore,
  companyBaseStore,
  companyVideoStore,
  publicDataStore,
  externalTalentsStore,
  talentPoolStore,
  sourcingRobotStore,
  sourcingStore,
};
