




































































import { Vue, Component, Prop } from "vue-property-decorator";
import TalentModalWrapper from "./common/TalentModalWrapper.vue";
import { authStore, companyBaseStore, notificationsStore } from "~/store";
import {
  canUpgradeToFixedAccount,
  getCompanyAccountType,
} from "~/helpers/agreemendHelper";

export type UpgradeToNewPricingModelModalResult =
  | {
      type: "Saved";
    }
  | {
      type: "Cancel";
    };

@Component({
  components: {
    TalentModalWrapper,
  },
})
export default class UpgradeToNewPricingModelModal extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isEditing!: boolean;

  dialog = false;
  resolve: ((v: UpgradeToNewPricingModelModalResult) => void) | null = null;

  get requiredRules() {
    return [(v: string) => !!v || this.$t("Obligatoriskt")];
  }

  get emailRules() {
    return [
      (v: string) =>
        /.+@.+\..+/.test(v) || this.$t("E-postadressen är ogiltig").toString(),
    ];
  }

  email: string | null = null;
  name: string | null = null;
  orgNumber: string | null = null;
  upgradeToAccessSuccess: boolean = false;

  open(v: {
    upgradeToAccessSuccess: boolean;
  }): Promise<UpgradeToNewPricingModelModalResult> {
    this.dialog = true;
    const info = companyBaseStore.companyDetailsObject?.accountInfo;
    this.upgradeToAccessSuccess = v.upgradeToAccessSuccess;

    this.email = info?.invoiceEmail ?? (authStore.companyUser?.email || null);
    this.name =
      info?.contactName ?? authStore.companyUser?.firstName
        ? `${authStore.companyUser?.firstName} ${authStore.companyUser?.lastName}`
        : null;
    this.orgNumber = info?.orgNumber ?? null;

    this.$refs.editForm && this.$refs.editForm.resetValidation();

    return new Promise<UpgradeToNewPricingModelModalResult>(resolve => {
      this.resolve = resolve;
    });
  }

  get accountType() {
    return getCompanyAccountType(companyBaseStore.companyDetailsObject);
  }

  get canUpgradeToFixed() {
    return canUpgradeToFixedAccount(companyBaseStore.companyDetailsObject);
  }

  get greyText(): string {
    if (this.isEditing) {
      switch (this.accountType) {
        case "Explore":
          return "";
        case "AccessSuccess":
          return this.$t("grå text AccessSuccess redigera").toString();
        case "OldFixed":
          return "";
        case "NewFixed":
          return this.$t("grå text NewFixed redigera").toString();
      }
    }

    switch (this.accountType) {
      case "Explore":
        return !this.canUpgradeToFixed
          ? this.$t("grå text Explore till AccessSuccess").toString()
          : this.$t("grå text Explore till fixed").toString();
      case "AccessSuccess":
        return this.$t("grå text AccessSuccess till fixed").toString();
      case "OldFixed":
        return this.$t("grå text OldFixed till new fixed").toString();
      case "NewFixed":
        return "";
    }
  }

  get heading(): string {
    if (this.isEditing) {
      switch (this.accountType) {
        case "Explore":
          return "";
        case "AccessSuccess":
          return this.$t("heading AccessSuccess redigera").toString();
        case "OldFixed":
          return "";
        case "NewFixed":
          return this.$t("heading NewFixed redigera").toString();
      }
    }

    switch (this.accountType) {
      case "Explore":
        return !this.canUpgradeToFixed
          ? this.$t("heading Explore till AccessSuccess").toString()
          : this.$t("heading Explore till fixed").toString();
      case "AccessSuccess":
        return this.$t("heading AccessSuccess till fixed").toString();
      case "OldFixed":
        return this.$t("heading OldFixed till new fixed").toString();
      case "NewFixed":
        return "";
    }
  }

  get buttonText(): string {
    if (this.isEditing) {
      switch (this.accountType) {
        case "Explore":
          return "";
        case "AccessSuccess":
          return this.$t("buttonText AccessSuccess redigera").toString();
        case "OldFixed":
          return "";
        case "NewFixed":
          return this.$t("buttonText NewFixed redigera").toString();
      }
    }

    switch (this.accountType) {
      case "Explore":
        return !this.canUpgradeToFixed
          ? this.$t("buttonText Explore till AccessSuccess").toString()
          : this.$t("buttonText Explore till fixed").toString();
      case "AccessSuccess":
        return this.$t("buttonText AccessSuccess till fixed").toString();
      case "OldFixed":
        return this.$t("buttonText OldFixed till new fixed").toString();
      case "NewFixed":
        return "";
    }
  }

  getThankYouText(): string {
    if (this.isEditing) {
      switch (this.accountType) {
        case "Explore":
          return "";
        case "AccessSuccess":
          return this.$t("tack text AccessSuccess redigera").toString();
        case "OldFixed":
          return "";
        case "NewFixed":
          return this.$t("tack text NewFixed redigera").toString();
      }
    }

    switch (this.accountType) {
      case "Explore":
        return !this.canUpgradeToFixed
          ? this.$t("tack text Explore till AccessSuccess").toString()
          : this.$t("tack text Explore till fixed").toString();
      case "AccessSuccess":
        return this.$t("tack text AccessSuccess till fixed").toString();
      case "OldFixed":
        return this.$t("tack text OldFixed till new fixed").toString();
      case "NewFixed":
        return "";
    }
  }

  $refs!: Vue["$refs"] & {
    editForm: any;
  };

  cancel() {
    if (this.resolve) {
      this.resolve({
        type: "Cancel",
      });
    }
    this.dialog = false;
  }

  loadingSave = false;

  async save() {
    if (this.$refs.editForm.validate()) {
      if (this.resolve) {
        try {
          this.loadingSave = true;
          const thankYouText = this.getThankYouText();
          if (this.upgradeToAccessSuccess) {
            await companyBaseStore.upgradeCompanyToAccessSuccess({
              contactName: this.name!,
              invoiceEmail: this.email!,
              orgNumber: this.orgNumber!,
            });
            notificationsStore.setSuccessMessage(thankYouText);
          } else {
            await companyBaseStore.upgradeCompanyToFixedPrice({
              contactName: this.name!,
              invoiceEmail: this.email!,
              orgNumber: this.orgNumber!,
            });
            notificationsStore.setSuccessMessage(thankYouText);
          }

          this.resolve({
            type: "Saved",
          });
          this.dialog = false;
        } catch (error) {
          notificationsStore.setErrorMessage({ error });
        } finally {
          this.loadingSave = false;
        }
      }
    }
  }
}
