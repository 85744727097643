import {
  VisualCompletenessListItem,
  VisualCompletenessType,
  JobOfferVisaulToUpdate,
  GetRolesDto,
} from "~/models/types";

export default class VisualJobOfferHelper {
  static minimumTasks = 2;

  static announcementTitleMaxLength = 300;
  static ingressMaxLength = 1500;

  static getShowSkillsInVisual(v: {
    roles: GetRolesDto[];
    roleId: string | null;
  }) {
    if (!v.roleId) {
      return false;
    }

    const role = v.roles.find(r => r.id === v.roleId);

    if (role) {
      return role.showSkillsInVisual;
    }
    return false;
  }

  static getVisualTaskList(
    v: JobOfferVisaulToUpdate
  ): VisualCompletenessListItem[] {
    const result: VisualCompletenessListItem[] = [];

    result.push(
      {
        type: VisualCompletenessType.AnnouncementTitle,
        complete:
          !!v.announcementTitle &&
          v.announcementTitle.length <= this.announcementTitleMaxLength,
      },
      {
        type: VisualCompletenessType.Ingress,
        complete: !!v.ingress && v.ingress.length <= this.ingressMaxLength,
      },
      {
        type: VisualCompletenessType.Tasks,
        complete: v.taskIds.length >= this.minimumTasks,
      },
      {
        type: VisualCompletenessType.Manager,
        complete: !v.includeInterview || !!v.manager,
      },
      {
        type: VisualCompletenessType.InterviewText,
        complete:
          !v.includeInterview ||
          !!(v.textInterview ?? []).filter(
            i =>
              !!i.question && i.question.length && !!i.answer && i.answer.length
          ).length,
      }
    );

    return result;
  }

  static getMappedVisual(v: any): JobOfferVisaulToUpdate {
    const result: JobOfferVisaulToUpdate = {
      ...v,
      announcementTitle: v.announcementTitle || null,
      imageUrl: v.imageUrl,
      useSeparateInterview: v.useSeparateInterview || false,
      department:
        v.department && v.department.id
          ? {
              ...v.department,
              imageUrls: v.department.imageUrls || [],
            }
          : null,
      manager:
        v.manager && v.manager.id
          ? {
              ...v.manager,
              interview: v.manager.interview || {
                interviewType: null,
                textInterview: [],
                uploadInProgress: false,
                videoUrl: null,
                youtubeUrl: null,
              },
            }
          : null,
      coWorkers: v.coWorkers || [],
      skills: v.skills || [],
      taskIds: v.taskIds || [],
      perks: v.perks || [],
    };

    return result;
  }
}
