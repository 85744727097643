













import { Vue, Component, Prop } from "vue-property-decorator";

import DOMPurify from "isomorphic-dompurify";

@Component
export default class GrHtmlSpan extends Vue {
  @Prop({ type: String, required: true })
  readonly html!: string;

  get htmlSanitized() {
    const test = false;
    if (test) {
      return this.html;
    }
    return DOMPurify.sanitize(this.html);
  }
}
