import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { coWorkersStore } from "~/store";
import coWorkersService from "~/services/coWorkerService";
import { CoWorkerDto, CoWokerToAdd, ImageEntityType } from "~/models/types";
import {
  notificationsStore,
  jobbOfferVisualStore,
} from "~/utils/store-accessor";
import CompanyPhotosService from "~/services/CompanyPhotosService";
import TokenHelpers from "~/helpers/tokenHelpers";

@Module({
  name: "coWorkers",
  stateFactory: true,
  namespaced: true,
})
export default class CoWorkersStore extends VuexModule {
  coWorkers: CoWorkerDto[] = [];
  coWorkersLoaded: boolean = false;
  removingIds: string[] = [];
  showRemoved: boolean = false;

  @Mutation
  SET_SHOW_REMOVED(v: boolean) {
    this.showRemoved = v;
  }

  showRemovedManagers: boolean = false;

  @Mutation
  SET_SHOW_REMOVED_MANAGERS(v: boolean) {
    this.showRemovedManagers = v;
  }

  @Mutation
  SET_REMOVEING_IDS(v: string[]) {
    this.removingIds = v;
  }

  @Mutation
  SET_COWORKERS(deps: CoWorkerDto[]) {
    this.coWorkers = deps;
  }

  @Mutation
  SET_COWORKERS_LOEADED(loaded: boolean) {
    this.coWorkersLoaded = loaded;
  }

  @Action
  async loadCoWorkers() {
    if (!this.coWorkersLoaded) {
      const token = await TokenHelpers.getToken();
      const coWorkers = await coWorkersService.getCoWorkers(token);
      coWorkersStore.SET_COWORKERS_LOEADED(true);
      coWorkersStore.SET_COWORKERS(coWorkers);
    }
  }

  @Action
  async addCoWorker(v: {
    coWorker: CoWokerToAdd;
    imageFile: any;
  }): Promise<{ coworkerId: string; imageUrl: string }> {
    const token = await TokenHelpers.getToken();

    let newUrl: string | null = null;

    if (v.imageFile) {
      newUrl = await CompanyPhotosService.uploadImageToEntity({
        entityType: ImageEntityType.CoWorker,
        file: v.imageFile,
        token,
      });
    }

    const coWorkerToAdd = {
      ...v.coWorker,
      imageUrl: newUrl || v.coWorker.imageUrl,
    };

    const newId = await coWorkersService.createCoWorker(coWorkerToAdd, token);

    coWorkersStore.SET_COWORKERS([
      ...this.coWorkers,
      {
        ...coWorkerToAdd,
        id: newId,
      },
    ]);
    return {
      coworkerId: newId,
      imageUrl: newUrl ?? v.coWorker.imageUrl,
    };
  }

  @Action
  async updateCoWorker(v: {
    coWorker: CoWorkerDto;
    imageFile: any;
  }): Promise<{ coworkerId: string; imageUrl: string }> {
    const token = await TokenHelpers.getToken();
    let newUrl: string | null = null;

    if (v.imageFile) {
      newUrl = await CompanyPhotosService.uploadImageToEntity({
        entityType: ImageEntityType.CoWorker,
        file: v.imageFile,
        token,
      });
    }

    const coWorkerToUpdate = {
      ...v.coWorker,
      imageUrl: newUrl || v.coWorker.imageUrl,
    };

    await coWorkersService.updateCoWorker(coWorkerToUpdate, token);

    const visual = jobbOfferVisualStore.visual;
    const visualCoWorker = visual.coWorkers.find(x => x.id === v.coWorker.id);

    if (
      (visual.manager && visual.manager.id === v.coWorker.id) ||
      visualCoWorker
    ) {
      jobbOfferVisualStore.SET_JOB_OFFER_VISUAL({
        ...visual,
        manager:
          visual.manager && visual.manager.id === v.coWorker.id
            ? {
                ...coWorkerToUpdate,
                interview: visual.useSeparateInterview
                  ? visual.manager.interview
                  : coWorkerToUpdate.interview,
              }
            : visual.manager,
        coWorkers: visual.coWorkers.map(cw => {
          if (cw.id === v.coWorker.id) {
            return coWorkerToUpdate;
          }
          return cw;
        }),
      });
    }

    coWorkersStore.SET_COWORKERS(
      this.coWorkers.map(d => {
        if (d.id === coWorkerToUpdate.id) {
          return coWorkerToUpdate;
        }
        return d;
      })
    );
    return {
      coworkerId: coWorkerToUpdate.id,
      imageUrl: newUrl ?? v.coWorker.imageUrl,
    };
  }

  @Action
  async removeCoWorker(coWorkerId: string) {
    const coWorker = this.coWorkers.find(x => x.id === coWorkerId);
    if (coWorker) {
      coWorkersStore.SET_REMOVEING_IDS([...this.removingIds, coWorkerId]);
      const token = await TokenHelpers.getToken();
      try {
        await coWorkersService.updateCoWorker(
          { ...coWorker, isRemoved: true },
          token
        );

        coWorkersStore.SET_COWORKERS(
          this.coWorkers.map(d => {
            if (d.id === coWorker.id) {
              return { ...coWorker, isRemoved: true };
            }
            return d;
          })
        );
      } catch (error) {
        notificationsStore.setErrorMessage({ error });
      } finally {
        coWorkersStore.SET_REMOVEING_IDS(
          this.removingIds.filter(x => x !== coWorkerId)
        );
      }
    }
  }

  @Action
  async unremoveCoWorker(coWorkerId: string) {
    const coWorker = this.coWorkers.find(x => x.id === coWorkerId);
    if (coWorker) {
      coWorkersStore.SET_REMOVEING_IDS([...this.removingIds, coWorkerId]);
      const token = await TokenHelpers.getToken();
      try {
        await coWorkersService.updateCoWorker(
          { ...coWorker, isRemoved: false },
          token
        );

        coWorkersStore.SET_COWORKERS(
          this.coWorkers.map(d => {
            if (d.id === coWorker.id) {
              return { ...coWorker, isRemoved: false };
            }
            return d;
          })
        );
      } catch (error) {
        notificationsStore.setErrorMessage({ error });
      } finally {
        coWorkersStore.SET_REMOVEING_IDS(
          this.removingIds.filter(x => x !== coWorkerId)
        );
      }
    }
  }
}
