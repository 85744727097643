export default {
  yearsOfStudy: {
    zero: "0 år (Ingen utbildning krävs)",
    one: "1 år (t.ex. 60 hp, Yrkeshögskoleexamen)",
    two:
      "2 år (t.ex. 120 hp, Högskoleexamen, Kvalificerad yrkeshögskoleexamen)",
    three: "3 år (t.ex. 180 hp, Kandidatexamen)",
    four: "4 år (t.ex. 240 hp, Magisterexamen)",
    five: "5 år (t.ex. 300 hp, Masterexamen)",
    seven: "7 år (t.ex. 420 hp, Licentiatexamen)",
    nine: "9 år (t.ex. 540 hp, Doktorsexamen)",
  },
  educationLevels: {
    HigherVocational: "Yrkeshögskoleexamen (1 år)",
    AdvancedHigherVocational: "Kvalificerad yrkeshögskoleexamen (2 år)",
    UniversityDiploma: "Högskoleexamen (2 år)",
    Bachelor: "Kandidatexamen (3 år)",
    Master1Year: "Magisterexamen (4 år)",
    Master2Year: "Masterexamen (5 år)",
    Licentiate: "Licentiatexamen (7 år)",
    Doctorate: "Doktorsexamen (9 år)",
    NoDegree: "Ingen Examen",
  },
  educationLevelsShort: {
    HigherVocational: "KY",
    AdvancedHigherVocational: "Kv KY",
    UniversityDiploma: "Högskola",
    Bachelor: "Kandidat",
    Master1Year: "Magister",
    Master2Year: "Master",
    Licentiate: "Licentiat",
    Doctorate: "Doktor",
    NoDegree: "Ingen Examen",
  },
  CompanyTalentRecuritmentStatusText: {
    Saved: "Sparad",
    Invited: "Inbjuden",
    Accepted: "Accepterat",
    Removed: "Borttagen",
    Declined: "Tackat nej",
    Other: "Okänd",
    Hired: "Anställd",
  },
  jobOffer: {
    yesButton: "Ja, de får kontakta mig",
    noButton: "Nej tack",
    backToTheTop: "Tillbaka till toppen",
  },
  laneTypes: {
    ReviewVirtaul: "Granskning (Obligatorisk)",
    Inbox: "Inkorg (Obligatorisk)",
    Hired: "Anställd (Obligatorisk)",
    Custom: "Annan typ",
    Test: "Testning",
    Interview: "Intervju",
    Review: "Granskning",
    Offers: "Erbjudanden",
    References: "Referenser",
  },
  workFromHome: {
    AllWeek: "Hela veckan",
    FourDays: "4 dagar i veckan",
    ThreeDays: "3 dagar i veckan",
    TwoDays: "2 dagar i veckan",
    OneDay: "1 dag i veckan",
    No: "Nej",
  },
  skillPrios: {
    1: "Inte viktigt",
    2: "Lite viktigt",
    3: "Mellan",
    4: "Mycket viktigt",
    5: "Ett måste!",
  },
  dateStrings: {
    yesterday: "Igår",
    tomorrow: "Imorgon",
    today: "Idag",
    lastWeekFormat: "i dddd[s]",
  },
  laneNames: {
    Inkorg: "Sourcade",
    Granskning: "Matchningar",
    Anställd: "Anställd",
  },
  numberOfYearsEducation: "{years} år ({points} hp)",
  IndependentCourses: "Fristående kurser",
  skillpicker: {
    preItemHint: "Sök eller välj bland dina rekommenderade kompetenser",
    addSkill: "Lägg till kompetens",
    searchToFindMoreSkills: "Sök för att hitta ytterliggare kompetenser",
  },
  responsibilities: {
    personel: "Personal",
    personelLong: "Personalansvar",
    personelDescription:
      "Ansvar för rekrytering, lönesättning, ledning, utvecklingssamtal m.m.",
    project: "Projekt",
    projectLong: "Projektansvar",
    projectDescription: "Ansvar för att leda projekt från start till slut.",
    sales: "Budget",
    salesLong: "Budgetansvar",
    salesDescription:
      "Ansvar för intäkter och/eller kostnader för t.ex. en avdelning, ett kontor, en marknad, en divion eller liknande.",
  },
  other: {
    internDescription:
      "Anställningen är av karaktären Praktik, Deltid, Uppsats, Sommarjobb eller liknande och räknas inte in i totala arbetslivserfarenheten.",
    notRegisteredWorkYearsDescription:
      "Ytterliggare kvalificerad heltidstjänstgöring:",
  },
  sourcing: {
    reqStrings: {
      tasks: "Arbetat med {taskName}",
      skills: "Arbetat med {skillName}",
      salary: "Löneanspråk ej överstigande {totalSalary} {currency} i månaden",
      variableCompensationRequirement:
        "varav åtminstone {variableCompensation} {currency} accepteras som rörlig andel",
      responsibilityStaff:
        "Personalansvar | Personalansvar | Personalansvar för mer än {minAmount} personer",
      responsibilityProject:
        "Projektansvar | Projektansvar | Projektansvar för mer än {minAmount} Mkr",
      responsibilityBudget:
        "Budgetansvar | Budgetansvar | Budgetansvar för mer än {minAmount} Mkr",
      language: "Kan hantera språket: {languageText}",
      branchNew:
        "Har erfarenhet i branschen {branchText} | Minst 1 års erfarenhet i branschen {branchText} | Minst {years} års erfarenhet i branschen {branchText}",
      yearOfExperienceReq:
        "Har minst ett års arbetserfarenhet | Har minst {yearsOfWorkExperience} års arbetserfarenhet",
      maxYearOfExperienceReq:
        "Har högst 1 års arbetserfarenhet | Har högst {yearsOfWorkExperience} års arbetserfarenhet",
      educationDomain: "Har studerat {domainText}",
      educationDomainMulti: "Har studerat något av: {domainsText}",
      educationLevel:
        "Har studerat minst ett år | Har studerat minst {years} år",
      school: "Läst på {schoolName}",
      schools: "Läst på {schoolNames}",
      schoolsMulti: "Läst på något av {schoolNames}",
    },
  },
  common: {
    year: "år | år",
    save: "Spara",
    changeLanguage: "Change language",
    poolCandidateNamePrefix: "Kandidat",
  },
  notificationTexts: {
    MessageFromTalent:
      "En kandidat har skrivit till er i jobbet {recruitmentTitle}",
    TalentInterested:
      "En kandidat har visat intresse för jobbet {recruitmentTitle}",
    TalentDeclined:
      "En kandidat har tyvärr tackat nej i jobbet {recruitmentTitle}",
    TalentAccepted:
      "En kandidat har accepterat kontaktförfrågan på jobbet {recruitmentTitle}",
    TagOnTalent:
      "Du har blivit taggad i en kommentar på kandidaten {talentName}",
    TagOnRecruitment:
      "Du har blivit taggad i en kommentar på jobbet {recruitmentTitle}",
    CommentOnSharedTalent:
      "En kollega har skrivit till er i jobbet {recruitmentTitle}",
    PoolMessageFromTalent:
      "Talangen {talentName} i er kandidatpool har skrivit ett meddelande till er",
    PoolTeamComment: "Du har blivit nämnd i en anteckning i kandidatpoolen!",
  },
  recruitmentStatsColumns: {
    "Intresserade, ej hanterade": "Nya matchningar",
    "Aktiva kandidater": "Aktiva kandidater",
    "Obesvarade möjligheter": "Obesvarade möjligheter",
  },
  completionListItemTexts: {
    EMPLOYMENTS: "Arbetslivserfarenhet",
    TASKS: "Arbetsuppgifter",
    SKILLS: "Kompetenser",
    EDUCATION: "Utbildning",
    LANGUAGES: "Språk",
  },
  "Kandidaten är nu sparad!": "Kandidaten är nu sparad!",
  "Kandidaten är nu inbjuden!": "Kandidaten är nu inbjuden!",
  Språk: "Språk",
  Anonym: "Anonym",
  $vuetify: {
    close: "Stäng",
    badge: "bange",
    rating: {
      ariaLabel: {
        icon: "Ikon",
      },
    },
    dataIterator: {
      pageText: "{0}-{1} av {2}",
      noResultsText: "Inga matchande artiklar kunde hittas",
      loadingText: "Laddar artiklar...",
    },
    dataTable: {
      itemsPerPageText: "Rader per sida:",
      ariaLabel: {
        sortDescending:
          ": Sorterat fallande. Aktivera för att ta bort sortering.",
        sortAscending:
          ": Sorterat stigande. Aktivera för att sortera stigande.",
        sortNone: ": Inte sorterad. Aktivera för att sortera stigande.",
      },
      sortBy: "Sortera på",
    },
    dataFooter: {
      itemsPerPageText: "Element per sida:",
      itemsPerPageAll: "Alla",
      nextPage: "Nästa sida",
      prevPage: "Föregående sida",
      firstPage: "Första sidan",
      lastPage: "Sista sidan",
    },
    datePicker: {
      itemsSelected: "{0} Valda",
      prevYearAriaLabel: "prev year",
      nextYearAriaLabel: "next year",
    },
    noDataText: "Ingen data tillgänglig",
    carousel: {
      prev: "Föregående visuell",
      next: "Nästa visuell",
      ariaLabel: {
        delimiter: "delimiter",
      },
    },
    calendar: {
      moreEvents: "{0} mer",
    },
  },
};
