import LocalStorageHelpers from "~/helpers/localStorageHelpers";
import { profileLookupsStore } from "~/store";

export default function() {
  const inviterId = LocalStorageHelpers.getInviterId();
  if (inviterId) {
    profileLookupsStore.loadInvitationData({
      inviterId,
      recruitmentId: null,
    });
  }
}
