import { Plugin } from "@nuxt/types";
import GritifyMsal from "~/plugins/gritifyMsal";

declare module "vue/types/vue" {
  interface Vue {
    $msal: GritifyMsal;
  }
}

declare module "@nuxt/types" {
  interface NuxtAppOptions {
    $msal: GritifyMsal;
  }
}

const rootUrl =
  process.env.ENVIRONMENT === "Production"
    ? "https://www.gritify.io"
    : process.env.ENVIRONMENT === "Stage"
    ? "https://stage.gritify.io"
    : process.env.ENVIRONMENT === "Test"
    ? "https://test.gritify.io"
    : "http://localhost:3000";

// const talentMsalConfig: Options = {
//   auth: {
//     clientId:
//       process.env.ENVIRONMENT === "Production"
//         ? "e5e90fbc-3995-48b4-96f6-4d16e5786346"
//         : process.env.ENVIRONMENT === "Stage"
//         ? "b926573d-febd-4078-9f64-a035efd60071"
//         : // : "b926573d-febd-4078-9f64-a035efd60071",
//           "13212618-c9b9-4f8d-ad66-cf79d4f0ad69",
//     tenantName:
//       process.env.ENVIRONMENT === "Production"
//         ? "gritifyauth.b2clogin.com/gritifyauth.onmicrosoft.com"
//         : process.env.ENVIRONMENT === "Stage"
//         ? "gritifytalentauthtest.b2clogin.com/gritifytalentauthtest.onmicrosoft.com"
//         : // : "gritifytalentauthtest.b2clogin.com/gritifytalentauthtest.onmicrosoft.com",
//           "gritifycompanyauthtest.b2clogin.com/gritifycompanyauthtest.onmicrosoft.com",
//     // tenantId: "B2C_1A_Gritify_SignInSignUp_Talent",
//     tenantId: "B2C_1A_signup_signin",
//     validateAuthority: false,

//     redirectUri: `${rootUrl}/adredirect`,
//     postLogoutRedirectUri: rootUrl,
//     onAuthentication: (/* error: any, response: any */) => {},
//     onToken: (/* error: any, response: any */) => {},
//     beforeSignOut() {},
//   },
//   request: {
//     scopes: [
//       process.env.ENVIRONMENT === "Production"
//         ? "https://gritifyauth.onmicrosoft.com/api/talent"
//         : process.env.ENVIRONMENT === "Stage"
//         ? "https://gritifytalentauthtest.onmicrosoft.com/api/talent"
//         : // : "https://gritifytalentauthtest.onmicrosoft.com/api/talent",
//           "https://gritifycompanyauthtest.onmicrosoft.com/api/company",
//     ],
//   },
//   cache: {
//     cacheLocation: "sessionStorage" as CacheLocation,
//     storeAuthStateInCookie: true,
//   },
// };
let msalInstance: GritifyMsal | null = null;

const knownAuthority =
  process.env.ENVIRONMENT === "Production"
    ? "gritifyauth.b2clogin.com"
    : process.env.ENVIRONMENT === "Stage"
    ? "gritifyauthstage.b2clogin.com"
    : "gritifytalentauthtest.b2clogin.com";

const myPlugin: Plugin = ({ app }, inject) => {
  try {
    msalInstance = new GritifyMsal(
      {
        auth: {
          clientId:
            process.env.ENVIRONMENT === "Production"
              ? "e5e90fbc-3995-48b4-96f6-4d16e5786346"
              : process.env.ENVIRONMENT === "Stage"
              ? "c42c793c-4b05-4e8a-80a1-ba8cb7a63090"
              : "b926573d-febd-4078-9f64-a035efd60071",
          authority:
            process.env.ENVIRONMENT === "Production"
              ? "https://gritifyauth.b2clogin.com/gritifyauth.onmicrosoft.com/B2C_1A_Gritify"
              : process.env.ENVIRONMENT === "Stage"
              ? "https://gritifyauthstage.b2clogin.com/gritifyauthstage.onmicrosoft.com/B2C_1A_Gritify"
              : "https://gritifytalentauthtest.b2clogin.com/gritifytalentauthtest.onmicrosoft.com/B2C_1A_Gritify",
          redirectUri: `${rootUrl}/adredirect`,
          postLogoutRedirectUri: `${rootUrl}/${app.i18n.locale}`,
          knownAuthorities: [knownAuthority],
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: true,
        },
      },
      {
        scopes: [
          process.env.ENVIRONMENT === "Production"
            ? "https://gritifyauth.onmicrosoft.com/api/gritify"
            : process.env.ENVIRONMENT === "Stage"
            ? "https://gritifyauthstage.onmicrosoft.com/api/gritify"
            : "https://gritifytalentauthtest.onmicrosoft.com/api/gritify",
        ],
        extraQueryParameters: {
          policy_type: "Login",
        },
      }
    );
  } catch (error) {}

  if (msalInstance) {
    msalInstance.loadAuthModule();
    inject("msal", msalInstance);
  }
};

export default myPlugin;
