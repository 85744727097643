import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { sourcingRobotStore, jobbOfferStore } from "~/store";
import SortHelper from "~/helpers/sortHelper";
import TokenHelpers from "~/helpers/tokenHelpers";
import {
  GetMatchingProfilesUnfilteredDto,
  GetRoleWithSkillsAndTasksDto,
  GetSortedTalentsDto,
  getTalentsFromRobotRequirementsTestDto,
  SourcingRobotDefinitionDto,
  SourcingRobotRequirementsObject,
  ThingToLoad,
} from "~/models/types";
import SourcingRobotService from "~/services/sourcingRobotService";
import { getRecListRobotStatuses } from "~/helpers/RobotHelpers";
import jobOfferService from "~/services/jobOfferService";

@Module({
  name: "sourcingRobot",
  stateFactory: true,
  namespaced: true,
})
export default class SourcingRobotStore extends VuexModule {
  // loadedTalents: Record<string, GetSortedTalentsDto & { index: number }> = {};

  currentDefinitionByRecruitmentId: Record<
    string,
    | SourcingRobotDefinitionDto
    | Promise<SourcingRobotDefinitionDto | "empty">
    | undefined
    | "empty"
  > = {};

  currentRequirementsByRecruitmentId: Record<
    string,
    | SourcingRobotRequirementsObject
    | Promise<SourcingRobotRequirementsObject | "empty">
    | undefined
    | "empty"
  > = {};

  testRequirementsByRecruitmentId: Record<
    string,
    | SourcingRobotRequirementsObject
    | Promise<SourcingRobotRequirementsObject | "empty">
    | undefined
    | "empty"
  > = {};

  testResultByRecruitmentId: Record<
    string,
    | getTalentsFromRobotRequirementsTestDto
    | Promise<getTalentsFromRobotRequirementsTestDto | "empty">
    | undefined
    | "empty"
  > = {};

  get testResultByRecruitmentIdMap(): Map<
    string,
    | getTalentsFromRobotRequirementsTestDto
    | Promise<getTalentsFromRobotRequirementsTestDto | "empty">
    | undefined
    | "empty"
  > {
    return new Map(Object.entries(this.testResultByRecruitmentId));
  }

  get currentRequirementsByRecruitmentIdMap(): Map<
    string,
    | SourcingRobotRequirementsObject
    | Promise<SourcingRobotRequirementsObject | "empty">
    | undefined
    | "empty"
  > {
    return new Map(Object.entries(this.currentRequirementsByRecruitmentId));
  }

  get testRequirementsByRecruitmentIdMap(): Map<
    string,
    | SourcingRobotRequirementsObject
    | Promise<SourcingRobotRequirementsObject | "empty">
    | undefined
    | "empty"
  > {
    return new Map(Object.entries(this.testRequirementsByRecruitmentId));
  }

  get currentDefinitionByRecruitmentIdMap(): Map<
    string,
    | SourcingRobotDefinitionDto
    | Promise<SourcingRobotDefinitionDto | "empty">
    | undefined
    | "empty"
  > {
    return new Map(Object.entries(this.currentDefinitionByRecruitmentId));
  }

  showAiPresentationOnTalent: boolean = false;

  @Mutation
  SET_SHOW_AI_PRESENTATION_ON_TALENT(v: boolean) {
    this.showAiPresentationOnTalent = v;
  }

  showAiSuggestionsInVisual: boolean = true;

  @Mutation
  SET_SHOW_AI_SUGGESTIONS_IN_VISUAL(v: boolean) {
    this.showAiSuggestionsInVisual = v;
  }

  showOpennessScore: boolean = true;

  @Mutation
  SET_SHOW_OPENNESS_SCORE(v: boolean) {
    this.showOpennessScore = v;
  }

  showAiChatMessageHelp: boolean = false;

  @Mutation
  SET_SHOW_AI_CHAT_MESSAGE_HELP(v: boolean) {
    this.showAiChatMessageHelp = v;
  }

  showMoreTalentsTipInStepper: boolean = false;

  @Mutation
  SET_SHOW_MORE_TALENTS_TIP_IN_STEPPER(v: boolean) {
    this.showMoreTalentsTipInStepper = v;
  }

  @Mutation
  SET_DEFINITION(v: {
    recruitmentId: string;
    definition: SourcingRobotDefinitionDto | "empty";
  }) {
    this.currentDefinitionByRecruitmentId = {
      ...this.currentDefinitionByRecruitmentId,
      [v.recruitmentId]: v.definition ?? undefined,
    };
  }

  @Mutation
  SET_REQUIREMENTS(v: {
    recruitmentId: string;
    definition: SourcingRobotRequirementsObject | "empty";
  }) {
    this.currentRequirementsByRecruitmentId = {
      ...this.currentRequirementsByRecruitmentId,
      [v.recruitmentId]: v.definition ?? undefined,
    };
  }

  @Mutation
  SET_TEST_REQUIREMENTS(v: {
    recruitmentId: string;
    definition: SourcingRobotRequirementsObject | "empty";
  }) {
    this.testRequirementsByRecruitmentId = {
      ...this.testRequirementsByRecruitmentId,
      [v.recruitmentId]: v.definition ?? undefined,
    };
  }

  @Mutation
  SET_TEST_RESULT(v: {
    recruitmentId: string;
    result: getTalentsFromRobotRequirementsTestDto | "empty";
  }) {
    this.testResultByRecruitmentId = {
      ...this.testResultByRecruitmentId,
      [v.recruitmentId]: v.result ?? undefined,
    };
  }

  @Mutation
  SET_DEFINITION_LOADING(v: {
    recruitmentId: string;
    promise: Promise<SourcingRobotDefinitionDto | "empty">;
  }) {
    this.currentDefinitionByRecruitmentId = {
      ...this.currentDefinitionByRecruitmentId,
      [v.recruitmentId]: v.promise,
    };
  }

  @Mutation
  SET_REQUIREMENTS_LOADING(v: {
    recruitmentId: string;
    promise: Promise<SourcingRobotRequirementsObject | "empty">;
  }) {
    this.currentRequirementsByRecruitmentId = {
      ...this.currentRequirementsByRecruitmentId,
      [v.recruitmentId]: v.promise,
    };
  }

  get loadedTalents(): Record<string, GetSortedTalentsDto & { index: number }> {
    let result: Record<string, GetSortedTalentsDto & { index: number }> = {};

    Object.keys(this.loadedTalentsByRecruitmentId).forEach(recId => {
      result = {
        ...result,
        ...SortHelper.sortItemsBy(
          this.loadedTalentsByRecruitmentId[recId] ?? [],
          [
            {
              sortBy: x => x.totalScore,
              desc: true,
            },
          ]
        ).reduce(
          (
            acc: Record<string, GetSortedTalentsDto & { index: number }>,
            t,
            i
          ) => {
            acc[recId + t.talentId] = { ...t, index: i + 1 };

            return acc;
          },
          {}
        ),
      };
    });

    return result;
  }

  loadedTalentsByRecruitmentId: Record<string, GetSortedTalentsDto[]> = {};

  @Mutation
  ADD_LOADED_TALENT(v: { talent: GetSortedTalentsDto; recruitmentId: string }) {
    this.loadedTalentsByRecruitmentId = {
      ...this.loadedTalentsByRecruitmentId,
      [v.recruitmentId]: [
        ...(this.loadedTalentsByRecruitmentId[v.recruitmentId] ?? []),
        v.talent,
      ],
    };
  }

  @Mutation
  SET_LOADED_TALENTS(v: {
    talents: GetSortedTalentsDto[];
    recruitmentId: string;
  }) {
    this.loadedTalentsByRecruitmentId = {
      ...this.loadedTalentsByRecruitmentId,
      [v.recruitmentId]: v.talents,
    };
  }

  rolesWithSkillsAndTasksByToleId: Record<
    string,
    ThingToLoad<GetRoleWithSkillsAndTasksDto>
  > = {};

  @Mutation
  SET_ROLES_WITH_SKILLS_AND_TASKS_BY_TOLE_ID(v: {
    roleId: string;
    value: ThingToLoad<GetRoleWithSkillsAndTasksDto>;
  }) {
    this.rolesWithSkillsAndTasksByToleId = {
      ...this.rolesWithSkillsAndTasksByToleId,
      [v.roleId]: v.value,
    };
  }

  @Action
  async loadRoleWithSkillsAndTasks(v: { roleId: string }) {
    const thing = this.rolesWithSkillsAndTasksByToleId[v.roleId] ?? {
      type: "notFetched",
    };

    const resultFromLocal = TokenHelpers.getThingToLoadOrExit(thing, false);

    if (resultFromLocal.type === "exit") {
      return;
    }

    sourcingRobotStore.SET_ROLES_WITH_SKILLS_AND_TASKS_BY_TOLE_ID({
      roleId: v.roleId,
      value: resultFromLocal,
    });

    const token = await TokenHelpers.getToken();

    const loadedResult = await jobOfferService.getRoleWithSkillsAndTasks({
      accessToken: token,
      roleId: v.roleId,
    });

    sourcingRobotStore.SET_ROLES_WITH_SKILLS_AND_TASKS_BY_TOLE_ID({
      roleId: v.roleId,
      value: {
        type: "loaded",
        value: loadedResult,
      },
    });
  }

  @Action
  async loadRobotDefinition(v: { recruitmentId: string }) {
    console.log(this.currentDefinitionByRecruitmentId[v.recruitmentId]);
    if (this.currentDefinitionByRecruitmentId[v.recruitmentId]) {
      return;
    }

    const promise = TokenHelpers.getToken().then(token => {
      return SourcingRobotService.GetSourcingRobotDefinition({
        accessToken: token,
        recruitmentId: v.recruitmentId,
      }).then(x => {
        return x ?? "empty";
      });
    });

    sourcingRobotStore.SET_DEFINITION_LOADING({
      recruitmentId: v.recruitmentId,
      promise,
    });

    const definition = await promise.then(x => x ?? "empty");

    sourcingRobotStore.SET_DEFINITION({
      recruitmentId: v.recruitmentId,
      definition,
    });
  }

  unfilteredTalentsByRecruitmentId: Record<
    string,
    GetMatchingProfilesUnfilteredDto[]
  > = {};

  @Mutation
  SET_UNFILTERED_TALENTS(v: {
    recId: string;
    talents: GetMatchingProfilesUnfilteredDto[];
  }) {
    this.unfilteredTalentsByRecruitmentId = {
      ...this.unfilteredTalentsByRecruitmentId,
      [v.recId]: v.talents,
    };
  }

  @Action
  async loadUnfilteredTalents(v: { recruitmentId: string }) {
    const existing = this.unfilteredTalentsByRecruitmentId[v.recruitmentId];

    if (!existing) {
      const token = await TokenHelpers.getToken();
      const result = await SourcingRobotService.getMatchingProfilesUnfiltered({
        accessToken: token,
        recruitmentId: v.recruitmentId,
      });

      sourcingRobotStore.SET_UNFILTERED_TALENTS({
        recId: v.recruitmentId,
        talents: result,
      });
    }
  }

  @Action
  async loadRobotRequirements(v: { recruitmentId: string }) {
    if (this.currentRequirementsByRecruitmentIdMap.has(v.recruitmentId)) {
      return;
    }

    const promise = TokenHelpers.getToken().then(token => {
      return SourcingRobotService.getSourcingRobotRequirements({
        accessToken: token,
        recruitmentId: v.recruitmentId,
      }).then(x => {
        return x ?? "empty";
      });
    });

    sourcingRobotStore.SET_REQUIREMENTS_LOADING({
      recruitmentId: v.recruitmentId,
      promise,
    });

    const definition = await promise.then(x => x ?? "empty");

    sourcingRobotStore.SET_REQUIREMENTS({
      recruitmentId: v.recruitmentId,
      definition,
    });
  }

  @Action
  async upsertRobotRequirements(v: {
    sourcingRobotRequirementsObject: SourcingRobotRequirementsObject;
  }) {
    const token = await TokenHelpers.getToken();
    await SourcingRobotService.upsertSourcingRobotRequirements({
      accessToken: token,
      sourcingRobotRequirementsObject: v.sourcingRobotRequirementsObject,
    });

    jobbOfferStore.SET_RECRUITMENT_LIST(
      jobbOfferStore.recruitmentList.map(r => {
        if (r.id === v.sourcingRobotRequirementsObject.recruitmentId) {
          return {
            ...r,
            ...getRecListRobotStatuses(v.sourcingRobotRequirementsObject),
          };
        }
        return r;
      })
    );

    sourcingRobotStore.SET_REQUIREMENTS({
      definition: v.sourcingRobotRequirementsObject,
      recruitmentId: v.sourcingRobotRequirementsObject.recruitmentId,
    });
  }

  @Action
  async loadTalentForTestRequirements(v: {
    sourcingRobotRequirementsObject: SourcingRobotRequirementsObject;
  }) {
    const token = await TokenHelpers.getToken();
    const result = await SourcingRobotService.getTalentsFromRobotRequirementsTest(
      {
        accessToken: token,
        sourcingRobotRequirementsObject: v.sourcingRobotRequirementsObject,
      }
    );

    sourcingRobotStore.SET_TEST_RESULT({
      recruitmentId: v.sourcingRobotRequirementsObject.recruitmentId,
      result,
    });

    sourcingRobotStore.SET_TEST_REQUIREMENTS({
      definition: v.sourcingRobotRequirementsObject,
      recruitmentId: v.sourcingRobotRequirementsObject.recruitmentId,
    });
  }

  @Action
  async createRobotDefinition(v: {
    recruitmentId: string;
    sourcingRobotDefinitionDto: SourcingRobotDefinitionDto;
  }) {
    const token = await TokenHelpers.getToken();
    await SourcingRobotService.createSourcingRobotDefinition({
      accessToken: token,
      recruitmentId: v.recruitmentId,
      sourcingRobotDefinitionDto: v.sourcingRobotDefinitionDto,
    });

    jobbOfferStore.SET_RECRUITMENT_LIST(
      jobbOfferStore.recruitmentList.map(r => {
        if (r.id === v.recruitmentId) {
          return {
            ...r,
            oldRobotIsActive: !v.sourcingRobotDefinitionDto.isStopped,
          };
        }
        return r;
      })
    );

    sourcingRobotStore.SET_DEFINITION({
      definition: v.sourcingRobotDefinitionDto,
      recruitmentId: v.recruitmentId,
    });
  }

  @Action
  async updatePausedOnRobot(v: {
    recruitmentId: string;
    sourcingRobotDefinitionDto: SourcingRobotDefinitionDto;
    isPaused: boolean;
  }) {
    const token = await TokenHelpers.getToken();
    await SourcingRobotService.setPausedOnRobotDefinition({
      accessToken: token,
      recruitmentId: v.recruitmentId,
      paused: v.isPaused,
    });

    jobbOfferStore.SET_RECRUITMENT_LIST(
      jobbOfferStore.recruitmentList.map(r => {
        if (r.id === v.recruitmentId) {
          return {
            ...r,
            oldRobotIsActive: !v.isPaused,
          };
        }
        return r;
      })
    );

    sourcingRobotStore.SET_DEFINITION({
      definition: {
        ...v.sourcingRobotDefinitionDto,
        isStopped: v.isPaused,
      },
      recruitmentId: v.recruitmentId,
    });
  }
}
