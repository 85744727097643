// import uuidv1 from "uuid/v1";
import axios from "axios";
import { gritifyApiUrl } from "./ServiceHelper";
import {
  SavedManualSourcing,
  SavedManualSourcingFromServer,
} from "~/models/types";

export default class ManualSourcingService {
  public static getSavedManualSourcings(v: {
    accessToken: string;
    recruitmentId: string;
  }): Promise<SavedManualSourcing[]> {
    return axios
      .get<SavedManualSourcingFromServer[]>(
        `${gritifyApiUrl}/GetSavedManualSourcings?recruitmentId=${v.recruitmentId}`,
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x =>
        x.data.map(s => {
          const result: SavedManualSourcing = {
            ...s,
            changedDate: new Date(s.changedDate),
            openedDate: new Date(s.openedDate),
            createdDate: new Date(s.createdDate),
          };

          return result;
        })
      );
  }

  public static upsertSavedManualSourcing(v: {
    accessToken: string;
    savedManualSourcing: SavedManualSourcing;
  }): Promise<string> {
    return axios
      .post<string>(
        `${gritifyApiUrl}/UpsertSavedManualSourcing`,
        v.savedManualSourcing,
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static setSourcingOpened(v: {
    accessToken: string;
    recruitmentId: string;
    sourcingId: string;
  }): Promise<string> {
    return axios
      .post<string>(
        `${gritifyApiUrl}/SetSourcingOpened`,
        {
          recruitmentId: v.recruitmentId,
          sourcingId: v.sourcingId,
        },
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }
}
