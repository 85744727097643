// import uuidv1 from "uuid/v1";
import axios from "axios";
import { gritifyApiUrl } from "./ServiceHelper";

export default class RecruitmentTalentService {
  public static updateStatusTextOnRecTalent(v: {
    talentId: string;
    recruitmentId: string;
    statusText: string | null;
    accessToken: string;
  }): Promise<void> {
    return axios
      .put<void>(
        `${gritifyApiUrl}/UpdateStatusTextOnRecTalent`,
        {
          talentId: v.talentId,
          recruitmentId: v.recruitmentId,
          statusText: v.statusText,
        },
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static setRecTalentHandled(v: {
    talentId: string;
    recruitmentId: string;
    accessToken: string;
  }): Promise<void> {
    return axios
      .put<void>(
        `${gritifyApiUrl}/SetRecTalentHandled`,
        {
          talentId: v.talentId,
          recruitmentId: v.recruitmentId,
        },
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }
}
