

































import { Vue, Component } from "vue-property-decorator";

@Component
export default class IsOnIEModal extends Vue {
  dialog = false;
  resolve: (() => void) | null = null;

  open(): Promise<void> {
    this.dialog = true;

    return new Promise<void>(resolve => {
      this.resolve = resolve;
    });
  }

  save() {
    if (this.resolve) {
      this.resolve();
    }
    this.dialog = false;
  }

  cancel() {
    if (this.resolve) {
      this.resolve();
    }
    this.dialog = false;
  }
}
