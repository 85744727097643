window.$crisp = [];

const websiteId =
  process.env.ENVIRONMENT === "Production"
    ? "6bc6ab66-b06f-4258-982d-f9dc80776aa9"
    : "76405bb0-6329-4a3d-a61d-7f056960f27e";

window.CRISP_WEBSITE_ID = websiteId;
(function() {
  const d = document;
  const s = d.createElement("script");
  s.src = "https://client.crisp.chat/l.js";
  s.async = 1;
  d.getElementsByTagName("head")[0].appendChild(s);
})();
