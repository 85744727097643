var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.error.statusCode === 666)?_c('div',[_c('v-overlay',{attrs:{"color":"white"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"64"}})],1)],1):(_vm.error.statusCode === 404)?_c('v-container',{staticClass:"text-center"},[_c('div',{staticClass:"my-12"},[_c('GrHeadline',{attrs:{"type":"mega"}},[_vm._v("404")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('GrHeadline',{attrs:{"type":"large"}},[_vm._v("\n      "+_vm._s(_vm.$t("Denna sida finns inte tyvärr"))+"\n    ")])],1),_vm._v(" "),_c('br'),_vm._v(" "),_c('v-btn',{attrs:{"text":"","to":_vm.localePath({
        name: 'index',
      })}},[_vm._v("\n    "+_vm._s(_vm.$t("Gå till startsidan"))+"\n  ")])],1):(_vm.error.statusCode === 667)?_c('v-container',{staticClass:"text-center"},[_c('div',{staticClass:"my-12"},[_c('GrHeadline',{attrs:{"type":"mega"}},[_vm._v(_vm._s(_vm.$t("Cookies krävs")))]),_vm._v(" "),_c('br'),_vm._v(" "),_c('v-icon',[_vm._v("mdi-cookie")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('GrTextBlock',{attrs:{"type":"large"}},[_vm._v("\n      "+_vm._s(_vm.$t(
          "Cookies tillåts inte i din webbläsare. Aktivera cookies i webbläsarens inställningar för att fortsätta."
        ))+"\n    ")])],1),_vm._v(" "),_c('br'),_vm._v(" "),_c('v-btn',{attrs:{"text":"","to":_vm.localePath({
        name: 'index',
      })}},[_vm._v("\n    "+_vm._s(_vm.$t("Gå till startsidan"))+"\n  ")])],1):_c('v-container',{staticClass:"text-center"},[_c('div',{staticClass:"my-12"},[_c('GrHeadline',{staticClass:"primary--text",attrs:{"type":"mega"}},[_vm._v("=(")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('GrHeadline',{staticClass:"error--text",attrs:{"type":"medium"}},[_vm._v("\n      "+_vm._s(_vm.$t("Oops! Nånting gick fel när sidan skulle laddas"))+"\n      "),_c('br'),_vm._v("\n      "+_vm._s(_vm.$t("Försök igen senare!"))+"\n    ")])],1),_vm._v(" "),_c('br'),_vm._v(" "),_c('v-btn',{attrs:{"text":"","to":_vm.localePath({
        name: 'index',
      })}},[_vm._v("\n    "+_vm._s(_vm.$t("Gå till startsidan"))+"\n  ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }